import React, {useState} from 'react';
import e3Style from "../../../css/e3-style.css";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../util/statusMsg";
import axios from "axios";
import Joi from "joi";
import { useValidator } from "react-joi";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../store/bodyView";
import {loginPath} from "../layout/body";
import {getNonce, getAns, getId} from '../../store/pswdNonce';

const chgPassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [pswd2Match, setPswd2Match] = useState("");
	const [showPage, setShowPage] = useState(true);
	const nonceObj = useSelector(getNonce);
	const ansObj = useSelector(getAns);
	const userIdObj = useSelector(getId);
	
	const confPswdValidation = (value, helpers) => {
		if(value !== pswd2Match)
			return helpers.error('any.invalid');
		return value;
	};	


	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			password: "",
			confirmPswd: ""
		},
		schema: Joi.object({
		password: Joi.string().trim().min(10).max(64).regex(/^(?=.*[0-9])(?=.*[a-zA-Z]).*$/).required().messages({
				'string.empty':'flows.registration.reginfo.37',
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'string.pattern.base': 'flows.registration.reginfo.56'
			}),
		confirmPswd: Joi.string().custom(confPswdValidation, 'confirm password validation').messages({
				'string.empty':'flows.registration.reginfo.40',
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'any.invalid': 'flows.registration.reginfo.50'
			})
		}),
		explicitCheck: {
			password: false,
			confirmPswd: false
		}
	});
	
	const passwordChg = async () => {
		try {
			const response = await axios.post('/nologin/api',
			{	command: {name: 'verifyPswd'},
								params: {usernm: userIdObj.trim(),
								         secans: ansObj.trim(),
										 password: state.$data.password.trim(),
										 nonce: nonceObj	}
							}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setShowPage(false);
						 	break;
						case 422:
							setStatusMessage(dispatch, 'page.verifySecAnsr.7', STATUS_ERROR);
							break;
						case 999:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const onSubmit = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, loginPath);
	};
	
	const updateField = e => {
		const {name, value} = e.target;
		setData((old) => ({
				...old,
				[name]: value
		}));
	};
	
	const validateField = e => {
		const {name,value} = e.target;
		setExplicitField(name, true);
		if( name === 'password')
			setPswd2Match(value);
	}
	
	const updateInfo = e => {
		e.preventDefault();
		validate();
		if( state.$all_source_errors.length === 0 )
			passwordChg();
	};
	
	
	return (
	<>
		<StatusMsg/>
		<div className={`${e3Style['row']}`}>
		{showPage ?
		<>
	      <div className={`${e3Style['col-sm-24']} ${e3Style['col-md-10']} ${e3Style['col-lg-14']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
	
	        <h1>{t('page.chgPasswd.1')}</h1>
	        <p>{t('page.chgPasswd.2')}</p>
	      </div>
	      <div className={`${e3Style['col-sm-24']} ${e3Style['col-md-14']} ${e3Style['col-lg-10']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
  		<h3 className={`${e3Style['mb-20']} ${e3Style['row--gutter-gapless']}`}>{t('page.chgPasswd.5')}</h3>
		<form noValidate autoComplete="off" className={e3Style['account-preferences_notes']}>
		  <div className={e3Style.row}>
			      <div className={`${e3Style['col-xs-24']} ${e3Style['col-md-25']}`}>
 					<label  className={`${e3Style['text-uppercase']} ${e3Style['txt-io']} ${(state.$explict_fields.password && state.$source_errors.password.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="password">{t('page.chgPasswd.3')}</label>
					<input autoFocus  type="password" id="password" name="password" value={state.$data.password}
					 className={`${e3Style['input--fluid']} ${e3Style['txt-io']} ${(state.$explict_fields.password && state.$source_errors.password.length > 0) ? e3Style['sprint-form-error'] : ''}`}  
				 onChange={e => updateField(e)} onBlur={e => validateField(e)}/>
				{(state.$explict_fields.password && state.$source_errors.password.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.password.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				 }
			     </div>
		  </div>
		  <div className={e3Style.row}>
			      <div className={`${e3Style['col-xs-24']} ${e3Style['col-md-25']}`}>
 					<label className={`${e3Style['text-uppercase']} ${e3Style['txt-io']} ${(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="confirmPswd">{t('page.chgPasswd.4')}</label>
						<input type="password" id="confirmPswd" name="confirmPswd"  value={state.$data.confirmPswd}
					className={`${e3Style['input--fluid']} ${e3Style['txt-io']} ${(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ? e3Style['sprint-form-error'] : ''}`}
				   onChange={e => updateField(e)} onBlur={() => setExplicitField('confirmPswd', true)}/>
					 {(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ?
							<div className={e3Style['sprint-alert--error']}>
								<div className={e3Style['alert--error_message']}>
									{state.$source_errors.confirmPswd.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
								</div>
							</div>
							: ''
					}
			    </div>
		  </div>
		<div className={`${e3Style['row']}`}>
			<div className={`${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		  		<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
			onClick={updateInfo} disabled={state.$all_source_errors.length}>{t('button.submit')}</button>
		  	</div>
		</div>
		</form>
		</div>
		</> :
		<>
		<div className={`${e3Style['col-sm-24']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
		<h2>{t('page.chgPwdSucc.1')}</h2>
		<div className={`${e3Style['row']}`}>
			<div className={`${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		  		<button type="submit" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
			onClick={onSubmit} >{t('button.gotoLogin')}</button>
		  	</div>
		</div>
		</div>
		</>
		}
	</div>
		</>
	);
};

export default chgPassword;