import React from 'react';
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

export const STATUS_NONE = 1;
export const STATUS_ERROR = 2;
export const STATUS_NOTIFICATION = 3;

const slice = createSlice({
	name: "bodyView",
	initialState: {
		autoFocusFunc: null,
		statusMsg: null,
		statusDisplay: STATUS_NONE
	},
	reducers: {
		setAFFunc: (view, action) => {
			view.autoFocusFunc = action.payload.preset.autoFocusFunc;
		},
		setStatusMsg: (view, action) => {
			const disp = action.payload.preset.statusDisplay;
			if (disp !== STATUS_NONE) {
				view.statusMsg = action.payload.preset.statusMsg;
				view.statusDisplay = disp;
			} else {
				view.statusMsg = '';
				view.statusDisplay = STATUS_NONE;
			}
		}
	}
});

export const { setAFFunc, setStatusMsg } = slice.actions;
export default slice.reducer;

export const setAutoFocusFunc = (dispatch, afFunc) => {
	dispatch({type: setAFFunc.type, payload: {preset: {autoFocusFunc: afFunc}}});
};

export const setStatusMessage = (dispatch, msg, stat) => {
	dispatch({type: setStatusMsg.type, payload: {preset: {statusMsg: msg, statusDisplay: stat}}});
};

export const setHistoryPath = (dispatch, push, path, error) => {
	if (error) dispatch({type: setStatusMsg.type, payload: {preset: {statusMsg: error, statusDisplay: STATUS_ERROR}}});
	else dispatch({type: setStatusMsg.type, payload: {preset: {statusMsg: '', statusDisplay: STATUS_NONE}}});
	push(path);
};

export const getBodyViewSelector = createSelector(
	state => state.entities.bodyView,
	bodyView => bodyView
);

export const getStatusMsgSelector = createSelector(
	state => state.entities.bodyView.statusMsg,
	state => state.entities.bodyView.statusDisplay,
	(statusMsg, statusDisplay) => {
		return {statusMsg: statusMsg, statusDisplay:statusDisplay}
	}
);

export const doAutoFocus =
		bodyView => bodyView.autoFocusFunc && bodyView.autoFocusFunc();
