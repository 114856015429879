import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setHistoryPath} from "../../../store/bodyView";
import {accountListPath} from "../../layout/body";
import axios from "axios";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const ConfirmDeleteNumber = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const doDelete = async e => {
		e.preventDefault();
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{
					command: {name: 'deactivatePhoneNumber', csrfSessionNonce},
					params: {
						locale: i18n.language
					}
				}, {timeout: 5000});
			if (response.status === 200 && response.data.status.code === 200) {
				setHistoryPath(dispatch, navigate, accountListPath);
			} else {
				setHistoryPath(dispatch, navigate, accountListPath, 'generic.rgp.error');
			}
		} catch (error) {
			setHistoryPath(dispatch, navigate, accountListPath, 'generic.rgp.error');
		}
	};

	const cancelDelete = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, accountListPath);
	};

	return (
		<>
			<p>{t('flows.account.confirmdeletenumber.5')}</p>

			<div className={e3Style.row}>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
					<button type="button" onClick={cancelDelete}
					        className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
						{t('button.cancel')}
					</button>
					<button type="button" autoFocus onClick={doDelete}
					        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
						{t('button.deletenow')}
					</button>
				</div>
			</div>
		</>
	);
};

export default ConfirmDeleteNumber;
