import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import Joi from "joi";
import { useValidator } from "react-joi";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import StatusMsg from "../../util/statusMsg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";

import {createNote} from "../../../store/notesList";
import {notesPath, loginPath} from "../../layout/body";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedTextarea from "../../util/focusWrappedTextarea";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const addNote = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {newId} = useParams();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			note: ""
		},
 	schema: Joi.object({
			note: Joi.string().trim().min(1).max(80).required().messages({
				'string.empty':'page.popup.plsentrcnt',
				'string.max':'flows.notes.validation.1'
			})
		}),
		explicitCheck: {
			note: false
		}
	});

	const saveNote = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'addNote', csrfSessionNonce},
				 params: {
							orderId: newId,
							note: state.$data.note											
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				
				switch (data.status.code) {
					case 200:
					createNote(dispatch, {
							ORDERID: newId,
							NOTES: state.$data.note
					});
					setHistoryPath(dispatch, navigate, notesPath);
					break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const submitNote = e => {
		e.preventDefault();
		saveNote();
	};

	return(
		<>
		<StatusMsg/>
		<form noValidate autoComplete="off" onSubmit={submitNote}>		
			<fieldset className={e3Style['sprint-account_form_items']}>
				<div className={e3Style.row}>
					<FocusWrappedGrp baseAriaId="noteErr" cptErrCount={state.$errors.note.length}>
						<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="note">
							{t('flows.notes.notes.3') + ' '}</FocusWrappedLabel>
						<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
							<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-note']}`}>
								<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['note-input-wrapper']}`}>
									<FocusWrappedTextarea rows="2" cols="110" initialFocus id="note" name="note"
									                      onChange={e => updateField(e, 'note')}
									                      onBlur={() => setExplicitField('note', true)}
									                      className={e3Style['input--fluid']}/>
								</div>
							</div>
						</div>
						{!!state.$errors.note.length &&
							<div className={e3Style['sprint-alert--error']}>
								<div className={e3Style['alert--error_message']}>
									{state.$errors.note.map((err, idx) => <p index={idx} id={'noteErr-' + idx}><span>{t(err.$message)}</span></p>)}
								</div>
							</div>
						}
					</FocusWrappedGrp>
				</div>
			</fieldset>
			<div className={e3Style['sprint-alert--error']}>
				<div className={e3Style['alert--error_message']}>
					<p>{t('flows.notes.notes.4')}</p>
				</div>
			</div>
			<div className={e3Style.row}>
			    <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
					<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, notesPath)}}
						className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.cancel')} </button>

					<button type="submit" onClick={validate} disabled={state.$auto_invalid}
		             className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.submit')} </button>
				</div>
		    </div>
		</form>
		</>
	);
};

export default addNote;
