import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import e3Style from '../../../css/e3-style.css';
import sprStyle from '../../../css/sprint-style.css';
import {directLoginUser, loginUser} from '../../store/user';
import LoginNonce from './loginNonce';
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router';
import {setHistoryPath, setStatusMsg, STATUS_ERROR} from '../../store/bodyView';
import StatusMsg from "../util/statusMsg";
import {loadDisplaySettings} from "../../store/display";
import {emailNotVerifiedPath, registrationPath, requestUsernamePath} from "../layout/body";
import FocusWrappedGrp from "../util/focusWrappedGrp";
import FocusWrappedInput from "../util/focusWrappedInput";
import FocusWrappedLabel from "../util/focusWrappedLabel";
import {setRegUsrPwd} from "../../store/registry";
import {openForgotUserName} from "../../store/popupMgr";
import {updateActTime} from "../../store/activity";
import axios from "axios";

const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState({username: '', password: '', autologin: false, nonce: ''});

	const gotoLink = path => e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, path);
	};
	
	const setNonce = nonce => {
		setState({...state, nonce});
	};

	const handleChange = ({currentTarget: ct}) => {
		setState({...state, [ct.name]: ct.value});
	};

	const handleChecked = ({currentTarget: ct}) => {
		setState({...state, [ct.name]: !state[ct.name]});
	};

	const showForgotUserName = e => {
		openForgotUserName(dispatch);
	};

	const doLogin = e => {
		e.preventDefault();
		updateActTime(dispatch);
		loginUser(dispatch, state.username, state.password, state.autologin, state.nonce,
			(dispState, {response}) => loadDisplaySettings(dispatch, response.response.csrfSessionNonce, () => success => setHistoryPath(dispatch, navigate, "/")),
			(dispatchObj, respObj) => {
				setNonce('');
				if (respObj.status === 301) {
					setRegUsrPwd(dispatch, state.username, state.password);
					setHistoryPath(dispatch, navigate, emailNotVerifiedPath);
				} else {
					dispatch({type: setStatusMsg.type, payload: {preset: {statusMsg: 'login.error', statusDisplay: STATUS_ERROR}}});
				}
		});
	};

	const checkLogin = async () => {
		try {
			const response = await axios.post('/nologin/api', {command: {name: 'checkLogin'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200 && data.response.loggedIn) {
					const {userId, csrfSessionNonce} = data.response;
					directLoginUser(dispatch, userId, csrfSessionNonce);
					loadDisplaySettings(dispatch, csrfSessionNonce, () => success => setHistoryPath(dispatch, navigate, "/"));
				}
			}
		} catch (error) {}
	};

	useEffect(() => {
		checkLogin();
	}, []);

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style['container-xl']}>
				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-sm-24']} ${e3Style['col-md-10']} ${e3Style['col-lg-14']} ${e3Style['pt-60']} ${e3Style['pb-60']} ${e3Style['login-left']}`}>
						<h1 className={e3Style['pr-40']}>
							{t('page.login.1')} <span>{t('page.login.1a')}</span>
						</h1>
						<p className={e3Style['pr-40']}>
							{t('page.login.2')}
						</p>
						<p className={e3Style['pr-40']}><a href="#" onClick={gotoLink(registrationPath)}
						                        title={t('page.login.3b')} className={e3Style['text-underline']}>
							{t('page.login.3a')}
						</a> {t('page.login.3c')}
						</p>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-sm-24']} ${e3Style['col-md-14']} ${e3Style['col-lg-10']} ${e3Style['pt-60']} ${e3Style['pb-60']} ${e3Style['sprint-cta-sign-in']}`}>
						<h2>{t('page.login.4')}</h2>

						<StatusMsg/>

						<form onSubmit={doLogin} name='loginForm' role="form"
						      aria-label="form" noValidate autoComplete="off">
							<LoginNonce nonce={state.nonce} setNonce={setNonce} cmdName='getLoginNonce'/>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="username">{t('page.login.5')}</FocusWrappedLabel>
								<FocusWrappedInput initialFocus value={state.username} onChange={handleChange} type="text"
								                   name="username" id="username" className={e3Style['input--fluid']}/>
							</FocusWrappedGrp>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="password">{t('page.login.6')}</FocusWrappedLabel>
								<FocusWrappedInput value={state.password} onChange={handleChange} type="password"
								                   name="password" id="password" className={e3Style['input--fluid']}/>
							</FocusWrappedGrp>
							<input checked={state.autologin} onChange={handleChecked} type="checkbox" name='autologin' id="stay-signed-in"/>
							<label htmlFor="stay-signed-in">
								{t('page.login.7a')} <a href="#" className={sprStyle.tooltip} aria-describedby="details-tooltip"
								   ng-click="preventAction($event)">
									{t('page.login.7b')}
									<span role="tooltip" id="details-tooltip" className={sprStyle.tooltiptext}>{t('page.login.7c')}</span></a></label>
							<br/>

							<a href="#" onClick={showForgotUserName} title={t('page.login.17')}>
								{t('page.login.8')}
							</a> &nbsp;|&nbsp; <a href="#" onClick={gotoLink(requestUsernamePath)} title={t('page.login.18')}>
								{t('page.login.9')}
							</a>

							<button type="submit" id="loginButton"
							        className={`${e3Style['button']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['mt-20']}`}>
								{t('button.signin')}
							</button>
						</form>
					</div>
				</div>

				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<p>{t('page.login.10')}</p>
				</div>

				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-auto']} ${e3Style['display-flex']} ${e3Style.cta}`}>
						<img src="/resources/assets/images/427x318-banner-IP_Relay_left.jpg" alt={t('page.login.11')}/>
						<a href="https://www.tmobileaccess.com/services/iprelayinfo"
						   className={e3Style['sprint-ctas__block']} title={t('page.login.11hdr')}>
							<h3>{t('page.login.11hdr')}</h3>
							<div className={e3Style['sprint-ctas__label']}>{t('page.login.12')}</div>
						</a>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-auto']} ${e3Style['display-flex']} ${e3Style.cta}`}>
						<img src="/resources/assets/images/427x318-banner-TRS_middle.jpg" alt={t('page.login.13')}/>
						<a href="https://www.tmobileaccess.com/services/state-relay-services"
						   className={e3Style['sprint-ctas__block']} title={t('page.login.13hdr')}>
							<h3>{t('page.login.13hdr')}</h3>
							<div className={e3Style['sprint-ctas__label']}>{t('page.login.14')}</div>
						</a>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-auto']} ${e3Style['display-flex']} ${e3Style.cta}`}>
						<img src="/resources/assets/images/427x318-banner-Federal_right.jpg" alt={t('page.login.15')}/>
						<a href="https://www.tmobileaccess.com/services/captioning" className={e3Style['sprint-ctas__block']} title={t('page.login.15hdr')}>
							<h3>{t('page.login.15hdr')}</h3>
							<div className={e3Style['sprint-ctas__label']}>{t('page.login.16')}</div>
						</a>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Login;
