import React, {useEffect} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR, STATUS_NONE} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath, addEmergencyNumberPath} from "../../layout/body";
import {getEmerNumbers, updateEmerNbr, delEmerNbr, loadEmerNums} from "../../../store/emerNumberList";
import PhoneNumberItem from "../Common/PhoneNumberItem";
import {stripNonNums} from "../../util/stringFormatHelper";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const emergencyNumbers = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const curEmerList = useSelector(getEmerNumbers);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	useEffect(() => {
		const successFunc = () => {};
		const errorFunc = ({dispatch}, response) => {
			if (response.statusType === 0) {
				switch (response.status) {
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		};
		loadEmerNums(dispatch, csrfSessionNonce, successFunc, errorFunc);
	}, []);

	const updateEmergencyNumber = myPhoneNbrItem => {
		let matchedIdx = -1;

        if (curEmerList?.length > 0){
			matchedIdx = curEmerList.findIndex(function(value){ 
			        return value.PHONE_NUMBER === stripNonNums(myPhoneNbrItem.PHONE_NUMBER) && value.ORDERID !== myPhoneNbrItem.ORDERID;
			    });
			if(matchedIdx >= 0)
				setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
		}
		if(matchedIdx < 0)
			updateNumber({...myPhoneNbrItem});
	};

	const updateNumber = async myPhoneNbrItem => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'editEmergencyNumber', csrfSessionNonce},
					params: {
						emerId: myPhoneNbrItem.ORDERID,
						emerName: myPhoneNbrItem.NAME.trim(),
						phNbr: stripNonNums(myPhoneNbrItem.PHONE_NUMBER)
					}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						updateEmerNbr(dispatch,{"ORDERID": myPhoneNbrItem.ORDERID,
							"PHONE_NUMBER": stripNonNums(myPhoneNbrItem.PHONE_NUMBER),
							"NAME": myPhoneNbrItem.NAME.trim()});
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					case 426:
						setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const deleteNumber = async (myOrderId) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'deleteEmergencyNumber', csrfSessionNonce},
					params: {eId: myOrderId}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						delEmerNbr(dispatch,myOrderId );
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const findId = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, addEmergencyNumberPath + `/addEmergencyNumber`);
	};

	return (
	<>
		<StatusMsg/>
		<form className={e3Style['account-preferences_account-frequently-dialed-numbers']}>
			<p>{t('flows.emergency.emergencynumbers.1')}</p>
			<fieldset className={`${e3Style['sprint-frequently-dialed-numbers']} ${e3Style['sprint-account_form_items']}`}>
			{curEmerList?.length > 0 ?
			<div className ={e3Style['scrollable-div']}>
			{curEmerList.map(emerNbr =>
					<PhoneNumberItem key={emerNbr.ORDERID} phoneNbrItem={emerNbr}
						 onDeleteFunc={deleteNumber} onUpdateFunc={updateEmergencyNumber}
						 onActionFunc={() => {}}/>
      		)}
			</div>
			:
			<>
				<p>{t('flows.emergency.emergencynumbers.2')}</p>
			</>
			}
	</fieldset>
	{  curEmerList?.length > 0 &&
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']} ${e3Style['mt-60']} ${e3Style['pl-0']}`}>
			<p>{t('flows.emergency.emergencynumbers.3')} {curEmerList.length} {t('flows.emergency.emergencynumbers.4')}</p>
		</div>
	}
	<div className={e3Style.row}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		<button type="button" onClick={findId}
	        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
		{t('button.addNumber')}
	    </button>
		</div>
	</div>
	</form>
	</>
	);
};

export default emergencyNumbers;