import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import e3Style from "../../../css/e3-style.css";
import StatusMsg from "../util/statusMsg";
import LoginNonce from "./loginNonce";
import {setHistoryPath} from "../../store/bodyView";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {loginAdmin} from "../../store/user";
import {loadDisplaySettings} from "../../store/display";
import {rootPath} from "../layout/body";
import FocusWrappedGrp from "../util/focusWrappedGrp";
import FocusWrappedInput from "../util/focusWrappedInput";
import FocusWrappedLabel from "../util/focusWrappedLabel";
import {updateActTime} from "../../store/activity";

const AdminLogin = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState({adminId: '', adminPassword: '', nonce: ''});

	const setNonce = nonce => {
		setState({...state, nonce});
	};

	const handleChange = ({currentTarget: ct}) => {
		setState({...state, [ct.name]: ct.value});
	};

	const doAdminLogin = e => {
		e.preventDefault();
		updateActTime(dispatch);
		loginAdmin(dispatch, state.adminId, state.adminPassword, state.nonce,
			(dispState, {response}) => loadDisplaySettings(dispatch, response.response.csrfSessionNonce, () => success => setHistoryPath(dispatch, navigate, rootPath)), () => setNonce(''));
	};

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style['container-xl']}>
				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-sm-24']} ${e3Style['col-md-10']} ${e3Style['col-lg-14']} ${e3Style['pt-60']} ${e3Style['pb-60']} ${e3Style['login-left']}`}>
						<h1 className={e3Style['pr-40']}>{t('page.adminlogin.1')}</h1>
						<p className={e3Style['pr-40']}>{t('page.adminlogin.2')}</p>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-sm-24']} ${e3Style['col-md-14']} ${e3Style['col-lg-10']} ${e3Style['pt-60']} ${e3Style['pb-60']} ${e3Style['sprint-cta-sign-in']}`}>
						<h2>{t('page.adminlogin.3')}</h2>
						<StatusMsg/>
						<form onSubmit={doAdminLogin} name='adminLoginForm' role="form"
						      aria-label="form" noValidate autoComplete="off">
							<LoginNonce nonce={state.nonce} setNonce={setNonce} cmdName='getAdminLoginNonce'/>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="adminId">{t('page.login.5')}</FocusWrappedLabel>
								<FocusWrappedInput initialFocus value={state.adminId} onChange={handleChange} type="text"
								                   name="adminId" id="adminId" className={e3Style['input--fluid']}/>
							</FocusWrappedGrp>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="adminPassword">{t('page.login.6')}</FocusWrappedLabel>
								<FocusWrappedInput value={state.adminPassword} onChange={handleChange} type="password"
								                   name="adminPassword" id="adminPassword" className={e3Style['input--fluid']}/>
							</FocusWrappedGrp>
							<button type="submit" id="loginButton"
							        className={`${e3Style['button']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['mt-20']}`}>
								{t('button.signin')}
							</button>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

export default AdminLogin;
