import React from 'react';
import {useTranslation} from "react-i18next";
import e3Style from "../../../../css/e3-style.css";
import {useValidator} from "react-joi";
import Joi from "joi";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {accountListPath, loginPath, rootPath, regReserveNumberPath} from "../../layout/body";
import axios from "axios";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedInput from "../../util/focusWrappedInput";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const PortRequest = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {source} = useParams();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			phoneNumber: "",
			carrier: "",
			accountNumber: "",
			portDisclaimer: false,
			initials: ""
		},
		schema: Joi.object({
			phoneNumber: Joi.string().required().pattern(/^(?:(?:\(\s*\d{3}\s*\)|(?:\d{3}))\s*(?:-\s*)?\d{3}\s*(?:-\s*)?\d{4})$|^(?:\d{10})$/).messages({
				'string.base': 'phoneNumber.invalidFormat.error',
				'string.empty': 'phoneNumber.required.error',
				'any.required': 'phoneNumber.required.error',
				'string.pattern.base': 'phoneNumber.invalidFormat.error'
			}),
			carrier: Joi.string().trim().min(1).max(50).required().messages({
				'string.empty': 'flows.account.error.carrier.required',
				'string.max': 'flows.account.error.carrier.maxLen'
			}),
			accountNumber: Joi.string().allow('').trim().max(20).messages({
				'string.max': 'flows.account.error.acctNumber.maxLen'
			}),
			portDisclaimer: Joi.boolean().invalid(false).messages({
				'boolean.base': 'check.the.box.advisory',
				'any.invalid': 'check.the.box.advisory'
			}),
			initials: Joi.string().trim().min(1).required().messages({
				'string.empty': 'flows.account.error.initials.required'
			})
		}),
		explicitCheck: {
			phoneNumber: false,
			carrier: false,
			accountNumber: false,
			portDisclaimer: false,
			initials: false
		}
	});

	const goBack = e => {
		e.preventDefault();
		if(source === undefined || source === null)
			setHistoryPath(dispatch, navigate, accountListPath);
		else
			setHistoryPath(dispatch, navigate, regReserveNumberPath+'/0/registration');
	};

	const doPort = async e => {
		e.preventDefault();
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{
					command: {name: 'portPhoneNumber', csrfSessionNonce},
					params: {
						phoneNumber: state.$data.phoneNumber,
						carrier: state.$data.carrier,
						accountNumber: state.$data.accountNumber,
						locale: i18n.language
					}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						if(source === undefined || source === null)
							setHistoryPath(dispatch, navigate, accountListPath);
						else
							setHistoryPath(dispatch, navigate, regReserveNumberPath+'/0/afterport');
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setHistoryPath(dispatch, navigate, accountListPath, 'generic.rgp.error');
		}
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const updateCheckbox = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.checked
		}));
	};

	return (
		<>
			<h1 className={e3Style['pt-0']}>{t('flows.common.portrequest.9')}</h1>
			<div className={e3Style['col-xs-24']}><p>{t('flows.common.portrequest.10')}</p></div>
			<form noValidate autoComplete="off">
				<fieldset>
					<legend className={e3Style['form-title']}><h2>{t('flows.common.portrequest.1')}</h2></legend>
					<div className={e3Style.row}><div className={`${e3Style['col-xs-24']} ${e3Style['col-md-8']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="port-phone-number">
								{t('flows.common.portrequest.2')}</FocusWrappedLabel>
							<FocusWrappedInput className={`${e3Style['input--fluid']} ${e3Style['phone-number-input']}`} type="tel"
											   initialFocus id="port-phone-number" name="phoneNumber" placeholder="(000) 123-4567"
											   onBlur={() => setExplicitField('phoneNumber', true)}
											   onChange={e => updateField(e, 'phoneNumber')}/>
						</FocusWrappedGrp>
					</div></div>
					<div className={e3Style.row}><div className={`${e3Style['col-xs-24']} ${e3Style['col-md-8']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="port-carrier">
								{t('flows.common.portrequest.13')}</FocusWrappedLabel>
							<FocusWrappedInput className={e3Style['input--fluid']} type="text" id="port-carrier" name="carrier"
											   onBlur={() => setExplicitField('carrier', true)}
											   onChange={e => updateField(e, 'carrier')}/>
						</FocusWrappedGrp>
					</div></div>
					<div className={e3Style.row}><div className={`${e3Style['col-xs-24']} ${e3Style['col-md-8']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="port-account-number">
								{t('flows.common.portrequest.3')}</FocusWrappedLabel>
							<FocusWrappedInput className={e3Style['input--fluid']} type="text" id="port-account-number"
											   name="accountNumber" placeholder={t('flows.common.portrequest.11')}
											   onBlur={() => setExplicitField('accountNumber', true)}
											   onChange={e => updateField(e, 'accountNumber')}/>
						</FocusWrappedGrp>
					</div></div>
					<div className={e3Style.row}>
						<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
							<div className={e3Style['disclaimer-label']} id="disclaimer-label">
								{t('flows.common.portrequest.4')}
								<br/>
								{t('flows.common.portrequest.5')} <a href="/resources/pdf/LOA_Letter Of Authorization - rev 8-2021.pdf" target="_blank">{t('flows.common.portrequest.6')}</a>.
								<br/>
								{t('flows.common.portrequest.12')}
							</div>
						</div>
						<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']} ${e3Style['sprint-disclaimer']}`}>
							<input type="checkbox" name="portDisclaimer" id="port-disclaimer"
							       onBlur={() => setExplicitField('portDisclaimer', true)}
							       onChange={e => updateCheckbox(e, 'portDisclaimer')}/>
							<label htmlFor="port-disclaimer">{t('flows.common.portrequest.7')}</label>
						</div>
					</div>
					<div className={e3Style.row}><div className={`${e3Style['col-xs-24']} ${e3Style['col-md-16']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel htmlFor="initials-signature" className={e3Style['small-label']}>
								{t('flows.common.portrequest.8')}</FocusWrappedLabel>
							<FocusWrappedInput className={e3Style['sprint-signature-box']} type="text" id="initials-signature" name="initials"
											   onBlur={() => setExplicitField('initials', true)}
											   onChange={e => updateField(e, 'initials')}/>
						</FocusWrappedGrp>
					</div></div>
					<div className={e3Style.row}><div className={`${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" onClick={goBack}
						        className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.back')}
						</button>
						<button type="button" onClick={doPort} disabled={state.$all_source_errors.length}
						        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.submit')}
						</button>
					</div></div>
				</fieldset>
			</form>
		</>
	);
};

export default PortRequest;
