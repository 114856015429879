import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, getStatusMsgSelector, STATUS_ERROR, STATUS_NONE} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminLoginPath} from "../../../layout/body";
import {openOverrideIntrv} from "../../../../store/popupMgr";
import {getCsrfSessionNonce, getUserInfo, isCurrentUser} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";

const Intervention = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [interventionData, setInterventionData] = useState(null);
	const bodyView = useSelector(getStatusMsgSelector);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const userInfo = useSelector(getUserInfo);
	const isCurrentUserFlag = isCurrentUser(userInfo);

	useEffect(() => {
		if( bodyView.statusMsg === 'flows.admin.interventions.labels.10' ||  interventionData == null )
			getInterventions();
	},[bodyView]);

	const getInterventions = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getInterventions', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setStatusMessage(dispatch, '', STATUS_NONE);
				        setInterventionData(data.response);
					    break;

					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
	<>
	<StatusMsg/>
	<form id="interventionsModel" className={`${e3Style['account-preferences']}`}>
		<h2>{t('prefs.adminhome.interventions')}</h2>

		{ interventionData &&
			<>
			<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
				<div className={`${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
					<label className={e3Style['input--fluid']}>{t('flows.admin.interventions.labels.1')}</label>
				</div>
				<div className={`${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
					<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{interventionData.Suspended}</label>
				</div>
			</div>
			{ interventionData.List.length === 0 ?
			<>
			<p>{t('flows.admin.interventions.labels.3')}</p>
			</>
			:
			<>
			<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
				<div className={`${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
					<label className={e3Style['input--fluid']}>{t('flows.admin.interventions.labels.2')}</label>
				</div>
				<div className={`${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
					<label className={e3Style['input--fluid']}>{interventionData.ActiveCount}</label>
				</div>
			</div>
			{interventionData.List.map((intrv) => 
				<>
				<hr></hr>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style['col-md-3']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.4')}</label>
					</div>
					<div className={`${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.5')}</label>
					</div>
					<div className={`${e3Style['col-md-9']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.6')}</label>
					</div>
					<div className={`${e3Style['col-md-7']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.7')}</label>
					</div>
					<div className={`${e3Style['col-md-1']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.8')}</label>
					</div>
				</div>
				<div className={e3Style.row}>
					<div className={`${e3Style['col-md-3']} ${e3Style['display-flex']}`}>
						<label>{intrv.agentId}</label>
					</div>
					<div className={`${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
						<label>{intrv.formattedDate}</label>
					</div>
					<div className={`${e3Style['col-md-9']} ${e3Style['display-flex']}`}>
						<label>{intrv.desc}</label>
					</div>
					<div className={`${e3Style['col-md-7']} ${e3Style['display-flex']}`}>
						<label>{intrv.prodDesc}</label>
					</div>
					<div className={`${e3Style['col-md-1']} ${e3Style['display-flex']}`}>
						<label>{(intrv.callContinued? 'Yes':'No')}</label>
					</div>
				</div>
				<div className={e3Style.row}>
					<div className={`${e3Style['col-md-24']} ${e3Style['display-flex']}`}>
					<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.9')}:</label>
					</div>
				</div>
				{intrv.categoryList.map((category) =>
					<>
					<div className={e3Style.row}>
						<div className={`${e3Style['col-md-1']} ${e3Style['display-flex']}`}> </div>
						<div className={`${e3Style['col-md-7']} ${e3Style['display-flex']}`}>
							<label className={`${e3Style['text-bold']}`}>{category.name}:</label>
						</div>
						<div className={`${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
							<label className={`${e3Style['text-bold']}`}>{category.desc}</label>
						</div>
					</div>
					{category.criteriaList.map((criteria) =>
						<div className={e3Style.row}>
							<div className={`${e3Style['col-md-2']} ${e3Style['display-flex']}`}> </div>
							<div className={`${e3Style['col-md-2']} ${e3Style['display-flex']}`}>
								<label>{criteria.id}</label>
							</div>
							<div className={`${e3Style['col-md-20']} ${e3Style['display-flex']}`}>
								<label>{criteria.desc}</label>
							</div>
						</div>
					)}
					</>
				)}
				<div className={e3Style.row}>
					<div className={`${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.10')}</label>
					</div>
					<div className={`${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{(intrv.override? 'Yes':'No')}</label>
					</div>
				</div>
				{intrv.override &&
					<>
					<div className={e3Style.row}>
						<div className={`${e3Style['col-md-2']} ${e3Style['display-flex']}`}> </div>
						<div className={`${e3Style['col-md-3']} ${e3Style['display-flex']}`}>
							<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.4')}</label>
						</div>
						<div className={`${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
							<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.5')}</label>
						</div>
						<div className={`${e3Style['col-md-15']} ${e3Style['display-flex']}`}>
							<label className={`${e3Style['input--fluid']} ${e3Style['text-uppercase']}`}>{t('flows.admin.interventions.labels.6')}</label>
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style['col-md-2']} ${e3Style['display-flex']}`}> </div>
						<div className={`${e3Style['col-md-3']} ${e3Style['display-flex']}`}>
							<label>{intrv.override.agentId}</label>
						</div>
						<div className={`${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
							<label>{intrv.override.formattedDate}</label>
						</div>
						<div className={`${e3Style['col-md-15']} ${e3Style['display-flex']}`}>
							<label>{intrv.override.desc}</label>
						</div>
					</div>
					</>
				}
				</>
			)}
			{ interventionData.ActiveCount !== 0 && isCurrentUserFlag &&
			<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
				<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
				<button type="button" autoFocus onClick={e => {e.preventDefault();openOverrideIntrv(dispatch);}}
					className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>{t('prefs.admin.button.override.intrv')}</button>
				</div>
			</div>
			}
			</>
			}
		</>
		}
	</form>
	</>
	);
};

export default Intervention;	
