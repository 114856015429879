import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sprStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getUserExtraData, readUserExtraData} from "../../../../store/userExtraData";
import {getCsrfSessionNonce, getUserInfo, isCurrentUser} from "../../../../store/user";
import StatusMsg from "../../../util/statusMsg";
import {adminLoginPath} from "../../../layout/body";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import axios from "axios";
import {useNavigate} from "react-router";
import {updateActTime} from "../../../../store/activity";

const ValidateUser = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userInfo = useSelector(getUserInfo);
	const isCurrentUserFlag = isCurrentUser(userInfo);
	const userExtraData = useSelector(getUserExtraData);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [validateStatus, setValidateStatus] = useState(null);

	useEffect(() => {
		if (!userExtraData.dataValid)
			readUserExtraData(dispatch, csrfSessionNonce, () => {}, () => {});
		getValidateStatus();
	}, [userExtraData]);

	const getValidateStatus = async () => {
		try {
			if(!validateStatus) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getValidateUserModel', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						    setValidateStatus(data.response);
						  break;
						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const validateAction = async () => {
		const cmdString = validateStatus.emailVerified ? 'adminValidateUser' : 'adminValidateEmail';
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: cmdString, csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					  if( validateStatus.emailVerified )
						setValidateStatus({...validateStatus, veratadId: 1});
					  else
						setValidateStatus({...validateStatus, emailVerified: true});
					  break;
					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
		<>
			<StatusMsg/>

			<form noValidate autoComplete="off">
				<h2>{t((validateStatus ? (validateStatus.emailVerified ? (validateStatus.veratadId > 0 ? 'prefs.admin.validate.title_done' : 'prefs.admin.validate.title') : 'prefs.admin.validate.email_title') : 'prefs.admin.validate.title'))}</h2>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
				{ validateStatus && validateStatus.veratadId === 0 &&
					<>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.userid')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userInfo.userId}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.firstname')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.firstName}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.lastname')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.lastName}</label>
					</div>
					</>
					}
					{ validateStatus && validateStatus.veratadId === 0 && validateStatus.emailVerified &&
					<>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.addr1')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.addressLine1}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.addr2')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.addressLine2 ? userExtraData.addressLine2 : ""}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.city')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.city}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.state')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.stateAbbrev}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.zip')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.zip}</label>
					</div>
					</>
					}
					{ validateStatus && validateStatus.veratadId === 0 &&
					<>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.email')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.email}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.excessidreuse')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						{
							validateStatus.registrationIdentityCount >= validateStatus.maxRegistrationIdentityReuse ?
								<label className={`${e3Style['input--fluid']} ${sprStyle['red-txt']}`}>
									YES ({validateStatus.registrationIdentityCount} uses)
								</label> :
								<label className={e3Style['input--fluid']}>NO</label>
						}
					</div>
					</>
					}
				</div>
				<p className={sprStyle['std-txt-bigger']}>{ validateStatus ? ( validateStatus.veratadId > 0 ? t('prefs.admin.validate.userid')+' '+userInfo.userId+' '+t('prefs.admin.validate.already') : t(( validateStatus.emailVerified ? 'prefs.admin.validate.notice' : 'prefs.admin.validate.email_notice'))) : ''}</p>
				{ isCurrentUserFlag && validateStatus && validateStatus.veratadId === 0 &&
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
						<button type="button" autoFocus onClick={e => {e.preventDefault();validateAction();}}
						 className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>{t(validateStatus.emailVerified ? 'prefs.admin.validate.title' : 'prefs.admin.validate.email_title')}</button>
					</div>
				</div>
				}
			</form>
		</>
	);
};

export default ValidateUser;
