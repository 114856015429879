import React, {useEffect, useState} from "react";
import e3Style from '../../../../../css/e3-style.css';
import sStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedTextarea from "../../../util/focusWrappedTextarea";
import {getUserIdAndAdmin} from "../../../../store/user";
import {useSelector} from "react-redux";

const AdminNotesItem = ({
  noteItem,
  onDeleteFunc,
  isCurrentUser
}) => {
	const { t } = useTranslation();

	const [ origNotesItem, setOrigNotesItem] = useState({"AGENTID":0,"NOTE_ID":0,"NOTE_TEXT":"", "NOTE_DATE":0});
	const [ myNotesItem, setMyNotesItem] = useState({"AGENTID":0,"NOTE_ID":0,"NOTE_TEXT":"", "NOTE_DATE":0});
	const [ showDelete, setShowDelete ] = useState(false);
	const [ showDelConfirm, setShowDelConfirm ] = useState(false);
	const [ noteError, setNoteError ] = useState(false);

	const userIdAndAdmin = useSelector(getUserIdAndAdmin);
	
	useEffect(() => {
		setOrigNotesItem( {...noteItem});
		setOriginal(noteItem);
	}, []);

    const setOriginal = (thisNotesItem) => {
		setShowDelete((userIdAndAdmin.adminId.toLowerCase() === thisNotesItem.AGENTID.toLowerCase()) && isCurrentUser);
		setShowDelConfirm(false);
		setMyNotesItem({...thisNotesItem});
		setNoteError(( thisNotesItem.NOTE_TEXT == null || thisNotesItem.NOTE_TEXT.length === 0 ));
	}

    const notesDate = (dt) => {
		let nDate = new Date(dt);
		let month = (nDate.getMonth()+1 < 10) ? '0'+(nDate.getMonth()+1) : nDate.getMonth()+1;
		let newdt = (nDate.getDate() < 10) ? '0' + nDate.getDate() : nDate.getDate();
		return month + '-' + newdt + '-' + nDate.getFullYear();
	}
    
	return (
		<>
			<div className={`${e3Style.row} ${e3Style['pb-40']}`}>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-note']}`}>
					<label><b>Created on</b> {notesDate(myNotesItem.NOTE_DATE)} <b>by</b> {myNotesItem.AGENTID}</label>
				</div>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-note']}`}>
					<div className={`${e3Style['note-input-wrapper']} ${e3Style['agent-note-txt']}`}>
						{myNotesItem.NOTE_TEXT}
					</div>
					<div id="note-link-container"
						className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-note_links']} ${e3Style['note-links']}`}>
						{showDelete &&
							<a href='#' className={e3Style['button--link']} onClick={e => {
								e.preventDefault();
								setShowDelete(false);
								setShowDelConfirm(true)
							}}>{t('button.delete')}</a>
						}
						{showDelConfirm &&
							<>
								<label className={sStyle['notes-confirm-delete']}>{t('embedded.12')}</label>
								<a href='#' className={e3Style['button--link']} onClick={e => {
									e.preventDefault();
									onDeleteFunc(origNotesItem.NOTE_ID)
								}}>{t('button.yes')}</a>
							</>
						}
						{showDelConfirm &&
							<a href='#' className={e3Style['button--link']} onClick={e => {
								e.preventDefault();
								setOriginal(origNotesItem)
							}}>{t('button.cancel')}</a>
						}
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminNotesItem;