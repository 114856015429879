import React, {useEffect, useRef} from 'react';
import {closePopup, incomingCallSel} from "../../store/popupMgr";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import e3Style from '../../../css/e3-style.css';
import ModalWrapper from "./modalWrapper";
import {useNavigate} from "react-router";
import {setHistoryPath} from "../../store/bodyView";
import {rootPath} from "../layout/body";
import useAutoFocus from "../util/useAutoFocus";

const IncomingCall = ({setCloseFunc, data}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const acceptRef = useRef(false);
	const aFocus = useAutoFocus();
	const doClick = accept => e => {
		e.preventDefault();
		acceptRef.current = accept;
		closePopup(dispatch);
		if (accept) setHistoryPath(dispatch, navigate, rootPath);
	};
	useEffect(() => {setCloseFunc(() => {data?.callback(acceptRef.current)})}, [setCloseFunc, data]);
	return (
		<>
			<h3>{t('page.popup.incomingcall')}</h3>
			<h4 className={`${e3Style['sprint--title-icon']} ${e3Style['phone-icon']}`}>{data?.phoneNbr}</h4>
			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={doClick(false)}>
					{t('button.decline')}
				</button>
				<button type="button" className={`${e3Style['ml-40']} ${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={doClick(true)} ref={aFocus}>
					{t('button.accept')}
				</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(IncomingCall);

const IncomingCallModal = () => {
	const icSel = useSelector(incomingCallSel);
	return <ModalWindow {...icSel}/>;
};

export default IncomingCallModal;
