import React, {useEffect, useRef} from 'react';
import { Modal } from 'react-responsive-modal';
import {useDispatch} from "react-redux";
import {closePopup} from "../../store/popupMgr";
import e3Style from "../../../css/e3-style.css";

const ModalWrapper = PopupWindow => ({isOpen, popupTimeout, ...props}) => {
	const dispatch = useDispatch();
	const doClosePopup = () => {closePopup(dispatch)};
	const closeFuncRef = useRef(() => {});
	const setCloseFunc = func => closeFuncRef.current = func;
	useEffect(() => {
		if (isOpen) {
			const timerId = popupTimeout ? setTimeout(doClosePopup, popupTimeout) : null;
			return () => {
				clearTimeout(timerId);
				closeFuncRef.current(dispatch);
			};
		}
	}, [isOpen, popupTimeout]);
	return (
		<Modal open={isOpen} onClose={doClosePopup}
		       classNames={{modal: `${e3Style['sprint-modal']} ${e3Style['sprint--vision-settings_modal']} ${e3Style['white-popup']}`}} center>
			<PopupWindow setCloseFunc={setCloseFunc} {...props}/>
		</Modal>
	);
};

export default ModalWrapper;
