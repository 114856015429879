import React from 'react';
import e3Style from '../../../css/e3-style.css';
import sprStyle from '../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import {openShowDisclaimer} from "../../store/popupMgr";
import {useDispatch} from "react-redux";
import {version} from "../config/version";

const showDnsPage = (e, lang) => {
	e.preventDefault();
	let dnsUrl = "https://www.t-mobile.com/dns";
	if (lang === 'es') dnsUrl = "https://es.t-mobile.com/dns";
	const dnsFormId = "dnsFormId";
	let mapForm = document.getElementById(dnsFormId);
	if (mapForm) {
		mapForm.parentNode.removeChild(mapForm);
	}
	mapForm = document.createElement("form");
	mapForm.id = dnsFormId;
	mapForm.classList.add("no-display");
	mapForm.target = "dnsWindow";
	mapForm.method = "post";
	mapForm.action = dnsUrl;
	const mapInput2 = document.createElement("input");
	mapInput2.type = "hidden";
	mapInput2.name = "Brand";
	mapInput2.value = "Sprint";
	mapForm.appendChild(mapInput2);
	const mapInput3 = document.createElement("input");
	mapInput3.type = "hidden";
	mapInput3.name = "Site";
	mapInput3.value = "NoSell_Web";
	mapForm.appendChild(mapInput3);
	const mapInput4 = document.createElement("input");
	mapInput4.type = "hidden";
	mapInput4.name = "Origin_URL";
	mapInput4.value = window.location.protocol + "//" + window.location.host + window.location.pathname;
	mapForm.appendChild(mapInput4);
	document.body.appendChild(mapForm);
	window.open(dnsUrl, "dnsWindow", "width=800,height=800,resizeable,scrollbars");
	mapForm.submit();
	return false;
};

const Footer = () => {
	const { t,i18n } = useTranslation();
	const dispatch = useDispatch();

	return (
		<footer role="contentinfo">
			<div className={e3Style['sprint-footer']}>
				<div className={e3Style['sprint-footer__inner']}>
					<div className={e3Style['sprint-footer-nav']}>
						<ul className={e3Style['sprint-footer-nav__main']}>
							<li><a href="https://www.fcc.gov/sites/default/files/ten-digit_numbering_and_911_calls_for_internet-based_trs_-_what_they_mean_for_users.pdf" target="_blank">
								{t('page.footer.1')}
							</a></li>
							<li><a href="#" onClick={() => openShowDisclaimer(dispatch)}>
								{t('page.footer.2')}
							</a></li>
							<li><a href="https://www.t-mobile.com/responsibility/consumer-info/policies/terms-of-use" target="_blank">
								{t('page.footer.4')}
							</a></li>
							<li><a href={t('page.footer.pnotice_url')} target="_blank">
								{t('page.footer.privacy-policy')}
							</a></li>
							<li><a href={t('page.footer.pcenter_url')} target="_blank">
								{t('page.footer.privacy-center')}
							</a></li>
							<li><a href="#" onClick={e => showDnsPage(e, i18n.language)}>
								{t('page.footer.dns')}
							</a></li>
						</ul>
					</div>
				</div>

				<div className={e3Style['sprint-footer__legal']}>
					<div className={e3Style['container-xl']}>
						<div className={`${e3Style['sprint-footer-copyright']} ${sprStyle.flex_container}`}>
							<span className={sprStyle.first_flex_item}>&copy; {new Date().getFullYear()} T-Mobile - {t('page.footer.6')}</span>
							<span>{version}</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
