import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Navigate, Route, useNavigate, useParams} from "react-router";
import DisplayMessage from "./displayMessage";
import {accountListPath, loginPath, reserveNumberPath} from "../../layout/body";
import {setHistoryPath} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";
import Spinner from "../root/spinner";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../util/ioHelper";

const DisplayMsgLimit = () => {
	const { t } = useTranslation();
	return <div>{t('validation.retry.limit.message')} <a href="mailto:IPRelay@t-mobile.com" target="_top">IPRelay@t-mobile.com</a>.</div>;
};

const DisplayMsgReject = () => {
	const { t } = useTranslation();
	return <div>{t('validation.data.error')} <a href="mailto:IPRelay@t-mobile.com" target="_top">IPRelay@t-mobile.com</a>.</div>;
};

export const DisplayMsgObj = (dispatch, navigate, path, msgType) => ({
	displayMessage: {
		headerMsg: 'validation.retry.limit.header',
		DisplayMsg: (msgType == 0)? DisplayMsgLimit:DisplayMsgReject,
		buttonList: [
			{
				onClick: e => {
					e.preventDefault();
					setHistoryPath(dispatch, navigate, path);
				},
				btnText: 'button.ok'
			}
		]
	}
});

const VerifyIdent = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {phone} = useParams();
	const [questions, setQuestions] = useState({userAddressVerified: false});
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	useEffect(() => {
		verifyIdentity();
	}, []);

	const verifyIdentity = async () => {
			try {
				updateActTime(dispatch);
				const response = await postWithTimeout(
					{
						command: {name: 'submitIdentityAnswers', csrfSessionNonce},
						params: {
							phNbr:phone
						}
					}, EXTENDED_TIMEOUT);
					if (response.status === 200) {
						const {data} = response;
						switch (data.status.code) {
							case 200:
							case 305:
								setQuestions({userAddressVerified: true});
								setHistoryPath(dispatch, navigate, reserveNumberPath + `/${phone}`);
								break;

							case 306:
							case 308:
								setQuestions(DisplayMsgObj(dispatch, navigate, accountListPath, 1));
								break;
								
							case 307:
								setQuestions(DisplayMsgObj(dispatch, navigate, accountListPath, 0));
								break;

							case 998:
								setHistoryPath(dispatch, navigate, loginPath);
								break;

							default:
								setQuestions(DisplayMsgObj(dispatch, navigate, accountListPath, 0));
								break;
						}
					} else {
						setQuestions(DisplayMsgObj(dispatch, navigate, accountListPath, 0));
					}
			} catch (error) {
				setQuestions(DisplayMsgObj(dispatch, navigate, accountListPath, 0));
			}
	};

	return (
		<>
		{questions.userAddressVerified ?
	      <Route path="*" element={<Navigate to={reserveNumberPath + `/${phone}`}/>}/>
		: questions.displayMessage ?
			<DisplayMessage displayMsgObj={questions.displayMessage}/>
		: <Spinner/>
		}
	</>
	);
};

export default VerifyIdent;
