import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {apiDoCall} from "./api";

const slice = createSlice({
	name: "permissionsList",
	initialState: {
		numbers: null
	},
	reducers: {
		loadList: (permissionsList, action) => {
			const newList = action.payload.response.response.blockedNumbers.slice().sort((a, b) => a.ORDERID - b.ORDERID);
			if (permissionsList.numbers === null || permissionsList.numbers.length !== newList.length)
				permissionsList.numbers = newList;
			else {
				for (let i = 0;i < permissionsList.numbers.length;++i) {
					const curItem = permissionsList.numbers[i];
					const newItem = newList[i];
					if (curItem.ORDERID !== newItem.ORDERID ||
						curItem.PHONE_NUMBER !== newItem.PHONE_NUMBER || curItem.NAME !== newItem.NAME) {
						permissionsList.numbers = newList;
						break;
					}
				}
			}
		},

		addPhone: (permissionsList, action) => {
			if(permissionsList.numbers === null)
				permissionsList.numbers = [...permissionsList.numbers, action.payload.preset];
			else{
				const insertIdx = permissionsList.numbers.findIndex(
					value => value.ORDERID > action.payload.preset.ORDERID);
				if(insertIdx < 0)
					permissionsList.numbers = [...permissionsList.numbers, action.payload.preset];
				else {
					const newArray = [...permissionsList.numbers];
					newArray.splice(insertIdx,0,action.payload.preset);
					permissionsList.numbers = newArray;
				}
			}
		},

		updatePhone: (permissionsList, action) => {
			permissionsList.numbers = permissionsList.numbers.map(
				thisNbr => thisNbr.ORDERID === action.payload.preset.ORDERID ? action.payload.preset : thisNbr);
		},

		deletePhone: (permissionsList, action) => {
			permissionsList.numbers = permissionsList.numbers.filter(
				value => value.ORDERID !== action.payload.preset);
		}
	}
});

export const {
	loadList,
	addPhone,
	updatePhone,
	deletePhone
} = slice.actions;
export default slice.reducer;

export const loadBlockNums = (dispatch, csrfSessionNonce, successFunc, errorFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'getBlockedNumbers', csrfSessionNonce}
			},
			onSuccess: [{type: loadList.type}, (dispState, response) => {successFunc(dispState, response)}],
			onError: (dispState, response) => {errorFunc(dispState, response)}
		})
	);
};

export const addBlockNbr = (dispatch, newPhone) => {
	dispatch({type: addPhone.type, payload: {preset: newPhone}});
};

export const updateBlockNbr = (dispatch, thisPhone) => {
	dispatch({type: updatePhone.type, payload: {preset: thisPhone}});
};

export const delBlockNbr = (dispatch, orderId) => {
	dispatch({type: deletePhone.type, payload: {preset: orderId}});
};

export const getBlockNumbers = createSelector(
	state => state.entities.permissionsList.numbers,
	numbers => numbers
);