import React from 'react';
import {useTranslation} from "react-i18next";
import e3Style from "../../../../css/e3-style.css";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfo, isCurrentUser, isLoggedIn} from "../../../store/user";
import {
	adminCanceledUserCanceledUserPath,
	adminChangeLandlineChangePath,
	adminDeleteUserDeletePath,
	adminEditUserUserDetailsPath,
	adminInterventionsShowInterventionsPath,
	adminManagePhoneNumsTablePath,
	adminNotesMgrNotesPath,
	adminRegScanShowRegScanPath,
	adminSelectCanceledUserQueryPath,
	adminSelectUserQueryPath,
	adminSTSMessagesMessagesPath,
	adminUserDataShowUserPath,
	adminValidateUserValidatePath, registrationPath
} from "../body";
import {setHistoryPath} from "../../../store/bodyView";
import {useLocation, useNavigate} from "react-router";

const AdminBodyMenu = BodyComponent => ({pageTitle, ...props}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const userData = useSelector(getUserInfo);
	const isLoggedInFlag = isLoggedIn(userData);
	const isCurrentUserFlag = isCurrentUser(userData);

	const gotoLink = path => e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, path);
	};

	const activeLoc = loc => {
		const locs = location.pathname.split("/");
		return locs[2] === loc;
	};

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style["container-xl"]}>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-24']}`}>
						<h1 className={e3Style['sprint-title']}>{t('prefs.adminhome.title')}</h1>
					</div>
				</div>
				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-10']} ${e3Style['col-md-8']} ${e3Style['col-lg-7']}`}>
						<div className={e3Style['sprint-cta_side_nav']}>
							<nav aria-label="customer profile menu" role="navigation">
								{isLoggedInFlag && (isCurrentUserFlag ?
									<>
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminUserDataShowUserPath)} className={activeLoc('UserData') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
														className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-user-info']}`}></span>
													{t('prefs.adminhome.userdata')}
												</div>
											</a>
										</div>
									}
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminManagePhoneNumsTablePath)} className={activeLoc('ManagePhoneNums') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-tdnum-phone']}`}></span>
													{t('prefs.adminhome.manage.phonenums')}
												</div>
											</a>
										</div>
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminNotesMgrNotesPath)} className={activeLoc('NotesMgr') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-notes']}`}></span>
													{t('prefs.adminhome.admin.notes')}
												</div>
											</a>
										</div>
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminInterventionsShowInterventionsPath)} className={activeLoc('Interventions') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-interventions']}`}></span>
													{t('prefs.adminhome.interventions')}
												</div>
											</a>
										</div>
									}
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminRegScanShowRegScanPath)} className={activeLoc('RegScan') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-reg-scan']}`}></span>
													{t('prefs.adminhome.regscan')}
												</div>
											</a>
										</div>
									}
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminValidateUserValidatePath)} className={activeLoc('ValidateUser') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-verify']}`}></span>
													{t('prefs.adminhome.validate.user')}
												</div>
											</a>
										</div>
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminEditUserUserDetailsPath)} className={activeLoc('EditUser') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-chg-usr']}`}></span>
													{t('prefs.adminhome.change.nameid')}
												</div>
											</a>
										</div>
									}
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminChangeLandlineChangePath)} className={activeLoc('ChangeLandline') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-chg-llnum']}`}></span>
													{t('prefs.adminhome.change.landline')}
												</div>
											</a>
										</div>
									}
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminDeleteUserDeletePath)} className={activeLoc('DeleteUser') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent_del_usr']}`}></span>
													{t('prefs.adminhome.delete.user')}
												</div>
											</a>
										</div>
									}
									{ !userData.adminId.startsWith("0") &&
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminSTSMessagesMessagesPath)} className={activeLoc('STSMessages') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-sts-msg']}`}></span>
													{t('prefs.adminhome.stsmessages')}
												</div>
											</a>
											<hr className={e3Style['hr-light']}/>
										</div>
									}
									</>
									:
									<>
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminCanceledUserCanceledUserPath)} className={activeLoc('UserData') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-user-info']}`}></span>
													{t('prefs.adminhome.cancelled.user')}
												</div>
											</a>
										</div>
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminNotesMgrNotesPath)} className={activeLoc('NotesMgr') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-notes']}`}></span>
													{t('prefs.adminhome.admin.notes')}
												</div>
											</a>
										</div>
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminInterventionsShowInterventionsPath)} className={activeLoc('Interventions') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-interventions']}`}></span>
													{t('prefs.adminhome.interventions')}
												</div>
											</a>
										</div>
										<div className={e3Style['sprint-cta_side_nav_item']}>
											<a onClick={gotoLink(adminRegScanShowRegScanPath)} className={activeLoc('RegScan') ? e3Style.active : ''}>
												<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-reg-scan']}`}></span>
													{t('prefs.adminhome.regscan')}
												</div>
											</a>
											<hr className={e3Style['hr-light']}/>
										</div>
									</>
								)}
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(adminSelectUserQueryPath)} className={activeLoc('SelectUser') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-srch-usr']}`}></span>
											{t('prefs.adminhome.select.user')}
										</div>
									</a>
								</div>
							{ !userData.adminId.startsWith("0") &&
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(adminSelectCanceledUserQueryPath)} className={activeLoc('SelectCanceledUser') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-srch-del-usr']}`}></span>
											{t('prefs.adminhome.select.cancelled.user')}
										</div>
									</a>
								</div>
							}
							{ !userData.adminId.startsWith("0") &&
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(registrationPath)}>
										<div className={e3Style.nav_item_style}>
													<span aria-hidden="true"
													      className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-agent-reg-usr']}`}></span>
											{t('prefs.adminhome.create.landline')}
										</div>
									</a>
								</div>
							}
							</nav>
						</div>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>
						<BodyComponent {...props}/>
					</div>
				</div>
			</div>
		</main>
	);
};

export default AdminBodyMenu;
