import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {useSelector} from "react-redux";
import {getConvoDataSel} from "../../store/convoLog";
import ConvoEntry from "./convoEntry";
import e3Style from "../../../css/e3-style.css";

const ConvoArea = forwardRef((props, cptRef) => {
	const ref = useRef();
	useImperativeHandle(cptRef, () => ref.current, [ref]);
	const convoData = useSelector(getConvoDataSel);
	const convo = convoData.map((entry, index) => <ConvoEntry key={index} entry={entry}/>);
	useEffect(() => {
		const cnvArea = ref.current;
		if (cnvArea !== null && cnvArea !== undefined ) cnvArea.scrollTop = cnvArea.scrollHeight;
	}, [convo]);
	return <div className={e3Style['call-chat-content']} id="convo-area-content" role="log" ref={ref}>{convo}</div>;
});

export default ConvoArea;
