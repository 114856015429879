import React from "react";
import e3Style from "../../../../css/e3-style.css";
import sprStyle from "../../../../css/sprint-style.css";
import {useSelector} from "react-redux";
import {
	ACTIVE_CALL_IN_OTHER_TAB,
	CALL_TIMEOUT, getActionState,
	getCallErrorStatus,
	getCallState,
	INVALID_PHONE_NBR,
	MAX_CALLS_ERROR,
	NETWORK_ERROR, SYSTEM_BUSY,NETWORK_POLLING_PROBLEM,
	NO_ERROR,
	USER_ACTION_DIALING,
	USER_ACTION_DONE,
	USER_ACTION_END_CALL,
	USER_ACTION_READY,
	USER_NOT_LOGGED_IN
} from "../../../store/call";
import {useTranslation} from "react-i18next";

const CallErrorStatus = () => {
	const { t } = useTranslation();
	const callState = useSelector(getCallState);
	const callStatus = getActionState(callState);
	const callError = getCallErrorStatus(callState);

	const getStatusMsg = (status, error) => {
		if (error === NO_ERROR) {
			switch (status) {
				case USER_ACTION_READY:
					return t('call.status.stsrdy');

				case USER_ACTION_DIALING:
					return t('call.status.stsdial');

				case USER_ACTION_END_CALL:
					return t('call.status.stsinprog');

				case USER_ACTION_DONE:
					return t('call.status.stsdone');

				default:
					return t('call.status.stsinit');
			}
		} else {
			switch (error) {
				case ACTIVE_CALL_IN_OTHER_TAB:
					return t('call.status.othertab');

				case USER_NOT_LOGGED_IN:
					return t('call.status.loginreq');

				case MAX_CALLS_ERROR:
					return t('call.status.maxcalls');

				case INVALID_PHONE_NBR:
					return t('call.status.badphnum');

				case CALL_TIMEOUT:
					return t('call.status.conntmout');

				case NETWORK_POLLING_PROBLEM:
				case NETWORK_ERROR:
					return t('call.status.neterr');
				
				case SYSTEM_BUSY:
					return t('call.status.sysbusy');
				
				default:
					return t('call.status.undeferr');
			}
		}
	};

	return <p className={`${e3Style['sprint--title-icon']} ${e3Style['phone-icon']} ${sprStyle['call-status']}`} role="status">{getStatusMsg(callStatus, callError)}</p>
};

export default CallErrorStatus;