import {createSlice} from "@reduxjs/toolkit";
import {apiDoCall} from "./api";
import {createSelector} from "reselect";

const slice = createSlice({
	name: "defaultSettings",
	initialState: {
		adminSessionTimeout: 7200000,
		userSessionTimeout: 1200000,
		callEndTimeout: 300000,
		popupWindowTimeout: 30000,
		connectingWaitTimeout: 60000
	},
	reducers: {
		setDefaultSettings: (settings, action) => {
			settings.adminSessionTimeout = action.payload.response.response.adminSessionTimeout;
			settings.userSessionTimeout = action.payload.response.response.userSessionTimeout;
			settings.callEndTimeout = action.payload.response.response.callEndTimeout;
			settings.popupWindowTimeout = action.payload.response.response.popupWindowTimeout;
			settings.connectingWaitTimeout = action.payload.response.response.connectingWaitTimeout;
		}
	}
});

export const {
	setDefaultSettings
} = slice.actions;
export default slice.reducer;

export const loadDefaultSettings = (dispatch, csrfSessionNonce, callbackFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'getDefaultSettings', csrfSessionNonce}
			},
			onSuccess: [{type: setDefaultSettings.type}, () => {callbackFunc(true)}],
			onError: () => {callbackFunc(false)}
		})
	);
};

export const getDefaultSettings = createSelector(
	state => state.entities.defaultSettings,
	defaultSettings => defaultSettings
);
