import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiDoCall } from "./api";
import {setHistoryPath, setStatusMessage, setStatusMsg, STATUS_ERROR} from "./bodyView";
import {readUserExtraData, resetUserExtraData, setCustomUserExtraData} from "./userExtraData";
import {setNoteList} from "./notesList";
import {setLocationList} from "./AddressList";
import {loginPath, rootPath} from "../components/layout/body";
import {resetRegistryData} from "./registry";

const LOGOUT_URL = '/resources/auth/do_logout';

const slice = createSlice({
	name: "user",
	initialState: {
		userId: null,
		adminId: null,
		sessionNonce: null,
		isCurrentUser: false,
		customerId: null,
		updUserInfo: null
	},
	reducers: {
		login: (user, action) => {
			user.userId = action.payload.data.params.username;
			user.sessionNonce = action.payload.response.response.csrfSessionNonce;
		},
		adminLogin: (user, action) => {
			user.adminId = action.payload.data.params.adminId;
			user.sessionNonce = action.payload.response.response.csrfSessionNonce;
		},
		adminChooseUser: (user, action) => {
			user.customerId = action.payload.response.response.customerId;
			user.userId = action.payload.response.response.userid;
			user.isCurrentUser = true;
		},
		adminChooseCanceledUser: (user, action) => {
			user.customerId = action.payload.data.params.customerId;
			user.userId = action.payload.data.params.userId;
			user.isCurrentUser = false;
		},
		setUserState: (user, action) => {
			user.userId = action.payload.response.response.userId;
			user.adminId = action.payload.response.response.adminId;
			user.isCurrentUser = action.payload.response.response.isCurrentUser;
		},
		logout: (user, action) => {
			user.userId = null;
			user.adminId = null;
			user.sessionNonce = null;
			user.isCurrentUser = false;
			user.customerId = null;
		},
		adminClearUser: (user, action) => {
			user.userId = null;
			user.isCurrentUser = false;
		},
		setUpdUserInfo: (user, action) => {
			user.updUserInfo = action.payload.preset;
		}
	}
});

export const {
	login,
	adminLogin,
	adminChooseUser,
	adminChooseCanceledUser,
	setUserState,
	logout,
	adminClearUser,
	setUpdUserInfo
} = slice.actions;
export default slice.reducer;

export const loginUser = (dispatch, username, password, autologin, nonce, successFunc, failureFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'doLogin'},
				params: {
					username,
					password,
					autologin,
					nonce
				}
			},
			onSuccess: [{type: login.type}, successFunc],
			onError: failureFunc
		})
	);
};

export const directLoginUser = (dispatch, username, csrfSessionNonce) => {
	dispatch({type: login.type, payload: {data: {params: {username}}, response: {response: {csrfSessionNonce}}}});
};

export const loginAdmin = (dispatch, adminId, adminPassword, nonce, successFunc, failureFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'doAdminLogin'},
				params: {
					adminId,
					adminPassword,
					nonce
				}
			},
			onSuccess: [{type: adminLogin.type}, successFunc],
			onError: [{type: setStatusMsg.type, preset: {statusMsg: 'login.error', statusDisplay: STATUS_ERROR}}, failureFunc]
		})
	);
};

export const logoutUser = dispatch => {
	dispatch([{type: logout.type}, {type: resetUserExtraData.type}, {type: resetRegistryData.type}]);
	window.location.href = LOGOUT_URL;
};

export const clearUser = dispatch => {
		dispatch([{type: adminClearUser.type}, {type: resetUserExtraData.type}, {type: resetRegistryData.type}]);
};

export const chooseUser = (dispatch, push, cmd, params, path, csrfSessionNonce, successFunc, failureFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: cmd, csrfSessionNonce},
				params
			},
			onSuccess: [{type: adminChooseUser.type}, () => {
				setNoteList(dispatch, null);
				setLocationList(dispatch, null);
				setSTSCntcts(dispatch, null);
				readUserExtraData(dispatch, csrfSessionNonce, () => {setHistoryPath(dispatch, push, path)},
					() => {});
			}, successFunc],
			onError: [({dispatch}, {status, statusType}) => {
				if (statusType === 0) {
					switch (status) {
						case 992:
							setStatusMessage(dispatch, 'invalid.data.error', STATUS_ERROR);
							break;

						case 993:
							setStatusMessage(dispatch, 'user.notfound.error', STATUS_ERROR);
							break;

						case 998:
							setHistoryPath(dispatch, push, loginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			}, failureFunc]
		})
	);
};

export const chooseCanceledUser = (dispatch, push, user, csrfSessionNonce, successFunc, failureFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'adminSelectCanceledUser', csrfSessionNonce},
				params: {userId: user.userId, customerId: user.customerId}
			},
			onSuccess: [
				{type: adminChooseCanceledUser.type},
				{type: setCustomUserExtraData.type, preset: user},
				successFunc
			],
			onError: failureFunc
		})
	);
};

export const setUpdUserInfoDet = (dispatch, newValue) => {
	dispatch({type: setUpdUserInfo.type, payload: {preset: newValue}});
};

export const getUserInfo = createSelector(
	state => state.entities.user,
	user => user
);

export const getUpdUserInfoDet = createSelector(
		state => state.entities.user.updUserInfo,
		updUserInfo => updUserInfo
);

export const getUserIdAndAdmin = createSelector(
	state => state.entities.user.userId,
	state => state.entities.user.adminId,
	(userId, adminId) => ({userId, adminId})
);

export const getLoggedInSel = createSelector(
	state => state.entities.user && state.entities.user.userId,
	loggedIn => loggedIn
);

export const getCsrfSessionNonce = createSelector(
	state => state.entities.user.sessionNonce,
	csrfSessionNonce => csrfSessionNonce
);

export const isLoggedIn = user => user && user.userId;

export const isAdmin = user => user && user.adminId;

export const isCurrentUser = user => user && user.isCurrentUser;

export const isLoggedInOrAdmin = user => user && (user.userId || user.adminId);

export const isLoggedInAndNotAdmin = user => user && (user.userId && !user.adminId);
