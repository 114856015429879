import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";

const Ipblock = () => {
	const { t } = useTranslation();
	const {ipaddr, fwdaddr} = useParams();
	const addrStr = ipaddr + (fwdaddr !== "null" ? (" (" + t("page.ipblock.11") + ":" + fwdaddr + ")") : "");

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style['container-xl']}>
				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style['col-sm-24']} ${e3Style['col-md-10']} ${e3Style['col-lg-14']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
						<h1>{t("page.ipblock.1")}</h1>
						<p>{t("page.ipblock.2")}</p>
						<p>{t("page.ipblock.3")}</p>
						<ol>
							<li>{t("page.ipblock.6")}</li>
							<li>{t("page.ipblock.7")}</li>
							<li>{t("page.ipblock.8")}</li>
							<li>{t("page.ipblock.9")} {addrStr}</li>
						</ol>
						<p>{t("page.ipblock.10")}</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Ipblock;
