import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {setHistoryPath} from "../../store/bodyView";

const RedirectPath = ({path}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {setHistoryPath(dispatch, navigate, path)}, []);

	return null;
};

export default RedirectPath;
