import {useTranslation} from "react-i18next";
import ModalWrapper from "./modalWrapper";
import e3Style from "../../../css/e3-style.css";
import {closePopup, warnCallInProgSel} from "../../store/popupMgr";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {setInCallState} from "../../store/call";
import {logoutUser} from "../../store/user";
import useAutoFocus from "../util/useAutoFocus";

const WarnInCall = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const aFocus = useAutoFocus();

	const disconnectClick = e => {
		e.preventDefault();
		setInCallState(dispatch, false);
		logoutUser(dispatch);
		closePopup(dispatch);
	};

	const cancelClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h3>{t('page.popup.callinprog')}</h3>
			<p>{t('page.popup.warncall')}</p>
			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={disconnectClick}>
					{t('button.disconnect')}
				</button>
				<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={cancelClick}  ref={aFocus}>
					{t('button.cancel')}
				</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(WarnInCall);

const WarnInCallModal = () => {
	const {isOpen} = useSelector(warnCallInProgSel);
	return <ModalWindow isOpen={isOpen}/>;
};

export default WarnInCallModal;
