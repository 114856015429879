import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
	name: "pswdNonce",
	initialState: {
		obj: null,
		userId: null,
		ques: null,
		ans: null
	},
	reducers: {
		addNonce: (pswdNonce, action) => {
			pswdNonce.obj =	action.payload.preset;
		},
		setUserId: (pswdNonce, action) => {
			pswdNonce.userId =	action.payload.preset;
		},
		setQuestion: (pswdNonce, action) => {
			pswdNonce.ques = action.payload.preset;
		},
		setSecAns: (pswdNonce, action) => {
			pswdNonce.ans =	action.payload.preset;
		}
	}
});	

export const {
	addNonce, setUserId, setQuestion, setSecAns
} = slice.actions;
export default slice.reducer;

export const addForgotPasswordNonce = (dispatch, nonce) => {
	dispatch({type: addNonce.type, payload: {preset: nonce}});
};

export const setId = (dispatch, newValue) => {
	dispatch({type: setUserId.type, payload: {preset: newValue}});
};

export const setQues = (dispatch, newValue) => {
	dispatch({type: setQuestion.type, payload: {preset: newValue}});
};

export const setAns = (dispatch, newValue) => {
	dispatch({type: setSecAns.type, payload: {preset: newValue}});
};

export const getNonce = createSelector(
	state => state.entities.pswdNonce.obj,
	obj => obj
);

export const getId = createSelector(
		state => state.entities.pswdNonce.userId,
		userId => userId
);

export const getQues = createSelector(
		state => state.entities.pswdNonce.ques,
		ques => ques
);

export const getAns = createSelector(
		state => state.entities.pswdNonce.ans,
		ans => ans
);

