import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router";
import {useDispatch} from "react-redux";
import {setHistoryPath} from "../../../store/bodyView";
import {rootPath} from "../../layout/body";

const Errors = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {errorMsgHead, errorMsg} = useParams();

	const gotoRoot = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, rootPath);
	};

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style['container-xl']}>
				<div className={e3Style.row}>
					<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-24']}`}>
						<h1>{t(errorMsgHead)}</h1>
						<p>{t(errorMsg)} <a onClick={gotoRoot} title={t('page.errorPage.1')}>{t('page.errorPage.1')}</a></p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Errors;
