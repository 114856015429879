import React from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sprStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import Joi from "joi";
import { useValidator } from "react-joi";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import StatusMsg from "../../../util/statusMsg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";

import {adminNotesMgrNotesPath, loginPath} from "../../../layout/body";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedTextarea from "../../../util/focusWrappedTextarea";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";

const addNote = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			noteText: ""
		},
 	schema: Joi.object({
			noteText: Joi.string().trim().min(1).max(4000).required().messages({
				'string.empty':'flows.admin.notes.title.4',
				'string.max':'flows.admin.notes.title.5'
			})
		}),
		explicitCheck: {
			noteText: false
		}
	});

	const saveNote = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'addAdminNote', csrfSessionNonce},
				 params: {
							note: state.$data.noteText
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setHistoryPath(dispatch, navigate, adminNotesMgrNotesPath);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const submitNote = e => {
		e.preventDefault();
		saveNote();
	};

	return(
		<>
			<StatusMsg/>
			<form noValidate autoComplete="off" onSubmit={submitNote} className={e3Style['account-preferences_notes']}>
				<h2>{t('flows.admin.notes.title.2')}</h2>
				<FocusWrappedGrp baseAriaId="noteTextErr" cptErrCount={state.$errors.noteText.length}>
					<FocusWrappedTextarea name="noteText" id="noteText" className={sprStyle['add-agt-note-ta']} initialFocus
					                      onChange={e => updateField(e, 'noteText')}
					                      onBlur={() => setExplicitField('noteText', true)}/>
					{!!state.$errors.noteText.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.noteText.map((err, idx) => <p index={idx} id={'noteTextErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" onClick={e => {
							e.preventDefault();
							setHistoryPath(dispatch, navigate, adminNotesMgrNotesPath)
						}}
						        className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.cancel')} </button>

						<button type="submit" onClick={validate} disabled={state.$auto_invalid}
						        className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.submit')} </button>
					</div>
				</div>
			</form>
		</>
	);
};

export default addNote;
