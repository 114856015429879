import React, {useEffect} from 'react';
import axios from 'axios';
import {updateActTime} from "../../store/activity";
import {useDispatch} from "react-redux";

const LoginNonce = ({nonce, setNonce, cmdName}) => {
	const dispatch = useDispatch();
	useEffect(() => {
		const nonceObj = {active: true, nonceTimer: null};

		const getNonce = async () => {
			if (nonceObj.active) {
				try {
					updateActTime(dispatch);
					const response = await axios.post('/nologin/api',
						{command: {name: cmdName}}, {timeout: 5000});
					if (nonceObj.active) {
						if (response.status === 200) {
							const {data} = response;
							if (data.status.code === 200) {
								setNonce(data.response.nonce);
							} else {
								nonceObj.nonceTimer = setTimeout(() => getNonce(), 2000);
							}
						} else {
							nonceObj.nonceTimer = setTimeout(() => getNonce(), 2000);
						}
					}
				} catch (error) {
					if (nonceObj.active) nonceObj.nonceTimer = setTimeout(() => getNonce(), 2000);
				}
			}
		};

		const refreshNonce = async () => {
			if (nonceObj.active) {
				try {
					updateActTime(dispatch);
					const response = await axios.post('/nologin/rgpProxy',
						{ProxyCommand: 'updateNonce', nonce}, {timeout: 5000});
					if (nonceObj.active) {
						if (response.status === 200 && response.data.status.code === 200)
							nonceObj.nonceTimer = setTimeout(() => refreshNonce(), 90000);
						else setNonce('');
					}
				} catch (error) {
					nonceObj.active && setNonce('');
				}
			}
		};

		if (!nonce)
			getNonce();
		else
			setTimeout(() => refreshNonce(), 90000);
		return () => {
			nonceObj.active = false;
			clearTimeout(nonceObj.nonceTimer);
		};
	}, [nonce]);

	return <input type="hidden" name="nonce" value={nonce}/>;
};

export default LoginNonce;
