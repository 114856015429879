import React, {useEffect, useRef, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getUserExtraData, readUserExtraData} from "../../../../store/userExtraData";
import {getCsrfSessionNonce, getUserInfo, isCurrentUser} from "../../../../store/user";
import StatusMsg from "../../../util/statusMsg";
import {focusIfNoPopup, isAnyWindowOpen, openSuspendUser} from "../../../../store/popupMgr";
import {setAutoFocusFunc} from "../../../../store/bodyView";

const setSuspended = (dispatch, suspended) => e => {
	e.preventDefault();
	openSuspendUser(dispatch, !suspended);
};

const ShowUser = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userInfo = useSelector(getUserInfo);
	const userExtraData = useSelector(getUserExtraData);
	const afRef = useRef(null);
	const isPopupOpen = useSelector(isAnyWindowOpen);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const isCurrentUserFlag = isCurrentUser(userInfo);

	useEffect(() => {
		setAutoFocusFunc(dispatch, () => afRef.current.focus());
		focusIfNoPopup(isPopupOpen, afRef);
	}, []);

	useEffect(() => {
		if (!userExtraData.dataValid)
			readUserExtraData(dispatch, csrfSessionNonce, () => {}, () => {});
	}, [userExtraData]);

	return (
		<>
			<StatusMsg/>

			<form noValidate autoComplete="off">
				<h2>{t('prefs.admin.userdata.title')}</h2>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.userid')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userInfo.userId}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.suspended')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.suspended ? "Yes" : "No"}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.firstname')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.firstName}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.lastname')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.lastName}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.addr1')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.addressLine1}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.addr2')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.addressLine2 ? userExtraData.addressLine2 : ""}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.city')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.city}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.state')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.stateAbbrev}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.zip')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.zip}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.email')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.email}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.cancelleduser.stsUserId')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{userExtraData.stsUserId ? userExtraData.stsUserId : ""}</label>
					</div>
					{isCurrentUserFlag &&
						<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
							<div className={`${e3Style.colStyle} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
								<button type="button" ref={afRef}
										onClick={setSuspended(dispatch, userExtraData.suspended)}
										className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
									{t(userExtraData.suspended ? 'prefs.admin.button.unsuspend' : 'prefs.admin.button.suspend')}
								</button>
							</div>
						</div>
					}
				</div>
			</form>
		</>
	);
};

export default ShowUser;
