import { combineReducers } from "redux";
import userReducer from "./user";
import userExtraDataReducer from "./userExtraData";
import callReducer from "./call";
import convoLogReducer from "./convoLog";
import bodyViewReducer from "./bodyView";
import defaultSettingsReducer from "./defaultSettings";
import displayReducer from "./display";
import popupMgrReducer from "./popupMgr";
import freqNumberListReducer from "./FreqNumberList";
import emerNumberListReducer from "./emerNumberList";
import permissionsListReducer from "./permissionsList";
import stsContactListReducer from "./STSContactList";
import addressListReducer from "./AddressList";
import notesListReducer from "./notesList";
import tdNumReducer from "./tdNum";
import registryReducer from "./registry";
import activityReducer from "./activity";
import pswdNonceReducer from "./pswdNonce";

export default combineReducers({
	user: userReducer,
	call: callReducer,
	convoLog: convoLogReducer,
	bodyView: bodyViewReducer,
	defaultSettings: defaultSettingsReducer,
	display: displayReducer,
	popupMgr: popupMgrReducer,
	freqNumberList: freqNumberListReducer,
	emerNumberList: emerNumberListReducer,
	permissionsList: permissionsListReducer,
	stsContactList: stsContactListReducer,
	addressList: addressListReducer,
	userExtraData: userExtraDataReducer,
	notesList: notesListReducer,
	tdNum: tdNumReducer,
	registry: registryReducer,
	activity: activityReducer,
	pswdNonce: pswdNonceReducer
});
