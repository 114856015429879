import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminManagePhoneNumsAddNPAPath, adminManagePhoneNumsDeletePath} from "../../../layout/body";
import {updateTdNum} from "../../../../store/tdNum";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../../util/ioHelper";

const tdNumDisplay = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const [nbrList, setNbrList] = useState(null);

	useEffect(() => {
		getManameTdNumList();
	}, []);

	const getManameTdNumList = async () => {
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{command: {name: 'adminTdNumDisplay', csrfSessionNonce}}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setNbrList(data.response.tdnumlist);
					    break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
	<>
	<StatusMsg/>
	<form className={e3Style['account-preferences_account-frequently-dialed-numbers']}>
	<h2>{t('flows.admin.managetdnum.display.title')}</h2>
	{nbrList && (nbrList.length > 0) ?
	<>
	<fieldset className={`${e3Style['sprint-account_form_items']}`}>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
			<label className={`${e3Style['text-uppercase']}`}>{t('flows.admin.managetdnum.display.type')}</label>
		</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
			<label className={`${e3Style['text-uppercase']}`}>{t('flows.admin.managetdnum.display.num')}</label>
		</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
			<label className={`${e3Style['text-uppercase']}`}>{t('flows.admin.managetdnum.display.status')}</label>
		</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
			<label className={`${e3Style['text-uppercase']}`}>{t('flows.admin.managetdnum.display.action')}</label>
		</div>
	</div>
	{nbrList.map((numItem, idx) => {
     return(
 	<div index={idx} className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
	     <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{t(numItem.name)}</label>
		</div>
       <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{numItem.phoneNum}</label>
		</div>
	     <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{t(numItem.statusCodeTag)}</label>
		</div>
		{numItem.buttonTag != null ?
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
		<button type="button" disabled={numItem.disableBtn} onClick={e => {e.preventDefault();
		if (numItem.buttonTag === 'flows.admin.managetdnum.display.newnum') {
				setHistoryPath(dispatch, navigate, adminManagePhoneNumsAddNPAPath);
			}
			else if(numItem.buttonTag === 'flows.admin.managetdnum.display.modifynum'){
				updateTdNum(dispatch, numItem);
				setHistoryPath(dispatch, navigate, adminManagePhoneNumsDeletePath);
			}
		}} className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']}`}>{t(numItem.buttonTag)}</button>
		</div>
		:<></>
		}
	</div>
	);
	})}	
	</fieldset>
	</>
	:
	<></>			
	}
	</form>
	</>
	);
};

export default tdNumDisplay;	
