import React from 'react';
import {setHistoryPath} from "../../../../store/bodyView";
import {adminSelectCanceledUserUserListPath} from "../../../layout/body";
import QueryUserGeneric from "./queryUserGeneric";

const searchObj = {
	pgTxt: {
		title: 'prefs.adminhome.select.cancelled.user',
		phoneNumLabel: 'flows.admin.selectcancelleduser.query.phonenum',
		userIdLabel: 'flows.admin.selectcancelleduser.query.userid',
		emailLabel: 'flows.admin.selectcancelleduser.query.email',
		firstnameLabel: 'flows.admin.selectcancelleduser.query.firstname',
		lastnameLabel: 'flows.admin.selectcancelleduser.query.lastname'
	},
	searchUserPhoneNum: (phoneNum, dispatch, navigate) =>
		setHistoryPath(dispatch, navigate, adminSelectCanceledUserUserListPath + `/phone/${encodeURIComponent(phoneNum)}`),
	searchUserId: (userId, dispatch, navigate) =>
		setHistoryPath(dispatch, navigate, adminSelectCanceledUserUserListPath + `/userid/${encodeURIComponent(userId)}`),
	searchUserEmail: (email, dispatch, navigate) =>
		setHistoryPath(dispatch, navigate, adminSelectCanceledUserUserListPath + `/email/${encodeURIComponent(email)}`),
	searchUserFullname: (firstName, lastName, dispatch, navigate) => {
		if (!firstName.length) {
			setHistoryPath(dispatch, navigate, adminSelectCanceledUserUserListPath + `/lastonly/${encodeURIComponent(lastName)}`);
		} else if (!lastName.length) {
			setHistoryPath(dispatch, navigate, adminSelectCanceledUserUserListPath + `/firstonly/${encodeURIComponent(firstName)}`);
		} else {
			setHistoryPath(dispatch, navigate, adminSelectCanceledUserUserListPath + `/fullname/${encodeURIComponent(firstName)}/${encodeURIComponent(lastName)}`);
		}
	}
};

const QueryCanceledUser = () => {
	return <QueryUserGeneric searchObj={searchObj}/>;
};

export default QueryCanceledUser;
