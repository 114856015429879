import React, {useEffect} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR, STATUS_NONE} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath, addNotesPath} from "../../layout/body";
import {getNotes,getRefreshCount,setNoteList,updateNote,setRefreshCount,delNote} from "../../../store/notesList";
import NotesItem from "./notesItem";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";


const notes = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const curNoteList = useSelector(getNotes);
	const refreshCount =  useSelector(getRefreshCount);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const disAddbutton = curNoteList && curNoteList.length >= 10;

	useEffect(() => {
		getNotesList();
	}, []);

	const getNotesList = async () => {
		try {
			if( curNoteList == null) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getNotesList', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							const sortedList = data.response.customerNotes.slice(0).sort((a, b) => a.ORDERID - b.ORDERID);
							setNoteList(dispatch, sortedList);
							break;

						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updNote = async (myNoteItem) => {
		if (myNoteItem.NOTES.trim().length > 80) setStatusMessage(dispatch, 'flows.notes.validation.1', STATUS_ERROR);
		else{
			try {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{	command: {name: 'editNote', csrfSessionNonce},
					  	params: {
							orderId: myNoteItem.ORDERID,
							note: myNoteItem.NOTES.trim()
						}
					}, 
					{timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						updateNote(dispatch,{"ORDERID": myNoteItem.ORDERID,
							"NOTES": myNoteItem.NOTES.trim()});
							setStatusMessage(dispatch, '', STATUS_NONE);
							break;

						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
	
						default:
							setRefreshCount(dispatch, myNoteItem.ORDERID);
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setRefreshCount(dispatch, myNoteItem.ORDERID);
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} catch (error) {
				setRefreshCount(dispatch, myNoteItem.ORDERID);
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		}
	};

	const deleteNote = async (myOrderId) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'deleteNote', csrfSessionNonce},
				  	params: {orderId: myOrderId}
				}, 
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						delNote(dispatch,myOrderId );
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const handleNote = e => {
		e.preventDefault();
		let newId = findId();
		setHistoryPath(dispatch, navigate, addNotesPath + `/${newId}`);
	}

	const findId = () => {
		let id = 1;
		if (!curNoteList || !curNoteList.length) return id.toString();
		const sortedList = curNoteList.slice(0).sort((a, b) => a.ORDERID - b.ORDERID);
		if (sortedList[0].ORDERID != 1) return id.toString();
 		for (let i = 1;i < sortedList.length;++i) {
			if (sortedList[i].ORDERID - id > 1) break;
			++id;
		}
		return (id + 1).toString();
	};	

	return (
	<>
		<StatusMsg/>
		<form>
			<fieldset className={`${e3Style['sprint-account_form_items']}`}>
			{curNoteList && (curNoteList.length > 0) ?
			<div className ={e3Style['scrollable-div']}>
			{curNoteList.map(curNote => {
			return (
					<NotesItem key={curNote.ORDERID} noteItem={curNote} 
						 onDeleteFunc={deleteNote} onUpdateFunc={updNote} refreshCount={refreshCount}/>
			);

      		})}
			</div>
			:
			<></>
		}
	</fieldset>
	{  curNoteList &&
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
			<p>{t('flows.notes.notes.1')} {curNoteList.length} {t('flows.notes.notes.2')}</p>
		</div>
	}
	<div className={e3Style.row}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		<button type="button" onClick={handleNote} disabled={disAddbutton}
	        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
		{t('button.addnote')}
	    </button>
		</div>
	</div>

	</form>
	</>
	);
};

export default notes;
