import React, {useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import StatusMsg from "../../../util/statusMsg";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../../util/focusWrappedLabel";
import FocusWrappedInput from "../../../util/focusWrappedInput";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {getCsrfSessionNonce} from "../../../../store/user";

const QueryUserGeneric = ({searchObj}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [state, setState] = useState({
		phoneNum: "",
		userId: "",
		email: "",
		firstName: "",
		lastName: ""
	});
	const pgTxt = searchObj.pgTxt;

	const handleChange = ({currentTarget: ct}) => {
		setState({...state, [ct.name]: ct.value});
	};

	const searchUserPhoneNum = e => {
		e.preventDefault();
		const trimPhoneNum = state.phoneNum.trim();
		if (trimPhoneNum.length)
			searchObj.searchUserPhoneNum(trimPhoneNum, dispatch, navigate, csrfSessionNonce);
	};

	const searchUserId = e => {
		e.preventDefault();
		const trimUserId = state.userId.trim();
		if (trimUserId.length)
			searchObj.searchUserId(trimUserId, dispatch, navigate, csrfSessionNonce);
	};

	const searchUserEmail = e => {
		e.preventDefault();
		const trimEmail = state.email.trim();
		if (trimEmail.length)
			searchObj.searchUserEmail(trimEmail, dispatch, navigate, csrfSessionNonce);
	};

	const searchUserFullname = e => {
		e.preventDefault();
		const trimFirstName = state.firstName.trim();
		const trimLastName = state.lastName.trim();
		if (trimFirstName.length || trimLastName.length)
			searchObj.searchUserFullname(trimFirstName, trimLastName, dispatch, navigate);
	};

	return (
		<>
			<StatusMsg/>

			<form noValidate autoComplete="off" className={e3Style['account-preferences_account-permissions']}>
				<fieldset>
					<h2 className={e3Style['pb-20']}>{t(pgTxt.title)}</h2>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-18']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="phoneNum">
									{t(pgTxt.phoneNumLabel)}</FocusWrappedLabel>
								<FocusWrappedInput name="phoneNum" id="phoneNum" value={state.phoneNum} onChange={handleChange} onEnter={searchUserPhoneNum}
								                   className={`${e3Style['input--fluid']} ${sStyle['impt-0mb']}`} type="text" initialFocus/>
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-6']}`}>
							<button type="button" onClick={searchUserPhoneNum}
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']}`}>
								{t('button.search')}
							</button>
						</div>
					</div>
				</fieldset>
				<hr className={sStyle['hr-bold']}/>
				<fieldset>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-18']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="userId">
									{t(pgTxt.userIdLabel)}</FocusWrappedLabel>
								<FocusWrappedInput name="userId" id="userId" value={state.userId} onChange={handleChange} onEnter={searchUserId}
								                   className={`${e3Style['input--fluid']} ${sStyle['impt-0mb']}`} type="text"/>
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-6']}`}>
							<button type="button" onClick={searchUserId}
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']}`}>
								{t('button.search')}
							</button>
						</div>
					</div>
				</fieldset>
				<hr className={sStyle['hr-bold']}/>
				<fieldset>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-18']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="email">
									{t(pgTxt.emailLabel)}</FocusWrappedLabel>
								<FocusWrappedInput name="email" id="email" value={state.email} onChange={handleChange} onEnter={searchUserEmail}
								                   className={`${e3Style['input--fluid']} ${sStyle['impt-0mb']}`} type="text"/>
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-6']}`}>
							<button type="button" onClick={searchUserEmail}
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']}`}>
								{t('button.search')}
							</button>
						</div>
					</div>
				</fieldset>
				<hr className={sStyle['hr-bold']}/>
				<fieldset>
					<div className={`${e3Style.row} ${e3Style['mb-40']}`}>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-9']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="firstName">
									{t(pgTxt.firstnameLabel)}</FocusWrappedLabel>
								<FocusWrappedInput name="firstName" id="firstName" value={state.firstName} onChange={handleChange} onEnter={searchUserFullname}
								                   className={`${e3Style['input--fluid']} ${sStyle['impt-0mb']}`} type="text"/>
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-9']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="lastName">
									{t(pgTxt.lastnameLabel)}</FocusWrappedLabel>
								<FocusWrappedInput name="lastName" id="lastName" value={state.lastName} onChange={handleChange} onEnter={searchUserFullname}
								                   className={`${e3Style['input--fluid']} ${sStyle['impt-0mb']}`} type="text"/>
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-6']}`}>
							<button type="button" onClick={searchUserFullname}
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']}`}>
								{t('button.search')}
							</button>
						</div>
					</div>
				</fieldset>
			</form>
		</>
	);
};

export default QueryUserGeneric;
