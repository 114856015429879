import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import DisplayMessage from "../Common/displayMessage";
import {loginPath} from "../../layout/body";
import {useDispatch} from "react-redux";
import {DisplayMsgObj} from "../Common/verifyIdent";
import Spinner from "../root/spinner";

const userIdentity = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [questions, setQuestions] = useState({});

	useEffect(() => {
		setQuestions(DisplayMsgObj(dispatch, navigate, loginPath));
	}, []);

	return (
		<>
		{questions.displayMessage ?
				<DisplayMessage displayMsgObj={questions.displayMessage}/>
		: <Spinner/>
		}
	</>
	);
};

export default userIdentity;
