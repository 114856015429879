import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sStyle from '../../../../../css/sprint-style.css';
import StatusMsg from "../../../util/statusMsg";
import {useTranslation} from "react-i18next";
import {getCsrfSessionNonce} from "../../../../store/user";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../root/spinner";

const selectUser = (selId, dispatch, navigate, csrfSessionNonce, displayObj, userList) => e => {
	e.preventDefault();
	displayObj.selectUser(selId, dispatch, navigate, csrfSessionNonce, userList);
};

const UserListGeneric = ({displayObj}) => {
	const { t } = useTranslation();
	const [userList, setUserList] = useState([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [showSpin, setShowSpin] = useState(true);
	const pgTxt = displayObj.pgTxt;

	useEffect(() => {
		(async () => {
			await displayObj.readUserList(dispatch, csrfSessionNonce, setUserList);
			setShowSpin(false);
		})();
	},[]);

	useEffect(() =>
		{userList.length === 1 && displayObj.selectUser(pgTxt.phoneNumLabel ? userList[0].userId : userList[0].customerId, dispatch, navigate, csrfSessionNonce, userList)},
		[userList]);

	return (
		<>
			{showSpin ? <Spinner/> :
				<>
					<StatusMsg/>

					<form noValidate autoComplete="off" className={e3Style['account-preferences_account-permissions']}>
						<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
							<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
								<label className={e3Style['text-uppercase']}>&nbsp;</label>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
								<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']} ${sStyle['std-txt-bold']}`}>
									{t(pgTxt.lastnameLabel)}
								</label>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
								<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']} ${sStyle['std-txt-bold']}`}>
									{t(pgTxt.firstnameLabel)}
								</label>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
								<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']} ${sStyle['std-txt-bold']}`}>
									{t(pgTxt.addressLabel)}
								</label>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['col-lg-3']} ${e3Style['display-flex']}`}>
								<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']} ${sStyle['std-txt-bold']}`}>
									{t(pgTxt.cityLabel)}
								</label>
							</div>
							<div className={`${e3Style.colStyle} ${e3Style['col-lg-2']} ${e3Style['display-flex']}`}>
								<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']} ${sStyle['std-txt-bold']}`}>
									{t(pgTxt.stateLabel)}
								</label>
							</div>
							{pgTxt.phoneNumLabel &&
								<div className={`${e3Style.colStyle} ${e3Style['col-lg-3']} ${e3Style['display-flex']}`}>
									<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']} ${sStyle['std-txt-bold']}`}>
										{t(pgTxt.phoneNumLabel)}
									</label>
								</div>
							}
						</div>
						{userList.length ? userList.map((user, idx) =>
								<div key={idx} className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
									<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
										<button type="button" onClick={selectUser(pgTxt.phoneNumLabel ? user.userId : user.customerId, dispatch, navigate, csrfSessionNonce, displayObj, userList)}
												className={`${e3Style.button} ${e3Style['display-block']} ${sStyle['std-txt-80pct']}`}>
											{t('button.select')}
										</button>
									</div>
									<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
										<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']}`}>{user.lastName}</label>
									</div>
									<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
										<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']}`}>{user.firstName}</label>
									</div>
									<div className={`${e3Style.colStyle} ${e3Style['col-lg-4']} ${e3Style['display-flex']}`}>
										<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']}`}>{user.addressLine1}</label>
									</div>
									<div className={`${e3Style.colStyle} ${e3Style['col-lg-3']} ${e3Style['display-flex']}`}>
										<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']}`}>{user.city}</label>
									</div>
									<div className={`${e3Style.colStyle} ${e3Style['col-lg-2']} ${e3Style['display-flex']}`}>
										<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']}`}>{user.stateAbbrev}</label>
									</div>
									{pgTxt.phoneNumLabel &&
										<div className={`${e3Style.colStyle} ${e3Style['col-lg-3']} ${e3Style['display-flex']}`}>
											<label className={`${e3Style['text-uppercase']} ${sStyle['std-txt-80pct']}`}>{user.phoneNum}</label>
										</div>
									}
								</div>
							)
							:
							<div>No User Found</div>
						}
					</form>
					<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
						<div className={`${e3Style.colStyle} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
							<button type="button" onClick={e => {e.preventDefault();displayObj.goBack(dispatch, navigate)}}
									className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
								{t('button.back')}
							</button>
						</div>
					</div>
				</>
			}
		</>
	);
};

export default UserListGeneric;
