import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {apiDoCall} from "./api";

const slice = createSlice({
	name: "freqNumberList",
	initialState: {
		numbers: null
	},
	reducers: {
		loadList: (freqNumberList, action) => {
			const newList = action.payload.response.response.freqDialedNumbers.slice().sort((a, b) => a.ORDERID - b.ORDERID);
			if (freqNumberList.numbers === null || freqNumberList.numbers.length !== newList.length)
				freqNumberList.numbers = newList;
			else {
				for (let i = 0;i < freqNumberList.numbers.length;++i) {
					const curItem = freqNumberList.numbers[i];
					const newItem = newList[i];
					if (curItem.ORDERID !== newItem.ORDERID ||
						curItem.PHONE_NUMBER !== newItem.PHONE_NUMBER || curItem.NAME !== newItem.NAME) {
						freqNumberList.numbers = newList;
						break;
					}
				}
			}
		},

		addPhone: (freqNumberList, action) => {
			if(freqNumberList.numbers === null)
				freqNumberList.numbers = [...freqNumberList.numbers, action.payload.preset];
			else{
				const insertIdx = freqNumberList.numbers.findIndex(
					value => value.ORDERID > action.payload.preset.ORDERID);
				if(insertIdx < 0)
					freqNumberList.numbers = [...freqNumberList.numbers, action.payload.preset];
				else {
					const newArray = [...freqNumberList.numbers];
					newArray.splice(insertIdx,0,action.payload.preset);
					freqNumberList.numbers = newArray;
				}
			}
		},

		updatePhone: (freqNumberList, action) => {
			freqNumberList.numbers = freqNumberList.numbers.map(
				thisNbr => thisNbr.ORDERID === action.payload.preset.ORDERID ? action.payload.preset : thisNbr);
		},

		deletePhone: (freqNumberList, action) => {
			freqNumberList.numbers = freqNumberList.numbers.filter(
				value => value.ORDERID !== action.payload.preset);
		}
	}
});

export const {
	loadList,
	addPhone,
	updatePhone,
	deletePhone
} = slice.actions;
export default slice.reducer;

export const loadFreqDialedNums = (dispatch, csrfSessionNonce, successFunc, errorFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'getFreqNumberList', csrfSessionNonce}
			},
			onSuccess: [{type: loadList.type}, (dispState, response) => {successFunc(dispState, response)}],
			onError: (dispState, response) => {errorFunc(dispState, response)}
		})
	);
};

export const addFreqNbr = (dispatch, newPhone) => {
	dispatch({type: addPhone.type, payload: {preset: newPhone}});
};

export const updateFreqNbr = (dispatch, thisPhone) => {
	dispatch({type: updatePhone.type, payload: {preset: thisPhone}});
};

export const delFreqNbr = (dispatch, orderId) => {
	dispatch({type: deletePhone.type, payload: {preset: orderId}});
};

export const getFreqNumbers = createSelector(
		state => state.entities.freqNumberList.numbers,
		numbers => numbers
);

export const getFreqNumsNameSorted = createSelector(
	state => state.entities.freqNumberList.numbers,
	numbers => numbers?.slice().sort((a, b) => {
		const x = a.NAME.toLowerCase();
		const y = b.NAME.toLowerCase();
		if (x < y) return -1;
		if (x > y) return 1;
		return 0;
	})
);