import axios from "axios";
import * as actions from "../api";
import {batch} from "react-redux";

const api = ({ dispatch, getState }) => next => async action => {
	if (action.type !== actions.apiDoCall.type) return next(action);

	const { url, method, data, timeout, onStart, onSuccess, onError, onFinish } = action.payload;

	const lMethod = method ? method : 'post';
	const lUrl = url ? url : '/nologin/api';
	const lTimeout = timeout ? timeout : 4000;

	dispatchHandler(onStart, data);

	next(action);

	try {
		const response = await axios.request({
			url: lUrl,
			method: lMethod,
			data,
			timeout: lTimeout
		});
		batch(() => {
			if (response.status === 200) {
				const {data: respData} = response;
				const {code: statusCode} = respData.status;
				if (statusCode === 200) {
					dispatchHandler(onSuccess, data, {response: respData, status: 200, statusType: 0});
					dispatchHandler(onFinish, data);
				} else {
					dispatchHandler(onError, data, {response: respData, status: statusCode, statusType: 0});
					dispatchHandler(onFinish, data);
				}
			} else {
				dispatchHandler(onError, data, {response: response.data, status: response.status, statusType: 1});
				dispatchHandler(onFinish, data);
			}
		});
	} catch (error) {
		batch(() => {
			dispatchHandler(onError, data, {error, status: 500, statusType: 2});
			dispatchHandler(onFinish, data);
		});
	}

	function dispatchHandler(handler, data, extras) {
		if (handler) {
			if (Array.isArray(handler))
				for (let i = 0; i < handler.length; ++i) {
					const hndlr = handler[i];
					if (typeof hndlr === 'function')
						hndlr({dispatch, getState}, {data, ...extras});
					else
						dispatch({type: hndlr.type, payload: {data, preset: hndlr.preset, ...extras}});
				}
			else if (typeof handler === 'function')
				handler({dispatch, getState}, {data, ...extras});
			else
				dispatch({type: handler.type, payload: {data, preset: handler.preset, ...extras}});
		}
	}
};

export default api;
