import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sprStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath} from "../../../layout/body";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";

const showRegScan = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [userReg, setUserReg] = useState({
		clientType: 0,
		ipAddress: '',
		regDate: 0,
		userRegList: [],
		createdByCustSrv: false,
		regFound: false
	});

	useEffect(() => {
		getUserRegInfo();
	}, []);

	const getUserRegInfo = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
					{command: {name: 'adminRegScan', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setUserReg({...data.response, regFound:true});
						  	break;
						case 421:
							setStatusMessage(dispatch, 'flows.admin.regscan.showuserinfo.nouserdata', STATUS_ERROR);
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const formatRegDate = (dt) => {
		let nDate = new Date(dt);
		let month = (nDate.getMonth()+1 < 10) ? '0'+(nDate.getMonth()+1) : nDate.getMonth()+1;
		let newdt = (nDate.getDate() < 10) ? '0' + nDate.getDate() : nDate.getDate();
		return month + '-' + newdt + '-' + nDate.getFullYear();
	}
	
	return (
	<>
	<StatusMsg/>
	<h2>{t('flows.admin.regscan.showuserinfo.title')}</h2>
	{(userReg.regFound) ?
		<>
		<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
			<div className={`${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{t('flows.admin.regscan.showuserinfo.ipaddr')}:</label>
			</div>
			<div className={`${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{userReg.ipAddress}</label>
			</div>
			<div className={`${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{t('flows.admin.regscan.showuserinfo.date')}:</label>
			</div>
			<div className={`${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
				<label className={`${e3Style['input--fluid']}`}>{(formatRegDate(userReg.regDate))}</label>
			</div>
		</div>
		{(userReg.createdByCustSrv) ?<p>{t('flows.admin.regscan.showuserinfo.agentinfo')}</p>
			:
			<>
			{userReg.userRegList && (userReg.userRegList.length > 0) ?
				<>
					<table className={`${sprStyle['bdr-1-bs']} ${sprStyle['agt-note-tbl']}`}>
						<colgroup>
							<col className={`${sprStyle['width-10pct']}`}/>
							<col className={`${sprStyle['width-10pct']}`}/>
							<col className={`${sprStyle['width-16pct']}`}/>
							<col className={`${sprStyle['width-10pct']}`}/>
							<col className={`${sprStyle['width-11pct']}`}/>
							<col className={`${sprStyle['width-12pct']}`}/>
							<col className={`${sprStyle['width-18pct']}`}/>
							<col/>
						</colgroup>
						<tr>
							<th className={`${sprStyle['bdr-1-bs']}`}>Active</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>Device Match</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>IP Address</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>First Name</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>Last Name</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>User ID</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>Email</th>
							<th className={`${sprStyle['bdr-1-bs']}`}>Date</th>
						</tr>
						{userReg.userRegList.map((regData, idx) => 
						<tr index={idx}>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.DELETED ? 'Deleted' : 'Active'}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.HASH_MATCH ? 'Y' : 'N'}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.IP_ADDRESS}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.FIRST_NAME}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.LAST_NAME}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.USERID}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{regData.EMAIL}</td>
				 			<td className={`${sprStyle['bdr-1-bs']}`}>{(formatRegDate(regData.REG_DATE))}</td>
				 		</tr>
						)}
					</table>
				</>
				:
				<p>{t('flows.admin.regscan.showuserinfo.noreginfo')}</p>
			}
			</>
		}
		</>
		: 
		<p>{t('flows.admin.regscan.showuserinfo.nouserdata')}</p>
	}
	</>
	);
};

export default showRegScan;	
