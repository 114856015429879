import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
const englishFile = require('../../locales/en/translations.json');
const spanishFile = require('../../locales/es/translations.json');

i18n
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: '/resources/locales/{{lng}}/{{ns}}.json'
		},
		/* default language when loading the website in the browser */
		lng: "en",
		/* language to use when the requested language is unavailable */
		fallbackLng: "en",
		/* debugger For Development environment */
		debug: process.env.NODE_ENV === 'development',
		ns: ["translations"],
		defaultNS: "translations",
		keySeparator: ".",
		interpolation: {
			escapeValue: false,
			formatSeparator: ","
		},
/*		react: {
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default'
		}*/
	})

export default i18n;
