import React, {useEffect, useState}  from 'react';
import e3Style from '../../../../css/e3-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import Joi from "joi";
import { useValidator } from "react-joi";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import StatusMsg from "../../util/statusMsg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";

import {adminNotesMgrNotesPath, STSMessagesMessagesPath, adminLoginPath} from "../../layout/body";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedTextarea from "../../util/focusWrappedTextarea";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const AdminAddText = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const params = useParams();
	const actionType = params.actionType;
	const itemIdx = Number(params.itemIdx);

	const [oldMsgText, setOldMsg] = useState("");

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			noteText: ""
		},
 	schema: Joi.object({
			noteText: Joi.string().trim().min(1).max(4000).required().messages({
				'string.empty':'flows.admin.notes.title.4',
				'string.max':'flows.admin.notes.title.5'
			})
		}),
		explicitCheck: {
			noteText: false
		}
	});

	useEffect(() => {
		if( actionType === 'editMsg' ) getMessage();
	}, []);

	const saveNote = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'addAdminNote', csrfSessionNonce},
				 params: {
							note: state.$data.noteText											
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setHistoryPath(dispatch, navigate, adminNotesMgrNotesPath);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const getMessage = async () => {
		try {
			if( oldMsgText.length === 0) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getSTSMessages', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							const msgText = String(Object.values(data.response.STSMsgs)[itemIdx]);
							setData((old) => ({
									...old,
								noteText: msgText
							}));
							setOldMsg(msgText);
						  	break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveMessage = async () => {
		try {
			let cmdName = 'adminAddSTSMsg';
			let msgData = {newMsg:state.$data.noteText};
			if( actionType === 'editMsg' ) {
				cmdName = 'adminUpdateSTSMsg';
				msgData = {newMsg:state.$data.noteText, oldMsg:oldMsgText};
			}
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: cmdName, csrfSessionNonce}, params: msgData}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setHistoryPath(dispatch, navigate, STSMessagesMessagesPath);
					    break;

					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const submitNote = e => {
		e.preventDefault();
		if( actionType === 'addNote' )
			saveNote();
		else
			saveMessage();
	};

	const onCancel = e => {
		e.preventDefault();
		if( actionType === 'addNote' )
			setHistoryPath(dispatch, navigate, adminNotesMgrNotesPath);
		else
			setHistoryPath(dispatch, navigate, STSMessagesMessagesPath);
	}
	return(
		<>
			<StatusMsg/>
			<form noValidate autoComplete="off" onSubmit={submitNote} className={e3Style['account-preferences_notes']}>
				<h2>{t( actionType === 'addNote'? 'flows.admin.notes.title.2' : ( actionType === 'editMsg'? 'flows.admin.stsmessage.title.2':'flows.admin.stsmessage.title.1') )}</h2>
				<FocusWrappedGrp baseAriaId="noteTextErr" cptErrCount={state.$errors.noteText.length}>
					<FocusWrappedTextarea initialFocus name="noteText" id="noteText" className={sprStyle['add-agt-note-ta']}
					                      onChange={e => updateField(e, 'noteText')}
					                      onBlur={() => setExplicitField('noteText', true)}
					                      value={state.$data.noteText}/>
					{!!state.$errors.noteText.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.noteText.map((err, idx) => <p index={idx} id={'noteTextErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
					<button type="button" onClick={onCancel}
						className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.cancel')} </button>

					<button type="submit" onClick={validate} disabled={state.$auto_invalid}
			            className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.submit')} </button>
					</div>
				</div>
			</form>
		</>
	);
};

export default AdminAddText;
