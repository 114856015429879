import React from 'react';
import {useTranslation} from "react-i18next";
import {setHistoryPath} from "../../../store/bodyView";
import {rootPath} from "../../layout/body";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";

const DisplayMsg = () => {
	const { t } = useTranslation();
	return <div>{t('oops.error.message')}</div>;
};

const Gblex = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const displayMessage = {
		headerMsg: 'oops.error.header',
		DisplayMsg,
		buttonList: [
			{
				onClick: e => {e.preventDefault();setHistoryPath(dispatch, navigate, rootPath)},
				btnText: 'button.ok'
			}
		]
	};
	return <DisplayMessage displayMsgObj={displayMessage}/>;
};

export default Gblex;
