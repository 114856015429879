import React, {useEffect} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_NONE, STATUS_ERROR} from "../../../store/bodyView";
import {
	getFreqNumbers,
	updateFreqNbr,
	delFreqNbr,
	loadFreqDialedNums
} from "../../../store/FreqNumberList";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import PhoneNumberItem from "../Common/PhoneNumberItem";
import {useNavigate} from "react-router";
import {loginPath, addFreqNumbersPath} from "../../layout/body";
import {stripNonNums} from "../../util/stringFormatHelper";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const FreqNumbers = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const curFreqList = useSelector(getFreqNumbers);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	useEffect(() => {
		const successFunc = () => {};
		const errorFunc = ({dispatch}, response) => {
			if (response.statusType === 0) {
				switch (response.status) {
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		};
		loadFreqDialedNums(dispatch, csrfSessionNonce, successFunc, errorFunc);
	}, []);

	const updateFrequentNumber = myPhoneNbrItem => {
		let matchedIdx = -1;

        if (curFreqList?.length > 0){
			matchedIdx = curFreqList.findIndex(
				value => (value.PHONE_NUMBER === stripNonNums(myPhoneNbrItem.PHONE_NUMBER) && value.ORDERID !== myPhoneNbrItem.ORDERID));
			if(matchedIdx >= 0)
				setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
		}
		if(matchedIdx < 0)
			updateNumber({...myPhoneNbrItem});
	};

	const updateNumber = async myPhoneNbrItem => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'editFreqNumber', csrfSessionNonce},
					params: {
						orderId: myPhoneNbrItem.ORDERID,
						phoneNumber: stripNonNums(myPhoneNbrItem.PHONE_NUMBER),
						name: myPhoneNbrItem.NAME.trim()
					}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						updateFreqNbr(dispatch,{"ORDERID": myPhoneNbrItem.ORDERID,
							"PHONE_NUMBER": stripNonNums(myPhoneNbrItem.PHONE_NUMBER),
							"NAME": myPhoneNbrItem.NAME.trim()});
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					case 426:
						setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const deleteNumber = async myOrderId => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{	command: {name: 'deleteFreqNumber', csrfSessionNonce},
					params: {orderId: myOrderId}
				},
				{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						delFreqNbr(dispatch,myOrderId);
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const findId = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, addFreqNumbersPath+`/addFreqNumber`);
	};

	return (
		<>
			<StatusMsg/>
			<form className={e3Style['account-preferences_accounts']}>
				<p>{t('flows.freqdialed.freqnumbers.1')}</p>

				<fieldset className={`${e3Style['sprint-frequently-dialed-numbers']} ${e3Style['sprint-account_form_items']}`}>
				{ curFreqList?.length > 0 ?
					<div className={e3Style['scrollable-div']}>
					{curFreqList.map(freqDailedNumber =>
						<PhoneNumberItem key={freqDailedNumber.ORDERID} phoneNbrItem={freqDailedNumber}
						                 onDeleteFunc={deleteNumber} onUpdateFunc={updateFrequentNumber}
										 onActionFunc={() => {}}/>
					)}
					</div>
					:
					<>
					<p>{t('flows.freqdialed.freqnumbers.2')}</p>
					</>
				}
				</fieldset>

				{  curFreqList?.length > 0 &&
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']} ${e3Style['mt-60']} ${e3Style['pl-0']}`}>
						<p>{t('flows.freqdialed.freqnumbers.3')} {curFreqList.length} {t('flows.freqdialed.freqnumbers.4')}</p>
					</div>
				}

				<div className={`${e3Style.row}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" onClick={e => {findId(e)}} autoFocus
						className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
						{t('button.addNumber')}
						</button>
					</div>
				</div>
				</form>
		</>
	);
}

export default FreqNumbers;