import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sprStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminLoginPath, adminAddTextPath} from "../../../layout/body";
import {getCsrfSessionNonce, getUserInfo, isCurrentUser} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";
import AdminNotesItem from "./adminNotesItem";

const adminNotes = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userData = useSelector(getUserInfo);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const isCurrentUserFlag = isCurrentUser(userData);
	const [notes, setNotes] = useState({notesList:[]});

	useEffect(() => {
		getNotesList();
	}, []);

	const getNotesList = async () => {
		try {
			//if( notes.notesList == null || notes.notesList.length == 0) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getAdminNotesList', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						  const sortedList = data.response.notesList.slice(0).sort((a, b) => a.id - b.id);
						  setNotes({notesList: sortedList});
						  break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
//			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const notesDate = (dt) => {
		let nDate = new Date(dt);
		let month = (nDate.getMonth()+1 < 10) ? '0'+(nDate.getMonth()+1) : nDate.getMonth()+1;
		let newdt = (nDate.getDate() < 10) ? '0' + nDate.getDate() : nDate.getDate();
		return month + '-' + newdt + '-' + nDate.getFullYear();
	}
	
	const deleteNote = async (myNoteId) => {
		try {
			  	setNotes({notesList: []});
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'deleteAdminNote', csrfSessionNonce}, 
					 params: {noteId: myNoteId.toString()}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						  const sortedList = data.response.notesList.slice(0).sort((a, b) => a.id - b.id);
						  setNotes({notesList: sortedList});
						  break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	}
	
	return (
	<>
		<StatusMsg/>
		<form id="account-anchor" className={`${e3Style['account-preferences_notes']}`}>
		<h2>{t('flows.admin.notes.title.1')}</h2>
		{notes.notesList && (notes.notesList.length > 0) ?
		<div className ={e3Style['scrollable-div']}>
		{notes.notesList.map(curNote => {
		return (
				<AdminNotesItem key={curNote.NOTE_ID} noteItem={curNote} 
					 onDeleteFunc={deleteNote} isCurrentUser={isCurrentUserFlag} />
		);
  		})}
		</div>
		:
		<><p>No notes recorded.</p>
		</>
		}

	{isCurrentUserFlag && 
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
			<button type="button" autoFocus onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, adminAddTextPath+'/addNote/0')}}
				className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>{t('flows.admin.notes.title.3')}</button>
		</div>
	</div>
    }

	</form>
	</>
	);
};

export default adminNotes;
