import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Body from "./components/layout/body";
import {useTranslation} from "react-i18next";
import {BrowserRouter} from "react-router-dom";
import {queryCallData} from "./store/call";
import {getCsrfSessionNonce, getUserIdAndAdmin} from "./store/user";
import {Helmet} from "react-helmet";
import {getDisplayClasses, getHtmlClasses} from "./store/display";
import ScrollToTop from "./components/util/scrollToTop";
import PopupGroup from "./components/popups/popupGroup";
import CallMgr from "./components/call/callMgr";
import {loadDefaultSettings} from "./store/defaultSettings";

const App = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const classes = useSelector(getDisplayClasses);
    const userIdAndAdmin = useSelector(getUserIdAndAdmin);
    const csrfSessionNonce = useSelector(getCsrfSessionNonce);
    useEffect(() => {
        if (csrfSessionNonce) {
            const timer = {id: null};
            const callback = success => {
                if (success) timer.id = null;
                else timer.id = setTimeout(() => loadDefaultSettings(dispatch, csrfSessionNonce, callback), 1000);
            };
            loadDefaultSettings(dispatch, csrfSessionNonce, callback);
            return () => clearTimeout(timer.id);
        }
    }, [csrfSessionNonce]);
    useEffect(() => {
        queryCallData(dispatch, userIdAndAdmin);
    }, [userIdAndAdmin]);

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Helmet>
                <html lang={i18n.language} className={getHtmlClasses(classes.color, classes.size, classes.font)}/>
            </Helmet>
            <Header />
            <CallMgr>
                <Body />
            </CallMgr>
            <Footer />
            <PopupGroup/>
        </BrowserRouter>
    );
};

export default App;
