import React, {useState} from 'react';
import e3Style from "../../../css/e3-style.css";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import StatusMsg from "../util/statusMsg";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../store/bodyView";
import {loginPath, chgPasswordPath} from "../layout/body";
import {getNonce, getId, getQues, setAns} from '../../store/pswdNonce';

const verifySecAnswer = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [secAns, setSecAns] = useState("");
	const [validAns, setValidAns] = useState(true);
	const nonceObj = useSelector(getNonce);
	const userIdObj = useSelector(getId);
	const queObj = useSelector(getQues);

	const verifySecAns = async () => {
		try {
			const response = await axios.post('/nologin/api',
			{	command: {name: 'forgotPswdSecAns'},
								params: {usernm: userIdObj.trim(),
								         secans: secAns.trim(),
										 nonce: nonceObj	}
							}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setHistoryPath(dispatch, navigate, chgPasswordPath);
						 	break;
						case 422:
							setStatusMessage(dispatch, 'page.verifySecAnsr.7', STATUS_ERROR);
							break;
						case 999:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const onSecAnsChange = e => {
		const curValue = e.target.value;
		setSecAns(curValue);
		let obj = (curValue.trim().length >=4 && curValue.trim().length <= 30 ) ? true : false;
		setValidAns(curValue.trim().length === 0? true:obj);
	    setAns(dispatch, curValue);
	}
	
	const onSubmit = e => {
		e.preventDefault();
		verifySecAns();
	};

	return (
	<>
		 <div className={e3Style['container-xl']}>
	    <div className={`${e3Style.row} ${e3Style['row--spacing-gapless']} ${e3Style['sprint-ctas']}`}>
	      <div className={`${e3Style['col-sm-24']} ${e3Style['col-md-10']} ${e3Style['col-lg-14']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
	        <h1>{t('page.verifySecAnsr.1')}</h1>
	        <p>{t('page.verifySecAnsr.2')}</p>
	      </div>
	      <div className={`${e3Style['col-sm-24']} ${e3Style['col-md-14']} ${e3Style['col-lg-10']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
  		<h3 className={`${e3Style['mb-20']} ${e3Style['row--gutter-gapless']}`}>{t('page.verifySecAnsr.3')}</h3>
		<StatusMsg/>
		<form noValidate autoComplete="off" className={e3Style['account-preferences_notes']}>
		<fieldset className={e3Style['sprint-account_form_items']}>
		  <div className={e3Style.row}>
			      <div className={`${e3Style['col-xs-24']} ${e3Style['col-md-25']}`}>
 		<label className={`${e3Style['text-uppercase']} ${e3Style['focused-label']} ${e3Style['txt-io']}`} htmlFor="secanslabel">{queObj}</label>
					<input autoFocus id="ans" name="ans" type="text" value={secAns}
					placeholder={t('page.verifySecAnsr.4')}
				 className={`${e3Style['input--fluid']} ${e3Style['txt-io']}`}  
				 onChange={onSecAnsChange}/>
			     </div>
			{!validAns && 
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							<p><span>{t('page.verifySecAnsr.5')}</span></p>
						</div>
					</div>
			}
		      </div>
		</fieldset>
		<div className={`${e3Style['row']}`}>
			<div className={`${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		  		<button type="submit" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
			  disabled={secAns.trim().length === 0 || (!validAns)} onClick={onSubmit}>{t('button.submit')}</button>
		  	</div>
		</div>
		</form>
		</div></div></div>
		</>
	);
};

export default verifySecAnswer;