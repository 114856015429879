import React, {useMemo} from 'react';
import {useParams} from "react-router";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {chooseUser} from "../../../../store/user";
import {rootPath} from "../../../layout/body";
import UserListGeneric from "./userListGeneric";
import {updateActTime} from "../../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../../util/ioHelper";

const displayObj = (nameTypeVal, firstVal, lastVal) => ({
	pgTxt: {
		lastnameLabel: 'prefs.admin.selectuser.userlist.lastname',
		firstnameLabel: 'prefs.admin.selectuser.userlist.firstname',
		addressLabel: 'prefs.admin.selectuser.userlist.address',
		cityLabel: 'prefs.admin.selectuser.userlist.city',
		stateLabel: 'prefs.admin.selectuser.userlist.state',
		phoneNumLabel: 'prefs.admin.selectuser.userlist.phone'
	},
	readUserList: async (dispatch, csrfSessionNonce, setUserList) => {
		let firstName = firstVal;
		let lastName = lastVal;
		switch (nameTypeVal) {
			case 'firstonly':
				lastName = '';
				break;

			case 'lastonly':
				firstName = '';
				break;
		}
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: 'adminSearchUserFullname', csrfSessionNonce},
					params: {firstName, lastName}
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200) {
					setUserList(data.response.userList);
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	},
	selectUser: (userId, dispatch, navigate, csrfSessionNonce) => {
		chooseUser(dispatch, navigate,'adminSearchUserById',{userId}, rootPath, csrfSessionNonce,
			() => {},() => {});
	},
	goBack: (dispatch, navigate) => {setHistoryPath(dispatch, navigate, rootPath)}
});

const UserList = () => {
	const {nametype, first, last} = useParams();
	const displayParam = useMemo(() => displayObj(nametype, first, last), [nametype, first, last]);

	return <UserListGeneric displayObj={displayParam}/>;
};

export default UserList;
