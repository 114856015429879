import React from 'react';
import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";

const NO_POPUP = 0;
const DISPLAY_SETTINGS_POPUP = 1;
const WINDOW_PREFS_POPUP = 2;
const INCOMING_CALL_POPUP = 3;
const WARN_CALL_IN_PROG = 4;
const WARN_DELETE_USER = 5;
const OVERRIDE_INTERVENTIONS = 6;
const CONTACT_CUST_CARE = 7;
const CALL_FINISHED = 8;
const FORGOT_USER_NAME = 9;
const WARN_INACTIVITY = 10;
const SHOW_DISCLAIMER = 11;
const SUSPEND_USER = 12;

const slice = createSlice({
	name: "popupMgr",
	initialState: {
		popupWinId: NO_POPUP,
		popupWinData: null,
		popupTimeout: null
	},
	reducers: {
		setPopupWinId: (view, action) => {
			const {popupWinId, popupWinData, popupTimeout} = action.payload.custom;
			view.popupWinId = popupWinId;
			view.popupWinData = popupWinData;
			view.popupTimeout = popupTimeout;
		}
	}
});

export const {setPopupWinId} = slice.actions;
export default slice.reducer;

const setPopupId = (dispatch, id, data, popupTimeout) => dispatch({type: setPopupWinId.type, payload: {custom: {popupWinId: id, popupWinData: data, popupTimeout}}});

export const closePopup = dispatch => setPopupId(dispatch, NO_POPUP, null);

export const openDisplaySettings = dispatch => setPopupId(dispatch, DISPLAY_SETTINGS_POPUP, null);

export const openWindowPrefs = dispatch => setPopupId(dispatch, WINDOW_PREFS_POPUP, null);

export const openIncomingCall = (dispatch, phoneNbr, callback, popupTimeout) => setPopupId(dispatch, INCOMING_CALL_POPUP, {phoneNbr, callback}, popupTimeout);

export const openWarnCallIn = (dispatch, disconnect) => setPopupId(dispatch, WARN_CALL_IN_PROG, disconnect);

export const openWarnDelUser = (dispatch, customerId) => setPopupId(dispatch, WARN_DELETE_USER, customerId);

export const openOverrideIntrv = dispatch => setPopupId(dispatch, OVERRIDE_INTERVENTIONS, null);

export const openCustCare = dispatch => setPopupId(dispatch, CONTACT_CUST_CARE, null);

export const openCallFinished = (dispatch, callback, popupTimeout) => setPopupId(dispatch, CALL_FINISHED, callback, popupTimeout);

export const openForgotUserName = dispatch => setPopupId(dispatch, FORGOT_USER_NAME, null);

export const openWarnInactivity = (dispatch, callback, popupTimeout) => setPopupId(dispatch, WARN_INACTIVITY, callback, popupTimeout);

export const openShowDisclaimer = dispatch => setPopupId(dispatch, SHOW_DISCLAIMER, null);

export const openSuspendUser = (dispatch, suspend) => setPopupId(dispatch, SUSPEND_USER, suspend);

export const isAnyWindowOpen = createSelector(
	state => state.entities.popupMgr.popupWinId !== NO_POPUP,
	id => id
);

export const isDisplaySettingsOpen = createSelector(
	state => state.entities.popupMgr.popupWinId === DISPLAY_SETTINGS_POPUP,
	id => id
);

export const isWindowPrefsOpen = createSelector(
	state => state.entities.popupMgr.popupWinId === WINDOW_PREFS_POPUP,
	id => id
);

export const incomingCallSel = createSelector(
	state => state.entities.popupMgr.popupWinId === INCOMING_CALL_POPUP,
	state => state.entities.popupMgr.popupWinId === INCOMING_CALL_POPUP ? state.entities.popupMgr.popupWinData : null,
	state => state.entities.popupMgr.popupWinId === INCOMING_CALL_POPUP ? state.entities.popupMgr.popupTimeout : null,
	(isOpen, data, popupTimeout) => ({isOpen, data, popupTimeout})
);

export const warnCallInProgSel = createSelector(
	state => state.entities.popupMgr.popupWinId === WARN_CALL_IN_PROG,
	state => state.entities.popupMgr.popupWinId === WARN_CALL_IN_PROG ? state.entities.popupMgr.popupWinData : null,
	(isOpen, data) => ({isOpen, disconnect: data})
);

export const warnDelUserSel = createSelector(
	state => state.entities.popupMgr.popupWinId === WARN_DELETE_USER,
	state => state.entities.popupMgr.popupWinId === WARN_DELETE_USER ? state.entities.popupMgr.popupWinData : null,
	(isOpen, data) => ({isOpen, data})
);

export const overrideIntrvSel = createSelector(
	state => state.entities.popupMgr.popupWinId === OVERRIDE_INTERVENTIONS,
	state => state.entities.popupMgr.popupWinId === OVERRIDE_INTERVENTIONS ? state.entities.popupMgr.popupWinData : null,
	(isOpen, data) => ({isOpen, data})
);

export const contactCustCareSel = createSelector(
	state => state.entities.popupMgr.popupWinId === CONTACT_CUST_CARE,
	state => state.entities.popupMgr.popupWinId === CONTACT_CUST_CARE ? state.entities.popupMgr.popupWinData : null,
	(isOpen, data) => ({isOpen, data})
);

export const callFinishedSel = createSelector(
	state => state.entities.popupMgr.popupWinId === CALL_FINISHED,
	state => state.entities.popupMgr.popupWinId === CALL_FINISHED ? state.entities.popupMgr.popupWinData : null,
	state => state.entities.popupMgr.popupWinId === CALL_FINISHED ? state.entities.popupMgr.popupTimeout : null,
	(isOpen, callback, popupTimeout) => ({isOpen, callback, popupTimeout})
);

export const forgotUserNameSel = createSelector(
		state => state.entities.popupMgr.popupWinId === FORGOT_USER_NAME,
		state => state.entities.popupMgr.popupWinId === FORGOT_USER_NAME ? state.entities.popupMgr.popupWinData : null,
		(isOpen, data) => ({isOpen, data})
);

export const warnInactivityOpenSel = createSelector(
	state => state.entities.popupMgr.popupWinId === WARN_INACTIVITY,
	state => state.entities.popupMgr.popupWinId === WARN_INACTIVITY ? state.entities.popupMgr.popupWinData : null,
	state => state.entities.popupMgr.popupWinId === WARN_INACTIVITY ? state.entities.popupMgr.popupTimeout : null,
	(isOpen, callback, popupTimeout) => ({isOpen, callback, popupTimeout})
);

export const isShowDisclaimerOpen = createSelector(
	state => state.entities.popupMgr.popupWinId === SHOW_DISCLAIMER,
	id => id
);

export const suspendUserSel = createSelector(
	state => state.entities.popupMgr.popupWinId === SUSPEND_USER,
	state => state.entities.popupMgr.popupWinId === SUSPEND_USER ? state.entities.popupMgr.popupWinData : null,
	(isOpen, suspend) => ({isOpen, suspend})
);

export const focusIfNoPopup = (isOpen, ref) => {
	if (!isOpen) ref.current?.focus();
};
