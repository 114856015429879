import React, {useEffect, useState} from 'react';
import ReactTooltip from "react-tooltip";
import e3Style from '../../../../css/e3-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import Joi from "joi";
import { useValidator } from "react-joi";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {emailVerifyMsgPath, loginPath, regConfirmAddressPath} from "../../layout/body";
import {setEditAddress, setFormattedAddress, setStateList, getStateList} from "../../../store/AddressList"
import {getRegistry, getInitData, resetRegistry, setRegistry} from "../../../store/registry"
import {updateActTime} from "../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../util/ioHelper";
import {stripNonNums} from "../../util/stringFormatHelper";

const trimRegData = regData => {
	return {
		...regData,
		firstName: regData.firstName.trim(),
		lastName: regData.lastName.trim(),
		homeAddress1: regData.homeAddress1.trim(),
		homeAddress2: regData.homeAddress2.trim(),
		city: regData.city.trim(),
		phoneNbr: stripNonNums(regData.phoneNbr.trim()),
		emailAddress: regData.emailAddress.trim(),
		username: regData.username.trim(),
		secAnswer: regData.secAnswer.trim(),
		birthDate: "01/01/1970",
		sprintIpNumber: "false",
		advisoryRead: "false",
		certifyDisability: "false"
	};
};

const Registration = () => {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const stateList = useSelector(getStateList);
	const registryData = useSelector(getRegistry);
	const [secparams, setSecparams] = useState({
		securityQuestionList: [],
		dataReady: false});
	const [pswd2Match, setPswd2Match] = useState("");
	const [ipNbrChecked, setIpNbrChecked] = useState(false);
	const [unameUsed, setUnameUsed] = useState(false);
	const [emailUsed, setEmailUsed] = useState(false);
	const [phoneNbrInvalid, setPhoneNbrInvalid] = useState(false);
	const [scroll, setScroll] = useState(false);

	const confPswdValidation = (value, helpers) => {
		if(value !== pswd2Match)
			return helpers.error('any.invalid');
		return value;
	};	

	const chkIpValidation = (value, helpers) => {
		if( ipNbrChecked && (!value) )
			return helpers.error('any.invalid');
		return value;
	};

	const chkEmailAvail = (value, helpers) => {	
		if( emailUsed )
			return helpers.error('any.invalid');
		else
			return value;
	};

	const chkUnameAvail = (value, helpers) => {
		if( unameUsed )
			return helpers.error('any.invalid');
		else
			return value;
	};

	const chkPhoneNbrInvalid = (value, helpers) => {
		if( phoneNbrInvalid )
			return helpers.error('any.invalid');
		else
			return value;
	};

	const schema_emailAddress = Joi.string().trim().max(100).email({
                    tlds: { allow: false },
                }).custom(chkEmailAvail, 'check if email address used').required().messages({
				'string.empty':'flows.registration.reginfo.21',
				'string.email':'flows.registration.reginfo.22',
				'any.invalid':'flows.registration.reginfo.20'
			});

	const schema_username = Joi.string().trim().min(8).max(50).regex(/^[a-zA-Z0-9@_\.-]{8,50}$/).custom(chkUnameAvail, 'check if username used').required().messages({
				'string.empty':'flows.registration.reginfo.33',
				'string.min': 'flows.registration.reginfo.31',
				'string.max':'flows.registration.reginfo.31',
				'any.invalid':'flows.registration.reginfo.35'
			});

	const schema_phoneNbr = Joi.string().trim().regex(/^(\(\s*\d{3}\s*\)|(\d{3}))\s*(-\s*)?\d{3}\s*(-\s*)?\d{4}$/).custom(chkPhoneNbrInvalid, 'check if phone number is valid NPA/NXX').required().messages({
			'string.empty':'flows.registration.reginfo.27',
			'string.pattern.base': 'flows.registration.reginfo.28',
			'any.invalid':'flows.registration.reginfo.28'
		});

	//TODO check on all validations later and Add error messages in trs.json
	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: getInitData(),
		schema: Joi.object({
		firstName: Joi.string().trim().regex(/^[a-z 'A-Z]{1,30}$/).required().messages({
				'string.empty':'flows.registration.reginfo.5',
				'string.pattern.base': 'flows.registration.reginfo.55'
			}),
		lastName: Joi.string().trim().regex(/^[a-z '\-A-Z]{1,30}$/).required().messages({
				'string.empty':'flows.registration.reginfo.7',
				'string.pattern.base': 'flows.registration.reginfo.55'
			}),	
		homeAddress1: Joi.string().trim().min(1).max(60).required().messages({
				'string.empty':'flows.registration.reginfo.9'
			}),
		city: Joi.string().trim().min(1).max(35).required().messages({
				'string.empty':'flows.registration.reginfo.12'
			}),
		stateAbbrev: Joi.string().min(1).required().messages({
				'string.empty':'flows.registration.reginfo.15'
			}),
		zipCode: Joi.string().trim().regex(/^[0-9]{5}$/).required().messages({
				'string.empty':'flows.registration.reginfo.17',
				'string.pattern.base': 'flows.registration.reginfo.18'
			}),
		emailAddress: schema_emailAddress,
		phoneNbr: schema_phoneNbr,
		username: schema_username,
		password: Joi.string().trim().min(10).max(64).regex(/^(?=.*[0-9])(?=.*[a-zA-Z]).*$/).required().messages({
				'string.empty':'flows.registration.reginfo.37',
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'string.pattern.base': 'flows.registration.reginfo.56'
			}),
		confirmPswd: Joi.string().custom(confPswdValidation, 'confirm password validation').messages({
				'string.empty':'flows.registration.reginfo.40',
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'any.invalid': 'flows.registration.reginfo.50'
			}),
		secQuestionId: Joi.number().min(2).required().messages({
				'number.min':'flows.registration.reginfo.42'
			}),
 		secAnswer: Joi.string().trim().min(4).max(30).required().messages({
				'string.empty':'flows.registration.reginfo.44',
				'string.min':'flows.registration.reginfo.34',
				'string.max':'flows.registration.reginfo.34'
			})
		}),
		explicitCheck: {
			firstName: false,
			lastName: false,
			homeAddress1: false,
			city: false,
			stateAbbrev: false,
			zipCode: false,
			emailAddress: false,
			phoneNbr: false,
			username: false,
			password: false,
			confirmPswd: false,
			secQuestionId: false,
			secAnswer: false
		}
	});

	useEffect(() => {
		if(stateList.length === 0)
			getStates();
		getSecurityQuestions();
		if (registryData?.initComplete) {
			const {initComplete: _, ...regData} = registryData;
			setData(regData);
			if (regData.password.length > 0)
				setPswd2Match(regData.password);
			setIpNbrChecked(regData.sprintIpNumber);
			getUserIdAvail(regData.username);
			getEmailAvail(regData.emailAddress);
		}
		else
			saveRegStartEvent();
	}, []);
	useEffect(() => {
		if (scroll) {
			window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
			setScroll(false);
		}
	}, [scroll]);

	const saveRegStartEvent = async () => {
		const campaign = (registryData.campaign === null || registryData.campaign.length == 0 ) ? searchParams.get('campaign') : registryData.campaign;
		try {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
						{command: {name: 'regSaveEvent'},
						 params: {
							eventType: 'regStart',
							campaign: campaign
						 }}, {timeout: 3000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							break;
					}
				}
		} catch (error) {
		}
	};

	const getStates = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
					{command: {name: 'getStates'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setStateList(dispatch, data.response.statesList);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const getSecurityQuestions = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getSecQuestions'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
				        setSecparams({...secparams,
	                    securityQuestionList: data.response.securityQuestionList,
						dataReady: true});
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						console.error("failed getting security questions "+data.status.code+"|"+data.status.description);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				console.error("failed getting security questions response status="+response.status);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			console.error("failed getting security questions error="+error);
		}
	};
	
	const getEmailAvail = async email => {
		const trimEmail = email.trim();
		if (trimEmail.length) {
			try{
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getEMailAvail' },
						params: {
							email:trimEmail
						}
					}, {timeout: 5000});

				if (response.status === 200) {
					const {data} = response;

					switch (data.status.code) {
						case 200:
							if(!data.response.emailAvailable){
								setEmailUsed(true);
								schema_emailAddress.validate(trimEmail);
								return;
							}
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							console.error("failed on email address used validation. "+data.status.code+"|"+data.status.description);
							break;
					}
				} else {
					console.error("failed getting email address used validation. response status="+response.status);
				}
			} catch (error) {
				console.error("failed getting email address used validation. error="+error);
			}
		}

		setEmailUsed(false);
		schema_emailAddress.validate(trimEmail);
	};

	const getUserIdAvail = async userId => {
		const trimUserId = userId.trim();
		if (trimUserId.length) {
			try {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getUserIdAvail' },
						params: {
							userid:trimUserId
						}
					}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;

					switch (data.status.code) {
						case 200:
							if(!data.response.useridAvailable){
								setUnameUsed(true);
								schema_username.validate(trimUserId);
								return;
							}
							break;
						default:
							console.error("failed on user id used validation. "+data.status.code+"|"+data.status.description);
							break;
					}
				} else {
					console.error("failed getting user id used validation. response.status="+response.status);
				}
			} catch (error) {
				console.error("failed getting user id used validation."+error);
			}
		}

		setUnameUsed(false);
		schema_username.validate(trimUserId);
	};

	const registerUser = async zip4 => {
		const campaign = (registryData.campaign === null || registryData.campaign.length == 0 ) ? searchParams.get('campaign') : registryData.campaign;
		try {
			updateActTime(dispatch);
			const tempParams = {
						...trimRegData(state.$data),
						secQuestionId:  state.$data.secQuestionId.toString(),
						securityAnswer: state.$data.secAnswer,
						//sprintIpNumber: state.$data.sprintIpNumber.toString(),
						//advisoryRead: state.$data.advisoryRead.toString(),
						//certifyDisability: state.$data.certifyDisability.toString(),
						sprintIpNumber: "false",
						advisoryRead: "false",
						certifyDisability: "false",
						zip4,
						language: i18n.language === 'es' ? 'SPA' : 'ENG',
						campaign
					};
			const{confirmPswd, secAnswer, ...dataParams} = tempParams;
			const response = await postWithTimeout(
				{command: {name: 'saveRegistry'},
					params: dataParams
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					case 205:
						resetRegistry(dispatch);
						setHistoryPath(dispatch, navigate, emailVerifyMsgPath);
						break;
					case 402:
						setStatusMessage(dispatch, 'flows.registration.reginfo.28', STATUS_ERROR);
						setPhoneNbrInvalid(true);
						setScroll(true);
						break;
					case 411:
						setStatusMessage(dispatch, 'flows.registration.reginfo.35', STATUS_ERROR);
						setUnameUsed(true);
						setScroll(true);
						break;
					case 412:
						setStatusMessage(dispatch, 'flows.registration.reginfo.20', STATUS_ERROR);
						setEmailUsed(true);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						setScroll(true);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const checkAddrInfo = async () => {
		const campaign = (registryData.campaign === null || registryData.campaign.length == 0 ) ? searchParams.get('campaign') : registryData.campaign;
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getRegistrationNonce'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200){
					try {
						const newAddress = {
							addressLine1: state.$data.homeAddress1.trim(),
							addressLine2: state.$data.homeAddress2? state.$data.homeAddress2.trim() : '',
							city: state.$data.city.trim(),
							stateAbbr: state.$data.stateAbbrev,
							zip: state.$data.zipCode,
							zip4: '',
							nonce: data.response.nonce
						};

						updateActTime(dispatch);
						const response = await postWithTimeout(
						{command: {name: 'formatRegisterAddr'},
							params: newAddress
						}, EXTENDED_TIMEOUT);

						if (response.status === 200) {
							const {nonce: _, ...editAddress} = newAddress;
							const {data} = response;
							switch (data.status.code) {
								case 200:
									const formattedAddress = {...data.response,
										addressLine2: data.response.addressLine2? data.response.addressLine2 : '',
										zip4: data.response.zip4? data.response.zip4 : ''};

									if( formattedAddress.addressLine1 === newAddress.addressLine1.toUpperCase() &&
										(formattedAddress.addressLine2 === undefined || formattedAddress.addressLine2 === newAddress.addressLine2.toUpperCase()) &&
										formattedAddress.city === newAddress.city.toUpperCase() &&
										formattedAddress.stateAbbr === newAddress.stateAbbr &&
										formattedAddress.zip === newAddress.zip) {
										registerUser(formattedAddress.zip4 ?? '');
									} else {
										setRegistry(dispatch, {...trimRegData(state.$data), campaign:campaign});
										setFormattedAddress(dispatch, formattedAddress);
										setEditAddress(dispatch, editAddress);
										setHistoryPath(dispatch, navigate, regConfirmAddressPath+ `/register/selectaddr`);
									}
									break;

								case 419:
									setRegistry(dispatch, {...trimRegData(state.$data), campaign:campaign});
									setEditAddress(dispatch, editAddress);
									setHistoryPath(dispatch, navigate, regConfirmAddressPath+ `/register/invalidaddr`);
									break;

								case 998:
									resetRegistry(dispatch);
									setHistoryPath(dispatch, navigate, loginPath);
									break;

								default:
									console.error('error in checking address!'+data.status.code+"|"+data.status.description)
									setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
									break;
							}
						} else {
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						}
					} catch (error) {
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = e => {
		const {name, value} = e.target;
		if(name === 'sprintIpNumber' || name === 'advisoryRead' || name === 'certifyDisability' ){
			setData((old) => ({
				...old,
				[name]: e.target.checked
			}));
			if(name === 'sprintIpNumber')
				setIpNbrChecked(e.target.checked);		
		} else {
			if (name === 'phoneNbr' && phoneNbrInvalid) setPhoneNbrInvalid(false);
			setData((old) => ({
				...old,
				[name]: value
			}));
		}
	};

	const validateField = e => {
		const {name,value} = e.target;
		setExplicitField(name, true);
		if( name === 'password')
			setPswd2Match(value);
		if( name === 'username')
			getUserIdAvail(value);
		if( name === 'emailAddress')
			getEmailAvail(value);
	}

	const updateInfo = e => {
		e.preventDefault();
		validate();
		if( state.$all_source_errors.length === 0 )
			checkAddrInfo();
	};

	return (
	<>
		<StatusMsg/>
		<form className={e3Style['sprint-register-form']} id="registrationInfoModel" noValidate autoComplete="off">
		<h1>{t('flows.registration.reginfo.1')}</h1>
		<fieldset>
		<legend className={e3Style['form-title']}>
			<h2>{t('flows.registration.reginfo.2')}</h2>
		</legend>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.firstName && state.$source_errors.firstName.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="legal-first-name">{t('flows.registration.reginfo.3')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="legal-first-name" name="firstName" autoFocus type="text" value={state.$data.firstName}
				 className={`${e3Style['input--fluid']} ${(state.$explict_fields.firstName && state.$source_errors.firstName.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				 onChange={updateField} onBlur={() => setExplicitField('firstName', true)}/>
				{(state.$explict_fields.firstName && state.$source_errors.firstName.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.firstName.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.lastName && state.$source_errors.lastName.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="legal-last-name">{t('flows.registration.reginfo.4')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="legal-last-name" name="lastName" type="text" value={state.$data.lastName}
				className={`${e3Style['input--fluid']} ${(state.$explict_fields.lastName && state.$source_errors.lastName.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				onChange={updateField} onBlur={() => setExplicitField('lastName', true)}/>
				{(state.$explict_fields.lastName && state.$source_errors.lastName.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.lastName.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
		</div>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.homeAddress1 && state.$source_errors.homeAddress1.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="home-address-1">{t('flows.registration.reginfo.8')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="home-address-1" name="homeAddress1" type="text" placeholder="(No P.O. Boxes)" value={state.$data.homeAddress1}
				className={`${e3Style['input--fluid']} ${(state.$explict_fields.homeAddress1 && state.$source_errors.homeAddress1.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				onChange={updateField} onBlur={() => setExplicitField('homeAddress1', true)}/>
				{(state.$explict_fields.homeAddress1 && state.$source_errors.homeAddress1.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.homeAddress1.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
		</div>
		<div className={`${e3Style.row}`}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}>
				<label className={`${e3Style['text-uppercase']}`} htmlFor="home-address-2">{t('flows.registration.reginfo.10')}</label>
				<input id="home-address-2" name="homeAddress2" className={`${e3Style['input--fluid']}`} type="text" 
				value={state.$data.homeAddress2} onChange={updateField}/>
			</div>
		</div>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.city && state.$source_errors.city.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="city">{t('flows.registration.reginfo.11')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="city" name="city" type="text" value={state.$data.city}
				 className={`${e3Style['input--fluid']} ${(state.$explict_fields.city && state.$source_errors.city.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				 onChange={updateField} onBlur={() => setExplicitField('city', true)}/>
				{(state.$explict_fields.city && state.$source_errors.city.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.city.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.stateAbbrev && state.$source_errors.stateAbbrev.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="state">{t('flows.registration.reginfo.13')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				{ stateList.length > 0 ?
					<select id="state" name="stateAbbrev" className={`${e3Style['input--fluid']} ${(state.$explict_fields.stateAbbrev && state.$source_errors.stateAbbrev.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
					 value={state.$data.stateAbbrev} onChange={updateField} onBlur={() => setExplicitField('stateAbbrev', true)} >
					<option value=""> </option>
					{stateList.map(stateAbrev => {
						return (
							( stateAbrev.STATE_ABBR === state.$data.stateAbbr ) ?
								<option value={stateAbrev.STATE_ABBR} selected="selected">{stateAbrev.STATE_ABBR}</option>
								:
								<option value={stateAbrev.STATE_ABBR}>{stateAbrev.STATE_ABBR}</option>
						);
					})}
					</select>
					:
					<>
					</>
				}
				{(state.$explict_fields.stateAbbrev && state.$source_errors.stateAbbrev.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.stateAbbrev.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.zipCode && state.$source_errors.zipCode.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="zipCode">{t('flows.registration.reginfo.16')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="zipCode" name="zipCode" type="text" value={state.$data.zipCode}
				 className={`${e3Style['input--fluid']} ${(state.$explict_fields.zipCode && state.$source_errors.zipCode.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				 onChange={updateField} onBlur={e => validateField(e)}/>
				{(state.$explict_fields.zipCode && state.$source_errors.zipCode.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.zipCode.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
		</div>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.emailAddress && state.$source_errors.emailAddress.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="email-address">{t('flows.registration.reginfo.19')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="email-address" name="emailAddress" placeholder="youremail@email.com" type="email" value={state.$data.emailAddress}
				 className={`${e3Style['input--fluid']} ${(state.$explict_fields.emailAddress && state.$source_errors.emailAddress.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				 onChange={updateField} onBlur={e => validateField(e)}/>
				{(state.$explict_fields.emailAddress && state.$source_errors.emailAddress.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.emailAddress.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
		</div>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.phoneNbr && state.$source_errors.phoneNbr.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="contact-phone-number">{t('flows.registration.reginfo.26')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
				<input id="contact-phone-number" name="phoneNbr" placeholder="(000) 123-4567" type="tel" value={state.$data.phoneNbr}
				 className={`${e3Style['input--fluid']} ${(state.$explict_fields.phoneNbr && state.$source_errors.phoneNbr.length > 0) ? e3Style['sprint-form-error'] : ''}`} 
				 onChange={updateField} onBlur={() => setExplicitField('phoneNbr', true)}/>
				{(state.$explict_fields.phoneNbr && state.$source_errors.phoneNbr.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.phoneNbr.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
		</div>
		</fieldset>
		<fieldset>
		<legend className={e3Style['form-title']}>
			<h2>{t('flows.registration.reginfo.29')}</h2>
		</legend>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.username && state.$source_errors.username.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="user-name">{t('flows.registration.reginfo.30')}<span className={`${e3Style['txt-upper']}`}>*</span>
				<a href="#" className={`e3Style['tooltip']} ${e3Style['sprint--information-icon']} ${e3Style['sprint-icon-info']}`} data-tip data-for="username-tooltip">i</a>
				<ReactTooltip id="username-tooltip" place="bottom" type="info" effect="float">
				<span className={sprStyle.tooltiptext}>{t('flows.registration.reginfo.31')} {t('flows.registration.reginfo.32')}</span>
				</ReactTooltip></label>
				<input id="user-name" name="username" type="text" value={state.$data.username}
				 className={`${e3Style['input--fluid']} ${(state.$explict_fields.username && state.$source_errors.username.length > 0) ? e3Style['sprint-form-error'] : ''}`}  
				 onChange={updateField} onBlur={e => validateField(e)}/>
				{(state.$explict_fields.username && state.$source_errors.username.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.username.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
				}
			</div>
		</div>
	    <div className={e3Style.row}>
	      <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
	        <label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.password && state.$source_errors.password.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="password">{t('flows.registration.reginfo.36')}
			<span className={`${e3Style['txt-upper']}`}>*</span>
			<a href="#" className={`e3Style['tooltip']} ${e3Style['sprint--information-icon']} ${e3Style['sprint-icon-info']}`} data-tip data-for="password-tooltip">i</a>
			<ReactTooltip id="password-tooltip" place="bottom" type="info" effect="float">
			<span className={sprStyle.tooltiptext}>{t('flows.registration.reginfo.38')}</span>
			</ReactTooltip></label>
	        <input type="password" id="password" name="password" value={state.$data.password}
				className={`${e3Style['input--fluid']} ${(state.$explict_fields.password && state.$source_errors.password.length > 0) ? e3Style['sprint-form-error'] : ''}`}
			   onChange={updateField} onBlur={e => validateField(e)}/>
			 {(state.$explict_fields.password && state.$source_errors.password.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.password.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
			}
	      </div>
	      <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
	        <label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="confirmPswd">{t('flows.registration.reginfo.39')}
			<span className={`${e3Style['txt-upper']}`}>*</span></label>
			<input type="password" id="confirmPswd" name="confirmPswd"  value={state.$data.confirmPswd}
				className={`${e3Style['input--fluid']} ${(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ? e3Style['sprint-form-error'] : ''}`}
			   onChange={updateField} onBlur={() => setExplicitField('confirmPswd', true)}/>
			 {(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.confirmPswd.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
			}
	      </div>
	    </div>
	    <div className={e3Style.row}>
	      <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
	        <label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.secQuestionId && state.$source_errors.secQuestionId.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="securityQuestion">{t('flows.registration.reginfo.41')}
			<span className={`${e3Style['txt-upper']}`}>*</span></label>
			<select name="secQuestionId" id="securityQuestion" disabled={!secparams.dataReady}  value={state.$data.secQuestionId}
			className={`${e3Style['input--fluid']} ${(state.$explict_fields.secQuestionId && state.$source_errors.secQuestionId.length > 0) ? e3Style['sprint-form-error'] : ''}`}
			onChange={updateField} onBlur={() => setExplicitField('secQuestionId', true)}>
				{secparams.securityQuestionList.map(item => <option value={item.QUESTION_CODE}>{item.QUESTION_TEXT}</option>)}
			</select>
			{(state.$explict_fields.secQuestionId && state.$source_errors.secQuestionId.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.secQuestionId.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
			}
	      </div>
	      <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
	        <label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.secAnswer && state.$source_errors.secAnswer.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="securityAnswer">{t('flows.registration.reginfo.43')}
			<span className={`${e3Style['txt-upper']}`}>*</span>
			<a href="#" className={`e3Style['tooltip']} ${e3Style['sprint--information-icon']} ${e3Style['sprint-icon-info']}`} data-tip data-for="secanswer-tooltip">i</a>
			<ReactTooltip id="secanswer-tooltip" place="bottom" type="info" effect="float">
			<span className={sprStyle.tooltiptext}>{t('flows.registration.reginfo.34')}</span>
			</ReactTooltip></label>
			<input type="text" id="securityAnswer" name="secAnswer"  value={state.$data.secAnswer}
			   className={`${e3Style['input--fluid']} ${(state.$explict_fields.secAnswer && state.$source_errors.secAnswer.length > 0) ? e3Style['sprint-form-error'] : ''}`}
			   onChange={updateField} onBlur={() => setExplicitField('secAnswer', true)}/>
			 {(state.$explict_fields.secAnswer && state.$source_errors.secAnswer.length > 0) ?
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							{state.$source_errors.secAnswer.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
						</div>
					</div>
					: ''
			}
	      </div>
	    </div>
	</fieldset>
	<div className={e3Style.row}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
		<button type="button" onClick={updateInfo} disabled={state.$all_source_errors.length}
	        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
		{t('button.next')}
	    </button>
		</div>
	</div>

	</form>
	</>
	);
};

export default Registration;
