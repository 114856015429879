import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import Joi from "joi";
import { useValidator } from "react-joi";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath} from "../../layout/body";
import {getCsrfSessionNonce, getUserInfo} from "../../../store/user";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedSelect from "../../util/focusWrappedSelect";
import {updateActTime} from "../../../store/activity";

const manageAccount = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userInfo = useSelector(getUserInfo);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [pswd2Match, setPswd2Match] = useState("");

	const [secparams, setSecparams] = useState({
		secQues: "No question was selected.",
		secCode: 1,
		securityQuestionList: [],
		dataReady: false});
				
	const confPswdValidation = (value, helpers) => {
		if(value !== pswd2Match)
			return helpers.error('any.invalid');
		return value;
	};	

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			oldPswd: "",
			newPswd: "",
			confirmPswd: "",
			secAnswer: ""
		},
		schema: Joi.object({
			oldPswd: Joi.string().trim().min(1).required().messages({
				'string.empty':'flows.security.validate.3'
			}),
		newPswd: Joi.string().empty('').trim().min(10).max(64).regex(/^(?=.*[0-9])(?=.*[a-zA-Z]).*$/).messages({
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'string.pattern.base': 'flows.registration.reginfo.56'
			}),
		confirmPswd: Joi.string().empty('').custom(confPswdValidation, 'confirm password validation').messages({
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'any.invalid': 'flows.registration.reginfo.50'
			}),
 		secAnswer: Joi.string().empty('').trim().min(4).max(30).messages({
				'string.min': 'flows.registration.reginfo.34',
				'string.max': 'flows.registration.reginfo.34'
			}) 
		}),
		explicitCheck: {
			oldPswd: false,
			newPswd: false,
			confirmPswd: false,
			secAnswer: false
		}
	});

	useEffect(() => {
		getSecurityQuestions();
	}, []);

	const getSecurityQuestions = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getSecQuestions'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
				        setSecparams({...secparams,
	                    securityQuestionList: data.response.securityQuestionList,
						dataReady: true});
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveSecInfo = async () => {
		let secCdStr = secparams.secCode;
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'updateSecInfo', csrfSessionNonce},
				 params: {
							pswd: state.$data.oldPswd,
							newPswd: state.$data.newPswd,
							secCd: secCdStr.toString(),
							secAns:	state.$data.secAnswer								
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;

				switch (data.status.code) {
					case 200:
						setStatusMessage(dispatch, 'flows.security.validate.2', STATUS_ERROR);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const changeField = e => {
		const {name, value} = e.target;
		setSecparams({...secparams, [name]: value, secCode:value});
	};

	const updateInfo = e => {
		e.preventDefault();
		validate();
		if(state.$all_source_errors.length === 0)
			saveSecInfo(); 
	};
	
	const validateField = e => {
		const {name,value} = e.target;
		setExplicitField(name, true);
		if( name === 'newPswd')
			setPswd2Match(value);
	}

	return (
		<>
			<StatusMsg/>
			<form className={e3Style['sprint-account-security']} noValidate autoComplete="off" onSubmit={updateInfo}>
				<fieldset className={`${e3Style['sprint-notes']} ${e3Style['sprint-account_form_items']}`}>

					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']}`}>
							<h3>
								<p>{t('flows.security.manacct.1')} {userInfo.userId}</p>
							</h3>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend className={e3Style['form-title']}><h2>{t('flows.security.manacct.2')}</h2></legend>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="oldPswdErr" cptErrCount={state.$errors.oldPswd.length}>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="oldPswd">
									{t('flows.security.manacct.3')}</FocusWrappedLabel>
								<FocusWrappedInput type="password" initialFocus id="oldPswd" name="oldPswd"
								                   onChange={e => updateField(e, 'oldPswd')}
								                   onBlur={() => setExplicitField('oldPswd', true)}
								                   className={e3Style['input--fluid']}/>
								{(state.$explict_fields.oldPswd && state.$source_errors.oldPswd.length > 0) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$source_errors.oldPswd.map((err, idx) => <p index={idx} id={'oldPswdErr-' + idx}>
												<span>{t(err.$message)}</span></p>)}
										</div>
									</div>: ''
								}
							</FocusWrappedGrp>
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="newPswdErr" cptErrCount={state.$errors.newPswd.length}>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="newPswd">
									{t('flows.security.manacct.4')}</FocusWrappedLabel>
								<FocusWrappedInput type="password" id="newPswd" name="newPswd"
								                   onChange={e => updateField(e, 'newPswd')}
								                   onBlur={e => validateField(e)}
								                   className={e3Style['input--fluid']}/>
									{(state.$explict_fields.newPswd && state.$source_errors.newPswd.length > 0) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$source_errors.newPswd.map((err, idx) => <p index={idx} id={'newPswdErr-' + idx}>
												<span>{t(err.$message)}</span></p>)}
										</div>
									</div>: ''
								}
							</FocusWrappedGrp>
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="confirmPswdErr" cptErrCount={state.$errors.confirmPswd.length}>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="confirmPswd">
									{t('flows.security.manacct.5')}</FocusWrappedLabel>
								<FocusWrappedInput type="password" id="confirmPswd" name="confirmPswd"
								                   onChange={e => updateField(e, 'confirmPswd')}
								                   onBlur={() => setExplicitField('confirmPswd', true)}
								                   className={e3Style['input--fluid']}/>
								{(state.$data.newPswd.length > 0 && !state.$data.confirmPswd.length) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											<span>{t('flows.registration.reginfo.40')}</span>
										</div>
									</div>: ''
								}
								{(state.$explict_fields.confirmPswd && state.$source_errors.confirmPswd.length > 0) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$source_errors.confirmPswd.map((err, idx) => <p index={idx} id={'confirmPswdErr-' + idx}>
												<span>{t(err.$message)}</span></p>)}
										</div>
									</div>: ''
								}
							</FocusWrappedGrp>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend className={e3Style['form-title']}><h2>{t('flows.security.manacct.6')}</h2></legend>
					<p>{t('flows.security.manacct.7')}</p>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="secQues">
									{t('flows.security.manacct.8')}</FocusWrappedLabel>
								<FocusWrappedSelect name="secQues" id="secQues" value={secparams.secQues}
								                    disabled={!secparams.dataReady} onChange={changeField}>
									{secparams.securityQuestionList.map(item =>
										<option value={item.QUESTION_CODE} selected={item.QUESTION_TEXT === secparams.secQues}>{item.QUESTION_TEXT}</option>)}
								</FocusWrappedSelect>
								{(state.$explict_fields.secAnswer && state.$data.secAnswer.length > 0 && (secparams.secQues == 'No question was selected.' || secparams.secQues == '1')) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											<span>{t('flows.registration.reginfo.42')}</span>
										</div>
									</div>: ''
								}
							</FocusWrappedGrp>
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="secAnswerErr" cptErrCount={state.$errors.secAnswer.length}>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="secAnswer">
									{t('flows.security.manacct.9')}</FocusWrappedLabel>
								<FocusWrappedInput type="text" id="secAnswer" name="secAnswer"
								                   onChange={e => updateField(e, 'secAnswer')}
								                   onBlur={() => setExplicitField('secAnswer', true)}
								                   className={e3Style['input--fluid']}/>
								 {(state.$explict_fields.secAnswer && state.$source_errors.secAnswer.length > 0) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$source_errors.secAnswer.map((err, idx) => <p index={idx} id={'secAnswerErr-' + idx}>
												<span>{t(err.$message)}</span></p>)}
										</div>
									</div>: ''
								 }
							</FocusWrappedGrp>
						</div>
					</div>
				</fieldset>

				<div className={e3Style.row}>
					<div
						className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="submit" onClick={updateInfo} disabled={state.$all_source_errors.length || (!state.$data.newPswd.length && !state.$data.secAnswer.length) || 
												(state.$data.secAnswer.length && (secparams.secQues == 'No question was selected.' || secparams.secQues == '1')) || !(state.$data.newPswd == state.$data.confirmPswd) || 
												(!state.$data.secAnswer.length && !(secparams.secQues == 'No question was selected.' || secparams.secQues == '1'))}
						        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.update')}
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default manageAccount;
