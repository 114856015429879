import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import e3Style from "../../../css/e3-style.css";
import ModalWrapper from "./modalWrapper";
import {closePopup, isShowDisclaimerOpen} from "../../store/popupMgr";
import useAutoFocus from "../util/useAutoFocus";

const ShowDisclaimer = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const aFocus = useAutoFocus();

	const okClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h3>{t('page.popup.disclaimer')}</h3>
			<p>{t('page.popup.disclaimermsg')} <a href="https://t-mobile.com/access" target="_blank">t-mobile.com/access</a>.</p>
			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`} onClick={okClick} ref={aFocus}>{t('button.ok')}</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(ShowDisclaimer);

const ShowDisclaimerModal = () => {
	const isOpen = useSelector(isShowDisclaimerOpen);
	return <ModalWindow isOpen={isOpen}/>;
};

export default ShowDisclaimerModal;
