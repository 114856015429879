import React, {useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import e3Style from '../../../css/e3-style.css';
import ModalWrapper from "./modalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {callFinishedSel, closePopup} from "../../store/popupMgr";
import {getCallState} from "../../store/call";
import useAutoFocus from "../util/useAutoFocus";

const CallFinished = ({setCloseFunc}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const callData = useSelector(getCallState);
	const {callback} = useSelector(callFinishedSel);
	const finishedRef = useRef(true);
	const aFocus = useAutoFocus();
	const finClick = finished => e => {
		e.preventDefault();
		finishedRef.current = finished;
		closePopup(dispatch);
	};
	useEffect(() => {setCloseFunc(() => {callback(finishedRef.current, callData)})}, [setCloseFunc, callback, callData]);
	return (
		<>
			<h3>{t('page.popup.callfinished')}</h3>
			<p>{t('page.popup.callfinishedmsg')}</p>
			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={finClick(false)}>{t('button.notfinished')}</button>
				<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={finClick(true)} ref={aFocus}>{t('button.finished')}</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(CallFinished);

const CallFinishedModal = () => {
	const cfSel = useSelector(callFinishedSel);
	return <ModalWindow {...cfSel}/>;
};

export default CallFinishedModal;
