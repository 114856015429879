import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {focusIfNoPopup, isAnyWindowOpen} from "../../store/popupMgr";
import {setAutoFocusFunc} from "../../store/bodyView";
import e3Style from "../../../css/e3-style.css";

const isFocusDisplay = e => e.target.type !== 'checkbox' && e.target.type !== 'radio';

const FocusWrappedCpt = WrappedComponent => forwardRef(({labelRef, initialFocus, doInitFocus, setDoInitFocus, execInitFocus, onFocus:focusFunc, onBlur:blurFunc, ...props}, fwRef) => {
	const dispatch = useDispatch();
	const afRef = useRef(null);
	const isPopupOpen = useSelector(isAnyWindowOpen);
	const isExecInitFocus = typeof execInitFocus === 'undefined' || execInitFocus;

	useImperativeHandle(fwRef, () => ({
		focus: () => afRef.current.focus(),
		addClass: clsName => afRef.current.classList.add(clsName),
		removeClass: clsName => afRef.current.classList.remove(clsName)
	}));

	useEffect(() => {
		if (initialFocus && doInitFocus && isExecInitFocus) {
			setAutoFocusFunc(dispatch, () => afRef.current.focus());
			setDoInitFocus(false);
			focusIfNoPopup(isPopupOpen, afRef);
		}
	}, [isExecInitFocus]);

	const doFocus = e => {
		e.preventDefault();
		if (isFocusDisplay(e) && labelRef) labelRef.current.classList.add(e3Style['focused-label']);
		if (focusFunc) focusFunc(e);
	};

	const doBlur = e => {
		e.preventDefault();
		if (isFocusDisplay(e) && labelRef) labelRef.current.classList.remove(e3Style['focused-label']);
		if (blurFunc) blurFunc(e);
	};

	return <WrappedComponent ref={afRef} onFocus={doFocus} onBlur={doBlur} {...props}/>;
});

export default FocusWrappedCpt;
