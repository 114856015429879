import React, {useState} from 'react';
import e3Style from "../../../css/e3-style.css";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import axios from "axios";
import StatusMsg from "../util/statusMsg";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../store/bodyView";
import {verifySecAnswerPath} from "../layout/body";
import {addForgotPasswordNonce, setId, setQues} from '../../store/pswdNonce';
import {openForgotUserName} from "../../store/popupMgr";

const requestUsername = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [username, setUsername] = useState("");
	const [validUsernm, setValidUsernm] = useState(true);
	const nmPattern=/^[a-zA-Z0-9@_\.-]{8,50}$/;

	const getforgotPswdNonce = async () => {
		try {
			const response = await axios.post('/nologin/api',
					{command: {name: 'getForgotPasswordNonce'}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						    addForgotPasswordNonce(dispatch, data.response.nonce);
							getSecQuestionText(data.response.nonce);
						 	break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const getSecQuestionText = async (nonceObj) => {
		try {
			const response = await axios.post('/nologin/api',
			{	command: {name: 'forgotPswdSecQuestions'},
								params: {usernm: username,
										 nonce: nonceObj	}
							}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setQues(dispatch, data.response.questionText);
							setHistoryPath(dispatch, navigate, verifySecAnswerPath);
						 	break;
						case 421:
							setStatusMessage(dispatch, 'page.verifySecAnsr.6', STATUS_ERROR);
							break;
						case 999:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const onUsernmChange = e => {
		const curValue = e.target.value;
		setUsername( curValue );
		setValidUsernm(curValue.trim().length === 0? true:nmPattern.test(curValue.trim()));
		setId(dispatch, curValue);
	}
	
	const onSubmit = e => {
		e.preventDefault();
		getforgotPswdNonce();
	};
	
	const showForgotUserName = () => {
		openForgotUserName(dispatch);
	};
	
	return (
             <div className={`${e3Style['row']}`}>
					<div className={`${e3Style['col-sm-24']} ${e3Style['col-md-10']} ${e3Style['col-lg-14']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
						<h1 className={e3Style['pr-40']}>
							{t('page.rqstUsrnm.1')}
						</h1>
						<p className={e3Style['pr-40']}>
							{t('page.rqstUsrnm.2')}
						</p>
						<p className={e3Style['pr-40']}>{t('page.rqstUsrnm.3')}
						</p>
					</div>
					<div className={`${e3Style['col-sm-24']} ${e3Style['col-md-14']} ${e3Style['col-lg-10']} ${e3Style['pt-60']} ${e3Style['pb-60']}`}>
						<h3>{t('page.rqstUsrnm.5')}</h3>

						<StatusMsg/>

						<form noValidate autoComplete="off">
							 <div className={e3Style.row}>
			      <div className={`${e3Style['col-xs-24']} ${e3Style['col-md-25']}`}>
					<input autoFocus id="usernm" name="usernm" type="text" value={username}
				 className={`${e3Style['input--fluid']} ${e3Style['txt-io']}`}  
				 onChange={onUsernmChange}/>
			     </div>
			{!validUsernm && 
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							<p><span>{t('page.rqstUsrnm.7')}</span></p>
						</div>
					</div>
			}
		   </div>
				<br/>

				<a href="#" onClick={showForgotUserName} title={t('page.rqstUsrnm.6')}>
					{t('page.rqstUsrnm.4')}
				</a> 

				<button type="submit" disabled={username.trim().length === 0 || (!validUsernm)} onClick={onSubmit}
				        className={`${e3Style['button']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['mt-20']}`}>
					{t('button.submit')}
				</button>
			</form>
		</div>
	</div>
	);
};

export default requestUsername;