import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
	name: "tdNum",
	initialState: {
		statusCodeTag: null,
		phoneNum: null,
		dashId: null,
		portNum: null,
		portProvider: null,
		portAcct: null
	},
	reducers: {
		setTdNumData: (tdNum, action) => {
			tdNum.statusCodeTag = action.payload.preset.statusCodeTag;
			tdNum.phoneNum = action.payload.preset.phoneNum;
			tdNum.dashId = action.payload.preset.dashId;
			tdNum.portNum = action.payload.preset.portNum;
			tdNum.portProvider = action.payload.preset.portProvider;
			tdNum.portAcct = action.payload.preset.portAcct;
		}
	}
});

export const {
	setTdNumData
} = slice.actions;
export default slice.reducer;

export const updateTdNum = (dispatch, numobj) => {
	dispatch({type: setTdNumData.type, payload: {preset: numobj}});
};

export const getTdNumData = createSelector(
	state => state.entities.tdNum,
	tdNum => tdNum
);

