import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import Joi from "joi";
import { useValidator } from "react-joi";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath, adminUserDetailsPath} from "../../../layout/body";
import {
	getUserInfo,
	chooseUser,
	setUpdUserInfoDet,
	getUpdUserInfoDet,
	getCsrfSessionNonce
} from "../../../../store/user";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../../util/focusWrappedLabel";
import FocusWrappedInput from "../../../util/focusWrappedInput";
import FocusWrappedSelect from "../../../util/focusWrappedSelect";
import {updateActTime} from "../../../../store/activity";

const userDetails = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userData = useSelector(getUserInfo);
	const updatedUserDet = useSelector(getUpdUserInfoDet);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [pswd2Match, setPswd2Match] = useState("");
	
	const confPswdValidation = (value, helpers) => {
		if(value !== pswd2Match)
			return helpers.error('any.invalid');
		return value;
	};	

	const [origUserInfo, setOrigUserInfo] = useState({
		firstName: "",
		lastName: "",
		email: "",
		userId: "",
		oldPswd: "",
		newPswd: "",
		confirmPswd: "",
		securityQuestionCode: "",
		securityAnswer: ""
	});

	const [secQuesList, setSecQuesList] = useState({
		securityQuestionList: [],
		defSecCd: "1"
		});

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			firstName: "",
			lastName: "",
			email: "",
			userId: "",
			oldPswd: "",
			newPswd: "",
			confirmPswd: "",
			securityQuestionCode: "",
			securityAnswer: ""
		},
		schema: Joi.object({
        firstName: Joi.string().trim().min(1).max(30).required().pattern(/^[A-Za-z\u00C0-\u00FF]+$/).messages({
				'string.base': 'firstName.invalidFormat.error',
				'string.empty': 'firstName.required.error',
				'any.required': 'firstName.required.error',
				'string.pattern.base': 'firstName.invalidFormat.error'
			}), 
		lastName: Joi.string().trim().min(1).max(30).required().pattern(/^[A-Za-z\u00C0-\u00FF]+$/).messages({
				'string.base': 'lastName.invalidFormat.error',
				'string.empty': 'lastName.required.error',
				'any.required': 'lastName.required.error',
				'string.pattern.base': 'lastName.invalidFormat.error'
			}),
		email: Joi.string().trim().min(6).max(100).allow('').pattern(/.+@.+[.].+/).messages({
				'string.base': 'email.invalidFormat.error',
				'string.pattern.base': 'email.invalidFormat.error'
			}), 
		userId: Joi.string().trim().min(8).max(50).required().pattern(/.*[^0-9]+.*/).messages({
				'string.base': 'userId.invalidFormat.error',
				'string.empty': 'userId.required.error',
				'any.required': 'userId.required.error',
				'string.pattern.base': 'userId.invalidFormat.error'
			}), 
		newPswd: Joi.string().trim().allow('').min(8).max(10).messages({
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38'
			}), 
		confirmPswd: Joi.string().allow('').custom(confPswdValidation, 'confirm password validation').messages({
				'string.min': 'flows.registration.reginfo.38',
				'string.max': 'flows.registration.reginfo.38',
				'any.invalid': 'flows.registration.reginfo.50'
			}),
 		securityAnswer: Joi.string().trim().min(4).max(30).required().messages({
				'string.empty': 'securityAnswer.required.error',
				'any.required': 'securityAnswer.required.error'
			}) 
		}),
		explicitCheck: {
			newPswd: false,
			confirmPswd: false,
			securityAnswer: false
		}
	});

	useEffect(() => {
		getUserInfoResp();
	}, [updatedUserDet]);

	const getUserInfoResp = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'adminGetUserInfo', csrfSessionNonce},
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
				let info = {...data.response};
				setSecQuesList({...secQuesList,
	                    securityQuestionList: data.response.secQuestionList});

					setData((old) => ({
						...old,
						firstName: info.firstName,
						lastName: info.lastName,
						email: info.email,
						userId: info.userId,
						oldPswd: info.oldPswd,
						newPswd: "",
						confirmPswd: "",
						securityQuestionCode: info.securityQuestionCode,
						securityAnswer: info.securityAnswer
					}));

					setOrigUserInfo({
						firstName : info.firstName,
						lastName : info.lastName,
						email : info.email,
						userId : info.userId,
						oldPswd: info.oldPswd,
						newPswd: "",
						confirmPswd: "",
						securityQuestionCode : info.securityQuestionCode,
						securityAnswer : info.securityAnswer
					});

					break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveUserInfo = async () => {
		let cd = state.$data.securityQuestionCode;
		let userId = state.$data.userId.trim();
		const saveUserInfo = {...state.$data};
		const newUserInfo = {...state.$data,
							userId: origUserInfo.userId.trim() === state.$data.userId.trim() ? '':state.$data.userId.trim(),
							email: origUserInfo.email.trim() === state.$data.email.trim() ? '':state.$data.email.trim(),
							securityQuestionCode: cd.toString()};
							
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: 'adminChangeUserInfo', csrfSessionNonce},
				 params: newUserInfo	
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					    setUpdUserInfoDet(dispatch, saveUserInfo);
						updUser(userId);
						break;
					case 402:
						setStatusMessage(dispatch, 'flows.admin.edituser.change.invalidParameterError', STATUS_ERROR);
						break;
					case 411:
						setStatusMessage(dispatch, 'userId.duplicate.error', STATUS_ERROR);
						break;
					case 412:
						setStatusMessage(dispatch, 'email.duplicate.error', STATUS_ERROR);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const updUser = async (userId) => {
		chooseUser(dispatch, navigate,'adminSearchUserById',{userId}, adminUserDetailsPath, csrfSessionNonce,
				() => {},() => {});
	};
	
	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};
		
	const validateField = e => {
		const {name,value} = e.target;
		setExplicitField(name, true);
		if( name === 'newPswd')
			setPswd2Match(value);
	}

	const editInfo = e => {
		e.preventDefault();
 		if (state.$data.newPswd !== state.$data.confirmPswd){
			 setStatusMessage(dispatch, 'flows.admin.edituser.change.passwordError', STATUS_ERROR);
			 return;
		} else if ((origUserInfo.securityQuestionCode !== secQuesList.defSecCd && state.$data.securityQuestionCode === secQuesList.defSecCd)){
			setStatusMessage(dispatch, 'flows.admin.edituser.change.secQuesAnsError', STATUS_ERROR);
			return;
		} 

		if(origUserInfo.firstName === state.$data.firstName &&
           origUserInfo.lastName === state.$data.lastName &&
		   origUserInfo.email === state.$data.email &&
		   origUserInfo.userId === state.$data.userId &&
		   origUserInfo.securityQuestionCode === state.$data.securityQuestionCode &&
		   origUserInfo.securityAnswer === state.$data.securityAnswer) {
			if (state.$data.newPswd.trim() === "" || origUserInfo.oldPswd.trim() === state.$data.newPswd.trim())
				setStatusMessage(dispatch, 'flows.admin.edituser.change.nothingChanged', STATUS_ERROR);
			else saveUserInfo();
		}
		else saveUserInfo();
	};
	
	return (
	<>
		<StatusMsg/>
		<form noValidate autoComplete="off" onSubmit={editInfo}>
		<div className={`${e3Style.row} ${e3Style['mb-40']}`}>
			<div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
				<FocusWrappedGrp baseAriaId="firstNameErr" cptErrCount={state.$errors.firstName.length}>
					<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="firstName">
						{t('flows.admin.selectuser.query.firstname')}</FocusWrappedLabel>
					<FocusWrappedInput id="firstName" name="firstName" initialFocus className={`${e3Style['impt-0mb']} ${e3Style['input--fluid']}`}
					                   type="text" value={state.$data.firstName} onChange={e => updateField(e, 'firstName')}
					                   onBlur={() => setExplicitField('firstName', true)}/>
					{!!state.$errors.firstName.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.firstName.map((err, idx) => <p index={idx} id={'firstNameErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
			</div>
			<div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
				<FocusWrappedGrp baseAriaId="lastNameErr" cptErrCount={state.$errors.lastName.length}>
					<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="lastName">
						{t('flows.admin.selectuser.query.lastname')}</FocusWrappedLabel>
					<FocusWrappedInput id="lastName" name="lastName" className={`${e3Style['impt-0mb']} ${e3Style['input--fluid']}`}
					                   type="text" value={state.$data.lastName} onChange={e => updateField(e, 'lastName')}
					                   onBlur={() => setExplicitField('lastName', true)}/>
					{!!state.$errors.lastName.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.lastName.map((err, idx) => <p index={idx} id={'lastNameErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
			</div>
		</div>		
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
				<FocusWrappedGrp baseAriaId="emailErr" cptErrCount={state.$errors.email.length}>
					<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="email">
						{t('flows.admin.selectuser.query.email')}</FocusWrappedLabel>
					<FocusWrappedInput id="email" name="email" className={`${e3Style['impt-0mb']} ${e3Style['input--fluid']}`}
					                   type="text" value={state.$data.email} onChange={e => updateField(e, 'email')}
					                   onBlur={() => setExplicitField('email', true)}/>
					{!!state.$errors.email.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.email.map((err, idx) => <p index={idx} id={'emailErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
			</div>
		</div>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
				<FocusWrappedGrp baseAriaId="userIdErr" cptErrCount={state.$errors.userId.length}>
					<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="userId">
						{t('flows.admin.selectuser.query.userid')}</FocusWrappedLabel>
					<FocusWrappedInput id="userId" name="userId" className={`${e3Style['impt-0mb']} ${e3Style['input--fluid']}`}
					                   type="text" value={state.$data.userId} onChange={e => updateField(e, 'userId')}
					                   onBlur={() => setExplicitField('userId', true)}/>
					{!!state.$errors.userId.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.userId.map((err, idx) => <p index={idx} id={'userIdErr-' + idx}><span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
			</div>
		</div>		
	    <div className={`${e3Style.row} ${e3Style['mb-40']}`}>
		    <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
			    <FocusWrappedGrp baseAriaId="newPswdErr" cptErrCount={state.$errors.newPswd.length}>
				    <FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="newPswd">
					    {t('flows.admin.edituser.change.password')}</FocusWrappedLabel>
				    <FocusWrappedInput type="password" id="newPswd" name="newPswd" value={state.$data.newPswd}
				                       onChange={e => updateField(e, 'newPswd')}
				                       onBlur={e => validateField(e)}
				                       className={e3Style['input--fluid']}/>
				    {!!state.$errors.newPswd.length &&
					    <div className={e3Style['sprint-alert--error']}>
						    <div className={e3Style['alert--error_message']}>
							    {state.$errors.newPswd.map((err, idx) => <p index={idx} id={'newPswdErr-' + idx}><span>{t(err.$message)}</span></p>)}
						    </div>
					    </div>
				    }
			    </FocusWrappedGrp>
			</div>
		    <div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
			    <FocusWrappedGrp baseAriaId="confirmPswdErr" cptErrCount={state.$errors.confirmPswd.length}>
				    <FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="confirmPswd">
					    {t('flows.admin.edituser.change.confirmPassword')}</FocusWrappedLabel>
				    <FocusWrappedInput type="password" id="confirmPswd" name="confirmPswd" value={state.$data.confirmPswd}
				                       onChange={e => updateField(e, 'confirmPswd')}
				                       onBlur={() => setExplicitField('confirmPswd', true)}
				                       className={e3Style['input--fluid']}/>
					{(state.$data.newPswd.length > 0 && !state.$data.confirmPswd.length) ?
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								<span>{t('flows.registration.reginfo.40')}</span>
							</div>
						</div>: ''
					}
				    {!!state.$errors.confirmPswd.length &&
					    <div className={e3Style['sprint-alert--error']}>
						    <div className={e3Style['alert--error_message']}>
							    {state.$errors.confirmPswd.map((err, idx) => <p index={idx} id={'confirmPswdErr-' + idx}><span>{t(err.$message)}</span></p>)}
						    </div>
					    </div>
				    }
			    </FocusWrappedGrp>
			</div>
	    </div>
		<div className={`${e3Style.row} ${e3Style['mb-40']}`}>
			<div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
				<FocusWrappedGrp>
					<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="securityQuestionCode">
						{t('flows.admin.edituser.change.secquestion')}</FocusWrappedLabel>
					<FocusWrappedSelect name="securityQuestionCode" id="securityQuestionCode"
					                    onChange={e => updateField(e, 'securityQuestionCode')}>
					{Object.keys(secQuesList.securityQuestionList).map((key) => 
						<option value={key} selected={key == state.$data.securityQuestionCode}>{secQuesList.securityQuestionList[key]}</option>)}
					</FocusWrappedSelect>
				</FocusWrappedGrp>
			</div>
			<div className={`${e3Style.colStyle} ${e3Style['col-md-12']}`}>
				<FocusWrappedGrp baseAriaId="securityAnswerErr" cptErrCount={state.$errors.securityAnswer.length}>
					<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="securityAnswer">
						{t('flows.admin.edituser.change.secanswer')}</FocusWrappedLabel>
					<FocusWrappedInput type="text" id="securityAnswer" name="securityAnswer" value={state.$data.securityAnswer}
					                   onChange={e => updateField(e, 'securityAnswer')}
					                   onBlur={() => setExplicitField('securityAnswer', true)}
					                   className={e3Style['input--fluid']}/>
					{!!state.$errors.securityAnswer.length &&
						<div className={e3Style['sprint-alert--error']}>
							<div className={e3Style['alert--error_message']}>
								{state.$errors.securityAnswer.map((err, idx) => <p index={idx} id={'securityAnswerErr-' + idx}>
									<span>{t(err.$message)}</span></p>)}
							</div>
						</div>
					}
				</FocusWrappedGrp>
			</div>
		</div>

		<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
			<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
				<button type="submit" onClick={validate} disabled={(state.$auto_invalid || (state.$data.newPswd.length > 0 && !state.$data.confirmPswd.length))}
			        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
				{t('prefs.admin.button.edit.user')}
			    </button>
			</div>
		</div>
	</form>
	</>
	);
};

export default userDetails;


