import React, {useMemo} from 'react';
import {useParams} from "react-router";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {chooseCanceledUser, getCsrfSessionNonce} from "../../../../store/user";
import {rootPath} from "../../../layout/body";
import UserListGeneric from "./userListGeneric";
import {updateActTime} from "../../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../../util/ioHelper";

const displayObj = (srchtype, srchprm1, srchprm2) => ({
	pgTxt: {
		lastnameLabel: 'prefs.admin.selectuser.userlist.lastname',
		firstnameLabel: 'prefs.admin.selectuser.userlist.firstname',
		addressLabel: 'prefs.admin.selectuser.userlist.address',
		cityLabel: 'prefs.admin.selectuser.userlist.city',
		stateLabel: 'prefs.admin.selectuser.userlist.state'
	},
	readUserList: async (dispatch, csrfSessionNonce, setUserList) => {
		let cmdNm = '';
		let params = {};
		switch (srchtype) {
			case 'userid':
				cmdNm = 'adminSearchCanceledUserById';
				params['userId'] = srchprm1;
				break;

			case 'email':
				cmdNm = 'adminSearchCanceledUserEmail';
				params['email'] = srchprm1;
				break;

			case 'phone':
				cmdNm = 'adminSearchCanceledUserPhoneNum';
				params['phoneNum'] = srchprm1;
				break;

			case 'fullname':
				cmdNm = 'adminSearchCanceledUserFullname';
				params['firstName'] = srchprm1;
				params['lastName'] = srchprm2;
				break;

			case 'firstonly':
				cmdNm = 'adminSearchCanceledUserFullname';
				params['firstName'] = srchprm1;
				params['lastName'] = '';
				break;

			case 'lastonly':
				cmdNm = 'adminSearchCanceledUserFullname';
				params['firstName'] = '';
				params['lastName'] = srchprm1;
				break;
		}
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: cmdNm, csrfSessionNonce},
					params
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200) {
					setUserList(data.response.userList);
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	},
	selectUser: (customerId, dispatch, navigate, csrfSessionNonce, userList) => {
		chooseCanceledUser(dispatch, navigate, userList.find(user => user.customerId === customerId), csrfSessionNonce,
			() => setHistoryPath(dispatch, navigate, rootPath), () => setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR))
	},
	goBack: (dispatch, navigate) => {setHistoryPath(dispatch, navigate, rootPath)}
});

const UserListCanceled = () => {
	const {srchtype, srchprm1, srchprm2} = useParams();
	const displayParam = useMemo(() => displayObj(srchtype, srchprm1, srchprm2), [srchtype, srchprm1, srchprm2]);

	return <UserListGeneric displayObj={displayParam}/>;
};

export default UserListCanceled;
