import {useTranslation} from "react-i18next";
import {useState} from 'react';
import ModalWrapper from "./modalWrapper";
import e3Style from "../../../css/e3-style.css";
import {closePopup, forgotUserNameSel} from "../../store/popupMgr";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import React from "react";
import {updateActTime} from "../../store/activity";
import useAutoFocus from "../util/useAutoFocus";

const emailPattern=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ForgotUserName = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [emailAdr, setEmailAdr] = useState("");
	const [validEmail, setValidEmail] = useState(true);
	const [result, setResult] = useState("");
	const aFocus = useAutoFocus();

	const onSubmit = e => {
		e.preventDefault();
		setResult("");
		gotoForgotUser();
	};

	const gotoForgotUser = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getForgotUsernameNonce'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200){
					try {
						updateActTime(dispatch);
						const response2 = await axios.post('/nologin/api',
							{	command: {name: 'forgotUserName'},
								params: {emailAdr: emailAdr.trim(),
										 nonce: data.response.nonce	}
							}, {timeout: 5000});
						if (response2.status === 200) {
							const {data} = response2;
							switch (data.status.code) {
								case 200:
									setResult('success');
									break;
									
								case 413:
									setResult('error');
									break;
			
								default:
									setResult('error');
									break;
							}
						} else {
							setResult('error');
						}
					} catch (error2) {
						setResult('error');
					}
				}
				else
					setResult('error');
			}
			else
				setResult('error');
		} catch (error) {
			setResult('error');
		}
	};
	
	const cancelClick = e => {
		e.preventDefault();
		setResult("");
		closePopup(dispatch);
	};

	const onEmailChange = e => {
		const curValue = e.target.value;
		setEmailAdr( curValue );
		setValidEmail(curValue.trim().length === 0? true:emailPattern.test(curValue.trim()));
	};

	return (
		<>
			<form name="forgotUserForm">
			<div className={e3Style['sprint-modal__container']}>
				{ result === 'success' ?
					<h2>{t('page.popup.usrnmsent')}</h2>
					:
					<h3>{t('page.popup.frgtusrnm')}</h3>
				}
				{result === 'error' &&
					<div className={`${e3Style['sprint-alert--error']} ${e3Style['mb-20']}`}>{t('page.popup.usrnmfail')}</div>
				}
				{result !== 'success' &&
					<>
					<label className={`${e3Style['text-uppercase']}`} htmlFor='emailAddr'>
							{t('page.popup.entreml')}</label>
					<input initialFocus name="emailAddr" id="emailAddr"
						 className={`${e3Style['input--fluid']} ${e3Style['sprint-input']}`} 
						 placeholder={t('page.popup.regeml')} type="email"
						 value={emailAdr} onChange={onEmailChange} ref={aFocus}/>
					</>
				}
				{!validEmail && result !== 'success' &&
					<div className={e3Style['sprint-alert--error']}>
						<div className={e3Style['alert--error_message']}>
							<p><span>{t('page.popup.crteml')}</span></p>
						</div>
					</div>
				}
				<div className={e3Style['sprint--modal-submit']}>
				{ result === 'success'?

					<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        onClick={cancelClick} ref={aFocus}>
						{t('button.ok')}
					</button>
					:
					<>
					<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        disabled={emailAdr.trim().length === 0 || (!validEmail)} onClick={onSubmit}>
						{t('button.submit')}
					</button>
					<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        onClick={cancelClick}>
						{t('button.cancel')}
					</button>
					</>					
				}
				</div>
			</div>
			</form>
		</>
	);
};

const ModalWindow = ModalWrapper(ForgotUserName);

const ForgotUserNameModal = () => {
	const {isOpen} = useSelector(forgotUserNameSel);
	return <ModalWindow isOpen={isOpen}/>;
};

export default ForgotUserNameModal;
