import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminManagePhoneNumsTablePath} from "../../../layout/body";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../../util/focusWrappedLabel";
import FocusWrappedInput from "../../../util/focusWrappedInput";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";
import Spinner from "../../root/spinner";
import {postWithTimeout, EXTENDED_TIMEOUT} from "../../../../util/ioHelper";

const tdNumAddNPA = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [npaVal, setNpaVal] = useState({npa:''});
	const [showSpin, setShowSpin] = useState(false);

	useEffect(() => {
		getNPAInfo();
	}, []);

	const validateNPACheck = async e => {
		e.preventDefault();
		const code = npaVal.npa;
		const nbrformat = /^[0-9]{3}$/;
		if(!code.match(nbrformat))
			setStatusMessage(dispatch, 'flows.admin.managetdnum.add.invalidnpa', STATUS_ERROR);
		else {
			setShowSpin(true);
			await validateNPA();
			setShowSpin(false);
		}
	};

	const getNPAInfo = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
					{command: {name: 'adminTdNumGetNPA', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setNpaVal(data.response);
						  	break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const reserveNPA = async () => {
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: 'adminTdNumAddNPA', csrfSessionNonce},
					params: {npa: npaVal.npa}
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath);
					break;
					case 425:
					setStatusMessage(dispatch, 'flows.admin.managetdnum.add.nonpa', STATUS_ERROR);
					break;
					case 999:
					setStatusMessage(dispatch, 'flows.admin.managetdnum.add.npaprob', STATUS_ERROR);
					break;
					default:
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const validateNPA = async () => {
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: 'adminTdNumValidateNPA', csrfSessionNonce},
					params: {npa: npaVal.npa}
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					let res = data.response.valid;
					if (!res){
						setStatusMessage(dispatch, 'flows.admin.managetdnum.add.invalidnpa', STATUS_ERROR);
					} else {await reserveNPA();}
					break;
					default:
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
		<>
			{showSpin ? <Spinner/> :
				<>
					<StatusMsg/>
					<h2>{t('flows.admin.managetdnum.add.title')}</h2>
					<form noValidate autoComplete="off" className={e3Style['account-preferences_account-frequently-dialed-numbers']}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}>{t('flows.admin.managetdnum.add.addnpa')}</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={`${e3Style['text-uppercase']}`} htmlFor="npa">
									{t('flows.admin.managetdnum.add.areacode')}</FocusWrappedLabel>
								<FocusWrappedInput id="npa" name="npa" className={`${e3Style['input--fluid']}`} initialFocus
								                   type="number" value={npaVal.npa} maxLength="3"
								                   onChange={e => {
									                   setNpaVal(prevnpa => ({...prevnpa, "npa": e.target.value.replace(/[^\d]/g, '')}));
								                   }}/>
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
							<button type="button"
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
							        onClick={validateNPACheck}>{t('flows.admin.managetdnum.add.addbutton')} </button>

							<button type="submit"
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
							        onClick={e => {
								        e.preventDefault();
								        setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath)
							        }}>{t('flows.admin.managetdnum.add.backbutton')} </button>
						</div>
					</form>
				</>
			}
		</>
	);
};

export default tdNumAddNPA;
