import React from "react";
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";

const Spinner = () => {
    const { t } = useTranslation();
    return (
        <>
            <h1 className={sprStyle.pls_wait_msg}>{t('flows.spinner.msg')}</h1>
            <div className={sprStyle.spinning_loader}></div>
        </>
    );
};

export default Spinner;
