import axios from "axios";
import {updateActTime} from "../../store/activity";

export const RGB_DEFAULT_TIMEOUT = 5000;

export const cmdInterface = responseFunc => {
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();
	return {responseFunc, source};
};

const rgpCommand = async (command, params, tmout, proxyCmd, cmdIntf, dispatch) => {
	const {responseFunc, source} = cmdIntf;
	try {
		updateActTime(dispatch);
		const response = await axios.post('/nologin/rgpProxy',
			{...params, [proxyCmd ? "ProxyCommand" : "RGPCommand"]: command},
			{timeout: tmout ?? RGB_DEFAULT_TIMEOUT, cancelToken: source.token});
		if (response?.status === 200) {
			const {data} = response;
			if (typeof data?.status?.code === 'number') {
				responseFunc(data);
			} else {
				responseFunc({status: {code: 500}});
			}
		} else {
			responseFunc({status: {code: 500}});
		}
	} catch (error) {
		if (axios.isCancel(error)) {
			responseFunc(null);
		} else {
			responseFunc({status: {code: 500}});
		}
	}
};

export default rgpCommand;
