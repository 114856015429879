import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initData = {
	firstName: "",
	lastName: "",
	homeAddress1: "",
	homeAddress2: "",
	city: "",
	stateAbbrev: "",
	zipCode: "",
	emailAddress: "",
	birthDate: "",
	phoneNbr: "",
	username: "",
	password: "",
	confirmPswd: "",
	secQuestionId: 1,
	secAnswer: "",
	sprintIpNumber: false,
	advisoryRead: false,
	certifyDisability: false,
	campaign:""
};
export const getInitData = () => ({...initData});
const pInitData = {...initData, initComplete: false};

const slice = createSlice({
	name: "registry",
	initialState: {
		data: pInitData
	},
	reducers: {
		setRegistryData: (registry, action) => {
			registry.data = {...action.payload.preset, initComplete: true};
		},
		resetRegistryData: registry => {
			registry.data = pInitData;
		},
		setRegistryUsrPwd: (registry, action) => {
			registry.data = pInitData;
			registry.data.username = action.payload.username;
			registry.data.password = action.payload.password;
		}
	}
});

export const {
	setRegistryData, resetRegistryData, setRegistryUsrPwd
} = slice.actions;
export default slice.reducer;

export const setRegUsrPwd = (dispatch, username, password) => {
	dispatch({type: setRegistryUsrPwd.type, payload: {username, password}});
};

export const setRegistry = (dispatch, newValue) => {
	dispatch({type: setRegistryData.type, payload: {preset: newValue}});
};

export const resetRegistry = dispatch => {
	dispatch({type: resetRegistryData.type});
};

export const getRegistry = createSelector(
	state => state.entities.registry.data,
	data => data,
);