import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminLoginPath,addSTSMessagePath, stsSettingsPath} from "../../layout/body";
import {getCsrfSessionNonce, getUserInfo} from "../../../store/user";
import {updateActTime} from "../../../store/activity";
import STSMsgItem from "./STSMsgItem";

const STSMessages = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userData = useSelector(getUserInfo);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [messages, setMessagesList] = useState(null);
	const [showMsgList, setShowMsgList] = useState(false);

	useEffect(() => {
		getMessagesList(false);
	}, []);

	const getMessagesList = async (refreshFlag) => {
		try {
			if( messages == null || refreshFlag ) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getSTSMessages', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
					   	  	setMessagesList(data.response.STSMessages);
					   	  	setShowMsgList(true);
						  	break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const delMessage = async (idx)  => 
	{
		try {
			if( idx !== undefined ) {
				const theMsg = messages[idx]
				const msgData = {thisMsg: theMsg.TEXT};
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'adminDeleteSTSMsg', csrfSessionNonce}, params:msgData}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setShowMsgList(false);
							getMessagesList(true);
						  	break;

						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	}

	const updateMessage = (idx, expired)  => {
		setHistoryPath(dispatch, navigate, addSTSMessagePath+(expired? '/renewMsg/':'/editMsg/')+Object.keys(messages)[idx])
	}
	
	const expDate = (dt) => {
		let nDate = new Date(new Date(dt).getTime() + 60 * 60 * 24 * 1000 );
		let month = (nDate.getMonth()+1 < 10) ? '0'+(nDate.getMonth()+1) : nDate.getMonth()+1;
		let newdt = (nDate.getDate() < 10) ? '0' + nDate.getDate() : nDate.getDate();
		let newHr = (nDate.getHours() < 10) ? '0' + nDate.getHours() : nDate.getHours();
		let newMin = (nDate.getMinutes() < 10) ? '0' + nDate.getMinutes() : nDate.getMinutes();
		return month + '-' + newdt + '-' + nDate.getFullYear()+' '+newHr+ ':' +newMin+ ' PT';
	}
	
	return (
	<>
		<StatusMsg/>
		<form noValidate autoComplete="off" className={e3Style['account-preferences_notes']}>
		<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
				<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, stsSettingsPath)}}
						className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
				{t('button.back')}</button>
				<button type="button" autoFocus onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, addSTSMessagePath+'/addMsg/0')}}
						className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--md']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
				{t('button.addmsg')}</button>
			</div>
		</div>
		<fieldset className={`${e3Style['sprint-permissions']} ${e3Style['sprint-account_form_items']}`}>
		{  showMsgList && messages && (Object.keys(messages).length > 0) ?
			<div className={e3Style['scrollable-div']}>
			{ Object.values(messages).map( (val,idx)  =>
				<STSMsgItem key={idx} onDeleteFunc={delMessage} onUpdateFunc={updateMessage}
				 theMsgItem={{ORDERID: idx, TEXT: val.TEXT, EXPDATE: expDate(val.CREATE_DATE)}} 
				 expired={(new Date(new Date().getTime() + (new Date().getTimezoneOffset() * 60000) + (3600000 * - 7)) > new Date(new Date(val.CREATE_DATE).getTime() + 60 * 60 * 24 * 1000 )) }
				/>
			)}
			</div>
			:
			<p>There are no STS messages for this user.</p>
		}
		</fieldset>
		</form>
	</>
	);
};

export default STSMessages;	
