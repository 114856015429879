import React, {useEffect, useRef} from 'react';
import e3Style from '../../../css/e3-style.css';
import sStyle from '../../../css/sprint-style.css';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {doAutoFocus, getBodyViewSelector, setHistoryPath} from '../../store/bodyView';
import {getUserInfo, isLoggedIn, isAdmin, isLoggedInOrAdmin, isCurrentUser, logoutUser} from "../../store/user";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {accountListPath, registrationPath} from "./body";
import {openCustCare, openDisplaySettings, openWarnCallIn} from "../../store/popupMgr";
import {getCallInProgSel, getCallState, USER_ACTION_READY} from "../../store/call";
import {getShowHeaders} from "../../store/display";

const openInNewTab = url => {window.open(url, "_blank")};

const Header = () => {
	const { t, i18n } = useTranslation();
	const hdrRef = useRef(null);
	const hdrMetaRef = useRef(null);
	const hmbgrMnuRef = useRef(null);
	useEffect(() => {
		let prevScroll = false;
		let iScrollPos = 0;
		const onScroll = () => {
			const iCurScrollPos = window.scrollY;
			const currScroll = iCurScrollPos > iScrollPos;
			if (currScroll !== prevScroll) {
				prevScroll = currScroll;
				const hdr = hdrRef.current;
				if (hdr !== null) {
					if (currScroll)
						hdr.classList.add(e3Style['is-scrolled']);
					else
						hdr.classList.remove(e3Style['is-scrolled']);
				}
			}
			iScrollPos = iCurScrollPos;
		};
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);
	const tglHbgr = () => {
		const hdrMeta = hdrMetaRef.current;
		const hmbgrMnu = hmbgrMnuRef.current;
		if (hdrMeta !== null && hmbgrMnu !== null) {
			hmbgrMnu.classList.toggle(e3Style['is-open']);
			const hdrNav = hdrMeta.querySelectorAll('.' + e3Style['sprint-header-nav']);
			for (let nav of hdrNav) {
				nav.classList.toggle(e3Style['is-open']);
			}
			document.body.classList.toggle(e3Style['has__active-nav']);
			hmbgrMnu.setAttribute('aria-label', hmbgrMnu.classList.contains(e3Style['is-open']) ?
				t('embedded.17') : t('embedded.16'));
		}
	};
	const mnuTglHbgr = e => {
		e.preventDefault();
		const hmbgrMnu = hmbgrMnuRef.current;
		if (hmbgrMnu !== null && hmbgrMnu.classList.contains(e3Style['is-open'])) tglHbgr();
	};
	const clkTglHbgr = e => {
		e.preventDefault();
		tglHbgr();
	};
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bodyView = useSelector(getBodyViewSelector);
	const userData = useSelector(getUserInfo);
	const isInCall = useSelector(getCallInProgSel);
	const callData = useSelector(getCallState);
	const showHeaders = useSelector(getShowHeaders);
	const disableInCall = isInCall || callData.actionState > USER_ACTION_READY;

	const changeLang = e => {
		e.preventDefault();
		const newLang = i18n.language === 'en' ? 'es' : 'en';
		i18n.changeLanguage(newLang);
		document.documentElement.lang = newLang;
	};

	const skipToContent = e => {
		e.preventDefault();
		doAutoFocus(bodyView);
	};

	const gotoLink = path => e => {
		mnuTglHbgr(e);
		setHistoryPath(dispatch, navigate, path);
	};

	const gotoAccount = e => {
		gotoLink(accountListPath)(e);
	};

	const showDisplaySettings = e => {
		mnuTglHbgr(e);
		openDisplaySettings(dispatch);
	};

	const showCustCare = e => {
		mnuTglHbgr(e);
		openCustCare(dispatch);
	};

	const disconnect = () => {
		// TODO: disconnect call
		logoutUser(dispatch);
	};

	const logout = e => {
		e.preventDefault();
		if (isInCall) {
			openWarnCallIn(dispatch, disconnect);
		} else {
			logoutUser(dispatch);
		}
	};

	return (
	<header role="banner" aria-label="header" className={`${e3Style['layout-header']} ${e3Style['layout-header-simple']} ${sStyle['width-100pct']} ${showHeaders === false ? e3Style['is-hidden']:e3Style['is-visible']}`} ref={hdrRef}>
		<div className={`${e3Style['sprint-menu']} ${sStyle['skip-links-cls']}`} role="navigation" aria-label={t('page.header.skiplinks')}>
			<a href="#" onClick={e => skipToContent(e)}>{t('page.header.skiptocnt')}</a>
		</div>
		<div className={e3Style['sprint-header-top']}>
			<div className={e3Style['sprint-header-inner']}>
				<div className={e3Style['sprint-header-options']}>
					<div>
						<a href="#" onClick={changeLang}
						   className={e3Style['white-link']} lang={t('lang_alt_abbrev')}>
							{t('page.header.currlang')}</a>
					</div>
					<div className={e3Style['ml-auto']}>
						{isLoggedInOrAdmin(userData) &&
							<a href="#" onClick={logout} className={e3Style['white-link']}>
								{t('page.header.signout')}
							</a>
						}
					</div>
				</div>
			</div>
		</div>

		<div className={e3Style['sprint-header-bottom']}>
			<div className={e3Style['sprint-header-inner']}>
				<div className={e3Style['sprint-header-meta']} ref={hdrMetaRef}>
					<button type="button" className={e3Style['sprint-header-hamburger']} data-js-header-hamburger=""
					        aria-label={t('embedded.16')} ref={hmbgrMnuRef} onClick={clkTglHbgr}></button>
					<Link to="/" className={e3Style['sprint-header-logo']} title={t('page.header.goback')}>
						<svg viewBox="0 0 309 375" version="1.1" xmlns="http://www.w3.org/2000/svg"
						     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
						     xmlnsSerif="http://www.serif.com/"><g><rect x="2.321" y="172.872" width="75.092" height="75.104"/><path
							d="M183.053,19.419c61.18,1.079 97.646,41.854 103.021,116.412l19.859,-3.216l-3.792,-130.904l-296.117,-0l-3.733,130.904l19.829,3.216c5.379,-74.558 41.321,-115.333 103.021,-116.412l-0,274.129c-0,49.883 -8.592,57.946 -62.229,58.479l-0,20.909l182.383,-0l0,-20.909c-53.633,-0.533 -62.242,-8.596 -62.242,-58.479l0,-274.129Z"/><rect
							x="230.188" y="172.872" width="75.079" height="75.104"/></g></svg>
					</Link>
					{!isAdmin(userData) &&
						<nav className={e3Style['sprint-header-nav']} aria-label="main navigation" role="navigation">
							<ul className={e3Style['sprint-menu']} id="menu1">
								<li><a href="#" className={`${e3Style['sprint-menu__root']} ${e3Style['hide-md']}`} onClick={showDisplaySettings}><span>
									{t('page.header.dispset')}</span></a>
								</li>
								{isLoggedIn(userData) &&
									<li><a
										onClick={gotoAccount}
										href="#" className={`${e3Style['sprint-menu__root']} ${e3Style['hide-md']}`}><span>
										{t('page.header.account')}</span></a>
									</li>
								}
								<li><a href="#" className={e3Style['sprint-menu__root']}
								       onClick={showCustCare}
								       disabled={disableInCall}><span>
									{t('page.header.custcare')}</span></a>
								</li>
								<li><a href="#" className={e3Style['sprint-menu__root']}
								       onClick={e => {mnuTglHbgr(e);openInNewTab(t('page.header.911nfo_link'))}}>
										<span>{t('page.header.911nfo')}</span>
									</a>
								</li>
								<li><a href="#" className={e3Style['sprint-menu__root']} onClick={
									e => {
										mnuTglHbgr(e);
										openInNewTab("https://www.tmobileaccess.com/resources/ip-relay")
									}
								}><span>{t('page.header.faq')}</span></a>
								</li>
								{!isLoggedInOrAdmin(userData) &&
									<li>
										<a href="#" className={e3Style['sprint-menu__root']} onClick={gotoLink(registrationPath)}>
											<span>{t('page.header.register')}</span>
										</a>
									</li>
								}
							</ul>
						</nav>
					}
				</div>
				<div className={e3Style['sprint-header-tools']}>
					<nav className={e3Style['sprint-header-nav']} aria-label="secondary navigation" role="navigation">
						<ul className={e3Style['sprint-menu']} id="dispset">
							<li><a href="#" className={`${e3Style['sprint-menu__root']} ${e3Style['show-md']}`}
							       onClick={showDisplaySettings}><span>
								{t('page.header.dispset')}</span></a>
							</li>
							{ isLoggedIn(userData) && isCurrentUser(userData) && (!userData.adminId.startsWith("0")) &&
								<li><a
									onClick={gotoAccount}
									href="#" className={`${e3Style['sprint-menu__root']} ${e3Style['show-md']}`}><span>
									{t('page.header.account')}</span></a>
								</li>
							}
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</header>
)};

export default Header;
