import React, {useEffect, useRef} from 'react';
import {closePopup, isDisplaySettingsOpen} from "../../store/popupMgr";
import {useDispatch, useSelector} from "react-redux";
import e3Style from '../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import ModalWrapper from "./modalWrapper";
import {getDisplayPrefSettings, saveDisplaySettings, setColorSelection, setSizeSelection, setFontSelection} from "../../store/display";
import StatusMsg from "../util/statusMsg";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../store/bodyView";
import {getCsrfSessionNonce, getLoggedInSel} from "../../store/user";
import {loginPath} from "../layout/body";
import {useNavigate} from "react-router";
import useAutoFocus from "../util/useAutoFocus";

const font72 = "sprint-font-72";
const font60 = "no-class";
const font36 = "sprint-font-36";
const blackWhite = "sprint-black-white";
const blackYellow = "sprint-black-yellow";
const whiteBlack = "no-class";
const blueYellow = "sprint-blue-yellow";
const greenWhite = "sprint-green-white";

const DisplaySettings = ({setCloseFunc}) => {
	const { t } = useTranslation();
	const loggedIn = useSelector(getLoggedInSel);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = useSelector(getDisplayPrefSettings);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const aFocus = useAutoFocus();
	const colorSel = classes.colorSelection;
	const sizeSel = classes.sizeSelection;
	const fontSel = classes.fontSelection;
	const stateRef = useRef({color: colorSel, size: sizeSel, font: fontSel, saved: false});
	const currState = stateRef.current;

	const setSize = e => {
		if (e.target.value !== sizeSel) setSizeSelection(dispatch, e.target.value);
	};

	const setColor = e => {
		if (e.target.value !== colorSel) setColorSelection(dispatch, e.target.value);
	};

	const setFont = e => {
		if (e.target.value !== fontSel) setFontSelection(dispatch, e.target.value);
	};
	
	const setSelColor = color => {
		if (color !== colorSel) setColorSelection(dispatch, color);
	};

	const revertPrefs = () => {
		if (!currState.saved) {
			if (currState.color !== colorSel) setColorSelection(dispatch, currState.color);
			if (currState.size !== sizeSel) setSizeSelection(dispatch, currState.size);
			if (currState.font !== fontSel) setFontSelection(dispatch, currState.font);
		}
	};

	const cancelPrefs = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	const savePrefs = e => {
		e.preventDefault();
		if (loggedIn && (currState.color !== colorSel || currState.size !== sizeSel || currState.font !== fontSel)) {
			saveDisplaySettings(dispatch, classes, csrfSessionNonce, (dispatch, response) => {
				if (response !== null) {
					if (response.status === 200) {
						if (response.data.status.code === 200) {
							currState.saved = true;
							closePopup(dispatch);
						} else if (response.data.status.code === 998) {
							setHistoryPath(dispatch, navigate, loginPath);
						} else {
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						}
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			});
		} else {
			currState.saved = true;
			closePopup(dispatch);
		}
	};

	useEffect(() => {
		setCloseFunc(revertPrefs);
	}, [classes]);

	return (
		<>
			<h3>{t('page.popup.dispset')}</h3>

			<StatusMsg/>
			<div className={`${e3Style['row']} ${e3Style['sprint-ctas']} ${e3Style['pt-20']}`}>
			<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				<fieldset>
				<legend id="font-family" className={e3Style['text-bold']}>{t('page.popup.fontselect')}</legend>
				<ul className={e3Style['sprint-font-selection']}>
					<li>
						<input title="Font Arial" type="radio" value='Arial' name="fontSelection"
						       id="font-Arial" checked={fontSel === 'Arial'} onClick={setFont}/>
						<label className={`${e3Style['use-font-Arial']} ${e3Style['font-size-45']}`} 
							aria-labelledby="font-selection Arial" htmlFor="font-Arial">
							Arial
						</label>
					</li>
					<li>
						<input title="Helvetica" type="radio" value='Helvetica' name="fontSelection"
						       id="font-Helvetica" checked={fontSel === 'Helvetica'} onClick={setFont}/>
						<label className={`${e3Style['use-font-Helvetica']} ${e3Style['font-size-45']}`}
							aria-labelledby="font-selection Helvetica" htmlFor="font-Helvetica">
							Helvetica
						</label>
					</li>
					<li>
						<input title="OpenSans" type="radio" value='OpenSans' name="fontSelection"
						       id="font-OpenSans" checked={fontSel === 'OpenSans'} onClick={setFont}/>
						<label className={`${e3Style['use-font-OpenSans']} ${e3Style['font-size-45']}`}
							aria-labelledby="font-selection OpenSans" htmlFor="font-OpenSans">
							OpenSans
						</label>
					</li>
					<li>
						<input title="Font Tahoma" type="radio" value='Tahoma' name="fontSelection"
						       id="font-Tahoma" checked={fontSel === 'Tahoma'} onClick={setFont}/>
						<label className={`${e3Style['use-font-Tahoma']} ${e3Style['font-size-45']}`} 
							aria-labelledby="font-selection Tahoma" htmlFor="font-Tahoma">
							Tahoma
						</label>
					</li>
					<li>
						<input title="Font Verdana" type="radio" value='Verdana' name="fontSelection"
						       id="font-Verdana" checked={fontSel === 'Verdana'} onClick={setFont}/>
						<label className={`${e3Style['use-font-Verdana']} ${e3Style['font-size-45']}`}
							aria-labelledby="font-selection Verdana" htmlFor="font-Verdana">
							Verdana
						</label>
					</li>
				</ul>
			</fieldset>
		</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
			<fieldset>
			<legend id="font-size" className={e3Style['text-bold']}>{t('page.popup.fontsize')}</legend>
			<ul className={e3Style['sprint-font-selection']}>
				<li>
					<input title="Font Size 72" type="radio" value={font72} name="fontSize"
					       id="font-size_72" checked={sizeSel === font72} onClick={setSize}/>
					<label className={e3Style['font-size-72']} aria-labelledby="font-size font-size_72" htmlFor="font-size_72">
						{t('page.popup.large')}
					</label>
				</li>
				<li>
					<input title="Font Size 60" type="radio" value={font60} name="fontSize"
					       id="font-size_60" checked={sizeSel === font60} onClick={setSize}/>
					<label className={e3Style['font-size-60']} aria-labelledby="font-size font-size_60" htmlFor="font-size_60">
						{t('page.popup.standard')}
					</label>
				</li>
				<li>
					<input title="Font Size 36" type="radio" value={font36} name="fontSize"
					       id="font-size_36" checked={sizeSel === font36} onClick={setSize}/>
					<label className={e3Style['font-size-36']} aria-labelledby="font-size font-size_36" htmlFor="font-size_36">
						{t('page.popup.small')}
					</label>
				</li>
			</ul>
			</fieldset>
		</div>
		</div>
		&nbsp;
		<fieldset>
			<legend id="font-contrast" className={e3Style['text-bold']}>{t('page.popup.contrast')}</legend>
			<ul className={`${e3Style['list-md-inline']} ${e3Style['sprint-contrast-selection']}`}>
				<li>
					<input type="radio" id="font-contrast_black_white_sel" value={blackWhite}
					       name="fontColor" title="Font contrast black and white"
					       className={e3Style['sprint-contrast-radio']} aria-label="font-contrast_black_white"
					       checked={colorSel === blackWhite} onClick={setColor}/>
					<label id="font-contrast_black_white"
					       className={`${e3Style['font-contrast-black-white']} ${e3Style['sprint-contrast-radio-label']}`}
					       sip-font-color-picker="sprint-black-white" onClick={() => {setSelColor(blackWhite)}}>
						{t('page.popup.font')}
					</label>
				</li>
				<li>
					<input type="radio" id="font-contrast_black_yellow_sel" value={blackYellow}
					       name="fontColor" title="Font contrast black and yellow"
					       className={e3Style['sprint-contrast-radio']}
					       aria-labelledby="font-contrast_black_yellow"
					       checked={colorSel === blackYellow} onClick={setColor}/>
					<label id="font-contrast_black_yellow"
					       className={`${e3Style['font-contrast-black-yellow']} ${e3Style['sprint-contrast-radio-label']}`}
					       sip-font-color-picker="sprint-black-yellow" onClick={() => {setSelColor(blackYellow)}}>
						{t('page.popup.font')}
					</label>
				</li>
				<li>
					<input type="radio" id="font-contrast_white_black_sel" value={whiteBlack}
					       name="fontColor" title="Font contrast white and black"
					       className={e3Style['sprint-contrast-radio']} aria-labelledby="font-contrast_white_black"
					       checked={colorSel === whiteBlack} onClick={setColor}/>
					<label id="font-contrast_white_black"
					       className={`${e3Style['font-contrast-white-black']} ${e3Style['sprint-contrast-radio-label']}`}
					       sip-font-color-picker="no-class" onClick={() => {setSelColor(whiteBlack)}}>
						{t('page.popup.font')}
					</label>
				</li>
				<li>
					<input type="radio" id="font-contrast_yellow_blue_sel" value={blueYellow}
					       name="fontColor" title="Font contrast yellow and blue"
					       className={e3Style['sprint-contrast-radio']} aria-labelledby="font-contrast_yellow_blue"
					       checked={colorSel === blueYellow} onClick={setColor}/>
					<label id="font-contrast_yellow_blue"
					       className={`${e3Style['font-contrast-yellow-blue']} ${e3Style['sprint-contrast-radio-label']}`}
					       sip-font-color-picker="sprint-blue-yellow" onClick={() => {setSelColor(blueYellow)}}>
						{t('page.popup.font')}
					</label>
				</li>
				<li>
					<input type="radio" id="font-contrast_green_white_sel" value={greenWhite}
					       name="fontColor" title="Font contrast green and white"
					       className={e3Style['sprint-contrast-radio']} aria-labelledby="font-contrast_green_white"
					       checked={colorSel === greenWhite} onClick={setColor}/>
					<label id="font-contrast_green_white"
					       className={`${e3Style['font-contrast-green-white']} ${e3Style['sprint-contrast-radio-label']}`}
					       sip-font-color-picker="sprint-green-white" onClick={() => {setSelColor(greenWhite)}}>
						{t('page.popup.font')}
					</label>
				</li>
			</ul>
		</fieldset>
		<div className={e3Style['sprint--modal-submit']}>
			<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
			        onClick={cancelPrefs}>
				{t('button.cancel')}
			</button>
			<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
			        onClick={savePrefs} ref={aFocus}>
				{t('button.save')}
			</button>
		</div>
		</>
	);
};

const ModalWindow = ModalWrapper(DisplaySettings);

const DisplaySettingsModal = () => {
	const isOpen = useSelector(isDisplaySettingsOpen);
	return <ModalWindow isOpen={isOpen}/>;
};

export default DisplaySettingsModal;
