import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import sprStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {loginPath} from "../../layout/body";
import {getFreqNumsNameSorted, loadFreqDialedNums} from "../../../store/FreqNumberList";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";
import {fmtPhoneNum} from "../../util/stringFormatHelper";

const genderDropdown = [
	{text: 'prefs.dropdown.gender.1', value: 0},
	{text: 'prefs.dropdown.gender.2', value: 1},
	{text: 'prefs.dropdown.gender.3', value: 2}
];

const languageDropdown = [
	{text: 'prefs.dropdown.language.2', value: 2},
	{text: 'prefs.dropdown.language.3', value: 5},
	{text: 'prefs.dropdown.language.4', value: 6}
];

const answerTypeDropdown = [
	{text: 'prefs.dropdown.anstype.1', value: 0},
	{text: 'prefs.dropdown.anstype.2', value: 1},
	{text: 'prefs.dropdown.anstype.3', value: 2},
	{text: 'prefs.dropdown.anstype.4', value: 3},
	{text: 'prefs.dropdown.anstype.5', value: 4},
	{text: 'prefs.dropdown.anstype.6', value: 5},
	{text: 'prefs.dropdown.anstype.7', value: 6},
	{text: 'prefs.dropdown.anstype.8', value: 7},
	{text: 'prefs.dropdown.anstype.9', value: 8},
	{text: 'prefs.dropdown.anstype.10', value: 9},
	{text: 'prefs.dropdown.anstype.11', value: 10},
	{text: 'prefs.dropdown.anstype.12', value: 11},
	{text: 'prefs.dropdown.anstype.13', value: 12},
	{text: 'prefs.dropdown.anstype.14', value: 42}
];

const agentTextSpeedDropdown = [
	{text: 'prefs.dropdown.agentTextSpeed.standard', value: -1},
	{text: 'prefs.dropdown.agentTextSpeed.5wpm', value: 5},
	{text: 'prefs.dropdown.agentTextSpeed.10wpm', value: 10},
	{text: 'prefs.dropdown.agentTextSpeed.15wpm', value: 15},
	{text: 'prefs.dropdown.agentTextSpeed.20wpm', value: 20},
	{text: 'prefs.dropdown.agentTextSpeed.25wpm', value: 25},
	{text: 'prefs.dropdown.agentTextSpeed.30wpm', value: 30},
	{text: 'prefs.dropdown.agentTextSpeed.35wpm', value: 35},
	{text: 'prefs.dropdown.agentTextSpeed.40wpm', value: 40},
	{text: 'prefs.dropdown.agentTextSpeed.45wpm', value: 45},
	{text: 'prefs.dropdown.agentTextSpeed.50wpm', value: 50},
	{text: 'prefs.dropdown.agentTextSpeed.55wpm', value: 55},
	{text: 'prefs.dropdown.agentTextSpeed.unlimited', value: 0}
];

const PrintInfo = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [origUserInfo, setorigUserInfo] = useState({
		firstName : '',
		lastName : '',
		addressLine1 : '',
		addressLine2 : '',
		city : '',
		stateAbbr : '',
		zip : '',
		zip4 : '',
		dataReady: false
	});

	const [accountState, setAccountState] = useState({
		sprintIpNumber: null,
		sprintIpNumberStatus: null,
		fedIpNumber: null,
		fedIpNumberStatus: null,
		mobileIpNumber: null,
		userValidated: false
	});

	const [prefs, setPrefs] = useState({
		genderPreference: 0,
		languageId: 2,
		answerType: 0,
		agentTextSpeed: -1,
		announceRelay: false,
		explainRelay: false,
		relayBackground: false,
		relayToneOfVoice: false,
		typeRecordings: false,
		slowTypingSpeed: false,
		longHoldTimes: false,
		bkspTypeCorrectionAllowed: false,
		abbreviationAllowed: false,
		confirmPreferences: false,
		useHumanWare: false,
		dataReady: false
	});

	const [perms, setPerms] = useState({
		no800: false,
		noDA: false,
		dataReady: false
	});

	const freqNbrList = useSelector(getFreqNumsNameSorted);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const [noteList, setNoteList] = useState([]);

	const [emrgNbrList, setEmrgNbrList] = useState([]);

	const [blockNbrList, setBlockNbrList] = useState([]);
	
	const [stsNbrList, setStsNbrList] = useState([]);

	const currentDate = new Date();
	const currentDateText = currentDate.toLocaleDateString("en-US");

	useEffect(() => {
		getUserInfo();
	}, []);

	const loadFreqDialed = () => {
		const successFunc = () => {};
		const errorFunc = (dispState, response) => {
			if (response.statusType === 0) {
				switch (response.status) {
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		};
		loadFreqDialedNums(dispatch, csrfSessionNonce, successFunc, errorFunc);
	};

	const getUserInfo = async () => {
		try {
			updateActTime(dispatch);
			let response = await axios.post('/nologin/api',
			{command: {name: 'getAccountList', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setAccountState({
							...data.response
						});
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
					{command: {name: 'getRelayPrefs', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200) {
					let myPrefs = {...data.response};

					let matchIdx = genderDropdown.findIndex(function(item){ 
			        return item.value === data.response.genderPreference;
					});
					matchIdx = (matchIdx >= 0) ? matchIdx: 0;
					myPrefs = {...myPrefs, genderPreferenceText: genderDropdown[matchIdx].text };

					matchIdx = languageDropdown.findIndex(function(item){ 
			        return item.value === data.response.languageId;
					});
					matchIdx = (matchIdx >= 0) ? matchIdx: 1;
					myPrefs = {...myPrefs, languageText: languageDropdown[matchIdx].text };

					matchIdx = answerTypeDropdown.findIndex(function(item){ 
			        return item.value === data.response.answerType;
					});
					matchIdx = (matchIdx >= 0) ? matchIdx: 0;
					myPrefs = {...myPrefs, answerTypeText: answerTypeDropdown[matchIdx].text };

					matchIdx = agentTextSpeedDropdown.findIndex(function(item){ 
			        return item.value === data.response.agentTextSpeed;
					});
					matchIdx = (matchIdx >= 0) ? matchIdx: 0;
					myPrefs = {...myPrefs, agentTextSpeedText: agentTextSpeedDropdown[matchIdx].text };

					setPrefs({
						...myPrefs,
						dataReady: true
					});
				} else if (response.status === 998) {
					setHistoryPath(dispatch, navigate, loginPath);
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
					{command: {name: 'getOutdialRestrictions', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setPerms({
							...data.response,
							dataReady: true
						});
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
					{command: {name: 'getEmergencyNumbersList', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						const sortedList = data.response.emergencyNumbers.slice(0).sort(function(a, b){
								let x = a.NAME.toLowerCase();
								let y = b.NAME.toLowerCase();
								if (x < y) {return -1;}
								if (x > y) {return 1;}
								return 0;
						});
						setEmrgNbrList(sortedList);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
					{command: {name: 'getNotesList', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						const sortedList = data.response.customerNotes.slice(0).sort((a, b) => a.ORDERID - b.ORDERID);
						setNoteList(sortedList);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			loadFreqDialed();

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
					{command: {name: 'getBlockedNumbers', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						const sortedList = data.response.blockedNumbers.slice(0).sort(function(a, b){
								let x = a.NAME.toLowerCase();
								let y = b.NAME.toLowerCase();
								if (x < y) {return -1;}
								if (x > y) {return 1;}
								return 0;
						});
						setBlockNbrList(sortedList);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
				{command: {name: 'listSTSContacts', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						let sortedList = [];
						if( data.response.STSContacts !== undefined)
							sortedList = data.response.STSContacts.slice(0).sort(function(a, b){
								let x = a.NAME.toLowerCase();
								let y = b.NAME.toLowerCase();
								if (x < y) {return -1;}
								if (x > y) {return 1;}
								return 0;
						});
						setStsNbrList(sortedList);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

			updateActTime(dispatch);
			response = await axios.post('/nologin/api',
			{command: {name: 'getUserInfoEnhanced', csrfSessionNonce}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setorigUserInfo({...data.response,
							middleInitial: data.response.middleInitial? data.response.middleInitial:'',
							addressLine2: data.response.addressLine2? data.response.addressLine2:'',
							dataReady: true
						});
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}

	};

	const printPage = () => {
		const innerContents = document.getElementById('printArea').innerHTML;
		const popupWinindow = window.open('', 'iprelayprint', 'width=600,height=690,scrollbars=yes,resizable=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
		popupWinindow.document.open();
		popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="/resources/css/sprintip-js.min.css"><script type="text/javascript" src="/resources/js/print-dialog.min.js"></script></head><body>' + innerContents + '</html>');
		popupWinindow.document.close();
	};

	return (
		<>
			<StatusMsg/>
			<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>		
			{ origUserInfo.dataReady ?
				<div id="printArea">
				<div className={sprStyle.printtext}>
					<div className={sprStyle['text-ctr']}><img className={`${sprStyle['print-logo']}`} alt="Sprint relay Logo" src="/resources/assets/images/T-Mobile_Accessibility_Logo_Horiz_RGB_on-W_2020-08-19.png" border="0"/></div>
					<div className={`${sprStyle['text-ctr']} ${sprStyle.pageheading}`}>{t('flows.print.printpreview.1')}&nbsp;{origUserInfo.userid}</div>
					<div className={`${sprStyle['text-ctr']} ${sprStyle['pageheading-date']}`}>{t('flows.print.printpreview.2')}&nbsp;{currentDateText}</div>

					<table border="0" cellpadding="0" cellspacing="0" width="650px">
			  		<tbody>
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
						</tr>
						<tr>
							<td colspan="2" className={`${sprStyle.heading} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.3')}</td>
						</tr>
						<tr><td colspan="2">
							<table className={sprStyle['no-padding']} border="0" cellpadding="0" cellspacing="0" align="left">
								<tbody>
								<tr>
									<td className={sprStyle['text-right']} width="148">{t('flows.print.printpreview.4')}&nbsp;</td>
									<td className={sprStyle['text-left']} width="140">{origUserInfo.firstName}</td>
									<td className={sprStyle['text-right']} width="40">{t('flows.print.printpreview.5')}&nbsp;</td>
									<td className={sprStyle['text-left']} width="40">{origUserInfo.middleInitial}</td>
									<td className={sprStyle['text-right']} width="90">{t('flows.print.printpreview.6')}&nbsp;</td>
									<td className={sprStyle['text-left']} width="140">{origUserInfo.lastName}</td>
								</tr>
								</tbody>
							</table>
						</td></tr>
						<tr>
							<td className={`${sprStyle['title-text']} ${sprStyle['padding-8']}`} width="150">{t('flows.print.printpreview.7')}&nbsp;</td>
							<td className={sprStyle['padding-8']}>{origUserInfo.addressLine1}</td>
						</tr>
						<tr>
							<td className={`${sprStyle['title-text']} ${sprStyle['padding-8']}`} width="150">{t('flows.print.printpreview.8')}&nbsp;</td>
							<td className={sprStyle['padding-8']}>{origUserInfo.addressLine2}</td>
						</tr>
						<tr><td colspan="2">
							<table className={sprStyle['no-padding']} border="0" cellpadding="0" cellspacing="0" align="left">
								<tbody>
								<tr>
									<td className={sprStyle['text-right']} width="148">{t('flows.print.printpreview.9')}&nbsp;</td>
									<td className={sprStyle['text-left']} width="140">{origUserInfo.city}</td>
									<td className={sprStyle['text-right']} width="40">{t('flows.print.printpreview.10')}&nbsp;</td>
									<td className={sprStyle['text-left']} width="40">{origUserInfo.stateAbbrev}</td>
									<td className={sprStyle['text-right']} width="90">{t('flows.print.printpreview.11')}&nbsp;</td>
									<td className={sprStyle['text-left']} width="140">{origUserInfo.zip}</td>
								</tr>
								</tbody>
							</table>
						</td></tr>
						<tr>
							<td className={`${sprStyle['title-text']} ${sprStyle['padding-8']}`} width="150">{t('flows.print.printpreview.12')}&nbsp;</td>
							<td className={sprStyle['padding-8']}>{origUserInfo.email}</td>
						</tr>
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.13')}</td>
						</tr>
			  			<tr className={sprStyle['topleft-padding']}>
			  				<td colspan="2">
							<table className={sprStyle['no-padding']} border="0" cellpadding="0" cellspacing="0" align="left">
				      		<tbody>
								<tr>
									<th width="50%" align="left" className={sprStyle['text-phonenumber']}>{t('flows.print.printpreview.14')}</th>
									<th width="50%" align="left" className={sprStyle['text-phonenumber']}>{t('flows.print.printpreview.15')}</th>
								</tr>
					      		{
									accountState.sprintIpNumber != null ?
									<>
									<tr>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{t('flows.print.printpreview.16')}</td>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{fmtPhoneNum(accountState.sprintIpNumber)}</td>
									</tr>
									</>
									:
									<>
									</>
								}
								
					      		{
									accountState.fedIpNumber != null ?
									<>
									<tr>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{t('flows.print.printpreview.17')}</td>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{fmtPhoneNum(accountState.fedIpNumber)}</td>
									</tr>
									</>
									:
									<>
									</>
								}

								{
									accountState.mobileIpNumber != null ?
									<>
									<tr>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{t('flows.print.printpreview.18')}</td>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{fmtPhoneNum(accountState.mobileIpNumber)}</td>
									</tr>
									</>
									:
									<>
									</>
								}
					  		</tbody>
				  			</table>
			  				</td>
			  			</tr>
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.19')}</td>
						</tr>
						{ noteList.length == 0 ?
						  <>
						  <tr>
							<td colspan="2" width="100%">{t('flows.print.printpreview.20')}</td>
						  </tr>
						  </>
						  :
						  <>
						  <tr><td colspan="2" width="100%">
								<table border="0" cellpadding="0" cellspacing="0" width="100%">
								<tbody>
									{noteList.map(noteItem => {
									return (
									<tr>
										<td width="10%" align="left" className={sprStyle['text-phonenumber']}>{noteItem.ORDERID}</td>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']}>{noteItem.NOTES}</td>
										<td width="20%" align="left" className={sprStyle['text-phonenumber']}>{noteItem.AGENTID}</td>
										<td width="20%" align="left" className={sprStyle['text-phonenumber']}>{noteItem.CREATE_DATE}</td>
									</tr>
									);
									})}
								</tbody>
								</table>
						  </td></tr>
						  </>
						}
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.21')}</td>
						</tr>
						{ freqNbrList?.length !== 0 ?
							<>
								<tr>
									<td colspan="2" width="100%">
										<table border="0" cellpadding="0" cellspacing="0" width="100%">
											<tbody>
											{freqNbrList.map(freqNbr =>
													<tr>
														<td width="10%" align="left"
														    className={sprStyle['text-phonenumber']}>{freqNbr.ORDERID}</td>
														<td width="45%" align="left"
														    className={sprStyle['text-phonenumber']}>{freqNbr.NAME}</td>
														<td width="45%" align="left"
														    className={sprStyle['text-phonenumber']}>{fmtPhoneNum(freqNbr.PHONE_NUMBER)}</td>
													</tr>
											)}
											</tbody>
										</table>
									</td>
								</tr>
							</>
							:
							<>
								<tr>
									<td colspan="2" width="100%">{t('flows.print.printpreview.22')}</td>
								</tr>
							</>
						}
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.23')}</td>
						</tr>
						{ emrgNbrList.length == 0 ?
						  <>
						  <tr>
							<td colspan="2" width="100%">{t('flows.print.printpreview.24')}</td>
						  </tr>
						  </>
						  :
						  <>
						  <tr><td colspan="2" width="100%">
								<table border="0" cellpadding="0" cellspacing="0" width="100%">
								<tbody>
									{emrgNbrList.map(emrgNbr => {
									return (
									<tr>
										<td width="10%" align="left" className={sprStyle['text-phonenumber']}>{emrgNbr.ORDERID}</td>
										<td width="45%" align="left" className={sprStyle['text-phonenumber']}>{emrgNbr.NAME}</td>
										<td width="45%" align="left" className={sprStyle['text-phonenumber']}>{fmtPhoneNum(emrgNbr.PHONE_NUMBER)}</td>
									</tr>
									);
									})}
								</tbody>
								</table>
						  </td></tr>
						  </>
						}
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.25')}</td>
						</tr>
						{ blockNbrList.length === 0 ?
						  <>
						  <tr>
							<td colspan="2" width="100%">{t('flows.print.printpreview.26')}</td>
						  </tr>
						  </>
						  :
						  <>
						  <tr><td colspan="2" width="100%">
								<table border="0" cellpadding="0" cellspacing="0" width="100%">
								<tbody>
									{blockNbrList.map(blockNbr => {
									return (
									<tr>
										<td width="10%" align="left" className={sprStyle['text-phonenumber']}>{blockNbr.ORDERID}</td>
										<td width="45%" align="left" className={sprStyle['text-phonenumber']}>{blockNbr.NAME}</td>
										<td width="45%" align="left" className={sprStyle['text-phonenumber']}>{fmtPhoneNum(blockNbr.PHONE_NUMBER)}</td>
									</tr>
									);
									})}
								</tbody>
								</table>
						  </td></tr>
						  </>
						}
						{perms.no800 ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.27')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{perms.noDA ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.28')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.29')}</td>
						</tr>
						<tr>
							<td className={sprStyle['title-text']} width="150">{t('flows.print.printpreview.30')}&nbsp;</td>
							<td>{t(prefs.genderPreferenceText)}</td>
						</tr>
						<tr>
							<td className={sprStyle['title-text']} width="150">{t('flows.print.printpreview.31')}&nbsp;</td>
							<td>{t(prefs.languageText)}</td>
						</tr>
						<tr>
							<td className={sprStyle['title-text']} width="150">{t('flows.print.printpreview.32')}&nbsp;</td>
							<td>{t(prefs.answerTypeText)}</td>
						</tr>
						<tr>
							<td className={sprStyle['title-text']} width="150">{t('flows.print.printpreview.33')}&nbsp;</td>
							<td>{t(prefs.agentTextSpeedText)}</td>
						</tr>
						{prefs.announceRelay ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.34')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.explainRelay ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.35')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.relayBackground ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.36')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.relayToneOfVoice ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.37')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.typeRecordings ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.38')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.slowTypingSpeed ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.39')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.longHoldTimes ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.40')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.bkspTypeCorrectionAllowed ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.41')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.abbreviationAllowed ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.42')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.confirmPreferences ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.43')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						{prefs.useHumanWare ?
							<>
							<tr>
			    				<td className={sprStyle['title-text']} width="150"><img className={sprStyle.checkmark} alt="Checked" src="/resources/assets/images/checked.gif"/></td>
			    				<td>{t('flows.print.printpreview.44')}</td>
			  				</tr>
							</>
							:
							<>
							</>
						}
						<tr>
							<td height="10" width="150"></td>
							<td height="10" width="500"></td>
			  			</tr>
						<tr>
							<td colspan="2" className={`${sprStyle['heading']} ${sprStyle['heading-extra']}`}>{t('flows.print.printpreview.45')}</td>
						</tr>
						{ stsNbrList.length === 0 ?
						  <>
						  <tr>
							<td colspan="2" width="100%">{t('flows.print.printpreview.46')}</td>
						  </tr>
						  </>
						  :
						  <>
						  <tr><td colspan="2" width="100%">
								<table border="0" cellpadding="0" cellspacing="0" width="100%">
								<tbody>
									{stsNbrList.map(STSNbr => {
									return (
									<tr>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']} >{STSNbr.NAME}</td>
										<td width="50%" align="left" className={sprStyle['text-phonenumber']} >{fmtPhoneNum(STSNbr.PHONE_NUMBER)}</td>
									</tr>
									);
									})}
								</tbody>
								</table>
						 </td></tr>
						 </>
						}
					</tbody>
					</table>				
				</div>
				</div>
				:
				<>
				</>
			}
			<div className={e3Style.row}>
			<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>		
				<button type="button" onClick={printPage} 
				className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
				{t('button.printpage')}
				</button>
			</div>
			</div>
			</div>
		</>
	);
}

export default PrintInfo;