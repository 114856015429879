import rgpCommand, {cmdInterface} from "./rgbCommand";
import delayCmdMgr from "./delayCmdMgr";

const RgbCmdMgr = (command, params, tmout, proxyCmd, func, delay, dispatch) =>
	(() => {
		const cmdData = {
			cmdIntf: null
		};
		const dCmdMgr = delayCmdMgr(delay, () => {
			rgpCommand(command, params, tmout, proxyCmd, cmdData.cmdIntf, dispatch)});

		return {
			start: (state, dispatch) => {
				const cmdFunc = data => {
					cmdData.cmdIntf = null;
					func(data, state, dispatch);
				};
				cmdData.cmdIntf = cmdInterface(cmdFunc);
				dCmdMgr.start(state, dispatch);
			},
			getNextDelay: (delay, minDelay) => dCmdMgr.getNextDelay(delay, minDelay),
			cancel: () => {
				if (!dCmdMgr.cancel() && cmdData.cmdIntf !== null) cmdData.cmdIntf.source.cancel();
			}
		};
	})();

export default RgbCmdMgr;
