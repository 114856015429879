import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {useValidator} from "react-joi";
import Joi from "joi";
import {setHistoryPath} from "../../../store/bodyView";
import {reserveNumberPath} from "../../layout/body";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";

const NewNPA = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			npa: ""
		},
		schema: Joi.object({
			npa: Joi.string().trim().length(3).required().messages({
				'string.empty': 'flows.account.error.npa.required',
				'string.length': 'flows.account.error.npa.length'
			})
		}),
		explicitCheck: {
			npa: false
		}
	});

	const doSubmit = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, reserveNumberPath + `/${state.$data.npa}`);
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	return (
		<>
			<h1 className={e3Style['pt-0']}>{t('flows.common.newnpa.1')}</h1>
			<form noValidate autoComplete="off" onSubmit={doSubmit}>
				<div className={e3Style['col-xs-24']}>{t('flows.common.newnpa.2')}</div>
				<div className={`${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
					<FocusWrappedGrp baseAriaId="npaErr" cptErrCount={state.$errors.npa.length}>
						<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="npa">{t('flows.common.newnpa.3')}</FocusWrappedLabel>
						<FocusWrappedInput type="number" name="npa" id="npa" initialFocus className={e3Style['input--fluid']}
						                   onBlur={() => setExplicitField('npa', true)}
						                   onChange={e => updateField(e, 'npa')}/>
						{!!state.$errors.npa.length &&
							<div className={e3Style['sprint-alert--error']}>
								<div className={e3Style['alert--error_message']}>
									{state.$errors.npa.map((err, idx) => <p index={idx} id={'npaErr-' + idx}><span>{t(err.$message)}</span></p>)}
								</div>
							</div>
						}
					</FocusWrappedGrp>
				</div>
				<div className={e3Style.row}><div className={`${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
					<button type="submit" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['mt-40']} ${e3Style['mb-40']}`}>{t('button.submit')}</button>
				</div></div>
			</form>
		</>
	);
};

export default NewNPA;
