import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {apiDoCall} from "./api";

const slice = createSlice({
	name: "emerNumberList",
	initialState: {
		numbers: null
	},
	reducers: {
		loadList: (emerNumberList, action) => {
			const newList = action.payload.response.response.emergencyNumbers.slice().sort((a, b) => a.ORDERID - b.ORDERID);
			if (emerNumberList.numbers === null || emerNumberList.numbers.length !== newList.length)
				emerNumberList.numbers = newList;
			else {
				for (let i = 0;i < emerNumberList.numbers.length;++i) {
					const curItem = emerNumberList.numbers[i];
					const newItem = newList[i];
					if (curItem.ORDERID !== newItem.ORDERID ||
						curItem.PHONE_NUMBER !== newItem.PHONE_NUMBER || curItem.NAME !== newItem.NAME) {
						emerNumberList.numbers = newList;
						break;
					}
				}
			}
		},

		addPhone: (emerNumberList, action) => {
			if(emerNumberList.numbers === null)
				emerNumberList.numbers = [...emerNumberList.numbers, action.payload.preset];
			else{
				const insertIdx = emerNumberList.numbers.findIndex(
					value => value.ORDERID > action.payload.preset.ORDERID);
				if(insertIdx < 0)
					emerNumberList.numbers = [...emerNumberList.numbers, action.payload.preset];
				else {
					const newArray = [...emerNumberList.numbers];
					newArray.splice(insertIdx,0,action.payload.preset);
					emerNumberList.numbers = newArray;
				}
			}
		},

		updatePhone: (emerNumberList, action) => {
			emerNumberList.numbers = emerNumberList.numbers.map(
				thisNbr => thisNbr.ORDERID === action.payload.preset.ORDERID ? action.payload.preset : thisNbr);
		},

		deletePhone: (emerNumberList, action) => {
			emerNumberList.numbers = emerNumberList.numbers.filter(
				value => value.ORDERID !== action.payload.preset);
		}
	}
});

export const {
	loadList,
	addPhone,
	updatePhone,
	deletePhone
} = slice.actions;
export default slice.reducer;

export const loadEmerNums = (dispatch, csrfSessionNonce, successFunc, errorFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'getEmergencyNumbersList', csrfSessionNonce}
			},
			onSuccess: [{type: loadList.type}, (dispState, response) => {successFunc(dispState, response)}],
			onError: (dispState, response) => {errorFunc(dispState, response)}
		})
	);
};

export const addEmerNbr = (dispatch, newPhone) => {
	dispatch({type: addPhone.type, payload: {preset: newPhone}});
};

export const updateEmerNbr = (dispatch, thisPhone) => {
	dispatch({type: updatePhone.type, payload: {preset: thisPhone}});
};

export const delEmerNbr = (dispatch, orderId) => {
	dispatch({type: deletePhone.type, payload: {preset: orderId}});
};

export const getEmerNumbers = createSelector(
	state => state.entities.emerNumberList.numbers,
	numbers => numbers
);