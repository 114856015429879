import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useNavigate, useParams} from "react-router";
import DisplayMessage from "../Common/displayMessage";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setHistoryPath} from "../../../store/bodyView";
import {accountListPath, loginPath, newNPAPath, rootPath, regPortNumPath} from "../../layout/body";
import {getCsrfSessionNonce, getUserIdAndAdmin} from "../../../store/user";
import {getCallState, setUserPhoneNum} from "../../../store/call";
import {updateActTime} from "../../../store/activity";
import Spinner from "../root/spinner";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../util/ioHelper";

const DisplayMsg = () => {
	const { t } = useTranslation();
	return <div>{t('reserve.number.error.message')}</div>;
};

const ReseveNumber = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {phone,source} = useParams();
	const [state, setState] = useState({});
	const userIdAndAdmin = useSelector(getUserIdAndAdmin);
	const callState = useSelector(getCallState);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	useEffect(() => {
		const reserveNumber = async () => {
			try {
				updateActTime(dispatch);
				const response = await postWithTimeout(
					{
						command: {name: 'reservePhoneNumber', csrfSessionNonce},
						params: {
							locale: i18n.language,
							tddPhone: phone
						}
					}, EXTENDED_TIMEOUT);
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setUserPhoneNum(dispatch, data.response.phoneNumber);
							setState({newNumber: data.response.phoneNumber});
							break;

						case 310:
							setHistoryPath(dispatch, navigate, newNPAPath);
							break;

						case 311:
							setState({
								displayMessage: {
									headerMsg: 'reserve.number.error.header',
									DisplayMsg,
									buttonList: [
										{
											onClick: e => {e.preventDefault();setHistoryPath(dispatch, navigate, accountListPath)},
											btnText: 'button.ok'
										}
									]
								}
							});
							break;

						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;

						default:
							setHistoryPath(dispatch, navigate, accountListPath, 'generic.rgp.error');
							break;
					}
				}
			} catch (error) {
				setHistoryPath(dispatch, navigate, accountListPath, 'generic.rgp.error');
			}
		};
		if(phone != 0)
			reserveNumber();
		else
			setState({newNumber: callState.userPhoneNumber})
	}, []);

	return (
		<>
			{state.newNumber ?
				<>
				{ source === undefined || source === null ?
					<>
					<h1 className={e3Style['pt-0']}>{t('flows.account.reserveNumber.title')}</h1>
					<p>{t('flows.account.reserveNumber.congrats')} {state.newNumber}</p>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-14']}`}>
						<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, accountListPath)}}
						        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.ok')}
						</button>
					</div>
					</>
					:
					<>
					<h1 className={e3Style['pt-0']}>{t('flows.registration.regnumber.1')}</h1>
					<p>{t('flows.registration.regnumber.2')}</p>
					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-14']}`}>
							<label className={`${e3Style['text-uppercase']} ${e3Style['text-bold']}`}>{t('flows.registration.regnumber.3')}</label>
							<h3 className={e3Style['input--fluid']}>{userIdAndAdmin.userId}</h3>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-14']}`}>
							<label className={`${e3Style['text-uppercase']} ${e3Style['text-bold']}`}>{t('flows.registration.regnumber.4')}</label>
							<h3 className={e3Style['input--fluid']}>{state.newNumber}</h3>
						</div>
					</div>
					<p>{t('flows.registration.regnumber.5')}</p>
					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-14']}`}>
							<button type="button" disabled={source === 'afterport'}
									onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, regPortNumPath+'/registration')}}
							        className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
								{t('button.portmynum')}
							</button>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-14']}`}>
							<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, rootPath)}}
							        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
								{t('button.done')}
							</button>
						</div>
					</div>
					</>
				}
				</>
			: state.displayMessage ?
				<DisplayMessage displayMsgObj={state.displayMessage}/>
			: <Spinner/>
			}
		</>
	);
};

export default ReseveNumber;
