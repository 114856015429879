import React, {useEffect, useState} from "react";
import e3Style from '../../../../css/e3-style.css';
import sStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedTextarea from "../../util/focusWrappedTextarea";

const NotesItem = ({
  noteItem,
  onDeleteFunc,
  onUpdateFunc,
  refreshCount
}) => {
	const { t } = useTranslation();

	const [ origNotesItem, setOrigNotesItem] = useState({"ORDERID":0,"NOTES":""});
	const [ myNotesItem, setMyNotesItem] = useState({"ORDERID":0,"NOTES":""});
	const [ showDelete, setShowDelete ] = useState(true);
	const [ showDelConfirm, setShowDelConfirm ] = useState(false);
	const [ showUpdConfirm, setShowUpdConfirm ] = useState(true);
	const [ noteError, setNoteError ] = useState(false);
	const [ rCount, setRCount] = useState(0);

	useEffect(() => {
		if(refreshCount > rCount)
		{
			setRCount(refreshCount);
			setOrigNotesItem( {...noteItem});
			setOriginal(noteItem);
		}
	}, [refreshCount, rCount]);

    const setOriginal = (thisNotesItem) => {
		setShowDelete(true);
		setShowDelConfirm(false);
		setShowUpdConfirm(false);
		setMyNotesItem({...thisNotesItem});
		setNoteError(( thisNotesItem.NOTES == null || thisNotesItem.NOTES.length === 0 ));
	}

	return (
		<>
			<div className={`${e3Style.row} ${e3Style['pb-40']}`}>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-note']}`}>
					<FocusWrappedGrp baseAriaId="noteErr" cptErrCount={noteError ? 1 : 0}>
						<div className={`${e3Style['note-input-wrapper']}`}>
							<FocusWrappedTextarea id="note" rows="1" name="note" title={t('page.popup.plsentrcnt')}
							                      className={e3Style['sprint-form-note_input']} value={myNotesItem.NOTES}
							                      disabled={showDelConfirm}
							                      onChange={e => {
								                      setMyNotesItem(prevNoteItem => ({...prevNoteItem, "NOTES": e.target.value}));
								                      if (e.target.value.trim().length === 0) {
									                      setNoteError(true);
									                      setShowUpdConfirm(false);
									                      setShowDelete(true);
								                      } else {
									                      setNoteError(false);
									                      const noChange = (e.target.value.trim() === origNotesItem.NOTES);
									                      setShowUpdConfirm(!noChange);
									                      setShowDelete(noChange);
								                      }
							                      }}/>
						</div>
						{noteError &&
							<label className={`${e3Style['input--fluid']} ${e3Style['is-invalid-label']}`} id="noteErr-0">
								{t('page.popup.plsentrcnt')}</label>
						}
					</FocusWrappedGrp>
					<div id="note-link-container"
						className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-note_links']} ${e3Style['note-links']}`}>
						{showDelete &&
							<a href='#' className={e3Style['button--link']} onClick={e => {
								e.preventDefault();
								setShowDelete(false);
								setShowUpdConfirm(false);
								setShowDelConfirm(true)
							}}>{t('button.delete')}</a>
						}
						{showDelConfirm &&
							<>
								<label className={sStyle['notes-confirm-delete']}>{t('embedded.12')}</label>
								<a href='#' className={e3Style['button--link']} onClick={e => {
									e.preventDefault();
									onDeleteFunc(origNotesItem.ORDERID)
								}}>{t('button.yes')}</a>
							</>
						}
						{(showDelConfirm || showUpdConfirm) &&
							<a href='#' className={e3Style['button--link']} onClick={e => {
								e.preventDefault();
								setOriginal(origNotesItem)
							}}>{t('button.cancel')}</a>
						}
						{showUpdConfirm &&
							<a href='#' className={e3Style['button--link']} onClick={e => {
								e.preventDefault();
								onUpdateFunc(myNotesItem);
							}}>{t('button.save')}</a>
						}
					</div>
				</div>
			</div>
		</>
	);
};

export default NotesItem;