import { unstable_batchedUpdates } from 'react-dom';
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import multi from 'redux-multi';
import { batchedSubscribe } from 'redux-batched-subscribe';
import reducer from "./reducer";
import api from "./middleware/api";

export default function() {
	return configureStore({
		reducer,
		middleware: [
			...getDefaultMiddleware(),
			api,
			multi
		],
		enhancers: [batchedSubscribe(unstable_batchedUpdates)]
	});
}
