import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import FocusWrappedCpt from "./focusWrappedCpt";

const Fwc = FocusWrappedCpt(forwardRef(({children, ...iProps}, myRef) => <select ref={myRef} {...iProps}>{children}</select>));

const FocusWrappedSelect = forwardRef((props, cptRef) => {
	const ref = useRef();
	useImperativeHandle(cptRef, () => ref.current, [ref]);
	const [doInitFocus, setDoInitFocus] = useState(true);
	return <Fwc ref={ref} doInitFocus={doInitFocus} setDoInitFocus={setDoInitFocus} {...props}/>;
});

FocusWrappedSelect.defaultProps = {
	__FOCUS_MANAGED: true
};

export default FocusWrappedSelect;
