import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate, useParams} from "react-router";
import {getLocationList, addLocation, updateLocation, delLocation, setStateList, getStateList, setFormattedAddress, setEditAddress} from "../../../store/AddressList"
import {loginPath, confirmAddressPath, locationsPath } from "../../layout/body";
import Joi from "joi";
import { useValidator } from "react-joi";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedSelect from "../../util/focusWrappedSelect";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const EditAddress = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateList = useSelector(getStateList);
	const locationList = useSelector(getLocationList);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const params = useParams();
	const editType = params.editType;
	const addressId = parseInt(params.addressId);

	let submitBtnTitle = t('button.submit');

	const [origAddrInfo, setOrigAddrInfo] = useState({
		ADDRESS_NAME: '',
		ADDRESS_LINE_1 : '',
		ADDRESS_LINE_2 : '',
		CITY : '',
		STATE : '',
		ZIP : '',
		ZIP4 : ''
	});

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			ADDRESSID: 0,
			ADDRESS_NAME: '',
			ADDRESS_LINE_1 : '',
			ADDRESS_LINE_2 : '',
			CITY : '',
			STATE : '',
			ZIP : '',
			ZIP4 : ''
		},
 	    schema: Joi.object({
			ADDRESS_NAME: Joi.string().trim().min(1).required().messages({
				'string.base': 'addressName.required.error',
				'string.empty': 'addressName.required.error',
				'any.required': 'addressName.required.error',
				'string.pattern.base': 'addressName.required.error'
			}), 
			ADDRESS_LINE_1: Joi.string().trim().min(1).required().messages({
				'string.base': 'addressLine1.required.error',
				'string.empty': 'addressLine1.required.error',
				'any.required': 'addressLine1.required.error',
				'string.pattern.base': 'addressLine1.required.error'
			}),
			CITY: Joi.string().trim().min(1).required().messages({
				'string.base': 'city.required.error',
				'string.empty': 'city.required.error',
				'any.required': 'city.required.error',
				'string.pattern.base': 'city.required.error'
			}),
			STATE: Joi.string().trim().min(2).required().messages({
				'string.base': 'stateAbbr.required.error',
				'string.empty': 'stateAbbr.required.error',
				'any.required': 'stateAbbr.required.error',
				'string.pattern.base': 'stateAbbr.required.error'
			}),
			ZIP: Joi.string().required().pattern(/^\d{5}$/).messages({
				'string.base': 'zip.invalidFormat.error',
				'string.empty': 'zip.required.error',
				'any.required': 'zip.required.error',
				'string.pattern.base': 'zip.invalidFormat.error'
			})
		}),
		explicitCheck: {
			ADDRESS_NAME: false,
			ADDRESS_LINE_1: false,
			CITY: false,
			STATE: false,
			ZIP:false
		}
	});

	useEffect(() => {
		getAddrInfo();
		if(editType === 'deleteLocation')
			submitBtnTitle = t('button.delete');
	}, []);
	
	const getAddrInfo = async () => {
		try {
			if( editType !== 'addLocation' ){
				let matchIdx = locationList.findIndex(function(value){ 
			        return value.ADDRESSID === addressId;
				});
				if(matchIdx >= 0){
					setData((old) => ({
						...old,
						ADDRESSID: locationList[matchIdx].ADDRESSID,
						ADDRESS_NAME: locationList[matchIdx].ADDRESS_NAME,
						ADDRESS_LINE_1: locationList[matchIdx].ADDRESS_LINE_1,
						ADDRESS_LINE_2: locationList[matchIdx].ADDRESS_LINE_2,
						CITY: locationList[matchIdx].CITY,
						STATE: locationList[matchIdx].STATE,
						ZIP: locationList[matchIdx].ZIP,
						ZIP4: locationList[matchIdx].ZIP4
					}));
					setOrigAddrInfo( {...locationList[matchIdx]} );
				}
			}

			if(stateList.length === 0){
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
						{command: {name: 'getStates'}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setStateList(dispatch, data.response.statesList);
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			console.log("error="+error);
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = (e) => {
		const {name, value} = e.target;
		setData((old) => ({
			...old,
			[name]: value
		}));
	};

	const restoreFields = () =>{
		setData((old) => ({
			...old,
			ADDRESS_NAME: origAddrInfo.ADDRESS_NAME,
			ADDRESS_LINE_1: origAddrInfo.ADDRESS_LINE_1,
			ADDRESS_LINE_2: origAddrInfo.ADDRESS_LINE_2,
			CITY: origAddrInfo.CITY,
			STATE: origAddrInfo.STATE,
			ZIP: origAddrInfo.ZIP,
			ZIP4: origAddrInfo.ZIP4
		}));
	}
	
	const onCancel = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, locationsPath);
	}

	const onSubmit = e => {
		e.preventDefault();
		if(editType == 'deleteLocation')
			saveUserAddressChange({addressId: addressId.toString()})
		else
			checkAddrInfo({...state.$data});
	}

	const checkAddrInfo = async (newInfo) => {
		try {
			let duplicateName = false;
			if( editType !== 'delLocation' ){
				const matchIdx = locationList.findIndex(function(value){ 
			        return value.ADDRESS_NAME === newInfo.ADDRESS_NAME;
				});
				if( matchIdx >= 0 ) {
					if(editType === 'editLocation' ){
						const matchIdxOrig = locationList.findIndex(function(value){ 
							return value.ADDRESS_NAME === origAddrInfo.ADDRESS_NAME;
						});
						if(matchIdx !== matchIdxOrig) duplicateName = true;
					}
					else duplicateName = true;
				}
			}
			
			if(duplicateName) {
					setData((old) => ({...old, ADDRESS_NAME:''}));
					setStatusMessage(dispatch, 'Duplicate Address Name. Please use a different one!', STATUS_ERROR);
			}
			else{
				let newAddress = {
					addressLine1: newInfo.ADDRESS_LINE_1.trim(),
					addressLine2: newInfo.ADDRESS_LINE_2? newInfo.ADDRESS_LINE_2.trim() : '',
					city: newInfo.CITY.trim(),
					stateAbbr: newInfo.STATE,
					zip: newInfo.ZIP,
					zip4: ''
				};

				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
				{command: {name: 'formatAddress'}, 
				params: newAddress
				},{timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							let formattedAddress = {...data.response, 
								addressLine2: data.response.addressLine2? data.response.addressLine2 : '',
								zip4: data.response.zip4? data.response.zip4 : ''};

							if( formattedAddress.addressLine1 === newAddress.addressLine1.toUpperCase() &&
								(formattedAddress.addressLine2 === undefined || formattedAddress.addressLine2 === newAddress.addressLine2.toUpperCase()) &&
								formattedAddress.city === newAddress.city.toUpperCase() &&
								formattedAddress.stateAbbr === newAddress.stateAbbr &&
								formattedAddress.zip === newAddress.zip) {
								let addrrToSave = {...formattedAddress, addressName:newInfo.ADDRESS_NAME.trim()};
								if( editType === 'editLocation' )
									addrrToSave = {...formattedAddress, addressName:newInfo.ADDRESS_NAME.trim(), addressId: addressId.toString() };
								saveUserAddressChange(addrrToSave);
							}
							else{
								let formattedAddr = {...formattedAddress, addressName:newInfo.ADDRESS_NAME.trim(), addressId: (newInfo.ADDRESSID && newInfo.ADDRESSID > 0) ? newInfo.ADDRESSID.toString():null };
								let newAddr = {...newAddress, addressName:newInfo.ADDRESS_NAME.trim(),addressId: (newInfo.ADDRESSID && newInfo.ADDRESSID > 0) ? newInfo.ADDRESSID.toString():null };
								setFormattedAddress(dispatch, formattedAddr);
								setEditAddress(dispatch, newAddr);
								setHistoryPath(dispatch, navigate, confirmAddressPath+ `/locations/selectaddr`);
							}
							break;

						case 419:
							let newAddr = {...newAddress, addressName:newInfo.ADDRESS_NAME.trim(), addressId: (newInfo.ADDRESSID && newInfo.ADDRESSID > 0) ? newInfo.ADDRESSID.toString():null };
							setEditAddress(dispatch, newAddr);
							setHistoryPath(dispatch, navigate, confirmAddressPath+ `/locations/invalidaddr`);
							break;
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;

						default:
							restoreFields();
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					restoreFields();
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			}
			

		} catch (error) {
			console.log("error="+error);
			restoreFields();
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveUserAddressChange = async (newInfo) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: editType, csrfSessionNonce},
			 params: newInfo
			},{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						if( editType === 'deleteLocation' ){
							delLocation(dispatch, addressId);
						}
						else{
							let addrToSave = {
							ADDRESSID: newInfo.addressId? parseInt(newInfo.addressId) : data.response.addressId,
							ADDRESS_NAME: newInfo.addressName,
							ADDRESS_LINE_1: newInfo.addressLine1,
							ADDRESS_LINE_2: newInfo.addressLine2? newInfo.addressLine2 : '',
							CITY: newInfo.city,
							STATE: newInfo.stateAbbr,
							ZIP: newInfo.zip,
							ZIP4: newInfo.zip4? newInfo.zip4:''
							}
							if( editType === 'addLocation' )
								addLocation(dispatch, addrToSave);
							else if( editType === 'editLocation' )
								updateLocation(dispatch, addrToSave);
						}
						
						setHistoryPath(dispatch, navigate, locationsPath);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						restoreFields();
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				restoreFields();
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

		} catch (error) {
			console.log("error="+error);
			restoreFields();
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	return (
		<>
			<StatusMsg/>
			<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>
				<form id="EditAddressModel" noValidate autoComplete="off" onSubmit={onSubmit}>
				{ editType === 'deleteLocation' ?
				<>
					<p>{t('flows.addresses.confirmdeleteaddresses.1')}</p>
					<p>{t('flows.addresses.confirmdeleteaddresses.2')}</p>
					<div className={e3Style['col-xs-24']}>{origAddrInfo.ADDRESS_NAME}</div>
					<div className={e3Style['col-xs-24']}>{origAddrInfo.ADDRESS_LINE_1}</div>
					{ !!origAddrInfo?.ADDRESS_LINE_2?.length &&
						<div className={e3Style['col-xs-24']}>{origAddrInfo.ADDRESS_LINE_2}</div>
					}
					{ origAddrInfo?.ZIP4?.length ?
						<div className={e3Style['col-xs-24']}>{origAddrInfo.CITY}, {origAddrInfo.STATE} {origAddrInfo.ZIP}-{origAddrInfo.ZIP4}</div>
						:
						<div className={e3Style['col-xs-24']}>{origAddrInfo.CITY}, {origAddrInfo.STATE} {origAddrInfo.ZIP}</div>
					}
					<p>{t('flows.addresses.confirmdeleteaddresses.3')}</p>
				</>
				:
				<>
				<fieldset>
					<legend className={e3Style['form-title']}>
						<h2>{t('flows.addresses.addaddress.1')}</h2>
					</legend>

					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="addrNameErr" cptErrCount={state.$errors.ADDRESS_NAME.length}>
								<FocusWrappedLabel htmlFor="ADDRESS_NAME" className={e3Style['text-uppercase']}>
									*{t('flows.addresses.addaddress.2')}</FocusWrappedLabel>
								<FocusWrappedInput id="ADDRESS_NAME" name="ADDRESS_NAME" className={e3Style['input--fluid']}
								                   initialFocus type="text" value={state.$data.ADDRESS_NAME}
								                   onChange={e => updateField(e)}
								                   onBlur={() => setExplicitField('ADDRESS_NAME', true)}/>
								{!!state.$errors.ADDRESS_NAME.length &&
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$errors.ADDRESS_NAME.map((err, idx) => <p index={idx} id={'addrNameErr-' + idx}><span>{t(err.$message)}</span></p>)}
										</div>
									</div>
								}
							</FocusWrappedGrp>
						</div>
					</div>

					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="addrLine1Err" cptErrCount={state.$errors.ADDRESS_LINE_1.length}>
								<FocusWrappedLabel htmlFor="ADDRESS_LINE_1" className={e3Style['text-uppercase']}>
									*{t('flows.addresses.addaddress.3')}</FocusWrappedLabel>
								<FocusWrappedInput id="ADDRESS_LINE_1" name="ADDRESS_LINE_1" className={e3Style['input--fluid']}
								       placeholder={t('flows.personalinfo.getinfo.9')} type="text" value={state.$data.ADDRESS_LINE_1}
								       onChange={e => updateField(e)} onBlur={() => setExplicitField('ADDRESS_LINE_1', true)}/>
								{!!state.$errors.ADDRESS_LINE_1.length &&
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$errors.ADDRESS_LINE_1.map((err, idx) => <p index={idx} id={'addrLine1Err-' + idx}><span>{t(err.$message)}</span></p>)}
										</div>
									</div>
								}
							</FocusWrappedGrp>
						</div>
					</div>
					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel htmlFor="ADDRESS_LINE_2" className={e3Style['text-uppercase']}>{t('flows.addresses.addaddress.4')}</FocusWrappedLabel>
								<FocusWrappedInput id="ADDRESS_LINE_2" name="ADDRESS_LINE_2" className={e3Style['input--fluid']}
								       type="text" value={state.$data.ADDRESS_LINE_2} onChange={e => updateField(e)}
								       onBlur={() => setExplicitField('ADDRESS_LINE_2', true)}/>
							</FocusWrappedGrp>
						</div>
					</div>
					<div className={e3Style['row']}>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-12']}`}>
							<FocusWrappedGrp baseAriaId="cityErr" cptErrCount={state.$errors.CITY.length}>
								<FocusWrappedLabel htmlFor="CITY" className={e3Style['text-uppercase']}>
									*{t('flows.personalinfo.getinfo.6')}</FocusWrappedLabel>
								<FocusWrappedInput id="CITY" name="CITY" className={e3Style['input--fluid']} type="text"
								       value={state.$data.CITY} onChange={e => updateField(e)}
								       onBlur={() => setExplicitField('CITY', true)}/>
								{!!state.$errors.CITY.length &&
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$errors.CITY.map((err, idx) => <p index={idx} id={'cityErr-' + idx}><span>{t(err.$message)}</span></p>)}
										</div>
									</div>
								}
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-6']}`}>
							<FocusWrappedGrp baseAriaId="stateErr" cptErrCount={state.$errors.STATE.length}>
								<FocusWrappedLabel htmlFor="STATE" className={e3Style['text-uppercase']}>
									*{t('flows.personalinfo.getinfo.7')}</FocusWrappedLabel>
								<FocusWrappedSelect id="STATE" name="STATE" onChange={e => updateField(e)}
								                    onBlur={() => setExplicitField('STATE', true)}>
									<option value="">&nbsp;</option>
									{!!stateList.length && stateList.map(stateAbrev =>
										<option value={stateAbrev.STATE_ABBR} selected={stateAbrev.STATE_ABBR === state.$data.STATE}>
											{stateAbrev.STATE_ABBR}</option>)}
								</FocusWrappedSelect>
								{!!state.$errors.STATE.length &&
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$errors.STATE.map((err, idx) => <p index={idx} id={'stateErr-' + idx}><span>{t(err.$message)}</span></p>)}
										</div>
									</div>
								}
							</FocusWrappedGrp>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['col-md-6']}`}>
							<FocusWrappedGrp baseAriaId="zipErr" cptErrCount={state.$errors.ZIP.length}>
								<FocusWrappedLabel htmlFor="ZIP" className={e3Style['text-uppercase']}>
									*{t('flows.personalinfo.getinfo.8')}</FocusWrappedLabel>
								<FocusWrappedInput type="number" name="ZIP" value={state.$data.ZIP} id="ZIP"
								                   className={e3Style['input--fluid']} onChange={e => updateField(e)}
								                   onBlur={() => setExplicitField('ZIP', true)}/>
								{!!state.$errors.ZIP.length &&
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$errors.ZIP.map((err, idx) => <p index={idx} id={'zipErr-' + idx}><span>{t(err.$message)}</span></p>)}
										</div>
									</div>
								}
							</FocusWrappedGrp>
						</div>
					</div>
				</fieldset>
				</>
				}
				<div className={e3Style['row']}>
    				<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button"  
						className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
						onClick={onCancel}>{t('button.cancel')} </button>
						<button type="submit" onClick={validate} disabled={state.$auto_invalid}
						className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{submitBtnTitle}</button>
					</div>
    			</div>
				</form>
			</div>
		</>
	);
}

export default EditAddress;