import {useTranslation} from "react-i18next";
import ModalWrapper from "./modalWrapper";
import e3Style from "../../../css/e3-style.css";
import {closePopup, warnDelUserSel} from "../../store/popupMgr";
import {clearUser, getCsrfSessionNonce} from "../../store/user";
import {adminLoginPath, rootPath} from "../layout/body";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import axios from "axios";
import React, {useState} from "react";
import {updateActTime} from "../../store/activity";
import useAutoFocus from "../util/useAutoFocus";

const WarnDelUser = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {data: customerId} = useSelector(warnDelUserSel);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [disabled, setDisabled] = useState(false);
	const aFocus = useAutoFocus();

	const deleteUserClick = async e => {
		e.preventDefault();
		setDisabled(true);
		await deleteUser();
		setDisabled(false);
	};

	const  deleteUser = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'adminDeleteUser', csrfSessionNonce}, params: {customerId:customerId}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						clearUser(dispatch);
					    setHistoryPath(dispatch, navigate, rootPath);
					  break;
					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;
					case 429:
						setStatusMessage(dispatch, 'deleteuser.acct_state.error', STATUS_ERROR);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}

		closePopup(dispatch);
	};
	
	const cancelClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h3>{t('page.popup.delusr')}</h3>
			<p>{t('page.popup.delusrmsg')}</p>
			<h4 className={e3Style['pt-40']}>{t('page.popup.delusrcfm')}</h4>
			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={deleteUserClick} disabled={disabled}>
					{t('button.delete')}
				</button>
				<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={cancelClick} ref={aFocus}>
					{t('button.cancel')}
				</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(WarnDelUser);

const WarnDelUserModal = () => {
	const {isOpen} = useSelector(warnDelUserSel);
	return <ModalWindow isOpen={isOpen}/>;
};

export default WarnDelUserModal;
