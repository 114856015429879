import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiDoCall } from "./api";

const slice = createSlice({
	name: "stsContactList",
	initialState: {
		userId: "",
		numbers: null
	},
	reducers: {
		/*
		loadList: (stsContactList, action) => {
			const tstList = action.payload.response.response.STSContacts !== undefined ?
				action.payload.response.response.STSContacts : [];
			const newList = tstList
				.map(item => ({"ORDERID":item.PHONE_NUMBER, "NAME": item.NAME, "PHONE_NUMBER": item.PHONE_NUMBER}))
				.sort((a, b) => a.ORDERID - b.ORDERID);
			if (stsContactList.numbers === null || stsContactList.numbers.length !== newList.length)
				stsContactList.numbers = newList;
			else {
				for (let i = 0;i < stsContactList.numbers.length;++i) {
					const curItem = stsContactList.numbers[i];
					const newItem = newList[i];
					if (curItem.PHONE_NUMBER !== newItem.PHONE_NUMBER || curItem.NAME !== newItem.NAME) {
						stsContactList.numbers = newList;
						break;
					}
				}
			}
			stsContactList.userId = action.payload.response.response.stsUserId;
		},
		*/
		addPhone: (stsContactList, action) => {
			if(stsContactList.numbers === null)
				stsContactList.numbers = [...stsContactList.numbers, action.payload.preset];
			else{
				const insertIdx = stsContactList.numbers.findIndex(
					value => value.ORDERID > action.payload.preset.ORDERID);
				if(insertIdx < 0)
					stsContactList.numbers = [...stsContactList.numbers, action.payload.preset];
				else {
					const newArray = [...stsContactList.numbers];
					newArray.splice(insertIdx,0,action.payload.preset);
					stsContactList.numbers = newArray;
				}
			}
		},

		updatePhone: (stsContactList, action) => {
			stsContactList.numbers = stsContactList.numbers.map(
				thisNbr => thisNbr.ORDERID === action.payload.preset.ORDERID ?
					{
						"ORDERID":action.payload.preset.PHONE_NUMBER.replace(/[^\d]/g, ''),
						"NAME":action.payload.preset.NAME,
						"PHONE_NUMBER":action.payload.preset.PHONE_NUMBER.replace(/[^\d]/g, '')
					}
					: thisNbr);
		},

		deletePhone: (stsContactList, action) => {
			stsContactList.numbers = stsContactList.numbers.filter(
				value => value.ORDERID !== action.payload.preset);
		},

		setContactList: (stsContactList, action) => {
			stsContactList.numbers = action.payload.preset;
		},
		
		setUserId: (stsContactList, action) => {
			stsContactList.userId = action.payload.preset;
		}
	}
});

export const {
	setContactList,
	addPhone,
	updatePhone,
	deletePhone,
	setUserId
} = slice.actions;
export default slice.reducer;
/*
export const loadSTSCntctNums = (dispatch, csrfSessionNonce, successFunc, errorFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'listSTSContacts', csrfSessionNonce}
			},
			onSuccess: [{type: loadList.type}, (dispState, response) => {successFunc(dispState, response)}],
			onError: (dispState, response) => {errorFunc(dispState, response)}
		})
	);
};
*/
export const addSTSCntct = (dispatch, newPhone) => {
	dispatch({type: addPhone.type, payload: {preset: {"ORDERID":newPhone.PHONE_NUMBER, "NAME":newPhone.NAME, "PHONE_NUMBER":newPhone.PHONE_NUMBER}}});
};

export const updateSTSCntct = (dispatch, thisPhone) => {
	dispatch({type: updatePhone.type, payload: {preset: thisPhone}});
};

export const delSTSCntct = (dispatch, phoneNbr) => {
	dispatch({type: deletePhone.type, payload: {preset: phoneNbr}});
};

export const setSTSCntcts = (dispatch, newValue) => {
	dispatch({type: setContactList.type, payload: {preset: newValue}});
};

export const setSTSUserId = (dispatch, newValue) => {
	dispatch({type: setUserId.type, payload: {preset: newValue}});
};

export const getSTSCntcts = createSelector(
		state => state.entities.stsContactList.numbers,
		numbers => numbers
);

export const getSTSUserId = createSelector(
		state => state.entities.stsContactList.userId,
		userId => userId
);