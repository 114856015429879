import React, {useState} from 'react';
import e3Style from "../../../css/e3-style.css";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {logoutUser} from "../../store/user";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setHistoryPath} from "../../store/bodyView";
import {loginPath} from "../layout/body";
import DisplayMessage from "../flows/Common/displayMessage";
import {getRegistry, resetRegistry} from "../../store/registry";
import {openCustCare} from "../../store/popupMgr";
import {updateActTime} from "../../store/activity";

const DisplayMsg = () => {
	const { t } = useTranslation();
	return <div>{t('page.emailresent.2')}</div>;
};

const EmailNotVerified = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState({});
	const registrySel = useSelector(getRegistry);

	const resendEmail = async e => {
		e.preventDefault();
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{
					command: {name: 'resendVerifyEmail'},
					params: {
						username: registrySel.username,
						password: registrySel.password
					}
				}, {timeout: 5000});
			if (response.status === 200 && response.data.status.code === 200) {
				setState({
					displayMessage: {
						headerMsg: 'page.emailresent.1',
						DisplayMsg,
						buttonList: [
							{
								onClick: e => {e.preventDefault();logoutUser(dispatch)},
								btnText: 'button.ok'
							}
						]
					}
				});
			} else {
				resetRegistry(dispatch);
				setHistoryPath(dispatch, navigate, loginPath, 'generic.rgp.error');
			}
		} catch (error) {
			resetRegistry(dispatch);
			setHistoryPath(dispatch, navigate, loginPath, 'generic.rgp.error');
		}
	};

	return (
		state.displayMessage ?
			<main role="main" aria-label="main content">
				<div className={e3Style['container-xl']}>
					<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
						<div className={e3Style['col-xs-24']}>
							<DisplayMessage displayMsgObj={state.displayMessage}/>
						</div>
					</div>
				</div>
			</main>
		:
			<main role="main" aria-label="main content">
				<div className={e3Style['container-xl']}>
					<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
						<div className={e3Style['col-xs-24']}>
							<h1>{t('page.emailnotver.1')}</h1>
							<p>{t('page.emailnotver.2')} <a href="#" onClick={() => openCustCare(dispatch)}><span>{t('page.emailresent.3')}</span></a>.</p>
						</div>
					</div>

					<form>
						<div className={e3Style.row}>
							<div className={`${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
								<button className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
								        type="button" onClick={resendEmail}>{t('button.resendEmail')}</button>
								<button className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
								        type="button" autoFocus onClick={() => logoutUser(dispatch)}>{t('button.ok')}</button>
							</div>
						</div>
					</form>
				</div>
			</main>
	);
};

export default EmailNotVerified;
