import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath} from "../../../store/bodyView";
import {loginPath} from "../../layout/body";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import FocusWrappedSelect from "../../util/focusWrappedSelect";
import {setBrailleUser} from "../../../store/display";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const genderDropdown = [
	{text: 'prefs.dropdown.gender.1', value: 0},
	{text: 'prefs.dropdown.gender.2', value: 1},
	{text: 'prefs.dropdown.gender.3', value: 2}
];

const languageDropdown = [
	{text: 'prefs.dropdown.language.2', value: 2},
	{text: 'prefs.dropdown.language.3', value: 5},
	{text: 'prefs.dropdown.language.4', value: 6}
];

const answerTypeDropdown = [
	{text: 'prefs.dropdown.anstype.1', value: 0},
	{text: 'prefs.dropdown.anstype.2', value: 1},
	{text: 'prefs.dropdown.anstype.3', value: 2},
	{text: 'prefs.dropdown.anstype.4', value: 3},
	{text: 'prefs.dropdown.anstype.5', value: 4},
	{text: 'prefs.dropdown.anstype.6', value: 5},
	{text: 'prefs.dropdown.anstype.7', value: 6},
	{text: 'prefs.dropdown.anstype.8', value: 7},
	{text: 'prefs.dropdown.anstype.9', value: 8},
	{text: 'prefs.dropdown.anstype.10', value: 9},
	{text: 'prefs.dropdown.anstype.11', value: 10},
	{text: 'prefs.dropdown.anstype.12', value: 11},
	{text: 'prefs.dropdown.anstype.13', value: 12},
	{text: 'prefs.dropdown.anstype.14', value: 42}
];

const agentTextSpeedDropdown = [
	{text: 'prefs.dropdown.agentTextSpeed.standard', value: -1},
	{text: 'prefs.dropdown.agentTextSpeed.5wpm', value: 5},
	{text: 'prefs.dropdown.agentTextSpeed.10wpm', value: 10},
	{text: 'prefs.dropdown.agentTextSpeed.15wpm', value: 15},
	{text: 'prefs.dropdown.agentTextSpeed.20wpm', value: 20},
	{text: 'prefs.dropdown.agentTextSpeed.25wpm', value: 25},
	{text: 'prefs.dropdown.agentTextSpeed.30wpm', value: 30},
	{text: 'prefs.dropdown.agentTextSpeed.35wpm', value: 35},
	{text: 'prefs.dropdown.agentTextSpeed.40wpm', value: 40},
	{text: 'prefs.dropdown.agentTextSpeed.45wpm', value: 45},
	{text: 'prefs.dropdown.agentTextSpeed.50wpm', value: 50},
	{text: 'prefs.dropdown.agentTextSpeed.55wpm', value: 55},
	{text: 'prefs.dropdown.agentTextSpeed.unlimited', value: 0}
];

const Preferences = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [prefs, setPrefs] = useState({
		genderPreference: 0,
		languageId: 2,
		answerType: 0,
		agentTextSpeed: -1,
		announceRelay: false,
		explainRelay: false,
		relayBackground: false,
		relayToneOfVoice: false,
		typeRecordings: false,
		slowTypingSpeed: false,
		longHoldTimes: false,
		bkspTypeCorrectionAllowed: false,
		abbreviationAllowed: false,
		confirmPreferences: false,
		useHumanWare: false,
		dataReady: false
	});

	useEffect(() => {
		let retryTimer = null;

		const getUserPrefs = async () => {
			try {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getRelayPrefs', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					if (data.status.code === 200) {
						setPrefs({
							...data.response,
							dataReady: true
						});
					} else if (response.status === 998) {
						setHistoryPath(dispatch, navigate, loginPath);
					} else {
						retryTimer = setTimeout(() => getUserPrefs(), 2000);
					}
				} else {
					retryTimer = setTimeout(() => getUserPrefs(), 2000);
				}
			} catch (error) {
				retryTimer = setTimeout(() => getUserPrefs(), 2000);
			}
		};

		getUserPrefs();
		return () => {
			clearTimeout(retryTimer);
		};
	}, []);

	const updatePref = async (name, value) => {
		if (prefs.dataReady) {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/rgpProxy',
				{RGPCommand: 'updateRelayPrefs', [name]: value}, {timeout: 5000});
			return response.status === 200 && response.data.status.code === 200;
		} else {
			return false;
		}
	};

	const onChange = e => {
		e.preventDefault();
		const {name, value} = e.target;
		updatePref(name, value).then(success => success && setPrefs({...prefs, [name]: value}));
	};

	const onClick = e => {
		e.preventDefault();
		const {name, checked} = e.target;
		updatePref(name, checked).then(success => {
			if (success) {
				setPrefs({...prefs, [name]: checked});
				if (name === "useHumanWare") setBrailleUser(dispatch, checked);
			}
		});
	};

	return (
		<form noValidate autoComplete="off">
			<fieldset className={`${e3Style['sprint-call-preferences']} ${e3Style['sprint-account_form_items']}`}>
				<legend className={e3Style['form-title']}>
					<h2>{t('flows.preferences.preferences.1')}</h2>
				</legend>

				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']} ${e3Style['pt-0']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-10']} ${e3Style['display-flex']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="gender-preferences">
								{t('flows.preferences.preferences.2')}
							</FocusWrappedLabel>
							<FocusWrappedSelect name="genderPreference" id="gender-preferences" disabled={!prefs.dataReady} onChange={onChange} initialFocus execInitFocus={prefs.dataReady}>
								{genderDropdown.map(item => <option value={item.value} selected={item.value === prefs.genderPreference}>{t(item.text)}</option>)}
							</FocusWrappedSelect>
						</FocusWrappedGrp>
					</div>
				</div>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-10']} ${e3Style['display-flex']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="language-preferences">
								{t('flows.preferences.preferences.3')}
							</FocusWrappedLabel>
							<FocusWrappedSelect name="languageId" id="language-preferences" disabled={!prefs.dataReady} onChange={onChange}>
								{languageDropdown.map(item => <option value={item.value} selected={item.value === prefs.languageId}>{t(item.text)}</option>)}
							</FocusWrappedSelect>
						</FocusWrappedGrp>
					</div>
				</div>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-10']} ${e3Style['display-flex']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="answer-type">
								{t('flows.preferences.preferences.4')}
							</FocusWrappedLabel>
							<FocusWrappedSelect name="answerType" id="answer-type" disabled={!prefs.dataReady} onChange={onChange}>
								{answerTypeDropdown.map(item => <option value={item.value} selected={item.value === prefs.answerType}>{t(item.text)}</option>)}
							</FocusWrappedSelect>
						</FocusWrappedGrp>
					</div>
				</div>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-10']} ${e3Style['display-flex']}`}>
						<FocusWrappedGrp>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="agent-text-speed">
								{t('flows.preferences.preferences.agentTextSpeed')}
							</FocusWrappedLabel>
							<FocusWrappedSelect name="agentTextSpeed" id="agent-text-speed" disabled={!prefs.dataReady} onChange={onChange}>
								{agentTextSpeedDropdown.map(item => <option value={item.value} selected={item.value === prefs.agentTextSpeed}>{t(item.text)}</option>)}
							</FocusWrappedSelect>
						</FocusWrappedGrp>
					</div>
				</div>
			</fieldset>

			<fieldset className={`${e3Style['sprint-call-preferences']} ${e3Style['sprint-account_form_items']}`}>
				<legend className={e3Style['form-title']}>
					<h2>{t('flows.preferences.preferences.5')}</h2>
				</legend>

				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="announceRelay" name="announceRelay" value="true" disabled={!prefs.dataReady} checked={prefs.announceRelay} onClick={onClick}/>
							<label htmlFor="announceRelay">
								{t('flows.preferences.preferences.6')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="explainRelay" name="explainRelay" value="true" disabled={!prefs.dataReady} checked={prefs.explainRelay} onClick={onClick}/>
							<label htmlFor="explainRelay">
								{t('flows.preferences.preferences.7')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="relayBackground" name="relayBackground" value="true" disabled={!prefs.dataReady} checked={prefs.relayBackground} onClick={onClick}/>
							<label htmlFor="relayBackground">
								{t('flows.preferences.preferences.8')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="relayToneOfVoice" name="relayToneOfVoice" value="true" disabled={!prefs.dataReady} checked={prefs.relayToneOfVoice} onClick={onClick}/>
							<label htmlFor="relayToneOfVoice">
								{t('flows.preferences.preferences.9')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="typeRecordings" name="typeRecordings" value="true" disabled={!prefs.dataReady} checked={prefs.typeRecordings} onClick={onClick}/>
							<label htmlFor="typeRecordings">
								{t('flows.preferences.preferences.10')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="slowTypingSpeed" name="slowTypingSpeed" value="true" disabled={!prefs.dataReady} checked={prefs.slowTypingSpeed} onClick={onClick}/>
							<label htmlFor="slowTypingSpeed">
								{t('flows.preferences.preferences.11')}
							</label>
						</div>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="longHoldTimes" name="longHoldTimes" value="true" disabled={!prefs.dataReady} checked={prefs.longHoldTimes} onClick={onClick}/>
							<label htmlFor="longHoldTimes">
								{t('flows.preferences.preferences.12')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="bkspTypeCorrectionAllowed" name="bkspTypeCorrectionAllowed" value="true" disabled={!prefs.dataReady} checked={prefs.bkspTypeCorrectionAllowed} onClick={onClick}/>
							<label htmlFor="bkspTypeCorrectionAllowed">
								{t('flows.preferences.preferences.13')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="abbreviationAllowed" name="abbreviationAllowed" value="true" disabled={!prefs.dataReady} checked={prefs.abbreviationAllowed} onClick={onClick}/>
							<label htmlFor="abbreviationAllowed">
								{t('flows.preferences.preferences.14')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="confirmPreferences" name="confirmPreferences" value="true" disabled={!prefs.dataReady} checked={prefs.confirmPreferences} onClick={onClick}/>
							<label htmlFor="confirmPreferences">
								{t('flows.preferences.preferences.15')}
							</label>
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-24']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
							<input type="checkbox" id="useHumanWare" name="useHumanWare" value="true" disabled={!prefs.dataReady} checked={prefs.useHumanWare} onClick={onClick}/>
							<label htmlFor="useHumanWare">
								{t('flows.preferences.preferences.16')}
							</label>
						</div>
					</div>
				</div>
			</fieldset>
			<br/>
			<p>*{t('flows.preferences.preferences.20')}</p>
		</form>
	);
};

export default Preferences;
