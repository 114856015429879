import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {userIdentityPath, loginPath, accountListPath, regReserveNumberPath} from "../../layout/body";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate, useParams} from "react-router";
import {getInitData, setRegistry, resetRegistry} from "../../../store/registry";
import {getCsrfSessionNonce, loginUser } from '../../../store/user';
import {loadDisplaySettings} from "../../../store/display";
import {updateActTime} from "../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../util/ioHelper";

const verifyEmail = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const {verifyId: id, verifyCheck: idCheck} = params;
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [resp, setResp] = useState({
		verified: false,
		aldVerified: false,
		verifiedErr: false,
		verifiedNoNbr: false,
		regData: getInitData()
	});

	useEffect(() => {
		verifyEmailAddr();
	}, []);

	const verifyEmailAddr = async () => {
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: 'regVerifyEmail' },
					params: {
							emailId: id,
							emailChk: idCheck
					}
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					if (data.response.firstName === null || data.response.lastName === null) 
						setResp({...resp, verifiedErr: true});
					else  {
						/*const newRegData = {
							firstName: data.response.regModel.customer.firstName,
							lastName: data.response.regModel.customer.lastName,
							homeAddress1: data.response.regModel.customer.address.address1,
							homeAddress2: data.response.regModel.customer.address.address2,
							city: data.response.regModel.customer.address.city,
							stateAbbrev: data.response.regModel.customer.address.stateAbbrev,
							zipCode: data.response.regModel.customer.address.zip,
							emailAddress: data.response.regModel.customer.email,
							birthDate: data.response.regModel.dob,
							phoneNbr: data.response.regModel.tddPhone,
							username: data.response.regModel.username,
							password: data.response.regModel.password,
							confirmPswd: data.response.regModel.confirmPassword,
							secQuestionId: data.response.regModel.securityQuestionId,
							secAnswer: data.response.regModel.securityAnswer,
							sprintIpNumber: data.response.regModel.sprintIpNumber,
							advisoryRead: data.response.regModel.advisoryRead,
							certifyDisability: data.response.regModel.certifyDisability
							};
						*/
						//if (data.response.regModel.sprintIpNumber)
							setResp({...resp, verified: true});
						//else
						//	setResp({...resp, verifiedNoNbr: true, regData: newRegData});
					}
					break;
					case 429:
					setResp({...resp, aldVerified: true});
					break;
					case 428:
					case 430:
					setResp({...resp, verifiedErr: true});
					break;
					default:
					setResp({...resp, verifiedErr: true});
					break;
				}
			} else {
				{setResp({...resp, verifiedErr: true});}
			}
		} catch (error) {
			{setResp({...resp, verifiedErr: true});}
		}
	};

	const getNonceAndLogin = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getLoginNonce'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200){
					loginUser(dispatch, resp.regData.username, resp.regData.password, false, data.response.nonce,
			(dispState, {response}) => loadDisplaySettings(dispatch, response.response.csrfSessionNonce, () => success => setHistoryPath(dispatch, navigate, accountListPath)),
			() => setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq'));
				} else {
					setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq');
				}
			} else {
				setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq');
			}
		} catch (error) {
			setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq');
		}
	};

	const checkAction = () => {
		/*if( resp.verifiedNoNbr ){
			getNonceAndLogin();
		} else if (resp.verified){ 
			setRegistry(dispatch, resp.regData);
			verifyIdentity(resp.regData.username, resp.regData.password, resp.regData.phoneNbr);
		}
		else*/
		setHistoryPath(dispatch, navigate, loginPath);
	}
	
	const getNonceAndReserve = async (phoneNbr, username, password) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getLoginNonce'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200){
					loginUser(dispatch, username, password, false, data.response.nonce,
			(dispState, {response}) => loadDisplaySettings(dispatch, response.response.csrfSessionNonce, () => success => {resetRegistry(dispatch); setHistoryPath(dispatch, navigate, regReserveNumberPath + `/${phoneNbr}`+'/registration')}),
			() => {resetRegistry(dispatch); setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq')});
				} else {
					setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq');
				}
			} else {
				setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq');
			}
		} catch (error) {
			setHistoryPath(dispatch, navigate, loginPath,'call.status.loginreq');
		}
	};
	
	const verifyIdentity = async (username, password, phoneNbr) => {
			const info = {
				    id: username,
					password: password,
					phone: phoneNbr
			};

			try {
				updateActTime(dispatch);
				const response = await postWithTimeout(
					{
						command: {name: 'verifyIdenQuestions', csrfSessionNonce},
						params: info
					}, EXTENDED_TIMEOUT);
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						case 305:
							getNonceAndReserve(phoneNbr, username, password);
							break;

						case 306:
						case 307:
						case 308:
							resetRegistry(dispatch);
							setHistoryPath(dispatch, navigate, userIdentityPath);
							break;

						default:
							resetRegistry(dispatch);
							setHistoryPath(dispatch, navigate, userIdentityPath);
							break;
					}
				} else {
					resetRegistry(dispatch);
					setHistoryPath(dispatch, navigate, userIdentityPath);
				}
			} catch (error) {
				resetRegistry(dispatch);
				setHistoryPath(dispatch, navigate, userIdentityPath);
			}
	};

	return (
	<>
		<StatusMsg/>
		<form noValidate autoComplete="off" className={e3Style['account-preferences_notes']}>
		{resp.verified ? <>
			<legend><h2>{t('flows.registration.veremail.3')}</h2></legend>
		    <span>{t('flows.registration.veremail.4')}</span> </>:<></>
		}	
		{resp.aldVerified ? <>
			<legend><h2>{t('flows.registration.veremail.5')}</h2></legend>
		    <span>{t('flows.registration.veremail.6')}</span> </>:<></>
		}	
		{resp.verifiedErr ? <>
			<legend><h2>{t('flows.registration.veremail.7')}</h2></legend>
		    <span>{t('flows.registration.veremail.8')}</span> </>:<></>
		}
		{resp.verifiedNoNbr ? <>
			<legend><h2>{t('flows.registration.veremail.9')}</h2></legend>
		    <span>{t('flows.registration.veremail.10')}</span> </>:<></>
		}
		<div className={`${e3Style['row']}`}>
			<div className={`${e3Style.colStyle} ${e3Style['$col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
				<button type="button" onClick={checkAction} disabled={!(resp.verified || resp.aldVerified || resp.verifiedNoNbr || resp.verifiedErr) }
				 className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
				>{t('button.ok')} </button>
			</div>
    	</div>
		</form>
		</>
	);
}

export default verifyEmail;
