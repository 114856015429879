import React, {useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sprStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {useNavigate} from "react-router";
import {adminManagePhoneNumsTablePath} from "../../../layout/body";
import {getTdNumData} from "../../../../store/tdNum";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../../util/focusWrappedLabel";
import FocusWrappedInput from "../../../util/focusWrappedInput";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../../util/ioHelper";

const tdNumDelete = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tdObj = useSelector(getTdNumData);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [modItem, setModItem] = useState({newDashId: tdObj.dashId, noEmail: false});

	const deleteNPA = async e => {
		e.preventDefault();
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'adminTdNumDelete', csrfSessionNonce},
				 params: {
							phoneNum: tdObj.phoneNum,
							noEmail: modItem.noEmail																	
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath);
					break;
					case 406:
					setStatusMessage(dispatch, 'flows.admin.managetdnum.modify.delerror', STATUS_ERROR);
					break;
					default:
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const restorePhNum = async e => {
		e.preventDefault();
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: 'adminTdNumRestore', csrfSessionNonce},
					params: {
						phoneNum: tdObj.phoneNum,
						noEmail: modItem.noEmail
					}
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath);
					break;
					case 406:
					setStatusMessage(dispatch, 'flows.admin.managetdnum.modify.delerror', STATUS_ERROR);
					break;
					default:
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const portPhNum = async e => {
		e.preventDefault();
		try {
			updateActTime(dispatch);
			const response = await postWithTimeout(
				{
					command: {name: 'adminTdNumPort', csrfSessionNonce},
					params: {
						phoneNum: tdObj.phoneNum,
						noEmail: modItem.noEmail,
						dashId: modItem.newDashId
					}
				}, EXTENDED_TIMEOUT);
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath);
					break;
					case 406:
					setStatusMessage(dispatch, 'flows.admin.managetdnum.modify.delerror', STATUS_ERROR);
					break;
					case 429:
					setStatusMessage(dispatch, 'flows.admin.managetdnum.modify.invacctstateerr', STATUS_ERROR);
					break;
					default:
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const handleChecked = ({currentTarget: ct}) => {
		setModItem({...modItem, [ct.name]: !modItem[ct.name]});
	};

	return (
	<>
	<StatusMsg/>
	<h2>{t('flows.admin.managetdnum.modify.title')}</h2>
	<form noValidate autoComplete="off" className={e3Style['account-preferences_account-frequently-dialed-numbers']}>	
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>{t('flows.admin.managetdnum.modify.acctsts')}</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>{t(tdObj.statusCodeTag)}</div>
	</div>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>{t('flows.admin.managetdnum.modify.phnum')}</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>{tdObj.phoneNum}</div>
	</div>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
	{tdObj.portNum != null ?
	<>
	<FocusWrappedGrp>
		<FocusWrappedLabel className={`${e3Style['text-uppercase']}`} htmlFor="newDashId">
			{t('flows.admin.managetdnum.modify.dashid')}</FocusWrappedLabel>
		<FocusWrappedInput name="newDashId" id="newDashId" className={`${e3Style['input--fluid']}`} value={modItem.newDashId}
						   onChange={e => {setModItem( prevModItem => ({...prevModItem,newDashId:e.target.value}));}}/>
	</FocusWrappedGrp>
	</>
	:
	<>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>{t('flows.admin.managetdnum.modify.dashid')}</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>{tdObj.dashId}</div>
	</>
	}
	</div>
	{tdObj.portNum != null &&
	<>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>{t('flows.admin.managetdnum.modify.portnum')}</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>{tdObj.portNum}</div>
	</div>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>{t('flows.admin.managetdnum.modify.portcarrier')}</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>{tdObj.portProvider}</div>
	</div>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>{t('flows.admin.managetdnum.modify.portacct')}</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>{tdObj.portAcct}</div>
	</div></>}

	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}></div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']} ${e3Style['display-flex']}`}>
			<input checked={modItem.noEmail} onChange={handleChecked} type="checkbox" id="noEmail" name="noEmail" className={`${sprStyle['chkbx-align']}`} />
			<label htmlFor="noEmail">{t('flows.admin.managetdnum.modify.noemail')}</label>
		</div>
		<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']} ${e3Style['display-flex']}`}></div>
	</div>
	{tdObj.statusCodeTag === 'flows.admin.managetdnum.display.normal' &&
	<>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={deleteNPA}>
		{t('flows.admin.managetdnum.modify.delbutton')} </button>

		<button type="submit" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath)}}>
		{t('flows.admin.managetdnum.add.backbutton')} </button>
	</div>
	</>}
	{tdObj.statusCodeTag === 'flows.admin.managetdnum.display.portin' &&
	<>
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={portPhNum}>
		{t('flows.admin.managetdnum.modify.finportbutton')} </button>

		<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={restorePhNum}>
		{t('flows.admin.managetdnum.modify.abortportbutton')} </button>

		<button type="submit" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath)}}>
		{t('flows.admin.managetdnum.add.backbutton')} </button>
	</div>
	</>}
	{tdObj.statusCodeTag === 'flows.admin.managetdnum.display.cancel' &&
	<>	
	<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
		<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={deleteNPA}>
		{t('flows.admin.managetdnum.modify.findelbutton')} </button>

		<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={restorePhNum}>
		{t('flows.admin.managetdnum.modify.abortdelbutton')} </button>

		<button type="submit" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
		onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, adminManagePhoneNumsTablePath)}}>
		{t('flows.admin.managetdnum.add.backbutton')} </button>
	</div>
	</>}
	</form>
	</>
	);
};

export default tdNumDelete;
