import {createSlice} from "@reduxjs/toolkit";
import rgpCommand, {cmdInterface, RGB_DEFAULT_TIMEOUT} from "../components/call/rgbCommand";
import {
	EXCH_STS_DISCONNECTED,
	EXCH_STS_ERROR,
	EXCH_STS_MISMATCH,
	EXCH_STS_OK,
	EXCHANGE_RESULT
} from "../components/call/callMgr";
import {createSelector} from "reselect";

export const USER_SOURCE = 0;
export const AGENT_SOURCE = 1;
const BUFFER_HOLD_TIME_LIMIT = 2500;

const convoLogEntry = (source, text) => ({source, text});

const removeLastText = (convoState, agentText, recvTime) => {
	if (!recvTime) {
		const buf = convoState.agentBuffer + agentText;
		convoState.agentBuffer = "";
		convoState.agentBufTime = null;
		return buf;
	}

	const timeExceeded = convoState.agentBufTime && recvTime > convoState.agentBufTime + BUFFER_HOLD_TIME_LIMIT;
	if (agentText.length || timeExceeded) {
		const buf = convoState.agentBuffer + agentText;
		let i;
		for (i = buf.length - 1;i >= 0;--i) {
			const ch = buf.charAt(i);
			if (ch === ' ' || ch === '\t' || ch === '\n') {
				break;
			}
		}
		const sPos = ++i > 0 ? i : (timeExceeded ? buf.length : 0);
		const outTxt = buf.substring(0, sPos);
		convoState.agentBuffer = buf.substring(sPos, buf.length);
		if (!convoState.agentBuffer.length) convoState.agentBufTime = null;
		else if (outTxt.length || !convoState.agentBufTime) convoState.agentBufTime = recvTime;
		return outTxt;
	}

	return "";
};

const updateConvoLog = (convoState, agentText, recvTime) => {

	const agentBuf = removeLastText(convoState, agentText, recvTime);
	const validAgentText = agentBuf.length > 0;
	const validUserText = convoState.sendBuffer.length > 0;

	const convoLog = convoState.convoData;
	let newConvoLog = null;
	if (convoLog.length) {
		const logEntry = convoLog[convoLog.length - 1];
		if (logEntry.source === AGENT_SOURCE) {
			if (validAgentText) {
				logEntry.text += agentBuf;
			}
			if (validUserText) {
				newConvoLog ??= convoLog.slice();
				newConvoLog.push(convoLogEntry(USER_SOURCE, convoState.sendBuffer));
			}
		} else {
			if (validUserText) {
				const lastChar = (logEntry.text.length) ? logEntry.text[logEntry.text.length - 1] : ' ';
				const userText = (lastChar !== ' ' && lastChar !== '\t' && lastChar !== '\n') ? ' ' + convoState.sendBuffer : convoState.sendBuffer;
				logEntry.text += userText;
			}
			if (validAgentText) {
				newConvoLog ??= convoLog.slice();
				newConvoLog.push(convoLogEntry(AGENT_SOURCE, agentBuf));
			}
		}
	} else {
		if (validAgentText) {
			newConvoLog ??= convoLog.slice();
			newConvoLog.push(convoLogEntry(AGENT_SOURCE, agentBuf));
		}
		if (validUserText) {
			newConvoLog ??= convoLog.slice();
			newConvoLog.push(convoLogEntry(USER_SOURCE, convoState.sendBuffer));
		}
	}
	if (newConvoLog) {
		convoState.convoData = newConvoLog;
	}
	return validAgentText || validUserText;
};

const stripDollarStrs = (convoState, response) => {
	const {text} = response;
	let agentText = typeof text === 'string' ? text : "";
	if (agentText.length > 0) {
		if (convoState.searchDollarHdr) {
			let found = true;
			let offset = 0;
			for (;convoState.searchDollarHdr && offset < agentText.length;++offset) {
				if (agentText[offset] === "$") {
					--convoState.searchDollarHdr;
				} else {
					found = false;
					break;
				}
			}
			if (found) {
				if (!convoState.searchDollarHdr) {
					agentText = agentText.substr(offset);
					convoState.searchDollarHdrEnd = 3;
				} else {
					agentText = "";
				}
			} else {
				convoState.searchDollarHdr += offset;
				for (let pCnt = 3 - convoState.searchDollarHdr;pCnt;--pCnt) {
					agentText = "$" + agentText;
				}
				convoState.searchDollarHdr = 0;
			}
		}

		if (convoState.searchDollarHdrEnd) {
			let eOff = 0;
			for (;convoState.searchDollarHdrEnd && eOff < agentText.length;++eOff) {
				if (agentText[eOff] === "$") {
					--convoState.searchDollarHdrEnd;
				} else {
					convoState.searchDollarHdrEnd = 3;
				}
			}

			if (!convoState.searchDollarHdrEnd) {
				agentText = agentText.substr(eOff);
			} else {
				agentText = "";
			}
		}
	}
	return agentText;
};

const slice = createSlice({
	name: "convoLog",
	initialState: {
		convoData: [],
		newSendChars: "",
		sendBuffer: "",
		prependSpace: "",
		agentBuffer: "",
		agentBufTime: null,
		transmitEnabled: false,
		searchDollarHdr: 0,
		searchDollarHdrEnd: 0,
		cmdIntf: null
	},
	reducers: {
		startConvoRdcr: (convoState, action) => {
			convoState.convoData = [];
			convoState.newSendChars = action.payload.custom.instructions ?? "";
			convoState.transmitEnabled = action.payload.custom.transmitEnabled;
			convoState.agentBuffer = "";
			convoState.agentBufTimeout = null;
			convoState.searchDollarHdr = 3;
			convoState.searchDollarHdrEnd = 0;
			convoState.cmdIntf = null;
		},
		addConvoDataRdcr: (convoState, action) => {
			if (updateConvoLog(convoState, stripDollarStrs(convoState, action.payload.response),
				action.payload.custom.recvTime)) convoState.transmitEnabled = true;
			if (convoState.sendBuffer.length) {
				const lastChar = convoState.sendBuffer[convoState.sendBuffer.length - 1];
				convoState.prependSpace = (lastChar !== ' ' && lastChar !== '\t' && lastChar !== '\n') ? " " : "";
				convoState.sendBuffer = "";
			}
		},
		endConvoRdcr: (convoState, action) => {
			updateConvoLog(convoState, " YOUR CALL HAS BEEN DISCONNECTED AT " + new Date() + ". THANK YOU FOR USING IP RELAY. TOTAL CONVERSATION TIME: " + action.payload.elapsedTime);
		},
		clearConvoRdcr: (convoState, action) => {
			convoState.convoData = [];
			convoState.newSendChars = "";
			convoState.transmitEnabled = false;
			convoState.agentBuffer = "";
			convoState.agentBufTimeout = null;
			convoState.searchDollarHdr = 3;
			convoState.searchDollarHdrEnd = 0;
			convoState.cmdIntf = null;
		},
		submitUserTextRdcr: (convoState, action) => {
			if (convoState.newSendChars.length) {
				const lastChar = convoState.newSendChars[convoState.newSendChars.length - 1];
				if (lastChar !== ' ' && lastChar !== '\t' && lastChar !== '\n') {
					convoState.newSendChars += " ";
				}
			}
			convoState.newSendChars += action.payload.custom.text;
		},
		prepTransmitBufRdcr: (convoState, action) => {
			convoState.cmdIntf = action.payload.custom.cmdIntf;
			if (convoState.transmitEnabled) {
				convoState.sendBuffer += convoState.newSendChars;
				convoState.newSendChars = "";
			}
		},
		finishTransmitBufRdcr: (convoState) => {
			convoState.cmdIntf = null;
		}
	}
});

export const {
	startConvoRdcr,
	addConvoDataRdcr,
	endConvoRdcr,
	clearConvoRdcr,
	submitUserTextRdcr,
	prepTransmitBufRdcr,
	finishTransmitBufRdcr
} = slice.actions;
export default slice.reducer;

export const startConvo = (dispatch, transmitEnabled, instructions) => {
	dispatch({type: startConvoRdcr.type, payload: {custom: {instructions, transmitEnabled}}});
};

export const addConvoData = (dispatch, agentText) => {
	dispatch({type: addConvoDataRdcr.type, payload: { response: {text: agentText}, custom: {recvTime: Date.now()}}});
};

export const clearConvoData = dispatch => {
	dispatch({type: clearConvoRdcr.type});
};

export const doExchange = (dispatch, callMgrDispatch, params) => {
	dispatch([
		{type: prepTransmitBufRdcr.type, payload: {custom: {cmdIntf: cmdInterface(data => {
				const {status, response} = data ?? {};
				switch (status?.code) {
					case 200:
						dispatch({type: addConvoDataRdcr.type, payload: {response, custom: {recvTime: Date.now()}}});
						callMgrDispatch({type: EXCHANGE_RESULT, payload: {status: EXCH_STS_OK}});
						break;

					case 420:
					case 998:
						callMgrDispatch({type: EXCHANGE_RESULT, payload: {status: EXCH_STS_DISCONNECTED}});
						break;

					case 994:
					case 995:
						callMgrDispatch({type: EXCHANGE_RESULT, payload: {status: EXCH_STS_MISMATCH}});
						break;

					default: {
						const {initialTime} = params;
						if (initialTime) callMgrDispatch({type: EXCHANGE_RESULT, payload: {status: EXCH_STS_ERROR, initialTime}});
						else callMgrDispatch({type: EXCHANGE_RESULT, payload: {status: EXCH_STS_ERROR}});
					}
				}
				dispatch({type: finishTransmitBufRdcr.type});
			})}}},
		(dispatch, getState) => {
			const {prependSpace, sendBuffer, cmdIntf} = getState().entities.convoLog;
			const {initialTime, ...xchgParams} = params;
			rgpCommand('exchange',
				sendBuffer.length ? {text: prependSpace + sendBuffer, ...xchgParams} : xchgParams,
				RGB_DEFAULT_TIMEOUT, false, cmdIntf, dispatch);
		}
	]);
};

export const submitUserText = (dispatch, text) => {
	if (typeof text === 'string' && text.length) dispatch({type: submitUserTextRdcr.type, payload: {custom: {text}}});
};

export const generateEmailBody = (convoData, agtTxt, isLowVis) => {
	let bodyStr = "";
	if (convoData.length) {
		convoData.forEach(convoEntry => {
			bodyStr += convoEntry.source === AGENT_SOURCE ? agtTxt + "%0D%0A" : "Me%0D%0A";
			bodyStr += (isLowVis? convoEntry.text.toLowerCase():convoEntry.text) + "%0D%0A%0D%0A";
		});
		bodyStr.replace(/&/g, "%26");
		bodyStr.replace(/"/g, "%22");
		bodyStr.replace(/=/g, "%3D");
		bodyStr.replace(/ /g, "%20");
	}
	return bodyStr;
};

export const getConvoDataSel = createSelector(
	state => state.entities.convoLog.convoData,
	convoData => convoData
);

export const isTransmitEnabledSel = createSelector(
	state => state.entities.convoLog.transmitEnabled,
	te => te
);
