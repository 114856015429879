import React, {useEffect, useRef} from "react";
import useReducerWithSideEffects, {
	UpdateWithSideEffect,
	Update,
	NoUpdate
} from 'use-reducer-with-side-effects';
import rgpCommand, {cmdInterface, RGB_DEFAULT_TIMEOUT} from "./rgbCommand";
import {
	ACTIVE_CALL_IN_OTHER_TAB, CALL_TIMEOUT, clearCallState,
	getActionState,
	getCallState, INVALID_PHONE_NBR,
	isPollingEnabled, MAX_CALLS_ERROR, NETWORK_ERROR, NETWORK_POLLING_PROBLEM, setCallErrorState, setEmptyCallState,
	setInCallState, setRecvPhoneState, USER_ACTION_DIALING,
	USER_ACTION_DONE, USER_ACTION_END_CALL,
	USER_ACTION_READY, USER_NOT_LOGGED_IN
} from "../../store/call";
import {useDispatch, useSelector} from "react-redux";
import {
	getCsrfSessionNonce,
	getUserInfo,
	isAdmin,
	isLoggedInAndNotAdmin,
	isLoggedInOrAdmin,
	logoutUser
} from "../../store/user";
import RgbCmdMgr from "./rgbCmdMgr";
import {openCallFinished, openIncomingCall, openWarnInactivity} from "../../store/popupMgr";
import {getDefaultSettings} from "../../store/defaultSettings";
import {doExchange, endConvoRdcr, startConvo} from "../../store/convoLog";
import delayCmdMgr from "./delayCmdMgr";
import {stripNonNums} from "../util/stringFormatHelper";
import {finishCall} from "../flows/root/call";
import {getActTime, updateActTime} from "../../store/activity";
import axios from "axios";
import {useTranslation} from "react-i18next";

const DEFAULT_RETRIES = 2;
const RECV_NORMAL_POLL = 2000;
const RECV_SLOW_POLL = 10000;
const CONVO_POLL = 1000;
const ERROR_MSG_THRESHOLD = 120000;
const CONVO_ERROR_TIME_LIMIT = 60000;
const MIN_POLL_GAP = 100;
const SHORT_RETRY_DELAY = 500;

const INITIAL_STATE = 0;
const RECEIVE_SETUP = 1;
const RECEIVE_ERROR_SETUP = 2;
const RECEIVE_STATUS = 3;
const GET_CALLER_ID = 4;
const INCOMING_POPUP = 5;
const INCOMING_NOTIFY = 6;
const CALL_EXCHANGE = 7;
const CALL_FINISHED = 8;
const HANGUP_CALL = 9;
const HANGUP_RECEIVE = 10;
const OUTBOUND_DIAL = 11;
const OUTBOUND_STATUS = 12;
const OUTBOUND_SETUP = 13;
const TOTAL_STATES = 14;

const CSRF_UPDATE = -1;
const ISSUE_CMD = 0;
const POLLING_RQST = 1;
const STATE_SUCCESS = 2;
const STATE_FAILURE = 3;
export const EXCHANGE_RESULT = 4;
const DIAL_RQST = 5;
const TOTAL_ACTIONS = 6;

export const EXCH_STS_OK = 1;
export const EXCH_STS_DISCONNECTED = 2;
export const EXCH_STS_MISMATCH = 3;
export const EXCH_STS_ERROR = 4;

const NO_POLLING = 0;
const NO_POLLING_DIALING = 1;
const RECEIVE_POLLING = 2;
const CONVO_POLLING = 3;

const initialState = {
	callId: null,
	receiveFlag: false,
	actionState: INITIAL_STATE,
	cmdMgr: null,
	csrfSessionNonce: null
};

const createCallParams = state =>
	state.receiveFlag ? {RGPCsrfSessionNonce: state.csrfSessionNonce, RGPCallId: state.callId, RGPReceiveTarget: true}
		: {RGPCsrfSessionNonce: state.csrfSessionNonce, RGPCallId: state.callId};

const dialErrFunc = (dispatch, storeDispatch, func, code, retries, initialTime, instr, phone) => {
	if (retries && Date.now() - initialTime <= ERROR_MSG_THRESHOLD && code !== 420) {
		dispatch({type: ISSUE_CMD, payload: {cmdFunc: func, delay: SHORT_RETRY_DELAY, params: {retries: retries - 1, instr, phone, initialTime}}});
	} else {
		setCallErrorState(storeDispatch, NETWORK_ERROR);
		dispatch({type: STATE_FAILURE});
	}
};

const setupReceive = (delay, state, params, {storeDispatch}) => {
	return RgbCmdMgr('receive', {RGPCsrfSessionNonce: state.csrfSessionNonce}, RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			const genErr = () => {
				if (state.actionState === RECEIVE_SETUP) {
					const {initialTime} = params || {};
					if (initialTime) {
						if (Date.now() - initialTime > ERROR_MSG_THRESHOLD) {
							dispatch({type: STATE_FAILURE, payload: {logout: false, cmdFunc: setupReceive, delay: RECV_NORMAL_POLL, params: {initialTime}}});
						} else {
							dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupReceive, delay: RECV_NORMAL_POLL, params: {initialTime}}});
						}
					} else {
						dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupReceive, delay: RECV_NORMAL_POLL, params: {initialTime: Date.now()}}});
					}
				} else {
					dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupReceive, delay: state.cmdMgr.getNextDelay(RECV_NORMAL_POLL, MIN_POLL_GAP)}});
				}
			};
			if (data) {
				switch (data.status.code) {
					case 200:
						dispatch({type: STATE_SUCCESS, payload: data.response});
						break;

					case 994:
						dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupReceive, delay: state.cmdMgr.getNextDelay(RECV_NORMAL_POLL, MIN_POLL_GAP)}});
						break;

					case 995:
						dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupReceive, delay: state.cmdMgr.getNextDelay(RECV_SLOW_POLL, MIN_POLL_GAP)}});
						break;

					case 997:
						dispatch({type: STATE_FAILURE, payload: {logout: true}});
						break;

					default:
						genErr();
						break;
				}
			}
		}, delay, storeDispatch);
};

const setupStatus = (delay, state, params, {storeDispatch}) => {
	return RgbCmdMgr('status', createCallParams(state), RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			if (data) {
				const status = data.status.code;
				if (status === 200) {
					const statusStr = data.response.status;
					if (statusStr === "CONNECTED" || statusStr === "INCOMING CALL") {
						dispatch({type: STATE_SUCCESS, payload: data.response});
					} else {
						dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupStatus, delay: state.cmdMgr.getNextDelay(RECV_NORMAL_POLL, MIN_POLL_GAP)}});
					}
				} else {
					dispatch({type: STATE_FAILURE});
				}
			}
		}, delay, storeDispatch);
};

const setupOutboundStatus = (delay, state, params, {storeDispatch}) => {
	return RgbCmdMgr('status', createCallParams(state), RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			const {retries: retriesParam, instr, initialTime} = params || {};
			const retries = typeof retriesParam !== 'undefined' && retriesParam !== null ? retriesParam : DEFAULT_RETRIES;
			const errFunc = code => dialErrFunc(dispatch, storeDispatch, setupOutboundStatus, code, retries, initialTime, instr);
			if (data) {
				const {code} = data.status;
				if (code === 200) {
					const {status} = data.response;
					if (status === "CONNECTED") {
						dispatch({type: STATE_SUCCESS, payload: {initialTime, params: {instr}}});
					} else if (status === "CONNECTING") {
						if (Date.now() - initialTime > ERROR_MSG_THRESHOLD) {
							setCallErrorState(storeDispatch, CALL_TIMEOUT);
						} else {
							dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupOutboundStatus, delay: SHORT_RETRY_DELAY, params: {initialTime, instr}}});
						}
					} else {
						errFunc();
					}
				} else {
					errFunc(code);
				}
			}
		}, delay, storeDispatch);
};

const setupCallerId = (delay, state, params, {storeDispatch}) => {
	return RgbCmdMgr('callerid', createCallParams(state), 8000, false,
		(data, state, dispatch) => {
			if (data) {
				const status = data.status.code;
				if (status === 200) {
					dispatch({type: STATE_SUCCESS, payload: data.response.callerId});
				}  else {
					dispatch({type: STATE_FAILURE});
				}
			}
		}, delay, storeDispatch);
};

const setupNotify = (delay, state, params, {storeDispatch}) => {
	const {retries, action, phone} = params ?? {};
	return RgbCmdMgr('notify', {action, ...createCallParams(state)}, RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			if (data) {
				const status = data.status.code;
				if (status === 200) {
					if (action === 'accept') {
						setRecvPhoneState(storeDispatch, phone);
						startConvo(storeDispatch, true);
						dispatch({type: STATE_SUCCESS});
					} else {
						dispatch({type: STATE_FAILURE, payload: {wait: false, errmsg: false}});
					}
				} else if (retries && status !== 420 && status !== 995) {
					dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupNotify, delay: SHORT_RETRY_DELAY, params: {retries: retries - 1, action, phone}}});
				} else {
					dispatch({type: STATE_FAILURE, payload: {wait: action === 'accept' || status !== 420 && status !== 995, errmsg: action === 'accept' && status !== 420 && status !== 995}});
				}
			}
		}, delay, storeDispatch);
};

const setupHangup = (delay, state, params, {storeDispatch}) => {
	const {phone, instr, language, retries, wait} = params ?? {};
	return RgbCmdMgr('hangup', createCallParams(state), RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			if (data) {
				const status = data.status.code;
				if (status === 200 || status === 420) {
					setInCallState(storeDispatch, false);
					dispatch({type: STATE_SUCCESS, payload: {params: {phone, instr, language, wait}}});
				}  else if (retries) {
					dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupHangup, delay: SHORT_RETRY_DELAY, params: {phone, instr, language, retries: retries - 1, wait}}});
				}  else {
					setInCallState(storeDispatch, false);
					dispatch({type: STATE_FAILURE, payload: {params: {phone, instr, language, wait}}});
				}
			}
		}, delay, storeDispatch);
};

const setupExchange = (delay, state, params, {storeDispatch}) => {
	const {initialTime} = params ?? {};
	return delayCmdMgr(delay, (state, dispatch) => doExchange(storeDispatch, dispatch, {...createCallParams(state), initialTime: initialTime ?? Date.now()}));
};

const setupDial = (delay, state, params, {storeDispatch}) => {
	const {initialTime: initialTimeParam, phone, instr, service, language} = params ?? {};
	const initialTime = initialTimeParam ? initialTimeParam : Date.now();
	return RgbCmdMgr('dial', {phone, service, language, RGPCsrfSessionNonce: state.csrfSessionNonce}, RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			if (data) {
				const {code} = data.status;
				switch (code) {
					case 200:
						dispatch({type: STATE_SUCCESS, payload: {...data.response, initialTime, params: {instr}}});
						break;

					case 401:
						setCallErrorState(storeDispatch, USER_NOT_LOGGED_IN);
						dispatch({type: STATE_FAILURE});
						break;

					case 406:
						setCallErrorState(storeDispatch, INVALID_PHONE_NBR);
						dispatch({type: STATE_FAILURE});
						break;

					case 409:
					case 994:
						setCallErrorState(storeDispatch, MAX_CALLS_ERROR);
						dispatch({type: STATE_FAILURE});
						break;

					case 420:
					case 503:
					case 504:
						setCallErrorState(storeDispatch, NETWORK_ERROR);
						dispatch({type: STATE_FAILURE});
						break;
						
					case 995:
						setCallErrorState(storeDispatch, ACTIVE_CALL_IN_OTHER_TAB);
						dispatch({type: STATE_FAILURE});
						break;

					default:
						if (Date.now() - initialTime > ERROR_MSG_THRESHOLD) {
							setCallErrorState(storeDispatch, CALL_TIMEOUT);
							dispatch({type: STATE_FAILURE});
						} else {
							dispatch({type: ISSUE_CMD, payload: {cmdFunc: setupDial, delay: SHORT_RETRY_DELAY, params: {initialTime, phone, instr, service, language}}});
						}
						break;
				}
			}
		}, delay, storeDispatch);
};

const setupSetup = (delay, state, params, {storeDispatch}) => {
	return RgbCmdMgr('setup', createCallParams(state), RGB_DEFAULT_TIMEOUT, false,
		(data, state, dispatch) => {
			const {retries: retriesParam, initialTime, instr} = params || {};
			const retries = typeof retriesParam !== 'undefined' && retriesParam !== null ? retriesParam : DEFAULT_RETRIES;
			const errFunc = code => dialErrFunc(dispatch, storeDispatch, setupSetup, code, retries, initialTime, instr);
			if (data) {
				const {code} = data.status;
				if (code === 200) {
					setInCallState(storeDispatch, true);
					startConvo(storeDispatch, false, instr);
					dispatch({type: STATE_SUCCESS});
				} else {
					errFunc(code);
				}
			}
		}, delay, storeDispatch);
};

const issueCmd = (state, action, storeData) => {
	const {cmdFunc, delay, params, csrfSessionNonce} = action?.payload ?? {};
	const cmdMgr = cmdFunc(delay, state, params, storeData);
	return UpdateWithSideEffect(csrfSessionNonce ? {...state, cmdMgr, csrfSessionNonce} : {...state, cmdMgr},
		(state, dispatch) => cmdMgr.start(state, dispatch));
};

const issueCmdWithFunc = (state, actionState, storeData, cmdFunc) =>
	issueCmd({...state, actionState}, {payload: {cmdFunc}}, storeData);

const issueCmdWithStoreFunc = (state, actionState, storeData, cmdFunc) =>
	issueCmd({...state, actionState}, {payload: {cmdFunc}}, storeData);

const issueCmdWithStoreFuncParams = (state, actionState, storeData, cmdFunc, params) =>
	issueCmd({...state, actionState}, {payload: {cmdFunc, params}}, storeData);

const issueCmdWithStoreDelayFunc = (state, actionState, storeData, cmdFunc, delayVal) =>
	issueCmd({...state, actionState}, {payload: {cmdFunc, delay: state.cmdMgr.getNextDelay(delayVal, MIN_POLL_GAP)}}, storeData);

const issueCmdWithStoreDelayFuncParams = (state, actionState, storeData, cmdFunc, delayVal, params) =>
	issueCmd({...state, actionState}, {payload: {cmdFunc, delay: state.cmdMgr.getNextDelay(delayVal, MIN_POLL_GAP), params}}, storeData);

const issueRecvSetupCmd = (state, storeData) => issueCmdWithFunc(state, RECEIVE_SETUP, storeData, setupReceive);

const issueRecvRetryCmd = (state, actionState, storeData) => issueCmdWithStoreDelayFunc(state, actionState, storeData, setupReceive, RECV_NORMAL_POLL);

const issueNotifyCmd = (state, action, storeData, phone) => issueCmdWithStoreFuncParams(state, INCOMING_NOTIFY, storeData, setupNotify, {action, phone, retries: DEFAULT_RETRIES});

const issueHangupCmd = (state, wait, storeData) => issueCmdWithStoreFuncParams(state, HANGUP_CALL, storeData, setupHangup, {retries: DEFAULT_RETRIES, wait});

const issueHangupCmdWithCancel = (state, wait, storeData) => {
	state.cmdMgr?.cancel();
	return issueHangupCmd(state, wait, storeData);
};

const issueHangupDialCmdWithCancel = (state, storeData, phone, instr, language) => {
	state.cmdMgr?.cancel();
	return issueCmdWithStoreFuncParams(state, HANGUP_RECEIVE, storeData, setupHangup, {phone, instr, language, retries: 0, wait: true});
};

let callFinTmr = null;
const startCallFinTmr = (storeData) => {
	const {storeDispatch, defaultSettings} = storeData;
	const {callEndTimeout, popupWindowTimeout} = defaultSettings;
	const callback = (finished, callData) => {
		if (finished) finishCall(storeDispatch, callData);
		else startCallFinTmr(storeData);
	};
	callFinTmr = setTimeout(() => {openCallFinished(storeDispatch, callback, popupWindowTimeout)}, callEndTimeout);
};

const issueCallFinishedCmd = (state, wait, storeData) => state.actionState !== INITIAL_STATE ?
	(wait ? UpdateWithSideEffect({...state, actionState: CALL_FINISHED}, () => {
		startCallFinTmr(storeData);
	}) : issueRecvSetupCmd(state, storeData))
	: NoUpdate();

const issueCallDisconnected = (state, wait, storeData) => {
	setInCallState(storeData.storeDispatch, false);
	return issueCallFinishedCmd(state, wait, storeData);
};

const issuePollingRqstCmd = (state, actionData, storeData, wait) => {
	switch (actionData?.payload?.pollingState) {
		case NO_POLLING:
			return state.actionState !== CALL_FINISHED ?
				issueHangupCmdWithCancel(state, wait, storeData) : NoUpdate();
		case RECEIVE_POLLING:
			return state.actionState !== INITIAL_STATE && state.actionState !== HANGUP_CALL && state.actionState !== CALL_FINISHED ?
				issueHangupCmdWithCancel(state, wait, storeData) : issueRecvSetupCmd(state, storeData);
		default:
			return NoUpdate();
	}
};

const issueExchCmdFunc = (state, storeData, delayVal) => delayVal ?
		issueCmdWithStoreDelayFunc(state, CALL_EXCHANGE, storeData, setupExchange, delayVal) :
		issueCmdWithStoreFunc(state, CALL_EXCHANGE, storeData, setupExchange);

const issueExchCmd = (state, action, storeData) => {
	const {status, initialTime} = action?.payload ?? {};
	switch (status) {
		case EXCH_STS_DISCONNECTED: return issueCallDisconnected(state, true, storeData);
		case EXCH_STS_MISMATCH:
		case EXCH_STS_ERROR: {
			if (initialTime) {
				if (Date.now() - initialTime > CONVO_ERROR_TIME_LIMIT) {
					setCallErrorState(storeData.storeDispatch, NETWORK_POLLING_PROBLEM);
					return issueHangupCmd(state, true, storeData);
				} else {
					return issueCmdWithStoreDelayFuncParams(state, CALL_EXCHANGE, storeData, setupExchange, CONVO_POLL, {initialTime});
				}
			}
			return issueCmdWithStoreDelayFuncParams(state, CALL_EXCHANGE, storeData, setupExchange, CONVO_POLL, {initialTime: Date.now()});
		}
		default: return issueExchCmdFunc(state, storeData, CONVO_POLL);
	}
};

const issueDialCmd = (state, storeData, phone, instr, language) =>
	issueCmdWithStoreFuncParams(state, OUTBOUND_DIAL, storeData, setupDial,
		{phone, instr, service: phone === "911" ? "EMERGENCY" : (phone === "8006763777" ? "HELP" : "RELAY"), language});

const issueSetupCmd = (state, storeData, initialTime, instr) =>
	issueCmdWithStoreFuncParams(state, OUTBOUND_SETUP, storeData, setupSetup, {retries: DEFAULT_RETRIES, initialTime, instr});

const issueFinishCmd = (state, action, storeData) => {
	if (action.payload.pollingState !== NO_POLLING) {
		clearTimeout(callFinTmr);
		clearCallState(storeData.storeDispatch);
		return issuePollingRqstCmd(state, action, storeData, false);
	} else return NoUpdate();
};

const doLogout = (storeData) => {
	logoutUser(storeData.storeDispatch);
	return Update(initialState);
};

const stateMachine = new Array(TOTAL_STATES);
stateMachine[INITIAL_STATE] = new Array(TOTAL_ACTIONS);
stateMachine[INITIAL_STATE][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[INITIAL_STATE][POLLING_RQST] = (state, action, storeData) =>
	action?.payload?.pollingState === RECEIVE_POLLING ? issueRecvSetupCmd(state, storeData) : NoUpdate();

stateMachine[RECEIVE_SETUP] = new Array(TOTAL_ACTIONS);
stateMachine[RECEIVE_SETUP][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[RECEIVE_SETUP][POLLING_RQST] = (state, action, storeData) => issuePollingRqstCmd(state, action, storeData, false);
stateMachine[RECEIVE_SETUP][STATE_SUCCESS] = (state, action, storeData) =>
	issueCmd({...state, actionState: RECEIVE_STATUS, callId: action.payload.callId, receiveFlag: true}, {payload: {cmdFunc: setupStatus}}, storeData);
stateMachine[RECEIVE_SETUP][STATE_FAILURE] = (state, action, storeData) =>
	action.payload.logout ? doLogout(storeData) : issueRecvRetryCmd(state, RECEIVE_ERROR_SETUP, storeData);
stateMachine[RECEIVE_SETUP][DIAL_RQST] = (state, action, storeData) => issueHangupDialCmdWithCancel(state, storeData, action.payload.phone, action.payload.instr, action.payload.language);

stateMachine[RECEIVE_ERROR_SETUP] = new Array(TOTAL_ACTIONS);
stateMachine[RECEIVE_ERROR_SETUP][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[RECEIVE_ERROR_SETUP][POLLING_RQST] = (state, action, storeData) => issuePollingRqstCmd(state, action, storeData, false);
stateMachine[RECEIVE_ERROR_SETUP][STATE_SUCCESS] = (state, action, storeData) =>
	issueCmd({...state, actionState: RECEIVE_STATUS, callId: action.payload.callId, receiveFlag: true}, {payload: {cmdFunc: setupStatus}}, storeData);
stateMachine[RECEIVE_ERROR_SETUP][STATE_FAILURE] = (state, action, storeData) =>
	action.payload.logout ? doLogout(storeData) : issueRecvRetryCmd(state, RECEIVE_ERROR_SETUP, storeData);
stateMachine[RECEIVE_ERROR_SETUP][DIAL_RQST] = (state, action, storeData) => issueHangupDialCmdWithCancel(state, storeData, action.payload.phone, action.payload.instr, action.payload.language);

stateMachine[RECEIVE_STATUS] = new Array(TOTAL_ACTIONS);
stateMachine[RECEIVE_STATUS][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[RECEIVE_STATUS][STATE_SUCCESS] = (state, action, storeData) => issueCmdWithFunc(state, GET_CALLER_ID, storeData, setupCallerId);
stateMachine[RECEIVE_STATUS][STATE_FAILURE] = (state, action, storeData) => issueRecvRetryCmd(state, RECEIVE_SETUP, storeData);
stateMachine[RECEIVE_STATUS][DIAL_RQST] = (state, action, storeData) => issueHangupDialCmdWithCancel(state, storeData, action.payload.phone, action.payload.instr, action.payload.language);

const incomingCallPopup = (dispatch, storeData, phoneNbr) => {
	const callback = accept => {dispatch({type: accept ? STATE_SUCCESS : STATE_FAILURE, payload: phoneNbr})};
	openIncomingCall(storeData.storeDispatch, phoneNbr, callback, storeData.defaultSettings.popupWindowTimeout);
};

const issueNotifyFailure = (state, storeData, wait, errmsg) => {
	const {storeDispatch} = storeData;
	if (errmsg) setCallErrorState(storeDispatch, NETWORK_POLLING_PROBLEM);
	if (wait) setEmptyCallState(storeDispatch);
	return issueHangupCmd(state, wait, storeData);
};

stateMachine[GET_CALLER_ID] = new Array(TOTAL_ACTIONS);
stateMachine[GET_CALLER_ID][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[GET_CALLER_ID][STATE_SUCCESS] = (state, action, storeData) =>
	UpdateWithSideEffect({...state, actionState: INCOMING_POPUP}, (state, dispatch) => incomingCallPopup(dispatch, storeData, action.payload));
stateMachine[GET_CALLER_ID][STATE_FAILURE] = (state, action, storeData) =>
	UpdateWithSideEffect({...state, actionState: INCOMING_POPUP}, (state, dispatch) => incomingCallPopup(dispatch, storeData, 'Unknown'));

stateMachine[INCOMING_POPUP] = new Array(TOTAL_ACTIONS);
stateMachine[INCOMING_POPUP][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[INCOMING_POPUP][STATE_SUCCESS] = (state, action, storeData) => issueNotifyCmd(state, 'accept', storeData, action.payload);
stateMachine[INCOMING_POPUP][STATE_FAILURE] = (state, action, storeData) => issueNotifyCmd(state, 'reject', storeData, action.payload);

stateMachine[INCOMING_NOTIFY] = new Array(TOTAL_ACTIONS);
stateMachine[INCOMING_NOTIFY][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[INCOMING_NOTIFY][STATE_SUCCESS] = (state, action, storeData) => issueExchCmdFunc(state, storeData);
stateMachine[INCOMING_NOTIFY][STATE_FAILURE] = (state, action, storeData) => issueNotifyFailure(state, storeData, action.payload.wait, action.payload.errmsg);

stateMachine[CALL_EXCHANGE] = new Array(TOTAL_ACTIONS);
stateMachine[CALL_EXCHANGE][POLLING_RQST] = (state, action, storeData) => issuePollingRqstCmd(state, action, storeData, true);
stateMachine[CALL_EXCHANGE][EXCHANGE_RESULT] = (state, action, storeData) => issueExchCmd(state, action, storeData);

stateMachine[CALL_FINISHED] = new Array(TOTAL_ACTIONS);
stateMachine[CALL_FINISHED][POLLING_RQST] = (state, action, storeData) => issueFinishCmd(state, action, storeData);

stateMachine[HANGUP_CALL] = new Array(TOTAL_ACTIONS);
stateMachine[HANGUP_CALL][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[HANGUP_CALL][STATE_SUCCESS] = (state, action, storeData) => issueCallFinishedCmd(state, action?.payload?.params?.wait, storeData);
stateMachine[HANGUP_CALL][STATE_FAILURE] = (state, action, storeData) => issueCallFinishedCmd(state, action?.payload?.params?.wait, storeData);

stateMachine[HANGUP_RECEIVE] = new Array(TOTAL_ACTIONS);
stateMachine[HANGUP_RECEIVE][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[HANGUP_RECEIVE][STATE_SUCCESS] = (state, action, storeData) => issueDialCmd(state, storeData, action.payload.params.phone, action.payload.params.instr, action.payload.params.language);
stateMachine[HANGUP_RECEIVE][STATE_FAILURE] = (state, action, storeData) => issueDialCmd(state, storeData, action.payload.params.phone, action.payload.params.instr, action.payload.params.language);

stateMachine[OUTBOUND_DIAL] = new Array(TOTAL_ACTIONS);
stateMachine[OUTBOUND_DIAL][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[OUTBOUND_DIAL][POLLING_RQST] = (state, action, storeData) => issuePollingRqstCmd(state, action, storeData, false);
stateMachine[OUTBOUND_DIAL][STATE_SUCCESS] = (state, action, storeData) =>
	issueCmd({...state, actionState: OUTBOUND_STATUS, callId: action.payload.callId, receiveFlag: false},
		{payload: {cmdFunc: setupOutboundStatus, initialTime: action.payload.initialTime, retries: DEFAULT_RETRIES, params: action.payload.params}}, storeData);
stateMachine[OUTBOUND_DIAL][STATE_FAILURE] = (state, action, storeData) => issueHangupCmd(state, true, storeData);

stateMachine[OUTBOUND_STATUS] = new Array(TOTAL_ACTIONS);
stateMachine[OUTBOUND_STATUS][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[OUTBOUND_STATUS][POLLING_RQST] = (state, action, storeData) => issuePollingRqstCmd(state, action, storeData, false);
stateMachine[OUTBOUND_STATUS][STATE_SUCCESS] = (state, action, storeData) => issueSetupCmd(state, storeData, action.payload.initialTime, action.payload.params.instr);
stateMachine[OUTBOUND_STATUS][STATE_FAILURE] = (state, action, storeData) => issueHangupCmd(state, true, storeData);

stateMachine[OUTBOUND_SETUP] = new Array(TOTAL_ACTIONS);
stateMachine[OUTBOUND_SETUP][ISSUE_CMD] = (state, action, storeData) => issueCmd(state, action, storeData);
stateMachine[OUTBOUND_SETUP][POLLING_RQST] = (state, action, storeData) => issuePollingRqstCmd(state, action, storeData, false);
stateMachine[OUTBOUND_SETUP][STATE_SUCCESS] = (state, action, storeData) => issueExchCmdFunc(state, storeData);
stateMachine[OUTBOUND_SETUP][STATE_FAILURE] = (state, action, storeData) => issueHangupCmd(state, true, storeData);


const doPing = async () => {
	const response = await axios.post('/nologin/rgpProxy',
		{ProxyCommand: 'pingProxy'}, {timeout: 5000});
	return response?.status === 200 && response?.data.status.code === 200;
};

const sendPing = async timerRef => {
	let retries = 3;
	while (retries--) {
		if (await doPing()) break;
		else await new Promise(r => timerRef.current = setTimeout(r, 3000));
	}
};

const doTimedPing = (timeout, timerRef) => {
	timerRef.current = setTimeout(async () => {
		await sendPing(timerRef);
		doTimedPing(timeout, timerRef);
	}, timeout);
};

const CallMgr = ({children}) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(getUserInfo);
	const callState = useSelector(getCallState);
	const defaultSettings = useSelector(getDefaultSettings);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const actTime = useSelector(getActTime);
	const storeDispatch = useDispatch();
	const actStateRef = useRef(NO_POLLING);
	const pingTimerRef = useRef(null);
//	let {setCallback, setDelay, setMinDelay} = useTimedFunction(null, null, MIN_DELAY_DEFAULT);
	const [currState, dispatch] = useReducerWithSideEffects(
		(state, action) => {
			if (action.type >= ISSUE_CMD) {
				const func = stateMachine[state.actionState][action.type];
				return func ? func(state, action, {storeDispatch, defaultSettings}) : NoUpdate();
			} else {
				return Update({...state, csrfSessionNonce: action.payload.csrfSessionNonce});
			}
		},
		initialState
	);
	useEffect(() => {
		const unloadHndlr = () => {
			currState.cmdMgr?.cancel();
			if (currState.callId) {
				rgpCommand('hangup', createCallParams(currState), RGB_DEFAULT_TIMEOUT, false, cmdInterface(() => {}), storeDispatch);
			}
		};
		window.addEventListener("beforeunload", unloadHndlr);
		return () => {
			window.removeEventListener("beforeunload", unloadHndlr);
		};
	}, [currState]);
	useEffect(() => {
		let pollingState;
		if (isLoggedInAndNotAdmin(user) && isPollingEnabled(callState)) {
			switch (getActionState(callState)) {
				case USER_ACTION_READY:
					pollingState = RECEIVE_POLLING;
					break;
				case USER_ACTION_DIALING:
					pollingState = NO_POLLING_DIALING;
					break;
				case USER_ACTION_END_CALL:
					pollingState = CONVO_POLLING;
					break;
				case USER_ACTION_DONE:
					pollingState = NO_POLLING;
					break;
			}
		} else {
			pollingState = NO_POLLING;
		}
		if (pollingState !== actStateRef.current) {
			actStateRef.current = pollingState;
			if (pollingState === NO_POLLING) {
				const elapsedTime = callSt => {
					const elTime = (callSt.endTime - callSt.startTime + 500) / 1000;
					let callSecs = Math.trunc(elTime % 60).toString();
					if (callSecs.length !== 2) callSecs = '0' + callSecs;
					let callMins = (Math.trunc(elTime / 60) % 60).toString();
					if (callMins.length !== 2) callMins = '0' + callMins;
					let callHrs = Math.trunc(elTime / 3600).toString();
					if (callHrs.length !== 2) callHrs = '0' + callHrs;
					return callHrs + ':' + callMins + ':' + callSecs;
				};
				storeDispatch({type: endConvoRdcr.type, payload: {elapsedTime: elapsedTime(callState)}});
			}
			if (pollingState !== NO_POLLING_DIALING) {
				if (!currState.csrfSessionNonce && pollingState === RECEIVE_POLLING) dispatch({type: CSRF_UPDATE, payload: {csrfSessionNonce}});
				dispatch({type: POLLING_RQST, payload: {pollingState}});
			} else
				dispatch({type: DIAL_RQST, payload: {phone: stripNonNums(callState.callPhoneNumber), instr: callState.dialingInstr?.trim(), language: i18n.language === 'es' ? "SPA":"ENG"}});
		}
	}, [user, callState, csrfSessionNonce]);
	useEffect(() => {
		if (isLoggedInOrAdmin(user)) {
			doTimedPing(300000, pingTimerRef);
			return () => clearTimeout(pingTimerRef.current);
		}
	}, [actTime, pingTimerRef, user]);
	useEffect(() => {
		if (isLoggedInOrAdmin(user)) {
			const defaultDelay = isAdmin(user) ? defaultSettings.adminSessionTimeout : defaultSettings.userSessionTimeout;
			const actTimer = setTimeout(() => {
				openWarnInactivity(storeDispatch, isLogout => {
					if (isLogout) logoutUser(storeDispatch);
					else updateActTime(storeDispatch);
				}, defaultSettings.popupWindowTimeout);
			}, Math.max(defaultDelay + actTime - Date.now(), 30000));
			return () => clearTimeout(actTimer);
		}
	}, [actTime, defaultSettings, user]);
	return <>{children}</>;
};

export default CallMgr;