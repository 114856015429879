import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const NoMenu = BodyComponent => ({pageTitle, ...props}) => {
	const { t } = useTranslation();

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style["container-xl"]}>
				{pageTitle != null && pageTitle != '' ?
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-24']}`}>
						<h1 className={e3Style['sprint-title']}><Link to="/"
							className={e3Style['sprint-title-back']} aria-label={t('prefs.backbtn')}>
							{t('prefs.goback')}</Link>
							{pageTitle}
						</h1>
					</div>
				</div>:<></>}
				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}>
						<BodyComponent {...props}/>
					</div>
				</div>
			</div>
		</main>
	);
};

export default NoMenu;
