import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getInitData, getRegistry, resetRegistry, setRegistry} from "../../../store/registry";
import {useValidator} from "react-joi";
import Joi from "joi";
import dobValidator from "../../../validators/dobValidator";
import StatusMsg from "../../util/statusMsg";
import e3Style from "../../../../css/e3-style.css";
import ReactTooltip from "react-tooltip";
import sprStyle from "../../../../css/sprint-style.css";
import {updateActTime} from "../../../store/activity";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {loginPath, regConfirmAddressPath, userIdentityPath} from "../../layout/body";
import {getStateList, setEditAddress, setFormattedAddress, setStateList} from "../../../store/AddressList";
import {updateAddr} from "../Common/confirmAddress";
import {EXTENDED_TIMEOUT, postWithTimeout} from "../../../util/ioHelper";


const trimRegData = regData => {
	return {
		...regData,
		homeAddress1: regData.homeAddress1.trim(),
		homeAddress2: regData.homeAddress2.trim(),
		city: regData.city.trim()
	};
};

const RetryRegistration = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateList = useSelector(getStateList);
	const registryData = useSelector(getRegistry);

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: getInitData(),
		schema: Joi.object({
			homeAddress1: Joi.string().trim().min(1).max(60).required().messages({
				'string.empty':'flows.registration.reginfo.9'
			}),
			city: Joi.string().trim().min(1).max(35).required().messages({
				'string.empty':'flows.registration.reginfo.12'
			}),
			stateAbbrev: Joi.string().min(1).required().messages({
				'string.empty':'flows.registration.reginfo.15'
			}),
			zipCode: Joi.string().trim().regex(/^[0-9]{5}$/).required().messages({
				'string.empty':'flows.registration.reginfo.17',
				'string.pattern.base': 'flows.registration.reginfo.18',
			}),
			birthDate: Joi.string().required().custom(dobValidator).messages({
				'string.base': 'dob.invalid.error',
				'string.empty': 'dob.required.error',
				'any.required': 'dob.required.error',
				'any.invalid': 'dob.invalid.error'
			})
		}),
		explicitCheck: {
			homeAddress1: false,
			city: false,
			stateAbbrev: false,
			zipCode: false,
			birthDate: false
		}
	});

	const getStates = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getStates'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setStateList(dispatch, data.response.statesList);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	useEffect(() => {
		if(stateList.length === 0)
			getStates();
		if (registryData?.initComplete) {
			const {initComplete: _, ...regData} = registryData;
			const {homeAddress1, homeAddress2, city, stateAbbrev, zipCode, birthDate} = regData;
			setData({homeAddress1, homeAddress2, city, stateAbbrev, zipCode, birthDate});
		}
	}, []);

	const checkAddrInfo = async () => {
		const editAddress = {
			addressLine1: state.$data.homeAddress1.trim(),
			addressLine2: state.$data.homeAddress2? state.$data.homeAddress2.trim() : '',
			city: state.$data.city.trim(),
			stateAbbr: state.$data.stateAbbrev,
			zip: state.$data.zipCode,
			zip4: ''
		};
		if (registryData.homeAddress1 === editAddress.addressLine1 &&
			registryData.homeAddress2 === editAddress.addressLine2 &&
			registryData.city === editAddress.city &&
			registryData.stateAbbrev === editAddress.stateAbbr &&
			registryData.zipCode === editAddress.zip) {
			setRegistry(dispatch, {...registryData, birthDate: state.$data.birthDate});
			setHistoryPath(dispatch, navigate, userIdentityPath);
			return;
		}

		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getRegistrationNonce'}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				if (data.status.code === 200){
					try {
						const newAddress = {
							...editAddress,
							nonce: data.response.nonce
						};

						updateActTime(dispatch);
						const response = await postWithTimeout(
							{command: {name: 'formatRegisterAddr'},
								params: newAddress
							}, EXTENDED_TIMEOUT);

						if (response.status === 200) {
							const {data} = response;
							switch (data.status.code) {
								case 200:
									const formattedAddress = {...data.response,
										addressLine2: data.response.addressLine2? data.response.addressLine2 : '',
										zip4: data.response.zip4? data.response.zip4 : ''};

									setRegistry(dispatch, {...registryData, ...trimRegData(state.$data)});
									if( formattedAddress.addressLine1 === newAddress.addressLine1.toUpperCase() &&
										(formattedAddress.addressLine2 === undefined || formattedAddress.addressLine2 === newAddress.addressLine2.toUpperCase()) &&
										formattedAddress.city === newAddress.city.toUpperCase() &&
										formattedAddress.stateAbbr === newAddress.stateAbbr &&
										formattedAddress.zip === newAddress.zip) {
										updateAddr({...formattedAddress}, registryData, dispatch, navigate);
									} else {
										setFormattedAddress(dispatch, formattedAddress);
										setEditAddress(dispatch, editAddress);
										setHistoryPath(dispatch, navigate, regConfirmAddressPath+ `/retryregister/selectaddr`);
									}
									break;

								case 419:
									setRegistry(dispatch, {...registryData, ...trimRegData(state.$data)});
									setEditAddress(dispatch, editAddress);
									setHistoryPath(dispatch, navigate, regConfirmAddressPath+ `/register/invalidaddr`);
									break;

								case 998:
									resetRegistry(dispatch);
									setHistoryPath(dispatch, navigate, loginPath);
									break;

								default:
									console.error('error in checking address!'+data.status.code+"|"+data.status.description)
									setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
									break;
							}
						} else {
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						}
					} catch (error) {
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateInfo = e => {
		e.preventDefault();
		validate();
		if( state.$all_source_errors.length === 0 )
			checkAddrInfo();
	};

	const updateField = e => {
		const {name, value} = e.target;
		setData((old) => ({
			...old,
			[name]: value
		}));
	};

	return (
		<>
			<StatusMsg/>
			<form className={e3Style['sprint-register-form']} id="registrationInfoModel" noValidate autoComplete="off">
				<h1>{t('flows.registration.reenter.1')}</h1>
				<fieldset>
					<legend className={e3Style['form-title']}>
						<h2>{t('flows.registration.reginfo.2')}</h2>
					</legend>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.homeAddress1 && state.$source_errors.homeAddress1.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="home-address-1">{t('flows.registration.reginfo.8')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
							<input id="home-address-1" name="homeAddress1" type="text" placeholder="(No P.O. Boxes)" value={state.$data.homeAddress1}
							       className={`${e3Style['input--fluid']} ${(state.$explict_fields.homeAddress1 && state.$source_errors.homeAddress1.length > 0) ? e3Style['sprint-form-error'] : ''}`}
							       onChange={updateField} onBlur={() => setExplicitField('homeAddress1', true)}/>
							{(state.$explict_fields.homeAddress1 && state.$source_errors.homeAddress1.length > 0) ?
								<div className={e3Style['sprint-alert--error']}>
									<div className={e3Style['alert--error_message']}>
										{state.$source_errors.homeAddress1.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
									</div>
								</div>
								: ''
							}
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<label className={`${e3Style['text-uppercase']}`} htmlFor="home-address-2">{t('flows.registration.reginfo.10')}</label>
							<input id="home-address-2" name="homeAddress2" className={`${e3Style['input--fluid']}`} type="text"
							       value={state.$data.homeAddress2} onChange={updateField}/>
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.city && state.$source_errors.city.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="city">{t('flows.registration.reginfo.11')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
							<input id="city" name="city" type="text" value={state.$data.city}
							       className={`${e3Style['input--fluid']} ${(state.$explict_fields.city && state.$source_errors.city.length > 0) ? e3Style['sprint-form-error'] : ''}`}
							       onChange={updateField} onBlur={() => setExplicitField('city', true)}/>
							{(state.$explict_fields.city && state.$source_errors.city.length > 0) ?
								<div className={e3Style['sprint-alert--error']}>
									<div className={e3Style['alert--error_message']}>
										{state.$source_errors.city.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
									</div>
								</div>
								: ''
							}
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']}`}>
							<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.stateAbbrev && state.$source_errors.stateAbbrev.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="state">{t('flows.registration.reginfo.13')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
							{ stateList.length > 0 ?
								<select id="state" name="stateAbbrev" className={`${e3Style['input--fluid']} ${(state.$explict_fields.stateAbbrev && state.$source_errors.stateAbbrev.length > 0) ? e3Style['sprint-form-error'] : ''}`}
								        value={state.$data.stateAbbrev} onChange={updateField} onBlur={() => setExplicitField('stateAbbrev', true)} >
									<option value=""> </option>
									{stateList.map(stateAbrev => {
										return (
											( stateAbrev.STATE_ABBR === state.$data.stateAbbr ) ?
												<option value={stateAbrev.STATE_ABBR} selected="selected">{stateAbrev.STATE_ABBR}</option>
												:
												<option value={stateAbrev.STATE_ABBR}>{stateAbrev.STATE_ABBR}</option>
										);
									})}
								</select>
								:
								<>
								</>
							}
							{(state.$explict_fields.stateAbbrev && state.$source_errors.stateAbbrev.length > 0) ?
								<div className={e3Style['sprint-alert--error']}>
									<div className={e3Style['alert--error_message']}>
										{state.$source_errors.stateAbbrev.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
									</div>
								</div>
								: ''
							}
						</div>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-6']}`}>
							<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.zipCode && state.$source_errors.zipCode.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="zipCode">{t('flows.registration.reginfo.16')}<span className={`${e3Style['txt-upper']}`}>*</span></label>
							<input id="zipCode" name="zipCode" type="text" value={state.$data.zipCode}
							       className={`${e3Style['input--fluid']} ${(state.$explict_fields.zipCode && state.$source_errors.zipCode.length > 0) ? e3Style['sprint-form-error'] : ''}`}
							       onChange={updateField} onBlur={e => validateField(e)}/>
							{(state.$explict_fields.zipCode && state.$source_errors.zipCode.length > 0) ?
								<div className={e3Style['sprint-alert--error']}>
									<div className={e3Style['alert--error_message']}>
										{state.$source_errors.zipCode.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
									</div>
								</div>
								: ''
							}
						</div>
					</div>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
							<div className={`e3Style['soar-input']} ${e3Style['soar-input--date']} ${e3Style['mb-0']}`}>
								<label className={`${e3Style['text-uppercase']} ${(state.$explict_fields.birthDate && state.$source_errors.birthDate.length > 0) ? e3Style['sprint-form-error'] : ''}`} htmlFor="date-of-birth">{t('flows.registration.reginfo.23')}<span className={`${e3Style['txt-upper']}`}>*</span>
									<a href="#" className={`e3Style['tooltip']} ${e3Style['sprint--information-icon']} ${e3Style['sprint-icon-info']}`} data-tip data-for="dob-tooltip">i</a>
									<ReactTooltip id="dob-tooltip" place="bottom" type="info" effect="float">
										<span className={sprStyle.tooltiptext}>{t('flows.registration.reginfo.24')}</span>
									</ReactTooltip></label>
								<input id="date-of-birth" name="birthDate" placeholder="MM/DD/YYYY" type="text" value={state.$data.birthDate}
								       className={`${e3Style['input--fluid']} ${(state.$explict_fields.birthDate && state.$source_errors.birthDate.length > 0) ? e3Style['sprint-form-error'] : ''}`}
								       onChange={updateField} onBlur={() => setExplicitField('birthDate', true)}/>
								{(state.$explict_fields.birthDate && state.$source_errors.birthDate.length > 0) ?
									<div className={e3Style['sprint-alert--error']}>
										<div className={e3Style['alert--error_message']}>
											{state.$source_errors.birthDate.map((err, idx) => <p index={idx}><span>{t(err.$message)}</span></p>)}
										</div>
									</div>
									: ''
								}
							</div>
						</div>
					</div>
				</fieldset>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" onClick={updateInfo} disabled={state.$all_source_errors.length}
						        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
							{t('button.next')}
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default RetryRegistration;
