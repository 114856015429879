import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import Joi from "joi";
import { useValidator } from "react-joi";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import StatusMsg from "../../util/statusMsg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";

import {addFreqNbr, getFreqNumbers} from "../../../store/FreqNumberList";
import {freqNumbersPath, loginPath} from "../../layout/body";
import {addEmerNbr, getEmerNumbers} from "../../../store/emerNumberList";
import {emergencyNumbersPath} from "../../layout/body";
import {addSTSCntct, getSTSCntcts} from "../../../store/STSContactList";
import {stsSettingsPath} from "../../layout/body";
import {addBlockNbr, getBlockNumbers} from "../../../store/permissionsList";
import {permissionsPath} from "../../layout/body";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import {stripNonNums} from "../../util/stringFormatHelper";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const AddNumberGeneric = ({addType, numFuncs, numList}) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const HeaderMsg = numFuncs.headerMsg(t);
	const newId = numFuncs.findId(numList);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			name: "",
			phoneNumber: ""
		},
 	    schema: Joi.object({
			name: Joi.string().trim().min(1).required().messages({
				'string.empty':'name.required.error'
			}),
			phoneNumber: Joi.string().required().pattern(/^(?:(?:\(\s*\d{3}\s*\)|(?:\d{3}))\s*(?:-\s*)?\d{3}\s*(?:-\s*)?\d{4})$|^(?:\d{10})$/).messages({
				'string.base': 'phoneNumber.invalidFormat.error',
				'string.empty': 'phoneNumber.required.error',
				'any.required': 'phoneNumber.required.error',
				'string.pattern.base': 'phoneNumber.invalidFormat.error'
			})
		}),
		explicitCheck: {
			name: false,
			phoneNumber: false
		}
	});

	const saveNewNumber = () => {
		let matchedIdx = -1;
		const newPhoneNbr = stripNonNums(state.$data.phoneNumber);

		if (numList.length)
			matchedIdx = numFuncs.saveNewNum(numList, newPhoneNbr);
		if(matchedIdx >= 0 )
			setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
		else 
			saveNumber();
	}

	const saveNumber = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: addType, csrfSessionNonce},
				 params: {
							orderId: newId,
							name: state.$data.name,
							phoneNumber:stripNonNums(state.$data.phoneNumber)
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				
				switch (data.status.code) {
					case 200:
						numFuncs.addNum(dispatch, newId, state.$data.name, state.$data.phoneNumber, navigate);
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
						
					case 426:
						setStatusMessage(dispatch, 'phoneNumber.duplicate.error', STATUS_ERROR);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const submitNbr = e => {
		e.preventDefault();
		saveNewNumber();
	};

	const formatPhoneNbr = (value) => {
	  	if (!value) return value;
	  	const currentValue = stripNonNums(value);
	  	const cvLength = currentValue.length;
	    if (cvLength < 4) return currentValue;
	    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
	   	if (cvLength < 11)
	    	return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
		else
			return currentValue;
	  	
	};

	return(
		<>
		<StatusMsg/>
		<form noValidate autoComplete="off" onSubmit={submitNbr}>		
			<fieldset class={`${e3Style['sprint-notes']} ${e3Style['sprint-account_form_items']}`}>
				<HeaderMsg/>
				<div className={e3Style.row}>
			      	<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				        <FocusWrappedGrp baseAriaId="nameErr" cptErrCount={state.$errors.name.length}>
					        <FocusWrappedLabel className={e3Style['text-uppercase']}
					               htmlFor="name">*{t('page.popup.name') + ' '}</FocusWrappedLabel>
					        <FocusWrappedInput type="text" autoFocus id="name" name="name"
					               onChange={e => updateField(e, 'name')}
					               onBlur={() => setExplicitField('name', true)}
					               className={e3Style['input--fluid']}/>
					        {!!state.$errors.name.length &&
						        <div className={e3Style['sprint-alert--error']}>
							        <div className={e3Style['alert--error_message']}>
								        {state.$errors.name.map((err, idx) => <p index={idx} id={'nameErr-' + idx}><span>{t(err.$message)}</span></p>)}
							        </div>
						        </div>
					        }
				        </FocusWrappedGrp>
					</div>
				</div>
				<div className={e3Style.row}>
			      	<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
				        <FocusWrappedGrp baseAriaId="phNumErr" cptErrCount={state.$errors.phoneNumber.length}>
					        <FocusWrappedLabel className={e3Style['text-uppercase']}
					               htmlFor="phoneNumber">*{t('page.popup.phnum') + ' '}</FocusWrappedLabel>
					        <FocusWrappedInput type="tel" name="phoneNumber" id="phoneNumber"
					               onChange={e =>{e.target.value = formatPhoneNbr(e.target.value);updateField(e, 'phoneNumber')}}
					               onBlur={() => setExplicitField('phoneNumber', true)}
					               className={e3Style['input--fluid']} placeholder="(000) 123-4567"/>
					        {!!state.$errors.phoneNumber.length &&
						        <div className={e3Style['sprint-alert--error']}>
							        <div className={e3Style['alert--error_message']}>
								        {state.$errors.phoneNumber.map((err, idx) => <p index={idx} id={'phNumErr-' + idx}><span>{t(err.$message)}</span></p>)}
							        </div>
						        </div>
					        }
				        </FocusWrappedGrp>
				   </div>
			   </div>
			</fieldset>
			<div className={e3Style.row}>
			    <div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
					<button type="button" onClick={e => {e.preventDefault();numFuncs.setPath(dispatch, navigate)}}
						className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.cancel')} </button>

					<button type="submit" onClick={validate} disabled={state.$auto_invalid}
		             className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
					{t('button.submit')} </button>
				</div>
		    </div>
		</form>
		</>
	);
};

const genericFuncs = {
	saveNewNum: (numList, newPhoneNbr) => (numList && numList.length) ? numList.findIndex(value => value.PHONE_NUMBER === newPhoneNbr) : -1,
	findId: numList => {
		let newId = 1;
		if (!numList || !numList.length || numList[0].ORDERID !== "1") return newId.toString();

		for (let i = 1;i < numList.length;++i) {
			if (numList[i].ORDERID - newId > 1) break;
			++newId;
		}
		return (newId + 1).toString();
	},
	addNum: (addNbrFunc, path, dispatch, newId, name, phoneNumber, navigate) => {
		addNbrFunc(dispatch, {
			ORDERID: newId,
			NAME: name,
			PHONE_NUMBER:stripNonNums(phoneNumber)
		});
		setHistoryPath(dispatch, navigate, path);
	},
	headerMsg: (t, msg) => <legend><h2>{t(msg)}</h2></legend>
};

const freqDialedNumFuncs = {
	saveNewNum: (numList, newPhoneNbr) => genericFuncs.saveNewNum(numList, newPhoneNbr),
	findId: numList => genericFuncs.findId(numList),
	addNum: (dispatch, newId, name, phoneNumber, navigate) =>
		genericFuncs.addNum(addFreqNbr, freqNumbersPath, dispatch, newId, name, phoneNumber, navigate),
	headerMsg: t => () => genericFuncs.headerMsg(t, 'flows.freqdialed.freqnumbers.5'),
	setPath: (dispatch, navigate) => setHistoryPath(dispatch, navigate, freqNumbersPath)
};

const emergencyNumFuncs = {
	saveNewNum: (numList, newPhoneNbr) => genericFuncs.saveNewNum(numList, newPhoneNbr),
	findId: numList => genericFuncs.findId(numList),
	addNum: (dispatch, newId, name, phoneNumber, navigate) =>
		genericFuncs.addNum(addEmerNbr, emergencyNumbersPath, dispatch, newId, name, phoneNumber, navigate),
	headerMsg: t => () => genericFuncs.headerMsg(t, 'flows.emergency.addemergencynumber.1'),
	setPath: (dispatch, navigate) => setHistoryPath(dispatch, navigate, emergencyNumbersPath)
};

const stsContactFuncs = {
	saveNewNum: (numList, newPhoneNbr) => genericFuncs.saveNewNum(numList, newPhoneNbr),
	findId: numList => genericFuncs.findId(numList),
	addNum: (dispatch, newId, name, phoneNumber, navigate) =>
		genericFuncs.addNum(addSTSCntct, stsSettingsPath, dispatch, newId, name, phoneNumber, navigate),
	headerMsg: t => () => genericFuncs.headerMsg(t, 'flows.stscontacts.stscontacts.9'),
	setPath: (dispatch, navigate) => setHistoryPath(dispatch, navigate, stsSettingsPath)
};

const blockNumFuncs = {
	saveNewNum: (numList, newPhoneNbr) => genericFuncs.saveNewNum(numList, newPhoneNbr),
	findId: numList => genericFuncs.findId(numList),
	addNum: (dispatch, newId, name, phoneNumber, navigate) =>
		genericFuncs.addNum(addBlockNbr, permissionsPath, dispatch, newId, name, phoneNumber, navigate),
	headerMsg: t => () => genericFuncs.headerMsg(t, 'flows.permissions.addblocknumber.1'),
	setPath: (dispatch, navigate) => setHistoryPath(dispatch, navigate, permissionsPath)
};

const addNumFreqDialed = props => {
	const curFreqList = useSelector(getFreqNumbers);
	return <AddNumberGeneric numFuncs={freqDialedNumFuncs} numList={curFreqList} {...props}/>;
};

const addNumEmergency = props => {
	const curEmerList = useSelector(getEmerNumbers);
	return <AddNumberGeneric numFuncs={emergencyNumFuncs} numList={curEmerList} {...props}/>;
};

const addSTSContactRec = props => {
	const curSTSList = useSelector(getSTSCntcts);
	return <AddNumberGeneric numFuncs={stsContactFuncs} numList={curSTSList} {...props}/>;
};

const addNumBlocked = props => {
	const curBlockList = useSelector(getBlockNumbers);
	return <AddNumberGeneric numFuncs={blockNumFuncs} numList={curBlockList} {...props}/>;
};

const addNumObject = {
	addFreqNumber: addNumFreqDialed,
	addEmergencyNumber: addNumEmergency,
	addBlockedNumber: addNumBlocked,
	addSTSContact: addSTSContactRec
};

const addNumber = () => {
	const params = useParams();
	const NumObj = addNumObject[params.addType];
	return <NumObj {...params}/>;
};

export default addNumber;
