import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {
	accountPortNumPath,
	loginPath,
	relayConfirmDelNumPath,
	relayVerifDataPath
} from "../../layout/body";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";
import {fmtPhoneNum} from "../../util/stringFormatHelper";

const AccountList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [accountState, setAccountState] = useState({
		sprintIpNumber: null,
		sprintIpNumberStatus: null,
		mobileIpNumber: null,
		userValidated: false
	});

	useEffect(() => {
		const getAccountState = async () => {
			try {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getAccountList', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setAccountState({
								...data.response
							});
							break;

						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;

						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} catch (error) {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		};

		getAccountState();
	}, []);

	const sPortInProg = accountState.sprintIpNumberStatus === 'port-in in progress';
	const sDelInProg = accountState.sprintIpNumberStatus === 'delete in progress';
	const sipPortIn = sPortInProg || sDelInProg;

	return (
		<>
			<StatusMsg/>

			<form className={e3Style['account-preferences_accounts']}>
				<fieldset className={`${e3Style['sprint-notes']} ${e3Style['sprint-account_form_items']}`}>
					<div className={`${e3Style.row} ${e3Style['sprint-account_account_item']}`}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-account']}`}>
							<h3 className={e3Style['mb-40']}>{t('flows.account.acctlist.1')}</h3>
							{accountState.sprintIpNumber ?
								<>
									<p>{t('flows.account.acctlist.2')} {fmtPhoneNum(accountState.sprintIpNumber)}</p>
									{sPortInProg ?
										<p>{t('flows.account.acctlist.17')}</p>
										: sDelInProg &&
										<p>{t('flows.account.acctlist.3')}</p>
									}
									<div>
										<button type="button" disabled={sipPortIn} onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, accountPortNumPath)}}
										        className={`${e3Style.button} ${sipPortIn ? e3Style.disabled : ''} ${e3Style['button--minwidth-xl']} ${e3Style['button--lg']} ${e3Style['mr-40']}`}>
											{t('button.portmynum')}
										</button>
										<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, relayConfirmDelNumPath)}} disabled={sDelInProg}
										        className={`${e3Style.button} ${sDelInProg ? e3Style.disabled : ''} ${e3Style['button--minwidth-xl']} ${e3Style['button--lg']}`}>
											{t('button.delete')}
										</button>
									</div>
								</>
								:
								<div>
									<p>{t('flows.account.acctlist.4')}</p>

									<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, relayVerifDataPath)}}
									        className={`${e3Style.button} ${e3Style['button--minwidth-xl']} ${e3Style['button--lg']}`}>
										{t('button.add')}
									</button>

									<h4 className={e3Style['mr-40']}>{t('flows.account.acctlist.12')}</h4>

									<p>{t('flows.account.acctlist.5')}</p>
									<p>
										{t('flows.account.acctlist.6')} <a href="https://t-mobile.com/access"
										   target="_blank">t-mobile.com/access</a>. &copy; {new Date().getFullYear()} T-Mobile. {t('flows.account.acctlist.18')}
									</p>
								</div>
							}
						</div>
					</div>

					{accountState.mobileIpNumber &&
						<>
							<div className={`${e3Style.row} ${e3Style['sprint-account_account_item']}`}>
								<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-account']}`}>
									<h3 className={e3Style['mb-40']}>{t('flows.account.acctlist.15')}</h3>
									<p>{t('flows.account.acctlist.16')} {fmtPhoneNum(accountState.mobileIpNumber)}</p>
								</div>
							</div>
						</>
					}
				</fieldset>
			</form>
		</>
	);
};

export default AccountList;
