import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
	accountListPath,
	emergencyNumbersPath,
	freqNumbersPath,
	permissionsPath,
	preferencesPath,
	stsSettingsPath
} from "../body";
import {useLocation, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {setHistoryPath} from "../../../store/bodyView";

const BodyMenu = BodyComponent => ({pageTitle, ...props}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const gotoLink = path => e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, path);
	};

	const activeLoc = loc => {
		const locs = location.pathname.split("/");
		return locs[1] === loc;
	};

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style["container-xl"]}>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-24']}`}>
						<h1 className={e3Style['sprint-title']}><Link to="/"
							className={e3Style['sprint-title-back']} aria-label={t('prefs.backbtn')}>
							{t('prefs.goback')}</Link>
							{pageTitle}
						</h1>
					</div>
				</div>
				<div className={`${e3Style.row} ${e3Style['sprint-ctas']}`}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-10']} ${e3Style['col-md-8']} ${e3Style['col-lg-7']}`}>
						<div className={e3Style['sprint-cta_side_nav']}>
							<nav aria-label="customer profile menu" role="navigation">
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(accountListPath)} className={activeLoc('Account') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-account-billing']}`}
											aria-hidden="true"></span>
											{t('prefs.account')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink("/Addresses/accountList")} className={activeLoc('Addresses') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-alert']}`}
											aria-hidden="true"></span>
											{t('prefs.emergency.informaton')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(freqNumbersPath)} className={activeLoc('FreqDialed') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-freq-dialed']}`}
											aria-hidden="true"></span>
											{t('prefs.frequently.dialed')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(preferencesPath)} className={activeLoc('Preferences') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-account-preferences']}`}
											aria-hidden="true"></span>
											{t('prefs.call.preferences')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink("/Notes/accountList")} className={activeLoc('Notes') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-review-confirm']}`}
											aria-hidden="true"></span>
											{t('prefs.notes')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(stsSettingsPath)} className={activeLoc('STSContacts') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-speech-to-speech']}`}
											aria-hidden="true"></span>
											{t('prefs.sts')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(emergencyNumbersPath)} className={activeLoc('Emergency') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-emergency-numbers']}`}
											aria-hidden="true"></span>
											{t('prefs.emergency.numbers')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink(permissionsPath)} className={activeLoc('Permissions') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-permissions']}`}
											aria-hidden="true"></span>
											{t('prefs.permissions')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink("/PersonalInfo/accountList")} className={activeLoc('PersonalInfo') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-identification']}`}
											aria-hidden="true"></span>
											{t('prefs.contact.informaton')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink("/Security/accountList")} className={activeLoc('Security') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-security']}`}
											aria-hidden="true"></span>
											{t('prefs.account_security')}
										</div>
									</a>
								</div>
								<div className={e3Style['sprint-cta_side_nav_item']}>
									<a onClick={gotoLink("/Print/accountList")} className={activeLoc('Print') ? e3Style.active : ''}>
										<div className={e3Style.nav_item_style}><span
											className={`${e3Style['sprint--title-icon']} ${e3Style['sprint-icon-large']} ${e3Style['sprint-icon-printer']}`}
											aria-hidden="true"></span>
											{t('prefs.print')}
										</div>
									</a>
								</div>
							</nav>
						</div>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>
						<BodyComponent {...props}/>
					</div>
				</div>
			</div>
		</main>
	);
};

export default BodyMenu;
