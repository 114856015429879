import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";

const DisplayMessage = ({displayMsgObj}) => {
	const {headerMsg, DisplayMsg, buttonList} = displayMsgObj;
	const { t } = useTranslation();

	return (
		<>
			<h1 className={e3Style['pt-0']}>{t(headerMsg)}</h1>
			<form>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}><DisplayMsg/></div>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						{buttonList.map((btn, idx) => <button type="button" onClick={btn.onClick} className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']} ${idx + 1 < buttonList.length ? e3Style['button--secondary'] : ''}`}>{t(btn.btnText)}</button>)}
					</div>
				</div>
			</form>
		</>
	);
};

export default DisplayMessage;
