import React, {useEffect, useState} from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_NONE, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {setLocationList, setCurrLocation, getLocationList, getCurrLocation} from "../../../store/AddressList"
import {getCallState} from "../../../store/call";
import {loginPath, editAddressPath, personalInfoPath} from "../../layout/body";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const Locations = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const locationList = useSelector(getLocationList);
	const curLocId = useSelector(getCurrLocation);
	const callState = useSelector(getCallState);
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);

	const [dataReady, setDataReady] = useState(false);

	useEffect(() => {
		setStatusMessage(dispatch, '', STATUS_NONE);
		getLocations();
	}, []);
	
	const getLocations = async () => {
		try {
			if( locationList == null) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
				{command: {name: 'getLocations', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setLocationList(dispatch, data.response.customerAddress);
							setCurrLocation(dispatch, data.response.activeAddressId);
							setDataReady(true);
							break;
	
						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;
	
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			}
			else
				setDataReady(true);
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const gotoEdit = e => {
		e.preventDefault();
		const {name, value} = e.target;
		let myArray = name.split('-');
		if( myArray.length > 2)
			setHistoryPath(dispatch, navigate, personalInfoPath);
		else{
			if(myArray[0].startsWith('editLocation'))
				setHistoryPath(dispatch, navigate, editAddressPath+`/editLocation/`+myArray[1]);
			else
				setHistoryPath(dispatch, navigate, editAddressPath+`/deleteLocation/`+myArray[1]);
		}

	};
	
	const onLocChange = e => {
		const {name, value} = e.target;
		setCurrentAddress(value);
	}

	const setCurrentAddress = async (newId) => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
			{command: {name: 'setLocation', csrfSessionNonce},
			 params: {addressId:newId.toString()}
			},{timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setCurrLocation(dispatch, newId);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}

		} catch (error) {
			console.log("error="+error);
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const addAddress = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, editAddressPath+`/addLocation/0`);
	};

	return (
		<>
		<StatusMsg/>
		<div className={`${e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['col-sm-14']} ${e3Style['col-md-16']} ${e3Style['col-lg-17']}`}>
			<p>{t('flows.addresses.addresses.1')}</p>
			<p>{t('flows.addresses.addresses.2')}</p>
			{ dataReady ?
				<>
				<form id="currentLocations">
					<div className={`${e3Style.row} ${e3Style['user-current-location']}`}>
						<div className={`e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
						<div className={`e3Style['soar-input']} ${e3Style['soar-input--date']} ${e3Style['mb-0']}`}>
							<label className={e3Style['text-uppercase']} id="selectedAddressId-label">{t('flows.addresses.addresses.3')}</label>
						</div>
						</div>

						<div className={`e3Style['e3Style.colStyle']} ${e3Style['col-xs-12']} ${e3Style['sprint-form-next']} ${e3Style['display-flex']}`}>
							{ locationList && (locationList.length > 0) ?
								<>
								<select id="selectedAddressId" name="selectedAddressId" title={t('flows.addresses.addresses.7')} 
								autoFocus value={curLocId} onChange={onLocChange}
								disabled={callState.userPhoneNumber.length == 0}>
								{locationList.map(locationInfo => {
									return (
										<option value={locationInfo.ADDRESSID}>{locationInfo.ADDRESS_NAME}</option>
									);
								})}
								</select> 
								</>
								:
								<>
								</>
							}
						</div>
					</div>
				</form>

				<form id="addressesList" className={e3Style['account-preferences_emergency-address-info']}>
				<fieldset className={e3Style['sprint-emergency-address-info']}>
					<legend><h2>{t('flows.addresses.addresses.4')}</h2></legend>
				</fieldset>
				<fieldset className={`${e3Style['sprint-emergency-info']} ${e3Style['sprint-account_form_items']} ${e3Style['mt-0']}`}>
					{ locationList && (locationList.length > 0) ?
						<div className={e3Style['scrollable-div']}>
						{locationList.map(locationInfo => {
							return (
								<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
									<div className={`e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['col-md-10']} ${e3Style['display-flex']}`}>
										<label className={e3Style['text-uppercase']}>{t('flows.addresses.addresses.5')}</label>
										<label className={e3Style['input--fluid']}>{locationInfo.ADDRESS_NAME}</label>
									</div>
									<div className={`e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['col-md-10']} ${e3Style['display-flex']}`}>
										<label className={e3Style['text-uppercase']}>{t('flows.addresses.addresses.6')}</label>
										<label className={e3Style['input--fluid']}>{locationInfo.ADDRESS_LINE_1}</label>
									</div>
									<div className={`e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['col-md-4']} ${e3Style['display-flex']}`}>
										{ locationInfo.ADDRESSID < 0 ?
											<>
											<a href="#" className={`e3Style['button--link']} ${e3Style['sprint-remove_item']}`} 
											name={'editLocation-'+locationInfo.ADDRESSID.toString()} onClick={gotoEdit}>
											{t('flows.addresses.addresses.8')}</a>
											</>
											:
											<>
											<a href="#" className={`e3Style['button--link']} ${e3Style['sprint-remove_item']}`} 
											name={'editLocation-'+locationInfo.ADDRESSID.toString()} onClick={gotoEdit}>
											{t('button.edit')}</a>
											{ curLocId !== locationInfo.ADDRESSID &&
											<a href="#" className={`e3Style['button--link']} ${e3Style['sprint-remove_item']}`} 
											name={'delLocation-'+locationInfo.ADDRESSID.toString()} onClick={gotoEdit}>
											{t('button.delete')}</a>
											}
											</>
										}
									</div>
								</div>
							);
						})}
						</div>
						:
						<>
						</>
					}
				</fieldset>

				<div className={`${e3Style.row}`}>
				<div className={`e3Style['e3Style.colStyle']} ${e3Style['col-xs-24']} ${e3Style['sprint-form-next']} ${e3Style['display-flex']}`}>
					<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}
					onClick={addAddress}>{t('button.addaddress')}</button>
				</div>
				</div>

				</form>
				</>
				:
				<>
				</>
			}
		</div>
		</>
	);
}

export default Locations;