import 'react-i18next';
import './services/i18n';
import 'react-responsive-modal/styles.css';

import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import configureStore from "./store/configureStore";

const store = configureStore();
const root = ReactDOMClient.createRoot(document.getElementById('app'));

root.render(
	<React.Suspense fallback="SpinnerMain...">
		<Provider store={store}>
			<App />
		</Provider>
	</React.Suspense>
)
