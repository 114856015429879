import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import FocusWrappedCpt from "./focusWrappedCpt";

const Fwc = FocusWrappedCpt(forwardRef(({onEnter, onKeyDown, ...iProps}, myRef) => {
	const myKeyDown = e => {
		if (typeof onEnter === 'function' && e.key === 'Enter') onEnter(e);
		if (typeof onKeyDown === 'function') onKeyDown(e);
	};

	return <textarea ref={myRef} onKeyDown={myKeyDown} {...iProps}/>;
}));

const FocusWrappedTextarea = forwardRef((props, cptRef) => {
	const ref = useRef();
	useImperativeHandle(cptRef, () => ref.current, [ref]);
	const [doInitFocus, setDoInitFocus] = useState(true);
	return <Fwc ref={ref} doInitFocus={doInitFocus} setDoInitFocus={setDoInitFocus} {...props}/>;
});

FocusWrappedTextarea.defaultProps = {
	__FOCUS_MANAGED: true
};

export default FocusWrappedTextarea;
