import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiDoCall } from "./api";
import {setStatusMsg, STATUS_ERROR} from "./bodyView";
import {logoutUser, setUserState} from "./user";
import {resetDisplayPrefs, setDisplayPrefs} from "./display";

export const USER_ACTION_NO_ACTION = -1;
export const USER_ACTION_READY = 0;
export const USER_ACTION_DIALING = 1;
export const USER_ACTION_END_CALL = 2;
export const USER_ACTION_DONE = 3;

export const NO_ERROR = 0;
export const ACTIVE_CALL_IN_OTHER_TAB = 1;
export const NETWORK_POLLING_PROBLEM = 2;
export const USER_NOT_LOGGED_IN = 3;
export const MAX_CALLS_ERROR = 4;
export const INVALID_PHONE_NBR = 5;
export const CALL_TIMEOUT = 6;
export const NETWORK_ERROR = 7;
export const SYSTEM_BUSY = 8;

const slice = createSlice({
	name: "call",
	initialState: {
		actionState: USER_ACTION_NO_ACTION,
		callPhoneNumber: "",
		userPhoneNumber: "",
		dialingInstr: "",
		backfill: false,
		calldone: false,
		enablePolling: false,
		extCallInProg: false,
		callErrorStatus: NO_ERROR,
		startTime: null,
		endTime: null,
		initialized: false
	},
	reducers: {
		setCallState: (call, action) => {
			call.actionState = action.payload.response.response.inCall ? USER_ACTION_END_CALL : USER_ACTION_READY;
			call.callPhoneNumber = action.payload.response.response.callPhoneNumber;
			call.userPhoneNumber = action.payload.response.response.userPhoneNumber;
			call.enablePolling = action.payload.response.response.enablePolling;
			call.extCallInProg = action.payload.response.response.inCall;
			call.initialized = true;
		},
		setCallStateFromPhone: (call, action) => {
			call.actionState = USER_ACTION_READY;
			call.userPhoneNumber = action.payload.custom.phnum;
			call.initialized = call.enablePolling = true;
		},
		resetCallState: call => {
			call.actionState = USER_ACTION_NO_ACTION;
			call.callPhoneNumber = call.userPhoneNumber = call.dialingInstr = "";
			call.initialized = call.backfill = call.calldone = call.enablePolling = call.extCallInProg = false;
			call.callErrorStatus = NO_ERROR;
			call.startTime = call.endTime = null;
		},
		clrCallState: call => {
			call.callPhoneNumber = call.dialingInstr = "";
			call.backfill = call.calldone = false;
			call.callErrorStatus = NO_ERROR;
			call.startTime = call.endTime = null;
		},
		setCallError: (call, action) => {
			call.callErrorStatus = action.payload.custom.callErrorStatus;
			call.actionState = USER_ACTION_DONE;
		},
		setInCallRdcr: (call, action) => {
			if (call.extCallInProg !== action.payload.custom.inCall) {
				if (call.extCallInProg) {
					call.extCallInProg = false;
					call.actionState = USER_ACTION_DONE;
					call.endTime = Date.now();
				} else {
					call.extCallInProg = true;
					call.actionState = USER_ACTION_END_CALL;
					call.startTime = Date.now();
				}
			}
		},
		setEmptyCallRdcr: call => {
			call.extCallInProg = false;
			call.actionState = USER_ACTION_DONE;
			call.startTime = call.endTime = Date.now();
		},
		setRecvPhoneRdcr: (call, action) => {
			call.extCallInProg = true;
			call.actionState = USER_ACTION_END_CALL;
			call.callPhoneNumber = action.payload.custom.callPhoneNumber;
			call.dialingInstr = action.payload.custom.dialingInstr;
			call.startTime = Date.now();
		},
		setActionStateRdcr: (call, action) => {
			call.actionState = action.payload.custom.actionState;
		},
		setDialingRdcr: (call, action) => {
			call.actionState = USER_ACTION_DIALING;
			call.callPhoneNumber = action.payload.custom.callPhoneNumber;
			call.dialingInstr = action.payload.custom.dialingInstr;
		},
		setDialingBackfillRdcr: (call, action) => {
			call.actionState = USER_ACTION_DIALING;
			call.callPhoneNumber = action.payload.custom.callPhoneNumber;
			call.dialingInstr = action.payload.custom.dialingInstr;
			call.backfill = true;
		},
		resetBackfillRdcr: call => {
			call.backfill = false;
		},
		setCalldoneRdcr: call => {
			call.actionState = USER_ACTION_READY;
			call.calldone = true;
		},
		resetCalldoneRdcr: call => {
			call.calldone = false;
		}
	}
});

export const {setCallState, setCallStateFromPhone, setRecvPhoneRdcr, resetCallState, clrCallState, setCallError, setInCallRdcr,
	setEmptyCallRdcr, setActionStateRdcr, setDialingRdcr, setDialingBackfillRdcr, resetBackfillRdcr,
	setCalldoneRdcr, resetCalldoneRdcr} = slice.actions;
export default slice.reducer;

export const queryCallData = (dispatch, userIdAndAdmin) => {
	if (userIdAndAdmin.userId !== null && !userIdAndAdmin.adminId) {
		return dispatch(
			apiDoCall({
				url: '/nologin/rgpProxy',
				data: {
					ProxyCommand: "getCallInfo"
				},
				onSuccess: [{type: setCallState.type}, {type: setUserState.type}, {type: setDisplayPrefs.type}],
				onError: [{type: setStatusMsg.type, preset: {statusMsg: 'generic.rgp.error', statusDisplay: STATUS_ERROR}}, () => logoutUser(dispatch)]
			})
		);
	} else {
		return dispatch([{type: resetCallState.type}, {type: resetDisplayPrefs.type}]);
	}
};

export const setUserPhoneNum = (dispatch, phnum) => {dispatch({type: setCallStateFromPhone.type, payload: {custom: {phnum}}})};
export const setInCallState = (dispatch, inCall) => {dispatch({type: setInCallRdcr.type, payload: {custom: {inCall}}})};
export const setEmptyCallState = dispatch => {dispatch({type: setEmptyCallRdcr.type})};
export const setRecvPhoneState = (dispatch, callPhoneNumber) => {dispatch({type: setRecvPhoneRdcr.type, payload: {custom: {callPhoneNumber}}})};
export const setCallActionState = (dispatch, actionState) => {dispatch({type: setActionStateRdcr.type, payload: {custom: {actionState}}})};
export const setCallErrorState = (dispatch, callErrorStatus) => {dispatch({type: setCallError.type, payload: {custom: {callErrorStatus}}})};
export const setDialingActionState = (dispatch, {callPhoneNumber, dialingInstr}) => {dispatch({type: setDialingRdcr.type, payload: {custom: {callPhoneNumber, dialingInstr}}})};
export const setDialingBackfillState = (dispatch, {callPhoneNumber, dialingInstr}) => {dispatch({type: setDialingBackfillRdcr.type, payload: {custom: {callPhoneNumber, dialingInstr}}})};
export const resetBackfillState = dispatch => {dispatch({type: resetBackfillRdcr.type})};
export const setCalldoneState = dispatch => {dispatch({type: setCalldoneRdcr.type})};
export const resetCalldoneState = dispatch => {dispatch({type: resetCalldoneRdcr.type})};
export const clearCallState = dispatch => {dispatch({type: clrCallState.type})};

export const getCallState = createSelector(
	state => state.entities.call,
	call => call
);

export const getCallInProgSel = createSelector(
	state => state.entities.call.extCallInProg,
	inCall => inCall
);

export const getCallTime = createSelector(
	state => state.entities.call.startTime,
	state => state.entities.call.endTime,
	(startTime, endTime) => ({startTime, endTime})
);

export const isExtCallInProg = call => call.extCallInProg;

export const isPollingEnabled = call => call.enablePolling;

export const getActionState = call => call.actionState;

export const getCallErrorStatus = call => call.callErrorStatus;
