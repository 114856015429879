import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getCallTime} from "../../../store/call";
import sprStyle from '../../../../css/sprint-style.css';

const zeroTime = "00:00:00";

const CallTimer = () => {
	const [timeStr, setTimeStr] = useState(zeroTime);
	const callTime = useSelector(getCallTime);
	const setElapsedTime = (startTime, currTime) => {
		const elTime = (currTime - startTime + 500) / 1000;
		let callSecs = Math.trunc(elTime % 60).toString();
		if (callSecs.length !== 2) callSecs = '0' + callSecs;
		let callMins = (Math.trunc(elTime / 60) % 60).toString();
		if (callMins.length !== 2) callMins = '0' + callMins;
		let callHrs = Math.trunc(elTime / 3600).toString();
		if (callHrs.length !== 2) callHrs = '0' + callHrs;
		setTimeStr(callHrs + ':' + callMins + ':' + callSecs);
	};
	useEffect(() => {
		const startTime = callTime.startTime;
		const endTime = callTime.endTime;
		if (startTime !== null) {
			if (endTime === null) {
				const timer = setInterval(() => setElapsedTime(startTime, Date.now()), 1000);
				return () => clearInterval(timer);
			} else {
				setElapsedTime(startTime, endTime);
			}
		} else {
			setTimeStr(zeroTime);
		}
	}, [callTime]);

	return !!callTime.startTime && <p className={sprStyle['call-status']} role="timer">{timeStr}</p>;
};

export default CallTimer;