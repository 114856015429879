import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import StatusMsg from "../../../util/statusMsg";
import {useDispatch, useSelector} from "react-redux";
import FocusWrappedGrp from "../../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../../util/focusWrappedLabel";
import FocusWrappedInput from "../../../util/focusWrappedInput";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";
import {loginPath} from "../../../layout/body";

const changeLandline = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [phoneError, setPhoneError] = useState(false);
	const [phoneNbr, setPhoneNbr] = useState('');

	useEffect(() => {getUserInfo()}, []);

	const getUserInfo = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'getUserInfo', csrfSessionNonce}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						const tddPhone = data.response.TDDPhoneNumber;
						if (tddPhone) setPhoneNbr(formatPhoneNbr(tddPhone, ''));
						break;

					case 998:
						setHistoryPath(dispatch, navigate, loginPath);
						break;

					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const saveLandlineNumber = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'adminChangeLandlineNbr', csrfSessionNonce},
				 params: {
							phNbr: phoneNbr.replace(/[^\d]/g, '')
						}
				}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setStatusMessage(dispatch, 'flows.admin.changelandline.phonenum.phnbrChanged', STATUS_ERROR);
						break;
					case 402:
						setStatusMessage(dispatch, 'flows.admin.changelandline.phonenum.invalidParamError', STATUS_ERROR);
						break;
					case 406:
						setStatusMessage(dispatch, 'flows.admin.changelandline.phonenum.invalidPhNbrError', STATUS_ERROR);
						break;
					case 414:
						setStatusMessage(dispatch, 'flows.admin.changelandline.phonenum.nbrExistsErr', STATUS_ERROR);
						break;
					case 428:
						setStatusMessage(dispatch, 'flows.admin.changelandline.phonenum.invalidAcctStateError', STATUS_ERROR);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};

	const formatPhoneNbr = (value, previousValue) => {
	  	if (!value) return value;
	  	const currentValue = value.replace(/[^\d]/g, '');
	  	const cvLength = currentValue.length;
  
		if (!previousValue || value.length > previousValue.length) {
		    if (cvLength < 4) return currentValue;
		    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
			if (cvLength < 11)
		    	return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
			else
				return currentValue;
	  	}
	};

	const submitChg = e => {
		e.preventDefault();
		phoneError ? setStatusMessage(dispatch, 'flows.admin.changelandline.phonenum.invalidPhNbrError', STATUS_ERROR) : saveLandlineNumber();
	};

	return(
		<>
			<StatusMsg/>
			<form noValidate autoComplete="off">
				<fieldset>
					<div className={e3Style.row}>
						<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}>
							<FocusWrappedGrp>
								<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="phoneNumber">
									{t('flows.admin.changelandline.phonenum.title')}</FocusWrappedLabel>
								<FocusWrappedInput type="text" name="phoneNumber" className={`${e3Style['input--fluid']} ${e3Style['phone-number-input']}`} pattern="\d*" maxLength="14" value={phoneNbr} placeholder="(000)123-4567"
												   onChange={e => {
													   setPhoneNbr(formatPhoneNbr(e.target.value, ''));
													   const thisValue = e.target.value.replace(/[^\d]/g, '');
													   setPhoneError(thisValue.trim().length !== 10);}}/>
							</FocusWrappedGrp>
						</div>
					</div>
				</fieldset>

				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
					<button type="submit" onClick={submitChg} className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>
						{t('flows.admin.changelandline.phonenum.button')} </button>
					</div>
				</div>
			</form>
		</>
	);
};

export default changeLandline;
