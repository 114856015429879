import {useEffect} from "react";
import {useLocation} from "react-router";

const ScrollToTop = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}), 20);
	}, [pathname]);
	return null;
};

export default ScrollToTop;
