import React from 'react';
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {setHistoryPath} from "../../../store/bodyView";
import {useDispatch} from "react-redux";
import {rootPath} from "../../layout/body";

const EmailVerifyMessage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const goHome = () => {
		setHistoryPath(dispatch, navigate, rootPath);
	}
	
	return (
		<>
			<h1 className={e3Style['pt-0']}>{t('flows.registration.veremail.1')}</h1>
			<form>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']}`}>{t('flows.registration.veremail.2')}</div>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="button" onClick={goHome} className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>{t('button.ok')}</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default EmailVerifyMessage;
