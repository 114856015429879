import React, {useState} from 'react';
import e3Style from "../../../css/e3-style.css";
import sprStyle from "../../../css/sprint-style.css";
import {useTranslation} from "react-i18next";
import StatusMsg from "../util/statusMsg";
import FocusWrappedGrp from "../util/focusWrappedGrp";
import FocusWrappedLabel from "../util/focusWrappedLabel";
import FocusWrappedTextarea from "../util/focusWrappedTextarea";
import {updateActTime} from "../../store/activity";
import axios from "axios";
import {setHistoryPath, setStatusMessage, STATUS_ERROR, STATUS_NONE} from "../../store/bodyView";
import {adminLoginPath} from "../layout/body";
import {closePopup, suspendUserSel} from "../../store/popupMgr";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getCsrfSessionNonce} from "../../store/user";
import ModalWrapper from "./modalWrapper";
import {setUserSuspended} from "../../store/userExtraData";

const getTextTags = suspend => {
	 return suspend ?
		['page.popup.suspusr', 'page.popup.suspusrmsg', 'page.popup.suspusrfrm', 'page.popup.suspusrdesc'] :
		['page.popup.unsuspusr', 'page.popup.unsuspusrmsg', 'page.popup.unsuspusrfrm', 'page.popup.unsuspusrdesc'];
};

const SuspendUser = ({suspend}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [reason, setReason] = useState("");
	const textTags = getTextTags(suspend);

	const suspendClick = e => {
		e.preventDefault();
		doSuspend();
	};

	const doSuspend = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'adminSuspend', csrfSessionNonce}, params: {suspend, description:reason}}, {timeout: 5000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
						setUserSuspended(dispatch, suspend);
						setStatusMessage(dispatch, '', STATUS_NONE);
						break;
					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
		closePopup(dispatch);
	};

	const cancelClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h3>{t(textTags[0])}</h3>
			<p>{t(textTags[1])}</p>
			<form name="suspUsrForm" role="form" aria-label={t(textTags[2])}>
				<StatusMsg/>
				<FocusWrappedGrp>
					<FocusWrappedLabel className={`${e3Style['text-uppercase']} ${e3Style['mt-10']}`} htmlFor="suspUsrDesc">
						{t(textTags[3])}
					</FocusWrappedLabel>
					<FocusWrappedTextarea initialFocus name="suspUsrDesc" id="suspUsrDesc"
					                      className={sprStyle['add-ovrd-intv']} placeholder={t(textTags[3])} rows="4" cols="64"
					                      onChange={e => setReason(e.target.value )} value={reason}/>
				</FocusWrappedGrp>
				<div className={e3Style['sprint--modal-submit']}>
					<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        disabled={reason.trim().length === 0} onClick={suspendClick}>
						{t('button.submit')}
					</button>
					<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        onClick={cancelClick}>
						{t('button.cancel')}
					</button>
				</div>
			</form>
		</>
	);
};

const ModalWindow = ModalWrapper(SuspendUser);

const SuspendUserModel = () => {
	const suSel = useSelector(suspendUserSel);
	return <ModalWindow {...suSel}/>;
};

export default SuspendUserModel;
