import React from 'react';
import {chooseUser} from "../../../../store/user";
import {setHistoryPath} from "../../../../store/bodyView";
import {adminSelectUserUserListPath, rootPath} from "../../../layout/body";
import QueryUserGeneric from "./queryUserGeneric";

const searchObj = {
	pgTxt: {
		title: 'prefs.adminhome.select.user',
		phoneNumLabel: 'flows.admin.selectuser.query.phonenum',
		userIdLabel: 'flows.admin.selectuser.query.userid',
		emailLabel: 'flows.admin.selectuser.query.email',
		firstnameLabel: 'flows.admin.selectuser.query.firstname',
		lastnameLabel: 'flows.admin.selectuser.query.lastname'
	},
	searchUserPhoneNum: (phoneNum, dispatch, navigate, csrfSessionNonce) => {
		chooseUser(dispatch, navigate,'adminSearchUserPhoneNum',{phoneNum}, rootPath, csrfSessionNonce,
			() => {},() => {});
	},
	searchUserId: (userId, dispatch, navigate, csrfSessionNonce) => {
		chooseUser(dispatch, navigate,'adminSearchUserById',{userId}, rootPath, csrfSessionNonce,
			() => {},() => {});
	},
	searchUserEmail: (email, dispatch, navigate, csrfSessionNonce) => {
		chooseUser(dispatch, navigate,'adminSearchUserEmail',{email}, rootPath, csrfSessionNonce,
			() => {},() => {});
	},
	searchUserFullname: (firstName, lastName, dispatch, navigate) => {
		let first = firstName;
		let last = lastName;
		let nametype = 'fullname';
		if (!first.length) {
			first = 'any';
			nametype = 'lastonly';
		} else if (!last.length) {
			last = 'any';
			nametype = 'firstonly';
		}
		setHistoryPath(dispatch, navigate, adminSelectUserUserListPath + `/${nametype}/${encodeURIComponent(first)}/${encodeURIComponent(last)}`);
	}
};

const QueryUser = () => {
	return <QueryUserGeneric searchObj={searchObj}/>;
};

export default QueryUser;
