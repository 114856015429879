import React, {useEffect, useState} from 'react';
import e3Style from '../../../../../css/e3-style.css';
import sprStyle from '../../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import StatusMsg from "../../../util/statusMsg";
import {adminLoginPath} from "../../../layout/body";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../../store/bodyView";
import {openWarnDelUser} from "../../../../store/popupMgr";
import axios from "axios";
import {useNavigate} from "react-router";
import {getCsrfSessionNonce} from "../../../../store/user";
import {updateActTime} from "../../../../store/activity";

const DeleteUser = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [deleteUserInfo, setDeleteUserInfo] = useState({customerId:null});

	useEffect(() => {
		getDeleteUserInfo();
	}, []);

	const getDeleteUserInfo = async () => {
		try {
			if(deleteUserInfo.customerId == null) {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'getDeleteUserModel', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
						    setDeleteUserInfo(data.response);
						  break;
						case 998:
							setHistoryPath(dispatch, navigate, adminLoginPath);
							break;
						default:
							setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} 
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
	};
	
	const openPopup = () => {
		console.log("before customerId="+deleteUserInfo.customerId);
		openWarnDelUser(dispatch, deleteUserInfo.customerId)
	};

	return (
		<>
			<StatusMsg/>

			<form noValidate autoComplete="off">
				<h2>{t('prefs.admin.button.delete.user')}</h2>
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
				{ deleteUserInfo.customerId != null  &&
					<>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.userid')}
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{deleteUserInfo.userId}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.firstname')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{deleteUserInfo.firstName}</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>
							{t('prefs.admin.validate.lastname')}:
						</label>
					</div>
					<div className={`${e3Style.colStyle} ${e3Style['col-md-16']} ${e3Style['display-flex']}`}>
						<label className={e3Style['input--fluid']}>{deleteUserInfo.lastName}</label>
					</div>
					</>
					}
				</div>
				<p className={sprStyle['std-txt-bigger']}>{ deleteUserInfo.customerId != null ? t('prefs.admin.deleteuser.warnmsg') : ''}</p>
				{ deleteUserInfo.customerId != null &&
				<div className={`${e3Style.row} ${e3Style['sprint-account_form_item']}`}>
					<div className={`${e3Style['col-md-6']} ${e3Style['display-flex']}`}>
						<button type="button" autoFocus onClick={e => {e.preventDefault();openPopup();}}
						 className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['ma-40']}`}>{t('prefs.admin.button.delete.user')}</button>
					</div>
				</div>
				}
			</form>
		</>
	);
};

export default DeleteUser;
