const dateFormat = /^(\d{1,2})\s*[/-]\s*(\d{1,2})\s*[/-]\s*(\d{4})$/;

const dobValidator = (dob, helpers) => {
	const match = dateFormat.exec(dob);
	if (!match) return helpers.error('any.invalid');
	const year = match[3];
	// At this moment, the oldest officially confirmed living person is Kane Tanaka from Fukuoka, Japan,
	// born January 2, 1903. So we are going to establish 1903 as the oldest DOB we will accept.
	if (year < 1903) return helpers.error('any.invalid');
	const bDay = new Date(year, match[1], match[2]);
	return bDay < Date.now() ? dob : helpers.error('any.invalid');
};

export default dobValidator;
