const delayCmdMgr = (delay, callback) =>
	(() => {
		const cmdData = {
			timerId: null,
			startTime: null
		};

		return {
			start: (state, dispatch) => {
				const doCmd = () => {
					cmdData.timerId = null;
					cmdData.startTime = Date.now();
					callback(state, dispatch);
				};
				if (delay) cmdData.timerId = setTimeout(doCmd, delay);
				else doCmd();
			},
			getNextDelay: (delay, minDelay) => {
				if (delay && cmdData.startTime) {
					const nextDelay = delay + Date.now() - cmdData.startTime;
					return minDelay ? (nextDelay > minDelay ? nextDelay : minDelay) : nextDelay;
				} else {
					return delay ?? minDelay;
				}
			},
			cancel: () => {
				if (cmdData.timerId) {
					clearTimeout(cmdData.timerId);
					cmdData.timerId = null;
					return true;
				}
				return false;
			}
		};
	})();

export default delayCmdMgr;
