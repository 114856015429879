import {useTranslation} from "react-i18next";
import {useState} from 'react';
import ModalWrapper from "./modalWrapper";
import e3Style from "../../../css/e3-style.css";
import sprStyle from "../../../css/sprint-style.css";
import {closePopup, overrideIntrvSel} from "../../store/popupMgr";
import {adminLoginPath} from "../layout/body";
import {setHistoryPath, setStatusMessage, STATUS_ERROR, STATUS_NOTIFICATION} from "../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import axios from "axios";
import React from "react";
import FocusWrappedGrp from "../util/focusWrappedGrp";
import FocusWrappedLabel from "../util/focusWrappedLabel";
import FocusWrappedTextarea from "../util/focusWrappedTextarea";
import {getCsrfSessionNonce} from "../../store/user";
import {updateActTime} from "../../store/activity";

const OverrideIntrv = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	const [reason, setReason] = useState("");

	const overrideClick = e => {
		e.preventDefault();
		doOverride();
	};

	const doOverride = async () => {
		try {
			updateActTime(dispatch);
			const response = await axios.post('/nologin/api',
				{command: {name: 'adminOverrideIntrvs', csrfSessionNonce}, params: {description:reason}}, {timeout: 10000});
			if (response.status === 200) {
				const {data} = response;
				switch (data.status.code) {
					case 200:
					    setStatusMessage(dispatch, 'flows.admin.interventions.labels.10', STATUS_NOTIFICATION);
					    break;
					case 998:
						setHistoryPath(dispatch, navigate, adminLoginPath);
						break;
					default:
						setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
						break;
				}
			} else {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		} catch (error) {
			setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
		}
		closePopup(dispatch);
	};

	const cancelClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h3>{t('page.popup.ovrdintv')}</h3>
			<p>{t('page.popup.ovrdintvmsg')}</p>
			<form name="ovrdIntvForm">
				<FocusWrappedGrp>
					<FocusWrappedLabel className={`${e3Style['text-uppercase']} ${e3Style['mt-10']}`} htmlFor="ovrdIntvDesc">
						{t('page.popup.ovrdintvdesc')}</FocusWrappedLabel>
					<FocusWrappedTextarea initialFocus name="ovrdIntvDesc" id="ovrdIntvDesc"
					                      className={sprStyle['add-ovrd-intv']} placeholder={t('page.popup.ovrdintvdesc')} rows="4" cols="64"
					                      onChange={e => setReason(e.target.value )} value={reason}/>
				</FocusWrappedGrp>
				<div className={e3Style['sprint--modal-submit']}>
					<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        disabled={reason.trim().length === 0} onClick={overrideClick}>
						{t('button.submit')}
					</button>
					<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
					        onClick={cancelClick}>
						{t('button.cancel')}
					</button>
				</div>
			</form>
		</>
	);
};

const ModalWindow = ModalWrapper(OverrideIntrv);

const OverrideIntrvModal = () => {
	const {isOpen} = useSelector(overrideIntrvSel);
	return <ModalWindow isOpen={isOpen}/>;
};

export default OverrideIntrvModal;
