import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getLoggedInSel} from "../../store/user";
import e3Style from "../../../css/e3-style.css";
import ModalWrapper from "./modalWrapper";
import {closePopup, contactCustCareSel} from "../../store/popupMgr";
import {dialPhNum} from "../flows/root/call";
import {useNavigate} from "react-router";
import {setHistoryPath} from "../../store/bodyView";
import {rootPath} from "../layout/body";
import {getCallState} from "../../store/call";
import useAutoFocus from "../util/useAutoFocus";

export const custCareNum = "8006763777";

const ContactCustCare = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loggedIn = useSelector(getLoggedInSel);
	const callData = useSelector(getCallState);
	const aFocus = useAutoFocus();

	const callCustCare = e => {
		e.preventDefault();
		dialPhNum(custCareNum, "", dispatch, callData);
		closePopup(dispatch);
		setHistoryPath(dispatch, navigate, rootPath);
	};

	const cancelClick = e => {
		e.preventDefault();
		closePopup(dispatch);
	};

	return (
		<>
			<h3>{t('page.popup.ctccare')}</h3>
			{loggedIn ?
				<>
					<p>{t('page.popup.ctccaremsg')} <a href="mailto:access@t-mobile.com" target="_top">access@t-mobile.com</a>.</p>
					<div className={e3Style['sprint--modal-submit']}>
						<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-md']} ${e3Style['button--lg']} ${e3Style['mt-10']}`} onClick={cancelClick}>{t('button.cancel')}</button>
						<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--lg']} ${e3Style['mt-10']}`} onClick={callCustCare} ref={aFocus}>{t('button.call')}</button>
					</div>
				</>
			:
				<>
					<p>{t('page.popup.ctccarenologmsg')} <a href="mailto:access@t-mobile.com" target="_top">access@t-mobile.com</a>.</p>
					<div className={e3Style['sprint--modal-submit']}>
						<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-md']} ${e3Style['button--lg']} ${e3Style['mt-10']}`} onClick={cancelClick} ref={aFocus}>{t('button.ok')}</button>
					</div>
				</>
			}
		</>
	);
};

const ModalWindow = ModalWrapper(ContactCustCare);

const ContactCustCareModal = () => {
	const {isOpen} = useSelector(contactCustCareSel);
	return <ModalWindow isOpen={isOpen}/>;
};

export default ContactCustCareModal;
