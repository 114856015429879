import React, {useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import e3Style from "../../../css/e3-style.css";
import ModalWrapper from "./modalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {closePopup, warnInactivityOpenSel} from "../../store/popupMgr";
import useAutoFocus from "../util/useAutoFocus";

const WarnInactivity = ({setCloseFunc}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {callback} = useSelector(warnInactivityOpenSel);
	const logoutRef = useRef(true);
	const aFocus = useAutoFocus();
	const wiClick = logout => e => {
		e.preventDefault();
		logoutRef.current = logout;
		closePopup(dispatch);
	};
	useEffect(() => {setCloseFunc(() => {callback(logoutRef.current)})}, [setCloseFunc, callback]);
	return (
		<>
			<h3>{t('page.popup.inactivity')}</h3>
			<p>{t('page.popup.inactmsg')}</p>
			<div className={e3Style['sprint--modal-submit']}>
				<button type="button" className={`${e3Style.button} ${e3Style['button--secondary']} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={wiClick(true)}>{t('button.logout')}</button>
				<button type="button" className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']}`}
				        onClick={wiClick(false)} ref={aFocus}>{t('button.stayloggedin')}</button>
			</div>
		</>
	);
};

const ModalWindow = ModalWrapper(WarnInactivity);

const WarnInactivityModal = () => {
	const wiSel = useSelector(warnInactivityOpenSel);
	return <ModalWindow {...wiSel}/>;
};

export default WarnInactivityModal;
