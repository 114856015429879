import {createSlice} from "@reduxjs/toolkit";
import {createSelector} from "reselect";

const slice = createSlice({
	name: "activity",
	initialState: {
		activityTime: Date.now()
	},
	reducers: {
		updActTime: state => {
			state.activityTime = Date.now();
		}
	}
});

export const {updActTime} = slice.actions;
export default slice.reducer;

export const updateActTime = dispatch => {dispatch({type: updActTime.type})};

export const getActTime = createSelector(
	state => state.entities.activity.activityTime,
	time => time
);
