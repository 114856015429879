import React, {useEffect, useRef} from "react";
import e3Style from '../../../../css/e3-style.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {accountListPath, relayVerifDataPath} from "../../layout/body";
import {setAutoFocusFunc, setHistoryPath} from "../../../store/bodyView";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";

const NoNum = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const afRef = useRef(null);

	useEffect(() => {
		setAutoFocusFunc(dispatch, () => afRef.current.focus());
	}, []);

	return (
		<main role="main" aria-label="main content">
			<div className={e3Style['container-xl']}>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-24']}`}>
						<h1>{t('page.home.1')}</h1>
						<p>{t('page.home.2')}</p>
						<p>
							{t('page.home.5')}
						</p>
						<button type="button" onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, relayVerifDataPath)}}
						        className={`${e3Style.button} ${e3Style['button--minwidth-xl']} ${e3Style['button--lg']} ${e3Style['mt-40']} ${e3Style['mb-80']}`}>
							{t('button.getIPNumber')}
						</button>
						<button type="button" autoFocus onClick={e => {e.preventDefault();setHistoryPath(dispatch, navigate, accountListPath)}}
						        className={`${e3Style.button} ${e3Style['button--minwidth-xl']} ${e3Style['button--lg']} ${e3Style['mt-40']} ${e3Style['mb-80']} ${e3Style['ml-10']}`}>
							{t('page.header.account')}
						</button>
					</div>
				</div>
			</div>
		</main>
	);
};

export default NoNum;
