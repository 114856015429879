import React, {Children, cloneElement, isValidElement, useLayoutEffect, useRef, useState} from "react";
import e3Style from "../../../css/e3-style.css";

const FocusWrappedGrp = ({cptErrCount, baseAriaId, children}) => {
	const cptRef = useRef();
	const labelRef = useRef();
	const refocRef = useRef(true);
	const [ariaDescBy, setAriaDecBy] = useState(undefined);

	useLayoutEffect(() => {
		if (cptErrCount) {
			if (refocRef.current) {
				refocRef.current = false;
				cptRef?.current?.focus();
			}
			cptRef?.current?.addClass(e3Style['sprint-form-error']);
			labelRef?.current?.classList?.add(e3Style['sprint-form-error']);
			let adb = '';
			for (let i=0;i<cptErrCount;++i) {
				adb += (i ? ' ' : '') + baseAriaId + '-' + i;
			}
			setAriaDecBy(adb);
		} else {
			setAriaDecBy(undefined);
			cptRef?.current?.removeClass(e3Style['sprint-form-error']);
			labelRef?.current?.classList?.remove(e3Style['sprint-form-error']);
			refocRef.current = true;
		}
	}, [cptErrCount]);

	const childrenWithProps = Children.map(children, child => {
		if (isValidElement(child)) {
			if (child?.props?.__FOCUS_MANAGED) {
				return cloneElement(child, {
					"aria-describedby": ariaDescBy,
					ref: cptRef,
					labelRef
				});
			} else if (child?.props?.__WRAPPED_LABEL) {
				return cloneElement(child, {
					ref: labelRef
				});
			}
		}
		return child;
	});

	return <>{childrenWithProps}</>;
};

export default FocusWrappedGrp;
