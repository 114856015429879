import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiDoCall } from "./api";
import {setStatusMsg, STATUS_ERROR, setViewItem, STATUS_NONE} from "./bodyView";

const slice = createSlice({
	name: "notesList",

	initialState: {
		notes: null,
		refreshCount: 0
	},
	reducers: {
		loadList: (notesList, action) => {
			notesList.notes = action.payload.preset;
			notesList.refreshCount++;
		},
		
		createNotes: (notesList, action) => {
			if(notesList.notes === null)
				notesList.notes.push(action.payload.preset);
			else{
				const insertIdx = notesList.notes.findIndex(function(value){ 
			        return value.ORDERID > action.payload.preset.ORDERID;
			    });
				if(insertIdx < 0)
					notesList.notes.push(action.payload.preset);
				else
					notesList.notes.splice(insertIdx,0,action.payload.preset);
			}
		},

		updateNotes: (notesList, action) => {
			notesList.notes = notesList.notes.map( thisNbr => 
			thisNbr.ORDERID === action.payload.preset.ORDERID ? action.payload.preset:thisNbr);
			notesList.refreshCount++;
		},
		
		deleteNote: (notesList, action) => {
			notesList.notes = notesList.notes.filter(function(value){ 
		        return value.ORDERID != action.payload.preset;
		    });
		},
		
		setRefresh: (notesList, action) => {
			notesList.refreshCount++;
		}

	}
});

export const {
	loadList,
	createNotes,
	updateNotes,
	deleteNote,
	setRefresh
} = slice.actions;
export default slice.reducer;

export const setNoteList = (dispatch, newList) => {
	dispatch({type: loadList.type, payload: {preset: newList}});
};

export const createNote = (dispatch, newNote) => {
	dispatch({type: createNotes.type, payload: {preset: newNote}});
};

export const updateNote = (dispatch, thisNote) => {
	dispatch({type: updateNotes.type, payload: {preset: thisNote}});
};

export const delNote = (dispatch, orderId) => {
	dispatch({type: deleteNote.type, payload: {preset: orderId}});
};

export const setRefreshCount = (dispatch, newValue) => {
	dispatch({type: setRefresh.type, payload: {preset: newValue}});
};

export const getNotes = createSelector(
	state => state.entities.notesList.notes,
	notes => (notes && notes.length) ? notes.slice(0).sort((a, b) => a.ORDERID - b.ORDERID) : notes,
);

export const getRefreshCount = createSelector(
		state => state.entities.notesList.refreshCount,
		refreshCount => refreshCount,
);


