import React from "react";
import e3Style from "../../../../css/e3-style.css";
import Spinner from "./spinner";

const SpinnerMain = () => {
	return (
		<main role="main" aria-label="main content">
			<div className={e3Style['container-xl']}>
				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-24']}`}>
						<Spinner/>
					</div>
				</div>
			</div>
		</main>
	);
};

export default SpinnerMain;
