import React, {useEffect, useState} from "react";
import e3Style from '../../../../css/e3-style.css';
import sStyle from '../../../../css/sprint-style.css';
import {useTranslation} from "react-i18next";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import {stripNonNums} from "../../util/stringFormatHelper";

const formatPhoneNbr = (value, previousValue) => {
	if (!value) return value;
	const currentValue = stripNonNums(value);
	const cvLength = currentValue.length;

	if (!previousValue || value.length > previousValue.length) {
		if (cvLength < 4) return currentValue;
		if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
		if (cvLength < 11)
			return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
		else
			return currentValue;
	}
};

const PhoneNumberItem = ({
  phoneNbrItem,
  onDeleteFunc,
  onUpdateFunc,
  onActionFunc,
  showOrderId=true
}) => {
	const { t } = useTranslation();

	const [ myPhoneNbrItem, setMyPhoneNbrItem] = useState({...phoneNbrItem,"PHONE_NUMBER":formatPhoneNbr(phoneNbrItem.PHONE_NUMBER, '')});
	const [ showDelete, setShowDelete ] = useState(true);
	const [ showDelConfirm, setShowDelConfirm ] = useState(false);
	const [ showUpdConfirm, setShowUpdConfirm ] = useState(false);
	const [ nameError, setNameError ] = useState(phoneNbrItem.NAME === null || phoneNbrItem.NAME.length === 0);
	const [ phoneError, setPhoneError ] = useState(phoneNbrItem.PHONE_NUMBER === null || phoneNbrItem.PHONE_NUMBER.length !== 10);

	const setOriginal = (thisPhoneNbrItem) => {
		setShowDelete(true);
		setShowDelConfirm(false);
		setShowUpdConfirm(false);
		setMyPhoneNbrItem( {...thisPhoneNbrItem,"PHONE_NUMBER":formatPhoneNbr(thisPhoneNbrItem.PHONE_NUMBER, '')});
		setNameError(( thisPhoneNbrItem.NAME === null || thisPhoneNbrItem.NAME.length === 0 ));
		setPhoneError(( thisPhoneNbrItem.PHONE_NUMBER === null || thisPhoneNbrItem.PHONE_NUMBER.length !== 10 ));
	}

	useEffect(() => {
		if (showDelete && !nameError && !phoneError &&
			(phoneNbrItem.NAME.trim() !== myPhoneNbrItem.NAME.trim() ||
				phoneNbrItem.PHONE_NUMBER !== stripNonNums(myPhoneNbrItem.PHONE_NUMBER)))
			setMyPhoneNbrItem({...phoneNbrItem, "PHONE_NUMBER": formatPhoneNbr(phoneNbrItem.PHONE_NUMBER, '')});
	}, [showDelete, nameError, phoneError, phoneNbrItem, myPhoneNbrItem]);

	return (
			<>
				<div className={`${e3Style.row} ${e3Style['mt-40']}`}>
				{ showOrderId &&
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-2']} ${e3Style['display-flex']}`}>
						<label className={`${e3Style['text-uppercase']}`}>{t('page.popup.id')}</label>
						<label className={`${e3Style['input--fluid']}`}>{myPhoneNbrItem.ORDERID}</label>
					</div>
				}
				{ !showOrderId &&
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-4']} ${e3Style['display-flex']}`} id="phone-number-link-container">
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();onActionFunc(myPhoneNbrItem.ORDERID)}}>{t('button.addmsg')}</a>
					</div>
				}
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-10']} ${e3Style['display-flex']}`}>
					<FocusWrappedGrp baseAriaId="nameErr" cptErrCount={nameError ? 1 : 0}>
						<FocusWrappedLabel className={`${e3Style['text-uppercase']}`}>*{t('page.popup.name')}</FocusWrappedLabel>
						<FocusWrappedInput type="text" name="phoneName" title={t('page.popup.entrname')}
						       className={`${e3Style['input--fluid']}`} value={myPhoneNbrItem.NAME}
						       placeholder={t('page.popup.name')} disabled = {showDelConfirm}
						       onChange={e => {setMyPhoneNbrItem( prevPhoneNbrItem => ({...prevPhoneNbrItem,"NAME":e.target.value}));
							       if( e.target.value.trim().length === 0 ) {
									   setNameError(true);
									   setShowUpdConfirm(false);
									   setShowDelete(true);
								   } else {
									   setNameError(false);

								       if(!phoneError) {
									       const noChange = (stripNonNums(myPhoneNbrItem.PHONE_NUMBER) === phoneNbrItem.PHONE_NUMBER) && (e.target.value.trim()===phoneNbrItem.NAME);
									       setShowUpdConfirm(!noChange);
									       setShowDelete(noChange);
								       }
							       }
						       }}/>
						{ nameError &&
							<label className={`${e3Style['input--fluid']} ${e3Style['is-invalid-label']}`} id="nameErr-0">{t('page.popup.plsentrname')}</label>
						}
					</FocusWrappedGrp>
				</div>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-8']} ${e3Style['display-flex']}`}>
					<FocusWrappedGrp baseAriaId="phNumErr" cptErrCount={phoneError ? 1 : 0}>
						<FocusWrappedLabel className={`${e3Style['text-uppercase']}`}>*{t('page.popup.phnum')}</FocusWrappedLabel>
						<FocusWrappedInput type="text" name="phoneNumber" title={t('page.popup.entrphnum')}
						                   className={`${e3Style['input--fluid']} ${e3Style['phone-number-input']}`} pattern="\d*"
						                   value={myPhoneNbrItem.PHONE_NUMBER} placeholder="(000)123-4567" disabled = {showDelConfirm}
						                   onChange={e => {setMyPhoneNbrItem(prevPhoneNbrItem => ({...prevPhoneNbrItem,"PHONE_NUMBER":formatPhoneNbr(e.target.value, '')}));
							                   const thisValue = stripNonNums(e.target.value);
							                   if( thisValue.trim().length === 10) {
								                   setPhoneError(false);
								                   if(!nameError) {
									                   const noChange = (myPhoneNbrItem.NAME.trim() === phoneNbrItem.NAME) && (thisValue.trim()===phoneNbrItem.PHONE_NUMBER);
									                   setShowUpdConfirm(!noChange);
									                   setShowDelete(noChange);
								                   }
							                   } else {
												   setPhoneError(true);
												   setShowUpdConfirm(false);
												   setShowDelete(true);
											   }
						                   }}/>
						{ phoneError &&
							<label className={`${e3Style['input--fluid']} ${e3Style['is-invalid-label']}`} id="phNumErr-0">{t('page.popup.invalphnum')}</label>
						}
					</FocusWrappedGrp>
				</div>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-4']} ${e3Style['display-flex']}`} id="phone-number-link-container">
					{ showDelete &&
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();setShowDelete(false);setShowUpdConfirm(false);setShowDelConfirm(true)}}>{t('button.delete')}</a>
					}
					{ showDelConfirm &&
						<>
						<label className={`${sStyle['confirm-delete']}`}>{t('embedded.12')}</label>
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();onDeleteFunc(phoneNbrItem.ORDERID)}}>{t('button.yes')}</a>
						</>
					}
					{ (showDelConfirm||showUpdConfirm) &&
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();setOriginal(phoneNbrItem)}}>{t('button.cancel')}</a>
					}
					{ showUpdConfirm &&
						<a href='#' className={`${e3Style['button--link']} ${e3Style['sprint-remove_item']}`} onClick={e => {e.preventDefault();onUpdateFunc({...myPhoneNbrItem});setShowUpdConfirm(false);setShowDelete(true);}}>{t('button.save')}</a>
					}
				</div>
				</div>
			</>
	);
};

export default PhoneNumberItem;