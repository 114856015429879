import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import e3Style from '../../../../css/e3-style.css';
import sStyle from '../../../../css/sprint-style.css';
import Joi from "joi";
import { useValidator } from "react-joi";
import StatusMsg from "../../util/statusMsg";
import {useNavigate} from "react-router";
import {verifyIdentBasePath} from "../../layout/body";
import {setHistoryPath, setStatusMessage, STATUS_ERROR} from "../../../store/bodyView";
import {useDispatch, useSelector} from "react-redux";
import FocusWrappedInput from "../../util/focusWrappedInput";
import FocusWrappedGrp from "../../util/focusWrappedGrp";
import FocusWrappedLabel from "../../util/focusWrappedLabel";
import {getCsrfSessionNonce} from "../../../store/user";
import {updateActTime} from "../../../store/activity";

const VerificationData = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const csrfSessionNonce = useSelector(getCsrfSessionNonce);
	
	const { state, setData, setExplicitField, validate } = useValidator({
		initialData: {
			tddPhone: "",
			advisoryRead: false,
			certifyDisability: false
		},
		schema: Joi.object({
			tddPhone: Joi.string().required().pattern(/^(?:(?:\(\s*\d{3}\s*\)|(?:\d{3}))\s*(?:-\s*)?\d{3}\s*(?:-\s*)?\d{4})$|^(?:\d{10})$/).messages({
				'string.base': 'phoneNumber.invalidFormat.error',
				'string.empty': 'phoneNumber.required.error',
				'any.required': 'phoneNumber.required.error',
				'string.pattern.base': 'phoneNumber.invalidFormat.error'
			}),
			advisoryRead: Joi.boolean().invalid(false).messages({
				'boolean.base': 'check.the.box.advisory',
				'any.invalid': 'check.the.box.advisory'
			}),
			certifyDisability: Joi.boolean().invalid(false).messages({
				'boolean.base': 'check.the.box.disability',
				'any.invalid': 'check.the.box.disability'
			})
		}),
		explicitCheck: {
			tddPhone: false,
			advisoryRead: false,
			certifyDisability: false
		}
	});

	const updateField = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.value
		}));
	};

	const updateCheckbox = (e, name) => {
		setData((old) => ({
			...old,
			[name]: e.target.checked
		}));
	};

	const submitForm = e => {
		e.preventDefault();
		setHistoryPath(dispatch, navigate, verifyIdentBasePath + `/${state.$data.tddPhone.replace(/[^\d]/g, "")}`);
	};

	useEffect(() => {

		const getContactPhone = async () => {
			try {
				updateActTime(dispatch);
				const response = await axios.post('/nologin/api',
					{command: {name: 'adminTdNumGetNPA', csrfSessionNonce}}, {timeout: 5000});
				if (response.status === 200) {
					const {data} = response;
					switch (data.status.code) {
						case 200:
							setData({
								tddPhone: data.response.phoneNumber,
								advisoryRead: false,
								certifyDisability: false
							});
							break;

						case 998:
							setHistoryPath(dispatch, navigate, loginPath);
							break;

						default:							
							break;
					}
				} else {
					setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
				}
			} catch (error) {
				setStatusMessage(dispatch, 'generic.rgp.error', STATUS_ERROR);
			}
		};
		
		getContactPhone();
	}, []);
	
	return (
		<>
			<h1 className={e3Style['pt-0']}>{t('flows.account.verificationdata.1')}</h1>
			<StatusMsg/>
			<form noValidate autoComplete="off" onSubmit={submitForm}>
				<p>{t('flows.account.verificationdata.2')}</p>

				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-12']}`}>
						<FocusWrappedGrp baseAriaId="tddPhErr" cptErrCount={state.$errors.tddPhone.length}>
							<FocusWrappedLabel className={e3Style['text-uppercase']} htmlFor="contact-phone-number">
								{t('flows.account.verificationdata.5')}
							</FocusWrappedLabel>
							<FocusWrappedInput type="tel" name="tddPhone" id="contact-phone-number"
											   disabled={true}
											   value={state.$data.tddPhone}
							                   onChange={e => updateField(e, 'tddPhone')}
							                   onBlur={() => setExplicitField('tddPhone', true)}
							                   className={e3Style['input--fluid']}
							                   placeholder="(000) 123-4567"/>
							{!!state.$errors.tddPhone.length &&
								<div className={e3Style['sprint-alert--error']}>
									<div className={e3Style['alert--error_message']}>
										{state.$errors.tddPhone.map((err, idx) => <p index={idx} id={'tddPhErr-' + idx}><span>{t(err.$message)}</span></p>)}
									</div>
								</div>
							}
						</FocusWrappedGrp>
					</div>
				</div>

				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-18']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
					<FocusWrappedGrp baseAriaId="advisErr" cptErrCount={state.$errors.advisoryRead.length}>
						<FocusWrappedInput type="checkbox" name="advisoryRead" id="advisory-agreement"
						                   onChange={e => updateCheckbox(e, 'advisoryRead')}
						                   onBlur={() => setExplicitField('advisoryRead', true)}/>
						<FocusWrappedLabel htmlFor="advisory-agreement" className={e3Style['mr-10']}>
							{t('flows.registration.reginfo.47a')}<a href="https://www.fcc.gov/sites/default/files/ten-digit_numbering_and_911_calls_for_internet-based_trs_-_what_they_mean_for_users.pdf" target="_blank">
							{t('flows.registration.reginfo.47b')}</a>{t('flows.registration.reginfo.47c')}
						</FocusWrappedLabel>
						{!!state.$errors.advisoryRead.length &&
							<div className={e3Style['sprint-alert--error']}>
								<div className={e3Style['alert--error_message']}>
									{state.$errors.advisoryRead.map((err, idx) => <p index={idx} id={'advisErr-' + idx}><span>{t(err.$message)}</span></p>)}
								</div>
							</div>
						}
					</FocusWrappedGrp>
				</div>
				<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['col-md-18']} ${e3Style['display-flex']} ${e3Style['sprint-checkbox']}`}>
					<FocusWrappedGrp baseAriaId="certErr" cptErrCount={state.$errors.certifyDisability.length}>
						<FocusWrappedInput type="checkbox" name="certifyDisability" id="eligibility-agreement"
						                   onChange={e => updateCheckbox(e, 'certifyDisability')}
						                   onBlur={() => setExplicitField('certifyDisability', true)}/>
						<FocusWrappedLabel htmlFor="eligibility-agreement" className={e3Style['mr-10']}>
							<strong>{t('flows.registration.reginfo.52')}</strong> {t('flows.registration.reginfo.53')} <strong>{t('flows.registration.reginfo.54')}</strong> {t('flows.registration.reginfo.49')}
						</FocusWrappedLabel>
						{!!state.$errors.certifyDisability.length &&
							<div className={e3Style['sprint-alert--error']}>
								<div className={e3Style['alert--error_message']}>
									{state.$errors.certifyDisability.map((err, idx) => <p index={idx} id={'certErr-' + idx}><span>{t(err.$message)}</span></p>)}
								</div>
							</div>
						}
					</FocusWrappedGrp>
				</div>

				<div className={e3Style.row}>
					<div className={`${e3Style.colStyle} ${e3Style['col-xs-24']} ${e3Style['display-flex']} ${e3Style['sprint-form-next']}`}>
						<button type="submit" onClick={validate} disabled={state.$auto_invalid}
						        className={`${e3Style.button} ${e3Style['button--minwidth-lg']} ${e3Style['button--lg']} ${e3Style['display-block']} ${e3Style['mt-40']} ${e3Style['mb-40']}`}>
							{t('button.submit')}
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default VerificationData;
