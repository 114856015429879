import React from "react";
import {useSelector} from "react-redux";
import {getCallState} from "../../../store/call";
import Call from "./call";
import NoNum from "./noNum";
import {getUserInfo, isAdmin, isLoggedIn} from "../../../store/user";
import RedirectPath from "../../util/redirectPath";
import {adminSelectUserQueryPath, adminUserDataShowUserPath, loginPath} from "../../layout/body";
import SpinnerMain from "./spinnerMain";

const Component = ({callData, userInfo}) => {
	if (isAdmin(userInfo)) {
		if (isLoggedIn(userInfo))
			return <RedirectPath path={adminUserDataShowUserPath}/>;
		else return <RedirectPath path={adminSelectUserQueryPath}/>;
	} else if (isLoggedIn(userInfo)) {
		if (callData && callData.initialized) {
			return callData.userPhoneNumber ? <Call/> : <NoNum/>;
		} else {
			return <SpinnerMain/>;
		}
	} else {
		return <RedirectPath path={loginPath}/>;
	}
};

const Home = () => {
	const callData = useSelector(getCallState);
	const userInfo = useSelector(getUserInfo);

	return <Component callData={callData} userInfo={userInfo}/>;
};

export default Home;
