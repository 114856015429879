import {createSlice} from "@reduxjs/toolkit";
import {apiDoCall} from "./api";
import {setStatusMsg, STATUS_ERROR} from "./bodyView";
import {createSelector} from "reselect";

const slice = createSlice({
	name: "userExtraData",
	initialState: {
		firstName: null,
		lastName: null,
		addressLine1: null,
		addressLine2: null,
		city: null,
		stateAbbrev: null,
		zip: null,
		zip4: null,
		email: null,
		stsUserId: null,
		suspended: null,
		dataValid: false
	},
	reducers: {
		setUserExtraData: (user, action) => {
			user.firstName = action.payload.response.response.firstName;
			user.lastName = action.payload.response.response.lastName;
			user.addressLine1 = action.payload.response.response.addressLine1;
			user.addressLine2 = action.payload.response.response.addressLine2;
			user.city = action.payload.response.response.city;
			user.stateAbbrev = action.payload.response.response.stateAbbrev;
			user.zip = action.payload.response.response.zip;
			user.zip4 = action.payload.response.response.zip4;
			user.email = action.payload.response.response.email;
			user.stsUserId = action.payload.response.response.stsUserId;
			user.suspended = action.payload.response.response.suspended;
			user.dataValid = true;
		},
		setCustomUserExtraData: (user, action) => {
			user.firstName = action.payload.preset.firstName;
			user.lastName = action.payload.preset.lastName;
			user.addressLine1 = action.payload.preset.addressLine1;
			user.addressLine2 = action.payload.preset.addressLine2;
			user.city = action.payload.preset.city;
			user.stateAbbrev = action.payload.preset.stateAbbrev;
			user.zip = action.payload.preset.zip;
			user.zip4 = action.payload.preset.zip4;
			user.email = action.payload.preset.email;
			user.stsUserId = action.payload.preset.stsUserId;
			user.suspended = action.payload.preset.suspended;
			user.dataValid = true;
		},
		resetUserExtraData: (user) => {
			user.firstName = null;
			user.lastName = null;
			user.addressLine1 = null;
			user.addressLine2 = null;
			user.city = null;
			user.stateAbbrev = null;
			user.zip = null;
			user.zip4 = null;
			user.email = null;
			user.stsUserId = null;
			user.suspended = null;
			user.dataValid = false;
		},
		setSuspended: (user, action) => {
			user.suspended = action.payload.custom.suspended;
		}
	}
});

export const {
	setUserExtraData,
	setCustomUserExtraData,
	resetUserExtraData,
	setSuspended
} = slice.actions;
export default slice.reducer;

export const readUserExtraData = (dispatch, csrfSessionNonce, successFunc, failureFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'getUserExtraData', csrfSessionNonce}
			},
			onSuccess: [{type: setUserExtraData.type}, successFunc],
			onError: [{type: setStatusMsg.type, preset: {statusMsg: 'generic.rgp.error', statusDisplay: STATUS_ERROR}}, failureFunc]
		})
	);
};

export const setUserSuspended = (dispatch, suspended) => {
	dispatch({type: setSuspended.type, payload: {custom: {suspended}}});
};

export const getUserExtraData = createSelector(
	state => state.entities.userExtraData,
	userExtraData => userExtraData
);
