import React from 'react';
import DisplaySettingsModal from "./displaySettings";
import WindowPrefsModal from "./windowPrefs";
import IncomingCallModal from "./incomingCall";
import WarnInCallModal from "./warnInCall";
import WarnDelUserModal from "./warnDelUser";
import OverrideIntrvModal from "./overrideIntrvs";
import ContactCustCareModal from "./contactCustCare";
import CallFinishedModal from "./callFinished";
import ForgotUserNameModal from "./forgotUserName";
import WarnInactivityModal from "./warnInactivity";
import ShowDisclaimerModal from "./showDisclaimer";
import SuspendUserModel from "./suspendUser";

const PopupGroup = () => (
	<>
		<DisplaySettingsModal/>
		<WindowPrefsModal/>
		<IncomingCallModal/>
		<WarnInCallModal/>
		<WarnDelUserModal/>
		<OverrideIntrvModal/>
		<ContactCustCareModal/>
		<CallFinishedModal/>
		<ForgotUserNameModal/>
		<WarnInactivityModal/>
		<ShowDisclaimerModal/>
		<SuspendUserModel/>
	</>
);

export default PopupGroup;
