import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
	name: "addressList",
	initialState: {
		currentLocId: -1,
		emerLocations: null,
		editAddress: null,
		formattedAddress: null,
		stateList: []
	},
	reducers: {
		setFormattedAddr: (addressList, action) => {
			addressList.formattedAddress = action.payload.preset;
		},

		setEditAddr: (addressList, action) => {
			addressList.editAddress = action.payload.preset;
		},

		loadList: (AddressList, action) => {
			AddressList.emerLocations = action.payload.preset;
		},

		loadStateList: (AddressList, action) => {
			AddressList.stateList = action.payload.preset;
		},

		setCurrentLocId: (AddressList, action) => {
			AddressList.currentLocId = action.payload.preset;
		},

		addAddress: (AddressList, action) => {
			if(AddressList.emerLocations === null)
				AddressList.emerLocations.push(action.payload.preset);
			else{
				const insertIdx = AddressList.emerLocations.findIndex(function(value){ 
			        return value.ADDRESSID > action.payload.preset.ADDRESSID;
			    });
				if(insertIdx < 0)
					AddressList.emerLocations.push(action.payload.preset);
				else
					AddressList.emerLocations.splice(insertIdx,0,action.payload.preset);
			}
		},

		updateAddress: (AddressList, action) => {
			AddressList.emerLocations = AddressList.emerLocations.map( thisLoc =>
			thisLoc.ADDRESSID == action.payload.preset.ADDRESSID ? 
				action.payload.preset
				:thisLoc);
		},
		
		deleteAddress: (AddressList, action) => {
			AddressList.emerLocations = AddressList.emerLocations.filter(function(value){ 
		        return value.ADDRESSID != action.payload.preset;
		    });
		}

	}
});

export const {
	loadList,
	loadStateList,
	setCurrentLocId,
	addAddress,
	updateAddress,
	deleteAddress,
	setFormattedAddr,
	setEditAddr
} = slice.actions;
export default slice.reducer;

export const setLocationList = (dispatch, newList) => {
	dispatch({type: loadList.type, payload: {preset: newList}});
};

export const setStateList = (dispatch, newList) => {
	dispatch({type: loadStateList.type, payload: {preset: newList}});
};

export const setCurrLocation = (dispatch, addressId) => {
	dispatch({type: setCurrentLocId.type, payload: {preset: addressId}});
};

export const addLocation = (dispatch, newLocation) => {
	dispatch({type: addAddress.type, payload: {preset: newLocation}});
};

export const updateLocation = (dispatch, thisLocation) => {
	dispatch({type: updateAddress.type, payload: {preset: thisLocation}});
};

export const delLocation = (dispatch, addressId) => {
	dispatch({type: deleteAddress.type, payload: {preset: addressId}});
};

export const getLocationList = createSelector(
		state => state.entities.addressList.emerLocations,
		emerLocations => emerLocations,
);

export const getStateList = createSelector(
		state => state.entities.addressList.stateList,
		stateList => stateList,
);

export const setFormattedAddress = (dispatch, newValue) => {
	dispatch({type: setFormattedAddr.type, payload: {preset: newValue}});
};

export const setEditAddress = (dispatch, newValue) => {
	dispatch({type: setEditAddr.type, payload: {preset: newValue}});
};

export const getCurrLocation = createSelector(
		state => state.entities.addressList.currentLocId,
		currentLocId => currentLocId
);

export const getFormattedAddress = createSelector(
		state => state.entities.addressList.formattedAddress,
		formattedAddress => formattedAddress
);

export const getEditAddress = createSelector(
		state => state.entities.addressList.editAddress,
		editAddress => editAddress
);