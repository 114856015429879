import React from "react";
import {getStatusMsgSelector, STATUS_ERROR, STATUS_NONE} from "../../store/bodyView";
import sprStyle from "../../../css/sprint-style.css";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const StatusMsg = () => {
	const { t } = useTranslation();
	const bodyView = useSelector(getStatusMsgSelector);

	return (
		<>
			{bodyView.statusDisplay !== STATUS_NONE &&
				<div className={bodyView.statusDisplay === STATUS_ERROR ? sprStyle['sprint-form-generic-error'] : sprStyle['sprint-form-generic-info']}>
					{t(bodyView.statusMsg)}
				</div>
			}
		</>
	);
};

export default StatusMsg;
